import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHoc from '@apollo/react-hoc';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDate: string;
  AWSDateTime: string;
  AWSEmail: string;
  AWSIPAddress: string;
  AWSJSON: string;
  AWSPhone: string;
  AWSTime: string;
  AWSTimestamp: number;
  AWSURL: string;
};

export enum AcceptanceMethod {
  MANUAL_ASSIGNMENT = 'MANUAL_ASSIGNMENT',
  MANUAL_OFFER = 'MANUAL_OFFER',
  STINT_MATCHER_OFFER = 'STINT_MATCHER_OFFER',
  WATERFALL_OFFER = 'WATERFALL_OFFER',
  STINT_MATCHER_TOP_UP_OFFER = 'STINT_MATCHER_TOP_UP_OFFER',
  REPEAT_STINTS_CAROUSEL = 'REPEAT_STINTS_CAROUSEL',
  REPEAT_STINTS_LIST = 'REPEAT_STINTS_LIST',
  ALL_STINTS_LIST = 'ALL_STINTS_LIST',
  ALL_STINTS_MAP = 'ALL_STINTS_MAP',
  TRAINED_STINTS_LIST = 'TRAINED_STINTS_LIST',
}

export type AddStinterCommitmentInput = {
  stinterId: Scalars['ID'];
  startDate: Scalars['AWSDate'];
  numberOfWeeks: Scalars['Int'];
  stintsPerWeek: Scalars['Int'];
  stintTemplateIds: Array<Scalars['ID']>;
};

export type AddStintersToPartnerPoolInput = {
  id: Scalars['ID'];
  partnerNodeId: Scalars['ID'];
  stinterIds: Array<Scalars['ID']>;
};

export type AddStintersToStintTemplatePoolInput = {
  stintTemplateId: Scalars['ID'];
  partnerNodeId: Scalars['ID'];
  stinterIds: Array<Scalars['ID']>;
};

export type Address = {
  __typename?: 'Address';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  modifiedAt: Scalars['AWSDateTime'];
  name: Scalars['String'];
  shortName?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  mapImage?: Maybe<Scalars['String']>;
  streetAndNumber?: Maybe<Scalars['String']>;
  streetAndNumber2?: Maybe<Scalars['String']>;
  isPrimaryContact: Scalars['Boolean'];
  isContact: Scalars['Boolean'];
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  cityFk?: Maybe<City>;
  countryFk?: Maybe<Country>;
  employerAddresses: Array<EmployerProfile>;
  stints: StintNodeTypeConnection;
  studentProfiles: Array<StudentProfile>;
  businessaddress: Array<Business>;
  mapUrl?: Maybe<Scalars['String']>;
  city_fk_id?: Maybe<Scalars['ID']>;
  country_fk_id?: Maybe<Scalars['ID']>;
};

export type AddressstintsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum AddressError {
  INVALID_DISPLAY_NAME = 'INVALID_DISPLAY_NAME',
  INVALID_PLACE_ID = 'INVALID_PLACE_ID',
}

export type AddressFilter = {
  name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  idIcontains?: Maybe<Scalars['String']>;
  cityFk_id?: Maybe<Scalars['ID']>;
  cityFk_id_isnull?: Maybe<Scalars['Boolean']>;
  countryFk_id?: Maybe<Scalars['ID']>;
  countryFk_id_isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_isnull?: Maybe<Scalars['Boolean']>;
  createdAt_gt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_gte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  createdAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_isnull?: Maybe<Scalars['Boolean']>;
  modifiedAt_gt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_gte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  name_lt?: Maybe<Scalars['String']>;
  name_search?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_isnull?: Maybe<Scalars['Boolean']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_iendswith?: Maybe<Scalars['String']>;
  name_istartswith?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  shortName_lt?: Maybe<Scalars['String']>;
  shortName_search?: Maybe<Scalars['String']>;
  shortName_lte?: Maybe<Scalars['String']>;
  shortName_isnull?: Maybe<Scalars['Boolean']>;
  shortName_gt?: Maybe<Scalars['String']>;
  shortName_gte?: Maybe<Scalars['String']>;
  shortName_iendswith?: Maybe<Scalars['String']>;
  shortName_istartswith?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  placeId_lt?: Maybe<Scalars['String']>;
  placeId_search?: Maybe<Scalars['String']>;
  placeId_lte?: Maybe<Scalars['String']>;
  placeId_isnull?: Maybe<Scalars['Boolean']>;
  placeId_gt?: Maybe<Scalars['String']>;
  placeId_gte?: Maybe<Scalars['String']>;
  placeId_iendswith?: Maybe<Scalars['String']>;
  placeId_istartswith?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  longitude_lt?: Maybe<Scalars['String']>;
  longitude_search?: Maybe<Scalars['String']>;
  longitude_lte?: Maybe<Scalars['String']>;
  longitude_isnull?: Maybe<Scalars['Boolean']>;
  longitude_gt?: Maybe<Scalars['String']>;
  longitude_gte?: Maybe<Scalars['String']>;
  longitude_iendswith?: Maybe<Scalars['String']>;
  longitude_istartswith?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  latitude_lt?: Maybe<Scalars['String']>;
  latitude_search?: Maybe<Scalars['String']>;
  latitude_lte?: Maybe<Scalars['String']>;
  latitude_isnull?: Maybe<Scalars['Boolean']>;
  latitude_gt?: Maybe<Scalars['String']>;
  latitude_gte?: Maybe<Scalars['String']>;
  latitude_iendswith?: Maybe<Scalars['String']>;
  latitude_istartswith?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postalCode_lt?: Maybe<Scalars['String']>;
  postalCode_search?: Maybe<Scalars['String']>;
  postalCode_lte?: Maybe<Scalars['String']>;
  postalCode_isnull?: Maybe<Scalars['Boolean']>;
  postalCode_gt?: Maybe<Scalars['String']>;
  postalCode_gte?: Maybe<Scalars['String']>;
  postalCode_iendswith?: Maybe<Scalars['String']>;
  postalCode_istartswith?: Maybe<Scalars['String']>;
  streetAndNumber?: Maybe<Scalars['String']>;
  streetAndNumber_lt?: Maybe<Scalars['String']>;
  streetAndNumber_search?: Maybe<Scalars['String']>;
  streetAndNumber_lte?: Maybe<Scalars['String']>;
  streetAndNumber_isnull?: Maybe<Scalars['Boolean']>;
  streetAndNumber_gt?: Maybe<Scalars['String']>;
  streetAndNumber_gte?: Maybe<Scalars['String']>;
  streetAndNumber_iendswith?: Maybe<Scalars['String']>;
  streetAndNumber_istartswith?: Maybe<Scalars['String']>;
  streetAndNumber2?: Maybe<Scalars['String']>;
  streetAndNumber2_lt?: Maybe<Scalars['String']>;
  streetAndNumber2_search?: Maybe<Scalars['String']>;
  streetAndNumber2_lte?: Maybe<Scalars['String']>;
  streetAndNumber2_isnull?: Maybe<Scalars['Boolean']>;
  streetAndNumber2_gt?: Maybe<Scalars['String']>;
  streetAndNumber2_gte?: Maybe<Scalars['String']>;
  streetAndNumber2_iendswith?: Maybe<Scalars['String']>;
  streetAndNumber2_istartswith?: Maybe<Scalars['String']>;
  isPrimaryContact?: Maybe<Scalars['Boolean']>;
  isPrimaryContact_lt?: Maybe<Scalars['Boolean']>;
  isPrimaryContact_lte?: Maybe<Scalars['Boolean']>;
  isPrimaryContact_isnull?: Maybe<Scalars['Boolean']>;
  isPrimaryContact_gt?: Maybe<Scalars['Boolean']>;
  isPrimaryContact_gte?: Maybe<Scalars['Boolean']>;
  isPrimaryContact_iendswith?: Maybe<Scalars['Boolean']>;
  isPrimaryContact_istartswith?: Maybe<Scalars['Boolean']>;
  isContact?: Maybe<Scalars['Boolean']>;
  isContact_lt?: Maybe<Scalars['Boolean']>;
  isContact_lte?: Maybe<Scalars['Boolean']>;
  isContact_isnull?: Maybe<Scalars['Boolean']>;
  isContact_gt?: Maybe<Scalars['Boolean']>;
  isContact_gte?: Maybe<Scalars['Boolean']>;
  isContact_iendswith?: Maybe<Scalars['Boolean']>;
  isContact_istartswith?: Maybe<Scalars['Boolean']>;
  city?: Maybe<Scalars['String']>;
  city_lt?: Maybe<Scalars['String']>;
  city_search?: Maybe<Scalars['String']>;
  city_lte?: Maybe<Scalars['String']>;
  city_isnull?: Maybe<Scalars['Boolean']>;
  city_gt?: Maybe<Scalars['String']>;
  city_gte?: Maybe<Scalars['String']>;
  city_iendswith?: Maybe<Scalars['String']>;
  city_istartswith?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  country_lt?: Maybe<Scalars['String']>;
  country_search?: Maybe<Scalars['String']>;
  country_lte?: Maybe<Scalars['String']>;
  country_isnull?: Maybe<Scalars['Boolean']>;
  country_gt?: Maybe<Scalars['String']>;
  country_gte?: Maybe<Scalars['String']>;
  country_iendswith?: Maybe<Scalars['String']>;
  country_istartswith?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  modifiedAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  shortName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  placeId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  longitude_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  latitude_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  postalCode_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  streetAndNumber_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  streetAndNumber2_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isPrimaryContact_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  isContact_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  city_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  country_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AddressInput = {
  displayName: Scalars['String'];
  placeId: Scalars['String'];
};

export type AdminCreateAppealInput = {
  offenceId: Scalars['ID'];
  status: AppealStatus;
  date: Scalars['AWSDateTime'];
  notes: Scalars['String'];
};

export type Agreement = {
  __typename?: 'Agreement';
  id: Scalars['ID'];
  agreementType: AgreementType;
  enabled: Scalars['Boolean'];
  documentUrl?: Maybe<Scalars['String']>;
  primaryPartnerNodeId: Scalars['ID'];
};

export enum AgreementError {
  DUPLICATE_AGREEMENT_TYPE_PARTNER_NODE_ID = 'DUPLICATE_AGREEMENT_TYPE_PARTNER_NODE_ID',
  INVALID_NODE_TYPE = 'INVALID_NODE_TYPE',
}

export enum AgreementType {
  EMPLOYEE_DISCLAIMER = 'EMPLOYEE_DISCLAIMER',
  ON_SITE_TRAINING = 'ON_SITE_TRAINING',
}

export type AppLifecycleEvent = {
  __typename?: 'AppLifecycleEvent';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  modifiedAt: Scalars['AWSDateTime'];
  deletedAt?: Maybe<Scalars['AWSDateTime']>;
  ref: Scalars['ID'];
  createdBy?: Maybe<StintUser>;
  user: StintUser;
  business?: Maybe<Business>;
  eventType: AppLifecycleEventEventType;
  created_by_id?: Maybe<Scalars['ID']>;
  user_id?: Maybe<Scalars['ID']>;
  business_id?: Maybe<Scalars['ID']>;
};

export enum AppLifecycleEventEventType {
  EVENT_APP_RESUME = 'EVENT_APP_RESUME',
  EVENT_APP_OPENED = 'EVENT_APP_OPENED',
  EVENT_APP_CLOSED = 'EVENT_APP_CLOSED',
  EVENT_APP_LOGIN = 'EVENT_APP_LOGIN',
  EVENT_APP_SWITCH_PROFILE = 'EVENT_APP_SWITCH_PROFILE',
}

export enum AppLifecycleEventEventTypes {
  EVENT_APP_RESUME = 'EVENT_APP_RESUME',
  EVENT_APP_OPENED = 'EVENT_APP_OPENED',
  EVENT_APP_CLOSED = 'EVENT_APP_CLOSED',
  EVENT_APP_LOGIN = 'EVENT_APP_LOGIN',
  EVENT_APP_SWITCH_PROFILE = 'EVENT_APP_SWITCH_PROFILE',
}

export type Appeal = Node & {
  __typename?: 'Appeal';
  id: Scalars['ID'];
  status: AppealStatus;
  date: Scalars['AWSDateTime'];
  notes?: Maybe<Scalars['String']>;
  decisionAt?: Maybe<Scalars['AWSDateTime']>;
  decisionBy?: Maybe<StintUser>;
};

export enum AppealStatus {
  PENDING = 'PENDING',
  UNSUCCESSFUL = 'UNSUCCESSFUL',
  SUCCESSFUL = 'SUCCESSFUL',
}

export type ApproveTrialStinterForStintTemplatePoolInput = {
  partnerNodeId: Scalars['ID'];
  stintTemplatePoolMemberId: Scalars['ID'];
  reasons: Array<Scalars['ID']>;
};

export type AutoPostingInfo = {
  __typename?: 'AutoPostingInfo';
  nextAutoPostingDate?: Maybe<Scalars['AWSDate']>;
  numberOfWeeks?: Maybe<Scalars['Int']>;
};

export type AvailabilityInput = {
  date: Scalars['AWSDate'];
  hours: Array<Maybe<Scalars['Int']>>;
};

export type AvailablePartnerMemberProfilesFilterInput = {
  email?: Maybe<Scalars['String']>;
  fullname?: Maybe<Scalars['String']>;
};

export type AvailablePartnerMemberProfilesInput = {
  partnerNodeId: Scalars['ID'];
  filterInput?: Maybe<AvailablePartnerMemberProfilesFilterInput>;
};

export type Business = {
  __typename?: 'Business';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  modifiedAt: Scalars['AWSDateTime'];
  deletedAt?: Maybe<Scalars['AWSDateTime']>;
  ref: Scalars['ID'];
  ltdName?: Maybe<Scalars['String']>;
  city?: Maybe<City>;
  freeStints?: Maybe<Scalars['Int']>;
  phoneNumber?: Maybe<Scalars['String']>;
  isBlockedByFinance: Scalars['Boolean'];
  exemptFromPaymentDetails: Scalars['Boolean'];
  exemptFromPaymentDetailsAt?: Maybe<Scalars['AWSDateTime']>;
  integrated: Scalars['Boolean'];
  unlockedLastMinuteStints: Scalars['Boolean'];
  address?: Maybe<Address>;
  owner?: Maybe<StintUser>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  branchCount?: Maybe<Scalars['Int']>;
  status: BusinessStatus;
  salesRepresentitive?: Maybe<StintUser>;
  accountManager?: Maybe<StintUser>;
  allowRampup: Scalars['Boolean'];
  businessType: BusinessBusinessType;
  qrCodeId: Scalars['ID'];
  specialNotes?: Maybe<Scalars['String']>;
  internalRanking?: Maybe<Scalars['Int']>;
  onboardingRanking?: Maybe<Scalars['Int']>;
  preferredPaymentMethod: BusinessPreferredPaymentMethod;
  createdBy?: Maybe<BusinessCreatedBy>;
  controlCheck: Scalars['Boolean'];
  repeatStintersEnabled: Scalars['Boolean'];
  name: Scalars['String'];
  creditControl: Array<BusinessCreditControlEvent>;
  stints?: Maybe<Array<Maybe<Stint>>>;
  logo?: Maybe<Scalars['String']>;
  averageRating?: Maybe<Scalars['Float']>;
  /** @deprecated Drop in favor of Address.placeId */
  placeId?: Maybe<Scalars['String']>;
  /** @deprecated Drop in favor of Address.name */
  displayAddress?: Maybe<Scalars['String']>;
  stintopedia?: Maybe<Stintopedia>;
  isPaymentDetailsBlocked?: Maybe<Scalars['Boolean']>;
  canPostStint?: Maybe<BusinessCanPostStintMessageType>;
  stintWeekTemplates?: Maybe<Array<Maybe<StintWeekTemplate>>>;
  stintTemplates?: Maybe<Array<Maybe<StintTemplate>>>;
  rateOverride?: Maybe<RateOverride>;
  rate?: Maybe<Scalars['String']>;
  isAddressBlocked?: Maybe<Scalars['Boolean']>;
  rates: BusinessRatesType;
  autoPostingInfo?: Maybe<AutoPostingInfo>;
  permissionsGroup?: Maybe<Group>;
  city_id?: Maybe<Scalars['ID']>;
  address_id?: Maybe<Scalars['ID']>;
  owner_id?: Maybe<Scalars['ID']>;
  sales_representitive_id?: Maybe<Scalars['ID']>;
  account_manager_id?: Maybe<Scalars['ID']>;
};

export type BusinesscanPostStintArgs = {
  dateFrom?: Maybe<Scalars['AWSDateTime']>;
  dateTo?: Maybe<Scalars['AWSDateTime']>;
};

export enum BusinessBusinessType {
  BUSINESS = 'BUSINESS',
  INDIVIDUAL = 'INDIVIDUAL',
}

export type BusinessCanPostStintMessageType = {
  __typename?: 'BusinessCanPostStintMessageType';
  canPostStint?: Maybe<Scalars['Boolean']>;
  errorType?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export enum BusinessCreatedBy {
  BUSINESS_ONBOARDING_API = 'BUSINESS_ONBOARDING_API',
}

export type BusinessCreditControlEvent = {
  __typename?: 'BusinessCreditControlEvent';
  id: Scalars['ID'];
  modifiedAt: Scalars['AWSDateTime'];
  business?: Maybe<Business>;
  controlType?: Maybe<BusinessCreditControlEventControlType>;
  duration?: Maybe<Scalars['Int']>;
  dateBlocked?: Maybe<Scalars['String']>;
  dateUnblocked?: Maybe<Scalars['String']>;
  blockedBy?: Maybe<StintUser>;
  unblockedBy?: Maybe<StintUser>;
  created_by_id?: Maybe<Scalars['ID']>;
  business_id?: Maybe<Scalars['ID']>;
};

export enum BusinessCreditControlEventControlType {
  BLOCKED = 'BLOCKED',
  UNBLOCKED = 'UNBLOCKED',
}

export type BusinessCreditControlEventFilter = {
  controlType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  idIcontains?: Maybe<Scalars['String']>;
  createdBy_id?: Maybe<Scalars['ID']>;
  createdBy_id_isnull?: Maybe<Scalars['Boolean']>;
  eventPtr_id?: Maybe<Scalars['ID']>;
  eventPtr_id_isnull?: Maybe<Scalars['Boolean']>;
  business_id?: Maybe<Scalars['ID']>;
  business_id_isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_isnull?: Maybe<Scalars['Boolean']>;
  createdAt_gt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_gte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  createdAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_isnull?: Maybe<Scalars['Boolean']>;
  modifiedAt_gt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_gte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  controlType_lt?: Maybe<Scalars['String']>;
  controlType_search?: Maybe<Scalars['String']>;
  controlType_lte?: Maybe<Scalars['String']>;
  controlType_isnull?: Maybe<Scalars['Boolean']>;
  controlType_gt?: Maybe<Scalars['String']>;
  controlType_gte?: Maybe<Scalars['String']>;
  controlType_iendswith?: Maybe<Scalars['String']>;
  controlType_istartswith?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  modifiedAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  controlType_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum BusinessDiscountBadge {
  PIZZA_EXPRESS = 'PIZZA_EXPRESS',
}

export enum BusinessError {
  INVALID_NAME = 'INVALID_NAME',
  CITY_ERROR = 'CITY_ERROR',
}

export type BusinessFilter = {
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  q?: Maybe<Scalars['String']>;
  controlCheck?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  idIcontains?: Maybe<Scalars['String']>;
  name_startswith?: Maybe<Scalars['String']>;
  search_phrase?: Maybe<Scalars['String']>;
  city_id?: Maybe<Scalars['ID']>;
  city_id_isnull?: Maybe<Scalars['Boolean']>;
  address_id?: Maybe<Scalars['ID']>;
  address_id_isnull?: Maybe<Scalars['Boolean']>;
  owner_id?: Maybe<Scalars['ID']>;
  owner_id_isnull?: Maybe<Scalars['Boolean']>;
  salesRepresentitive_id?: Maybe<Scalars['ID']>;
  salesRepresentitive_id_isnull?: Maybe<Scalars['Boolean']>;
  accountManager_id?: Maybe<Scalars['ID']>;
  accountManager_id_isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_isnull?: Maybe<Scalars['Boolean']>;
  createdAt_gt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_gte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  createdAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_isnull?: Maybe<Scalars['Boolean']>;
  modifiedAt_gt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_gte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  ltdName?: Maybe<Scalars['String']>;
  ltdName_lt?: Maybe<Scalars['String']>;
  ltdName_search?: Maybe<Scalars['String']>;
  ltdName_lte?: Maybe<Scalars['String']>;
  ltdName_isnull?: Maybe<Scalars['Boolean']>;
  ltdName_gt?: Maybe<Scalars['String']>;
  ltdName_gte?: Maybe<Scalars['String']>;
  ltdName_iendswith?: Maybe<Scalars['String']>;
  ltdName_istartswith?: Maybe<Scalars['String']>;
  freeStints?: Maybe<Scalars['Float']>;
  freeStints_lt?: Maybe<Scalars['Float']>;
  freeStints_lte?: Maybe<Scalars['Float']>;
  freeStints_isnull?: Maybe<Scalars['Boolean']>;
  freeStints_gt?: Maybe<Scalars['Float']>;
  freeStints_gte?: Maybe<Scalars['Float']>;
  freeStints_iendswith?: Maybe<Scalars['Float']>;
  freeStints_istartswith?: Maybe<Scalars['Float']>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumber_lt?: Maybe<Scalars['String']>;
  phoneNumber_search?: Maybe<Scalars['String']>;
  phoneNumber_lte?: Maybe<Scalars['String']>;
  phoneNumber_isnull?: Maybe<Scalars['Boolean']>;
  phoneNumber_gt?: Maybe<Scalars['String']>;
  phoneNumber_gte?: Maybe<Scalars['String']>;
  phoneNumber_iendswith?: Maybe<Scalars['String']>;
  phoneNumber_istartswith?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Float']>;
  rating_lt?: Maybe<Scalars['Float']>;
  rating_lte?: Maybe<Scalars['Float']>;
  rating_isnull?: Maybe<Scalars['Boolean']>;
  rating_gt?: Maybe<Scalars['Float']>;
  rating_gte?: Maybe<Scalars['Float']>;
  rating_iendswith?: Maybe<Scalars['Float']>;
  rating_istartswith?: Maybe<Scalars['Float']>;
  isBlockedByFinance?: Maybe<Scalars['Boolean']>;
  isBlockedByFinance_lt?: Maybe<Scalars['Boolean']>;
  isBlockedByFinance_lte?: Maybe<Scalars['Boolean']>;
  isBlockedByFinance_isnull?: Maybe<Scalars['Boolean']>;
  isBlockedByFinance_gt?: Maybe<Scalars['Boolean']>;
  isBlockedByFinance_gte?: Maybe<Scalars['Boolean']>;
  isBlockedByFinance_iendswith?: Maybe<Scalars['Boolean']>;
  isBlockedByFinance_istartswith?: Maybe<Scalars['Boolean']>;
  exemptFromPaymentDetails?: Maybe<Scalars['Boolean']>;
  exemptFromPaymentDetails_lt?: Maybe<Scalars['Boolean']>;
  exemptFromPaymentDetails_lte?: Maybe<Scalars['Boolean']>;
  exemptFromPaymentDetails_isnull?: Maybe<Scalars['Boolean']>;
  exemptFromPaymentDetails_gt?: Maybe<Scalars['Boolean']>;
  exemptFromPaymentDetails_gte?: Maybe<Scalars['Boolean']>;
  exemptFromPaymentDetails_iendswith?: Maybe<Scalars['Boolean']>;
  exemptFromPaymentDetails_istartswith?: Maybe<Scalars['Boolean']>;
  exemptFromPaymentDetailsAt?: Maybe<Scalars['AWSDateTime']>;
  exemptFromPaymentDetailsAt_lt?: Maybe<Scalars['AWSDateTime']>;
  exemptFromPaymentDetailsAt_lte?: Maybe<Scalars['AWSDateTime']>;
  exemptFromPaymentDetailsAt_isnull?: Maybe<Scalars['Boolean']>;
  exemptFromPaymentDetailsAt_gt?: Maybe<Scalars['AWSDateTime']>;
  exemptFromPaymentDetailsAt_gte?: Maybe<Scalars['AWSDateTime']>;
  exemptFromPaymentDetailsAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  exemptFromPaymentDetailsAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  integrated?: Maybe<Scalars['Boolean']>;
  integrated_lt?: Maybe<Scalars['Boolean']>;
  integrated_lte?: Maybe<Scalars['Boolean']>;
  integrated_isnull?: Maybe<Scalars['Boolean']>;
  integrated_gt?: Maybe<Scalars['Boolean']>;
  integrated_gte?: Maybe<Scalars['Boolean']>;
  integrated_iendswith?: Maybe<Scalars['Boolean']>;
  integrated_istartswith?: Maybe<Scalars['Boolean']>;
  unlockedLastMinuteStints?: Maybe<Scalars['Boolean']>;
  unlockedLastMinuteStints_lt?: Maybe<Scalars['Boolean']>;
  unlockedLastMinuteStints_lte?: Maybe<Scalars['Boolean']>;
  unlockedLastMinuteStints_isnull?: Maybe<Scalars['Boolean']>;
  unlockedLastMinuteStints_gt?: Maybe<Scalars['Boolean']>;
  unlockedLastMinuteStints_gte?: Maybe<Scalars['Boolean']>;
  unlockedLastMinuteStints_iendswith?: Maybe<Scalars['Boolean']>;
  unlockedLastMinuteStints_istartswith?: Maybe<Scalars['Boolean']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeCustomerId_lt?: Maybe<Scalars['String']>;
  stripeCustomerId_search?: Maybe<Scalars['String']>;
  stripeCustomerId_lte?: Maybe<Scalars['String']>;
  stripeCustomerId_isnull?: Maybe<Scalars['Boolean']>;
  stripeCustomerId_gt?: Maybe<Scalars['String']>;
  stripeCustomerId_gte?: Maybe<Scalars['String']>;
  stripeCustomerId_iendswith?: Maybe<Scalars['String']>;
  stripeCustomerId_istartswith?: Maybe<Scalars['String']>;
  branchCount?: Maybe<Scalars['Float']>;
  branchCount_lt?: Maybe<Scalars['Float']>;
  branchCount_lte?: Maybe<Scalars['Float']>;
  branchCount_isnull?: Maybe<Scalars['Boolean']>;
  branchCount_gt?: Maybe<Scalars['Float']>;
  branchCount_gte?: Maybe<Scalars['Float']>;
  branchCount_iendswith?: Maybe<Scalars['Float']>;
  branchCount_istartswith?: Maybe<Scalars['Float']>;
  status_lt?: Maybe<Scalars['String']>;
  status_search?: Maybe<Scalars['String']>;
  status_lte?: Maybe<Scalars['String']>;
  status_isnull?: Maybe<Scalars['Boolean']>;
  status_gt?: Maybe<Scalars['String']>;
  status_gte?: Maybe<Scalars['String']>;
  status_iendswith?: Maybe<Scalars['String']>;
  status_istartswith?: Maybe<Scalars['String']>;
  allowRampup?: Maybe<Scalars['Boolean']>;
  allowRampup_lt?: Maybe<Scalars['Boolean']>;
  allowRampup_lte?: Maybe<Scalars['Boolean']>;
  allowRampup_isnull?: Maybe<Scalars['Boolean']>;
  allowRampup_gt?: Maybe<Scalars['Boolean']>;
  allowRampup_gte?: Maybe<Scalars['Boolean']>;
  allowRampup_iendswith?: Maybe<Scalars['Boolean']>;
  allowRampup_istartswith?: Maybe<Scalars['Boolean']>;
  businessType?: Maybe<Scalars['String']>;
  businessType_lt?: Maybe<Scalars['String']>;
  businessType_search?: Maybe<Scalars['String']>;
  businessType_lte?: Maybe<Scalars['String']>;
  businessType_isnull?: Maybe<Scalars['Boolean']>;
  businessType_gt?: Maybe<Scalars['String']>;
  businessType_gte?: Maybe<Scalars['String']>;
  businessType_iendswith?: Maybe<Scalars['String']>;
  businessType_istartswith?: Maybe<Scalars['String']>;
  qrCodeId?: Maybe<Scalars['ID']>;
  qrCodeId_lt?: Maybe<Scalars['ID']>;
  qrCodeId_lte?: Maybe<Scalars['ID']>;
  qrCodeId_isnull?: Maybe<Scalars['Boolean']>;
  qrCodeId_gt?: Maybe<Scalars['ID']>;
  qrCodeId_gte?: Maybe<Scalars['ID']>;
  qrCodeId_iendswith?: Maybe<Scalars['ID']>;
  qrCodeId_istartswith?: Maybe<Scalars['ID']>;
  specialNotes?: Maybe<Scalars['String']>;
  specialNotes_lt?: Maybe<Scalars['String']>;
  specialNotes_search?: Maybe<Scalars['String']>;
  specialNotes_lte?: Maybe<Scalars['String']>;
  specialNotes_isnull?: Maybe<Scalars['Boolean']>;
  specialNotes_gt?: Maybe<Scalars['String']>;
  specialNotes_gte?: Maybe<Scalars['String']>;
  specialNotes_iendswith?: Maybe<Scalars['String']>;
  specialNotes_istartswith?: Maybe<Scalars['String']>;
  internalRanking?: Maybe<Scalars['Float']>;
  internalRanking_lt?: Maybe<Scalars['Float']>;
  internalRanking_lte?: Maybe<Scalars['Float']>;
  internalRanking_isnull?: Maybe<Scalars['Boolean']>;
  internalRanking_gt?: Maybe<Scalars['Float']>;
  internalRanking_gte?: Maybe<Scalars['Float']>;
  internalRanking_iendswith?: Maybe<Scalars['Float']>;
  internalRanking_istartswith?: Maybe<Scalars['Float']>;
  onboardingRanking?: Maybe<Scalars['Float']>;
  onboardingRanking_lt?: Maybe<Scalars['Float']>;
  onboardingRanking_lte?: Maybe<Scalars['Float']>;
  onboardingRanking_isnull?: Maybe<Scalars['Boolean']>;
  onboardingRanking_gt?: Maybe<Scalars['Float']>;
  onboardingRanking_gte?: Maybe<Scalars['Float']>;
  onboardingRanking_iendswith?: Maybe<Scalars['Float']>;
  onboardingRanking_istartswith?: Maybe<Scalars['Float']>;
  firebaseRef?: Maybe<Scalars['String']>;
  firebaseRef_lt?: Maybe<Scalars['String']>;
  firebaseRef_search?: Maybe<Scalars['String']>;
  firebaseRef_lte?: Maybe<Scalars['String']>;
  firebaseRef_isnull?: Maybe<Scalars['Boolean']>;
  firebaseRef_gt?: Maybe<Scalars['String']>;
  firebaseRef_gte?: Maybe<Scalars['String']>;
  firebaseRef_iendswith?: Maybe<Scalars['String']>;
  firebaseRef_istartswith?: Maybe<Scalars['String']>;
  preferredPaymentMethod?: Maybe<Scalars['String']>;
  preferredPaymentMethod_lt?: Maybe<Scalars['String']>;
  preferredPaymentMethod_search?: Maybe<Scalars['String']>;
  preferredPaymentMethod_lte?: Maybe<Scalars['String']>;
  preferredPaymentMethod_isnull?: Maybe<Scalars['Boolean']>;
  preferredPaymentMethod_gt?: Maybe<Scalars['String']>;
  preferredPaymentMethod_gte?: Maybe<Scalars['String']>;
  preferredPaymentMethod_iendswith?: Maybe<Scalars['String']>;
  preferredPaymentMethod_istartswith?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_lt?: Maybe<Scalars['String']>;
  createdBy_search?: Maybe<Scalars['String']>;
  createdBy_lte?: Maybe<Scalars['String']>;
  createdBy_isnull?: Maybe<Scalars['Boolean']>;
  createdBy_gt?: Maybe<Scalars['String']>;
  createdBy_gte?: Maybe<Scalars['String']>;
  createdBy_iendswith?: Maybe<Scalars['String']>;
  createdBy_istartswith?: Maybe<Scalars['String']>;
  controlCheck_lt?: Maybe<Scalars['Boolean']>;
  controlCheck_lte?: Maybe<Scalars['Boolean']>;
  controlCheck_isnull?: Maybe<Scalars['Boolean']>;
  controlCheck_gt?: Maybe<Scalars['Boolean']>;
  controlCheck_gte?: Maybe<Scalars['Boolean']>;
  controlCheck_iendswith?: Maybe<Scalars['Boolean']>;
  controlCheck_istartswith?: Maybe<Scalars['Boolean']>;
  repeatStintersEnabled?: Maybe<Scalars['Boolean']>;
  repeatStintersEnabled_lt?: Maybe<Scalars['Boolean']>;
  repeatStintersEnabled_lte?: Maybe<Scalars['Boolean']>;
  repeatStintersEnabled_isnull?: Maybe<Scalars['Boolean']>;
  repeatStintersEnabled_gt?: Maybe<Scalars['Boolean']>;
  repeatStintersEnabled_gte?: Maybe<Scalars['Boolean']>;
  repeatStintersEnabled_iendswith?: Maybe<Scalars['Boolean']>;
  repeatStintersEnabled_istartswith?: Maybe<Scalars['Boolean']>;
  name_lt?: Maybe<Scalars['String']>;
  name_search?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_isnull?: Maybe<Scalars['Boolean']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_iendswith?: Maybe<Scalars['String']>;
  name_istartswith?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  paymentMethods_ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  cardPaymentMethods_ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  modifiedAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  ltdName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  freeStints_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  phoneNumber_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  rating_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  isBlockedByFinance_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  exemptFromPaymentDetails_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  exemptFromPaymentDetailsAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  integrated_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  unlockedLastMinuteStints_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  stripeCustomerId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  branchCount_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  status_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  allowRampup_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  businessType_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  qrCodeId_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  specialNotes_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  internalRanking_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  onboardingRanking_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  firebaseRef_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferredPaymentMethod_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  controlCheck_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  repeatStintersEnabled_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BusinessIntelligenceSettings = {
  __typename?: 'BusinessIntelligenceSettings';
  labourOptimizationEnabled: Scalars['Boolean'];
  autoschedulingEnabled: Scalars['Boolean'];
  forecastType: ForecastType;
};

export type BusinessIntelligenceSettingsInput = {
  labourOptimizationEnabled: Scalars['Boolean'];
  autoschedulingEnabled: Scalars['Boolean'];
  forecastType: ForecastType;
};

export type BusinessListType = {
  __typename?: 'BusinessListType';
  pageInfo: PageInfoType;
  objects?: Maybe<Array<Maybe<Business>>>;
};

export enum BusinessPreferredPaymentMethod {
  CARD = 'CARD',
  DIRECT_DEBIT = 'DIRECT_DEBIT',
}

export type BusinessRateOverrideInput = {
  rate?: Maybe<Scalars['String']>;
};

export type BusinessRatesType = {
  __typename?: 'BusinessRatesType';
  stinterHourlyRate?: Maybe<Scalars['Float']>;
  businessHourlyFee?: Maybe<Scalars['Float']>;
  stintHourlyFee?: Maybe<Scalars['Float']>;
};

export type BusinessReview = {
  __typename?: 'BusinessReview';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  modifiedAt: Scalars['AWSDateTime'];
  deletedAt?: Maybe<Scalars['AWSDateTime']>;
  ref: Scalars['ID'];
  reviewType?: Maybe<ReviewReviewType>;
  reviewDate?: Maybe<Scalars['AWSDateTime']>;
  reviewer?: Maybe<StintUser>;
  rating?: Maybe<Scalars['Float']>;
  feedback?: Maybe<Scalars['String']>;
  hidden: Scalars['Boolean'];
  business: Business;
  stint: StintNode;
  tags: Array<Tag>;
  visible: Scalars['Boolean'];
  reviewer_id?: Maybe<Scalars['ID']>;
  business_id?: Maybe<Scalars['ID']>;
  stint_id?: Maybe<Scalars['ID']>;
};

export enum BusinessStatus {
  ACTIVE = 'ACTIVE',
  INTEGRATED = 'INTEGRATED',
  DORMANT = 'DORMANT',
}

export enum BusinessStatusChoices {
  ACTIVE = 'ACTIVE',
  INTEGRATED = 'INTEGRATED',
  DORMANT = 'DORMANT',
}

export type CalendarStint = {
  __typename?: 'CalendarStint';
  id: Scalars['ID'];
  name: Scalars['String'];
  trained: Scalars['Boolean'];
  dateFrom?: Maybe<Scalars['AWSDateTime']>;
  dateTo?: Maybe<Scalars['AWSDateTime']>;
  status?: Maybe<Scalars['Int']>;
  postMethod?: Maybe<PostMethod>;
  role?: Maybe<Scalars['String']>;
  business?: Maybe<Business>;
  student?: Maybe<StintUser>;
  stintTimesNormalized: Scalars['String'];
  dateFromPublic: Scalars['String'];
  stinterPay: StinterPay;
  studentReviewRating?: Maybe<Scalars['Int']>;
  businessReview?: Maybe<BusinessReview>;
  site: PartnerSiteProfile;
  business_id?: Maybe<Scalars['ID']>;
  address_id?: Maybe<Scalars['ID']>;
  city_id?: Maybe<Scalars['ID']>;
  selected_payment_method_id?: Maybe<Scalars['ID']>;
  selected_card_payment_method_id?: Maybe<Scalars['ID']>;
  student_id?: Maybe<Scalars['ID']>;
  business_role_id?: Maybe<Scalars['ID']>;
  business_category_id?: Maybe<Scalars['ID']>;
  cancelled_by_id?: Maybe<Scalars['ID']>;
  created_by_user_id?: Maybe<Scalars['ID']>;
  stint_template_id?: Maybe<Scalars['ID']>;
};

export type CalendarStintListType = {
  __typename?: 'CalendarStintListType';
  pageInfo: PageInfoType;
  objects?: Maybe<Array<Maybe<CalendarStint>>>;
};

export type CancelStintAsStinterInput = {
  stintId: Scalars['ID'];
};

export type CancelStints = {
  __typename?: 'CancelStints';
  entries?: Maybe<Array<Maybe<Stint>>>;
};

export type CardPaymentMethod = {
  __typename?: 'CardPaymentMethod';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  modifiedAt: Scalars['AWSDateTime'];
  deletedAt?: Maybe<Scalars['AWSDateTime']>;
  ref: Scalars['ID'];
  nameOnCard?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isPrimaryMethod: Scalars['Boolean'];
  lastFourDigits?: Maybe<Scalars['String']>;
  employerCardPaymentMethods: Array<EmployerProfile>;
  stints: StintNodeTypeConnection;
  businesses: Array<Business>;
};

export type CardPaymentMethodstintsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CardPaymentMethodFilter = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  idIcontains?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_isnull?: Maybe<Scalars['Boolean']>;
  createdAt_gt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_gte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  createdAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_isnull?: Maybe<Scalars['Boolean']>;
  modifiedAt_gt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_gte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  nameOnCard?: Maybe<Scalars['String']>;
  nameOnCard_lt?: Maybe<Scalars['String']>;
  nameOnCard_search?: Maybe<Scalars['String']>;
  nameOnCard_lte?: Maybe<Scalars['String']>;
  nameOnCard_isnull?: Maybe<Scalars['Boolean']>;
  nameOnCard_gt?: Maybe<Scalars['String']>;
  nameOnCard_gte?: Maybe<Scalars['String']>;
  nameOnCard_iendswith?: Maybe<Scalars['String']>;
  nameOnCard_istartswith?: Maybe<Scalars['String']>;
  description_lt?: Maybe<Scalars['String']>;
  description_search?: Maybe<Scalars['String']>;
  description_lte?: Maybe<Scalars['String']>;
  description_isnull?: Maybe<Scalars['Boolean']>;
  description_gt?: Maybe<Scalars['String']>;
  description_gte?: Maybe<Scalars['String']>;
  description_iendswith?: Maybe<Scalars['String']>;
  description_istartswith?: Maybe<Scalars['String']>;
  isPrimaryMethod?: Maybe<Scalars['Boolean']>;
  isPrimaryMethod_lt?: Maybe<Scalars['Boolean']>;
  isPrimaryMethod_lte?: Maybe<Scalars['Boolean']>;
  isPrimaryMethod_isnull?: Maybe<Scalars['Boolean']>;
  isPrimaryMethod_gt?: Maybe<Scalars['Boolean']>;
  isPrimaryMethod_gte?: Maybe<Scalars['Boolean']>;
  isPrimaryMethod_iendswith?: Maybe<Scalars['Boolean']>;
  isPrimaryMethod_istartswith?: Maybe<Scalars['Boolean']>;
  lastFourDigits?: Maybe<Scalars['String']>;
  lastFourDigits_lt?: Maybe<Scalars['String']>;
  lastFourDigits_search?: Maybe<Scalars['String']>;
  lastFourDigits_lte?: Maybe<Scalars['String']>;
  lastFourDigits_isnull?: Maybe<Scalars['Boolean']>;
  lastFourDigits_gt?: Maybe<Scalars['String']>;
  lastFourDigits_gte?: Maybe<Scalars['String']>;
  lastFourDigits_iendswith?: Maybe<Scalars['String']>;
  lastFourDigits_istartswith?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  modifiedAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  nameOnCard_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isPrimaryMethod_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  lastFourDigits_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Characteristic = {
  __typename?: 'Characteristic';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  modifiedAt: Scalars['AWSDateTime'];
  deletedAt?: Maybe<Scalars['AWSDateTime']>;
  ref: Scalars['ID'];
  name: Scalars['String'];
};

export type CharacteristicFilter = {
  name?: Maybe<Scalars['String']>;
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  idIcontains?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_isnull?: Maybe<Scalars['Boolean']>;
  createdAt_gt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_gte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  createdAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_isnull?: Maybe<Scalars['Boolean']>;
  modifiedAt_gt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_gte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  name_lt?: Maybe<Scalars['String']>;
  name_search?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_isnull?: Maybe<Scalars['Boolean']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_iendswith?: Maybe<Scalars['String']>;
  name_istartswith?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  modifiedAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CheckAddressAvailability = {
  __typename?: 'CheckAddressAvailability';
  isActive?: Maybe<Scalars['Boolean']>;
  city?: Maybe<City>;
};

export type CheckInStint = {
  __typename?: 'CheckInStint';
  stint?: Maybe<Stint>;
  business?: Maybe<Business>;
};

export type CheckoutSession = {
  __typename?: 'CheckoutSession';
  id?: Maybe<Scalars['String']>;
  mode?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  successUrl?: Maybe<Scalars['String']>;
  cancelUrl?: Maybe<Scalars['String']>;
  adaptedSuccessUrl?: Maybe<Scalars['String']>;
};

export type City = {
  __typename?: 'City';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  modifiedAt: Scalars['AWSDateTime'];
  deletedAt?: Maybe<Scalars['AWSDateTime']>;
  ref: Scalars['ID'];
  region?: Maybe<Region>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  isAvailabilityEnabled: Scalars['Boolean'];
  shortCode?: Maybe<Scalars['String']>;
  sessionUrl?: Maybe<Scalars['String']>;
  baseFee: Scalars['Float'];
  baseFeeStudent: Scalars['Float'];
  repeatStintersEnabled: Scalars['Boolean'];
  postalCodes?: Maybe<Array<Maybe<PostalCode>>>;
  postalCodeIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  regionId?: Maybe<Scalars['ID']>;
  studentSavedPlaces?: Maybe<Array<Maybe<StudentSavedPlace>>>;
  stintUserAvailabilities?: Maybe<Array<Maybe<StintUserAvailability>>>;
};

export type CityFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  idIcontains?: Maybe<Scalars['String']>;
  name_startswith?: Maybe<Scalars['String']>;
  postalCodeId?: Maybe<Scalars['String']>;
  region_id?: Maybe<Scalars['ID']>;
  region_id_isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_isnull?: Maybe<Scalars['Boolean']>;
  createdAt_gt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_gte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  createdAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_isnull?: Maybe<Scalars['Boolean']>;
  modifiedAt_gt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_gte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  name?: Maybe<Scalars['String']>;
  name_lt?: Maybe<Scalars['String']>;
  name_search?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_isnull?: Maybe<Scalars['Boolean']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_iendswith?: Maybe<Scalars['String']>;
  name_istartswith?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  state_lt?: Maybe<Scalars['String']>;
  state_search?: Maybe<Scalars['String']>;
  state_lte?: Maybe<Scalars['String']>;
  state_isnull?: Maybe<Scalars['Boolean']>;
  state_gt?: Maybe<Scalars['String']>;
  state_gte?: Maybe<Scalars['String']>;
  state_iendswith?: Maybe<Scalars['String']>;
  state_istartswith?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isActive_lt?: Maybe<Scalars['Boolean']>;
  isActive_lte?: Maybe<Scalars['Boolean']>;
  isActive_isnull?: Maybe<Scalars['Boolean']>;
  isActive_gt?: Maybe<Scalars['Boolean']>;
  isActive_gte?: Maybe<Scalars['Boolean']>;
  isActive_iendswith?: Maybe<Scalars['Boolean']>;
  isActive_istartswith?: Maybe<Scalars['Boolean']>;
  isAvailabilityEnabled?: Maybe<Scalars['Boolean']>;
  isAvailabilityEnabled_lt?: Maybe<Scalars['Boolean']>;
  isAvailabilityEnabled_lte?: Maybe<Scalars['Boolean']>;
  isAvailabilityEnabled_isnull?: Maybe<Scalars['Boolean']>;
  isAvailabilityEnabled_gt?: Maybe<Scalars['Boolean']>;
  isAvailabilityEnabled_gte?: Maybe<Scalars['Boolean']>;
  isAvailabilityEnabled_iendswith?: Maybe<Scalars['Boolean']>;
  isAvailabilityEnabled_istartswith?: Maybe<Scalars['Boolean']>;
  shortCode?: Maybe<Scalars['String']>;
  shortCode_lt?: Maybe<Scalars['String']>;
  shortCode_search?: Maybe<Scalars['String']>;
  shortCode_lte?: Maybe<Scalars['String']>;
  shortCode_isnull?: Maybe<Scalars['Boolean']>;
  shortCode_gt?: Maybe<Scalars['String']>;
  shortCode_gte?: Maybe<Scalars['String']>;
  shortCode_iendswith?: Maybe<Scalars['String']>;
  shortCode_istartswith?: Maybe<Scalars['String']>;
  sessionUrl?: Maybe<Scalars['String']>;
  sessionUrl_lt?: Maybe<Scalars['String']>;
  sessionUrl_search?: Maybe<Scalars['String']>;
  sessionUrl_lte?: Maybe<Scalars['String']>;
  sessionUrl_isnull?: Maybe<Scalars['Boolean']>;
  sessionUrl_gt?: Maybe<Scalars['String']>;
  sessionUrl_gte?: Maybe<Scalars['String']>;
  sessionUrl_iendswith?: Maybe<Scalars['String']>;
  sessionUrl_istartswith?: Maybe<Scalars['String']>;
  baseFee?: Maybe<Scalars['Float']>;
  baseFee_lt?: Maybe<Scalars['Float']>;
  baseFee_lte?: Maybe<Scalars['Float']>;
  baseFee_isnull?: Maybe<Scalars['Boolean']>;
  baseFee_gt?: Maybe<Scalars['Float']>;
  baseFee_gte?: Maybe<Scalars['Float']>;
  baseFee_iendswith?: Maybe<Scalars['Float']>;
  baseFee_istartswith?: Maybe<Scalars['Float']>;
  baseFeeStudent?: Maybe<Scalars['Float']>;
  baseFeeStudent_lt?: Maybe<Scalars['Float']>;
  baseFeeStudent_lte?: Maybe<Scalars['Float']>;
  baseFeeStudent_isnull?: Maybe<Scalars['Boolean']>;
  baseFeeStudent_gt?: Maybe<Scalars['Float']>;
  baseFeeStudent_gte?: Maybe<Scalars['Float']>;
  baseFeeStudent_iendswith?: Maybe<Scalars['Float']>;
  baseFeeStudent_istartswith?: Maybe<Scalars['Float']>;
  repeatStintersEnabled?: Maybe<Scalars['Boolean']>;
  repeatStintersEnabled_lt?: Maybe<Scalars['Boolean']>;
  repeatStintersEnabled_lte?: Maybe<Scalars['Boolean']>;
  repeatStintersEnabled_isnull?: Maybe<Scalars['Boolean']>;
  repeatStintersEnabled_gt?: Maybe<Scalars['Boolean']>;
  repeatStintersEnabled_gte?: Maybe<Scalars['Boolean']>;
  repeatStintersEnabled_iendswith?: Maybe<Scalars['Boolean']>;
  repeatStintersEnabled_istartswith?: Maybe<Scalars['Boolean']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  modifiedAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  state_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isActive_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  isAvailabilityEnabled_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  shortCode_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  sessionUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  baseFee_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  baseFeeStudent_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  repeatStintersEnabled_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
};

export type CityListType = {
  __typename?: 'CityListType';
  pageInfo: PageInfoType;
  objects?: Maybe<Array<Maybe<City>>>;
};

export type ClearDefaultStintWeekTemplateInput = {
  businessId: Scalars['ID'];
};

export type CommitmentWeek = {
  __typename?: 'CommitmentWeek';
  weekStart: Scalars['AWSDate'];
  weekEnd: Scalars['AWSDate'];
  numAcceptedStints: Scalars['Int'];
};

export type CommitmentWeeksFilterInput = {
  dateFrom?: Maybe<Scalars['AWSDate']>;
  dateTo?: Maybe<Scalars['AWSDate']>;
};

export type CommitmentWeeksInput = {
  filter?: Maybe<CommitmentWeeksFilterInput>;
};

export type CompaniesHouseInformation = {
  __typename?: 'CompaniesHouseInformation';
  id: Scalars['ID'];
  number: Scalars['String'];
  name: Scalars['String'];
  address: Scalars['String'];
};

export enum CompaniesHouseInformationError {
  DUPLICATE_NUMBER = 'DUPLICATE_NUMBER',
  DUPLICATE_PARTNER_NODE_ID = 'DUPLICATE_PARTNER_NODE_ID',
  INVALID_ADDRESS = 'INVALID_ADDRESS',
  INVALID_NAME = 'INVALID_NAME',
  INVALID_NUMBER = 'INVALID_NUMBER',
  PARTNER_NODE_ALREADY_HAS_COMPANIES_HOUSE_INFORMATION = 'PARTNER_NODE_ALREADY_HAS_COMPANIES_HOUSE_INFORMATION',
}

export type CompaniesHouseInformationInput = {
  number: Scalars['String'];
  name: Scalars['String'];
  address: Scalars['String'];
};

export type ConfirmEmployeeDisclaimerInput = {
  agreementId: Scalars['ID'];
  stinterProfileId: Scalars['ID'];
};

export type ConfirmRightToWorkDocUpload = {
  __typename?: 'ConfirmRightToWorkDocUpload';
  success?: Maybe<Scalars['Boolean']>;
};

export enum ContractType {
  FLEXIBLE = 'FLEXIBLE',
  FIXED = 'FIXED',
}

export type CoreTeamMember = {
  __typename?: 'CoreTeamMember';
  partnerMemberProfileId: Scalars['ID'];
  partnerMemberId: Scalars['ID'];
  fullname: Scalars['String'];
  role: PartnerRole;
  jobTitle: Scalars['String'];
  expectedHours: Scalars['Int'];
  workingPreferences: WorkingPreferences;
  userOverride: Scalars['Boolean'];
};

export type Country = {
  __typename?: 'Country';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  modifiedAt: Scalars['AWSDateTime'];
  deletedAt?: Maybe<Scalars['AWSDateTime']>;
  ref: Scalars['ID'];
  shortCode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  regions?: Maybe<Scalars['String']>;
};

export type CountryFilter = {
  name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  idIcontains?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_isnull?: Maybe<Scalars['Boolean']>;
  createdAt_gt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_gte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  createdAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_isnull?: Maybe<Scalars['Boolean']>;
  modifiedAt_gt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_gte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  shortCode?: Maybe<Scalars['String']>;
  shortCode_lt?: Maybe<Scalars['String']>;
  shortCode_search?: Maybe<Scalars['String']>;
  shortCode_lte?: Maybe<Scalars['String']>;
  shortCode_isnull?: Maybe<Scalars['Boolean']>;
  shortCode_gt?: Maybe<Scalars['String']>;
  shortCode_gte?: Maybe<Scalars['String']>;
  shortCode_iendswith?: Maybe<Scalars['String']>;
  shortCode_istartswith?: Maybe<Scalars['String']>;
  name_lt?: Maybe<Scalars['String']>;
  name_search?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_isnull?: Maybe<Scalars['Boolean']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_iendswith?: Maybe<Scalars['String']>;
  name_istartswith?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  modifiedAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  shortCode_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CreateAccountNodeInput = {
  name: Scalars['String'];
  companiesHouseInformation: CompaniesHouseInformationInput;
  partnerBrand?: Maybe<PartnerBrandInput>;
};

export type CreateAgreementInput = {
  primaryPartnerNodeId: Scalars['ID'];
  documentId: Scalars['ID'];
  agreementType: AgreementType;
  enabled: Scalars['Boolean'];
};

export type CreateCheckoutSessionInput = {
  successPath: Scalars['String'];
  cancelPath: Scalars['String'];
};

export type CreateCompaniesHouseInformationInput = {
  partnerNodeId: Scalars['ID'];
  number: Scalars['String'];
  address: Scalars['String'];
  name: Scalars['String'];
};

export type CreateGroupNodeInput = {
  parentNodeId: Scalars['ID'];
  name: Scalars['String'];
  companiesHouseInformation?: Maybe<CompaniesHouseInformationInput>;
  partnerBrand?: Maybe<PartnerBrandInput>;
};

export type CreateOffenceInput = {
  stinterProfileRef: Scalars['ID'];
  reason: ValidOffenceReason;
  stintRef?: Maybe<Scalars['ID']>;
};

export type CreatePartnerBrandInput = {
  primaryPartnerNodeId: Scalars['ID'];
  logoBase64: Scalars['String'];
};

export type CreatePartnerMemberInput = {
  primaryPartnerNodeId: Scalars['ID'];
  role: PartnerRole;
  profileId?: Maybe<Scalars['ID']>;
  profile?: Maybe<CreatePartnerMemberProfileInput>;
  employmentInfo?: Maybe<UpsertEmploymentInfoInput>;
  siteWorkingPreferences?: Maybe<WorkingPreferencesInput>;
};

export type CreatePartnerMemberProfileInput = {
  email: Scalars['String'];
  fullname: Scalars['String'];
};

export type CreatePartnerPoolInput = {
  partnerNodeId: Scalars['ID'];
  formUrl: Scalars['String'];
  perks?: Maybe<Scalars['String']>;
};

export type CreatePoolMemberFeedbackInput = {
  memberId: Scalars['ID'];
  comment: Scalars['String'];
};

export type CreateSiteNodeInput = {
  parentNodeId: Scalars['ID'];
  name: Scalars['String'];
  partnerUniqueId?: Maybe<Scalars['String']>;
  address: AddressInput;
  partnerContact: PartnerContactInput;
  partnerBrand?: Maybe<PartnerBrandInput>;
};

export type CreateStintInput = {
  stintTemplateId: Scalars['ID'];
  businessId: Scalars['ID'];
  dateFrom: Scalars['AWSDateTime'];
  dateTo: Scalars['AWSDateTime'];
};

export type CreateStintTemplateGroupInput = {
  partnerNodeId: Scalars['ID'];
  name: Scalars['String'];
  stintTemplateIds: Array<Scalars['ID']>;
};

export type CreateStintTemplateInput = {
  partnerNodeId: Scalars['ID'];
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  uniform: Array<StintUniform>;
  primaryTasks: Array<StintTaskInput>;
  secondaryTasks?: Maybe<Array<StintTaskInput>>;
  employmentExceptions?: Maybe<Array<Scalars['ID']>>;
  trained?: Maybe<Scalars['Boolean']>;
  prepVideoUrl?: Maybe<Scalars['String']>;
  stintTemplateGroupId?: Maybe<Scalars['ID']>;
};

export type CreateStintsInput = {
  businessId: Scalars['ID'];
  stintTemplateId: Scalars['ID'];
  dateFrom: Scalars['AWSDateTime'];
  dateTo: Scalars['AWSDateTime'];
  quantity: Scalars['Int'];
};

export type CreateWorkExperienceInput = {
  hospitalityWorkExperience: HospitalityWorkExperience;
};

export enum DataError {
  BAD_REQUEST = 'BAD_REQUEST',
  DOES_NOT_EXIST = 'DOES_NOT_EXIST',
  FAILED_TO_UPDATE = 'FAILED_TO_UPDATE',
  UNKNOWN_INTEGRITY_ERROR = 'UNKNOWN_INTEGRITY_ERROR',
}

export enum DayOfWeek {
  SUNDAY = 'SUNDAY',
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
}

export type DeletePartnerMemberInput = {
  partnerMemberId: Scalars['ID'];
  primaryPartnerNodeId: Scalars['ID'];
};

export type DeletePartnerMemberResponse = {
  __typename?: 'DeletePartnerMemberResponse';
  id: Scalars['ID'];
};

export type DemandForecast = {
  __typename?: 'DemandForecast';
  partnerNodeId: Scalars['ID'];
  forecastDate: Scalars['AWSDateTime'];
  expectedForecast: Scalars['Int'];
  demandRange?: Maybe<ForecastedDemandRange>;
  demandThresholds?: Maybe<Array<Scalars['Int']>>;
  shifts: Array<Shift>;
  forecastType: ForecastType;
  userEdited: Scalars['Boolean'];
  usualStaffedHours: Scalars['Float'];
};

export type DemandThresholds = {
  __typename?: 'DemandThresholds';
  partnerNodeId: Scalars['ID'];
  dayOfWeek: WeekDay;
  demandThresholds: Array<Scalars['Int']>;
};

export type DeviceInfo = {
  __typename?: 'DeviceInfo';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  modifiedAt: Scalars['AWSDateTime'];
  deletedAt?: Maybe<Scalars['AWSDateTime']>;
  ref: Scalars['ID'];
  user: StintUser;
  uniqueId: Scalars['String'];
  platform: DeviceInfoPlatform;
  locationSetting?: Maybe<DeviceInfoLocationSetting>;
  locationSettingUpdatedAt?: Maybe<Scalars['AWSDateTime']>;
  appVersion?: Maybe<Scalars['String']>;
  hasNotificationsEnabled: Scalars['Boolean'];
  hasNotificationsEnabledUpdatedAt?: Maybe<Scalars['AWSDateTime']>;
  user_id?: Maybe<Scalars['ID']>;
};

export enum DeviceInfoLocationSetting {
  NEVER = 'NEVER',
  ALWAYS = 'ALWAYS',
  USING = 'USING',
  UNDETERMINED = 'UNDETERMINED',
  DENIED = 'DENIED',
}

export enum DeviceInfoPlatform {
  IOS = 'IOS',
  ANDROID = 'ANDROID',
}

export type DeviceLocation = {
  __typename?: 'DeviceLocation';
  id: Scalars['ID'];
  heading?: Maybe<Scalars['Float']>;
  speed?: Maybe<Scalars['Float']>;
  accuracy?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  batteryLevel?: Maybe<Scalars['Float']>;
  batteryIsCharging: Scalars['Boolean'];
  deviceId?: Maybe<Scalars['String']>;
  recordedAt?: Maybe<Scalars['String']>;
  isMoving: Scalars['Boolean'];
  user_id?: Maybe<Scalars['ID']>;
  city_id?: Maybe<Scalars['ID']>;
};

export type DeviceLocationFilter = {
  user?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  idIcontains?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['ID']>;
  user_id_isnull?: Maybe<Scalars['Boolean']>;
  city_id?: Maybe<Scalars['ID']>;
  city_id_isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_isnull?: Maybe<Scalars['Boolean']>;
  createdAt_gt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_gte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  createdAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_isnull?: Maybe<Scalars['Boolean']>;
  modifiedAt_gt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_gte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  uuid?: Maybe<Scalars['String']>;
  uuid_lt?: Maybe<Scalars['String']>;
  uuid_search?: Maybe<Scalars['String']>;
  uuid_lte?: Maybe<Scalars['String']>;
  uuid_isnull?: Maybe<Scalars['Boolean']>;
  uuid_gt?: Maybe<Scalars['String']>;
  uuid_gte?: Maybe<Scalars['String']>;
  uuid_iendswith?: Maybe<Scalars['String']>;
  uuid_istartswith?: Maybe<Scalars['String']>;
  heading?: Maybe<Scalars['Float']>;
  heading_lt?: Maybe<Scalars['Float']>;
  heading_lte?: Maybe<Scalars['Float']>;
  heading_isnull?: Maybe<Scalars['Boolean']>;
  heading_gt?: Maybe<Scalars['Float']>;
  heading_gte?: Maybe<Scalars['Float']>;
  heading_iendswith?: Maybe<Scalars['Float']>;
  heading_istartswith?: Maybe<Scalars['Float']>;
  speed?: Maybe<Scalars['Float']>;
  speed_lt?: Maybe<Scalars['Float']>;
  speed_lte?: Maybe<Scalars['Float']>;
  speed_isnull?: Maybe<Scalars['Boolean']>;
  speed_gt?: Maybe<Scalars['Float']>;
  speed_gte?: Maybe<Scalars['Float']>;
  speed_iendswith?: Maybe<Scalars['Float']>;
  speed_istartswith?: Maybe<Scalars['Float']>;
  accuracy?: Maybe<Scalars['Float']>;
  accuracy_lt?: Maybe<Scalars['Float']>;
  accuracy_lte?: Maybe<Scalars['Float']>;
  accuracy_isnull?: Maybe<Scalars['Boolean']>;
  accuracy_gt?: Maybe<Scalars['Float']>;
  accuracy_gte?: Maybe<Scalars['Float']>;
  accuracy_iendswith?: Maybe<Scalars['Float']>;
  accuracy_istartswith?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  longitude_lt?: Maybe<Scalars['Float']>;
  longitude_lte?: Maybe<Scalars['Float']>;
  longitude_isnull?: Maybe<Scalars['Boolean']>;
  longitude_gt?: Maybe<Scalars['Float']>;
  longitude_gte?: Maybe<Scalars['Float']>;
  longitude_iendswith?: Maybe<Scalars['Float']>;
  longitude_istartswith?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  latitude_lt?: Maybe<Scalars['Float']>;
  latitude_lte?: Maybe<Scalars['Float']>;
  latitude_isnull?: Maybe<Scalars['Boolean']>;
  latitude_gt?: Maybe<Scalars['Float']>;
  latitude_gte?: Maybe<Scalars['Float']>;
  latitude_iendswith?: Maybe<Scalars['Float']>;
  latitude_istartswith?: Maybe<Scalars['Float']>;
  altitude?: Maybe<Scalars['Float']>;
  altitude_lt?: Maybe<Scalars['Float']>;
  altitude_lte?: Maybe<Scalars['Float']>;
  altitude_isnull?: Maybe<Scalars['Boolean']>;
  altitude_gt?: Maybe<Scalars['Float']>;
  altitude_gte?: Maybe<Scalars['Float']>;
  altitude_iendswith?: Maybe<Scalars['Float']>;
  altitude_istartswith?: Maybe<Scalars['Float']>;
  batteryLevel?: Maybe<Scalars['Float']>;
  batteryLevel_lt?: Maybe<Scalars['Float']>;
  batteryLevel_lte?: Maybe<Scalars['Float']>;
  batteryLevel_isnull?: Maybe<Scalars['Boolean']>;
  batteryLevel_gt?: Maybe<Scalars['Float']>;
  batteryLevel_gte?: Maybe<Scalars['Float']>;
  batteryLevel_iendswith?: Maybe<Scalars['Float']>;
  batteryLevel_istartswith?: Maybe<Scalars['Float']>;
  batteryIsCharging?: Maybe<Scalars['Boolean']>;
  batteryIsCharging_lt?: Maybe<Scalars['Boolean']>;
  batteryIsCharging_lte?: Maybe<Scalars['Boolean']>;
  batteryIsCharging_isnull?: Maybe<Scalars['Boolean']>;
  batteryIsCharging_gt?: Maybe<Scalars['Boolean']>;
  batteryIsCharging_gte?: Maybe<Scalars['Boolean']>;
  batteryIsCharging_iendswith?: Maybe<Scalars['Boolean']>;
  batteryIsCharging_istartswith?: Maybe<Scalars['Boolean']>;
  activityConfidence?: Maybe<Scalars['Float']>;
  activityConfidence_lt?: Maybe<Scalars['Float']>;
  activityConfidence_lte?: Maybe<Scalars['Float']>;
  activityConfidence_isnull?: Maybe<Scalars['Boolean']>;
  activityConfidence_gt?: Maybe<Scalars['Float']>;
  activityConfidence_gte?: Maybe<Scalars['Float']>;
  activityConfidence_iendswith?: Maybe<Scalars['Float']>;
  activityConfidence_istartswith?: Maybe<Scalars['Float']>;
  activityType?: Maybe<Scalars['String']>;
  activityType_lt?: Maybe<Scalars['String']>;
  activityType_search?: Maybe<Scalars['String']>;
  activityType_lte?: Maybe<Scalars['String']>;
  activityType_isnull?: Maybe<Scalars['Boolean']>;
  activityType_gt?: Maybe<Scalars['String']>;
  activityType_gte?: Maybe<Scalars['String']>;
  activityType_iendswith?: Maybe<Scalars['String']>;
  activityType_istartswith?: Maybe<Scalars['String']>;
  odometer?: Maybe<Scalars['String']>;
  odometer_lt?: Maybe<Scalars['String']>;
  odometer_search?: Maybe<Scalars['String']>;
  odometer_lte?: Maybe<Scalars['String']>;
  odometer_isnull?: Maybe<Scalars['Boolean']>;
  odometer_gt?: Maybe<Scalars['String']>;
  odometer_gte?: Maybe<Scalars['String']>;
  odometer_iendswith?: Maybe<Scalars['String']>;
  odometer_istartswith?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['String']>;
  deviceId_lt?: Maybe<Scalars['String']>;
  deviceId_search?: Maybe<Scalars['String']>;
  deviceId_lte?: Maybe<Scalars['String']>;
  deviceId_isnull?: Maybe<Scalars['Boolean']>;
  deviceId_gt?: Maybe<Scalars['String']>;
  deviceId_gte?: Maybe<Scalars['String']>;
  deviceId_iendswith?: Maybe<Scalars['String']>;
  deviceId_istartswith?: Maybe<Scalars['String']>;
  recordedAt?: Maybe<Scalars['String']>;
  recordedAt_lt?: Maybe<Scalars['String']>;
  recordedAt_search?: Maybe<Scalars['String']>;
  recordedAt_lte?: Maybe<Scalars['String']>;
  recordedAt_isnull?: Maybe<Scalars['Boolean']>;
  recordedAt_gt?: Maybe<Scalars['String']>;
  recordedAt_gte?: Maybe<Scalars['String']>;
  recordedAt_iendswith?: Maybe<Scalars['String']>;
  recordedAt_istartswith?: Maybe<Scalars['String']>;
  isMoving?: Maybe<Scalars['Boolean']>;
  isMoving_lt?: Maybe<Scalars['Boolean']>;
  isMoving_lte?: Maybe<Scalars['Boolean']>;
  isMoving_isnull?: Maybe<Scalars['Boolean']>;
  isMoving_gt?: Maybe<Scalars['Boolean']>;
  isMoving_gte?: Maybe<Scalars['Boolean']>;
  isMoving_iendswith?: Maybe<Scalars['Boolean']>;
  isMoving_istartswith?: Maybe<Scalars['Boolean']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  modifiedAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  uuid_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  heading_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  speed_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  accuracy_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  longitude_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  latitude_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  altitude_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  batteryLevel_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  batteryIsCharging_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  activityConfidence_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  activityType_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  odometer_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deviceId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  recordedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isMoving_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
};

export enum DifferentStartTimeReason {
  DIFFERENT_START_TIME_EARLY = 'DIFFERENT_START_TIME_EARLY',
  DIFFERENT_START_TIME_LAST_MINUTE = 'DIFFERENT_START_TIME_LAST_MINUTE',
  DIFFERENT_START_TIME_LATE_ASSIGNMENT = 'DIFFERENT_START_TIME_LATE_ASSIGNMENT',
  DIFFERENT_START_TIME_LATE_TRANSPORT = 'DIFFERENT_START_TIME_LATE_TRANSPORT',
  DIFFERENT_START_TIME_LEFT_TO_LATE = 'DIFFERENT_START_TIME_LEFT_TO_LATE',
  DIFFERENT_START_TIME_WRONG_BUSINESS_ADDRESS = 'DIFFERENT_START_TIME_WRONG_BUSINESS_ADDRESS',
  DIFFERENT_START_TIME_OTHER = 'DIFFERENT_START_TIME_OTHER',
}

export type DirectDebitPaymentMethod = {
  __typename?: 'DirectDebitPaymentMethod';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  modifiedAt: Scalars['AWSDateTime'];
  deletedAt?: Maybe<Scalars['AWSDateTime']>;
  ref: Scalars['ID'];
  isPrimaryMethod: Scalars['Boolean'];
  accountNumber?: Maybe<Scalars['String']>;
  accountName?: Maybe<Scalars['String']>;
  sortCode?: Maybe<Scalars['String']>;
  employerPaymentMethods: Array<EmployerProfile>;
  stints: StintNodeTypeConnection;
  studentProfiles: Array<StudentProfile>;
  businesses: Array<Business>;
};

export type DirectDebitPaymentMethodstintsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DjangoDebug = {
  __typename?: 'DjangoDebug';
  sql?: Maybe<Array<Maybe<DjangoDebugSQL>>>;
  exceptions?: Maybe<Array<Maybe<DjangoDebugException>>>;
};

export type DjangoDebugException = {
  __typename?: 'DjangoDebugException';
  excType: Scalars['String'];
  message: Scalars['String'];
  stack: Scalars['String'];
};

export type DjangoDebugSQL = {
  __typename?: 'DjangoDebugSQL';
  vendor: Scalars['String'];
  alias: Scalars['String'];
  sql?: Maybe<Scalars['String']>;
  duration: Scalars['Float'];
  rawSql: Scalars['String'];
  params: Scalars['String'];
  startTime: Scalars['Float'];
  stopTime: Scalars['Float'];
  isSlow: Scalars['Boolean'];
  isSelect: Scalars['Boolean'];
  transId?: Maybe<Scalars['String']>;
  transStatus?: Maybe<Scalars['String']>;
  isoLevel?: Maybe<Scalars['String']>;
  encoding?: Maybe<Scalars['String']>;
};

export type EditableSetting = {
  __typename?: 'EditableSetting';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  modifiedAt: Scalars['AWSDateTime'];
  deletedAt?: Maybe<Scalars['AWSDateTime']>;
  ref: Scalars['ID'];
  baseFee?: Maybe<Scalars['Float']>;
  baseFeeStudent?: Maybe<Scalars['Float']>;
  predictionLeadDays: Scalars['Int'];
  notificationBlockerWindowHours: Scalars['Float'];
  geoTrackingBufferTime: Scalars['Int'];
  advancedOperationalWindowStartTime: Scalars['AWSTime'];
  advancedOperationalWindowEndTime: Scalars['AWSTime'];
  automatedMatchingEnabled: Scalars['Boolean'];
  automatedOffersEnabled: Scalars['Boolean'];
  automatedTestingEnabled: Scalars['Boolean'];
  automatedLastMinuteStints: Scalars['Boolean'];
  featureFlagGeotrackingV2Enabled: Scalars['Boolean'];
  featureFlagGeotrackingReactAdminEnabled: Scalars['Boolean'];
  featureFlagMultipleStintOffersEnabled: Scalars['Boolean'];
  featureFlagEtdNotificationsEnabled: Scalars['Boolean'];
  serverDatetime?: Maybe<Scalars['AWSDateTime']>;
  featureFlagAvailabilityMatchingEnabled: Scalars['Boolean'];
  featureFlagStudentGoingToBeLateSmsEnabled: Scalars['Boolean'];
  availabilityMatchingBufferHours: Scalars['Int'];
  availabilityMatchingMaxOffersPerDay: Scalars['Int'];
  availabilityMatchingMaxOffersMonday: Scalars['Int'];
  availabilityMatchingMaxOffersTuesday: Scalars['Int'];
  availabilityMatchingMaxOffersWednesday: Scalars['Int'];
  availabilityMatchingMaxOffersThursday: Scalars['Int'];
  availabilityMatchingMaxOffersFriday: Scalars['Int'];
  availabilityMatchingMaxOffersSaturday: Scalars['Int'];
  availabilityMatchingMaxOffersSunday: Scalars['Int'];
  availabilityMatchingNotificationForNoOffersEnabled: Scalars['Boolean'];
  matchingAlgoNoOfferMaxCountPerTimeWindow: Scalars['Int'];
  matchingAlgoNoOfferTimeWindowSpan: Scalars['Int'];
  matchingAlgoNoOfferTitle?: Maybe<Scalars['String']>;
  matchingAlgoNoOfferMessage?: Maybe<Scalars['String']>;
  matchingAlgoNotificationDelayInMinutes: Scalars['Int'];
  featureFlagStintSecondStartReminderEnabled: Scalars['Boolean'];
  etdCalculationBeforeMiddayOffsetInMinutes: Scalars['Int'];
  etdCalculationAfterMiddayOffsetInMinutes: Scalars['Int'];
  sendingStudentLeaveReminderAtRouteTimeEnabled: Scalars['Boolean'];
  stintOfferExpiryThresholdInHours: Scalars['Int'];
  notificationTopUpThresholdInHours: Scalars['Int'];
  serverTime?: Maybe<Scalars['AWSDateTime']>;
  isTimeManipulationEnabled?: Maybe<Scalars['Boolean']>;
  studentCancellationOnHoldEmailEnabled: Scalars['Boolean'];
};

export type EmployeeDisclaimerInfo = {
  __typename?: 'EmployeeDisclaimerInfo';
  agreementId: Scalars['ID'];
  documentUrl: Scalars['String'];
  confirmed: Scalars['Boolean'];
};

export enum EmployeeStatement {
  A = 'A',
  B = 'B',
  C = 'C',
}

export type EmployerProfile = {
  __typename?: 'EmployerProfile';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  modifiedAt: Scalars['AWSDateTime'];
  deletedAt?: Maybe<Scalars['AWSDateTime']>;
  ref: Scalars['ID'];
  employer: StintUser;
  selectedBusiness?: Maybe<Business>;
  identityPhotoFile?: Maybe<Scalars['String']>;
  bookedOnboardingMeeting: Scalars['Boolean'];
  usingStintCorrectly: Scalars['Boolean'];
  stripeCustomerId?: Maybe<Scalars['String']>;
  addresses: Array<Address>;
  paymentMethods: Array<DirectDebitPaymentMethod>;
  cardPaymentMethods: Array<CardPaymentMethod>;
  selected_business_id?: Maybe<Scalars['ID']>;
};

export type EmploymentException = {
  __typename?: 'EmploymentException';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  modifiedAt: Scalars['AWSDateTime'];
  deletedAt?: Maybe<Scalars['AWSDateTime']>;
  ref: Scalars['ID'];
  name: Scalars['String'];
  category: EmploymentExceptionCategory;
  studentProfileExceptions: Array<StudentProfile>;
};

export enum EmploymentExceptionCategory {
  PREFERENCE = 'PREFERENCE',
  RELIGIOUS_AND_MEDICAL = 'RELIGIOUS_AND_MEDICAL',
}

export enum EmploymentExceptionCategoryInputType {
  PREFERENCE = 'PREFERENCE',
  RELIGIOUS_AND_MEDICAL = 'RELIGIOUS_AND_MEDICAL',
}

export type EmploymentExceptionFilter = {
  name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  idIcontains?: Maybe<Scalars['String']>;
  q?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_isnull?: Maybe<Scalars['Boolean']>;
  createdAt_gt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_gte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  createdAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_isnull?: Maybe<Scalars['Boolean']>;
  modifiedAt_gt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_gte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  name_lt?: Maybe<Scalars['String']>;
  name_search?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_isnull?: Maybe<Scalars['Boolean']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_iendswith?: Maybe<Scalars['String']>;
  name_istartswith?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  category_lt?: Maybe<Scalars['String']>;
  category_search?: Maybe<Scalars['String']>;
  category_lte?: Maybe<Scalars['String']>;
  category_isnull?: Maybe<Scalars['Boolean']>;
  category_gt?: Maybe<Scalars['String']>;
  category_gte?: Maybe<Scalars['String']>;
  category_iendswith?: Maybe<Scalars['String']>;
  category_istartswith?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  modifiedAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  category_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EmploymentExceptionListType = {
  __typename?: 'EmploymentExceptionListType';
  pageInfo: PageInfoType;
  objects?: Maybe<Array<Maybe<EmploymentException>>>;
};

export type EmploymentInfo = {
  __typename?: 'EmploymentInfo';
  id: Scalars['ID'];
  expectedHours: Scalars['Int'];
  jobTitle: Scalars['String'];
  payRate?: Maybe<Scalars['String']>;
  contractType?: Maybe<ContractType>;
};

export type FileUpload = {
  __typename?: 'FileUpload';
  documentId: Scalars['ID'];
  presignedUrl: Scalars['String'];
};

export type FilterInput = {
  search?: Maybe<Scalars['String']>;
};

export enum ForecastType {
  STATIC = 'STATIC',
  DYNAMIC = 'DYNAMIC',
}

export enum ForecastedDemandRange {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  VERY_HIGH = 'VERY_HIGH',
}

export type ForgotPassword = {
  __typename?: 'ForgotPassword';
  success?: Maybe<Scalars['Boolean']>;
};

export type GetPartnerMemberInput = {
  id: Scalars['ID'];
  partnerNodeId: Scalars['ID'];
};

export type GetPartnerMembersInput = {
  filter?: Maybe<PartnerMembersFilterInput>;
};

export type GetPartnerPoolInvitesInputType = {
  partnerPoolId: Scalars['ID'];
  partnerNodeId: Scalars['ID'];
};

export type GetStintTemplatesInput = {
  orderBy?: Maybe<StintTemplatesOrderInput>;
  filter?: Maybe<StintTemplatesFilterInput>;
};

export type GooglePlaceType = {
  __typename?: 'GooglePlaceType';
  displayAddress?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
};

export type GooglePlacesPredictionsType = {
  __typename?: 'GooglePlacesPredictionsType';
  predictions?: Maybe<Array<Maybe<GooglePlaceType>>>;
};

export enum GooglePlacesReturnType {
  ADDRESS = 'ADDRESS',
  CITY = 'CITY',
  PLACE = 'PLACE',
  POSTAL_CODE = 'POSTAL_CODE',
}

export type Group = {
  __typename?: 'Group';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  modifiedAt: Scalars['AWSDateTime'];
  deletedAt?: Maybe<Scalars['AWSDateTime']>;
  ref: Scalars['ID'];
  name: Scalars['String'];
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  grantedByAdminsOnly: Scalars['Boolean'];
};

export type HasStintStartingSoon = {
  __typename?: 'HasStintStartingSoon';
  success?: Maybe<Scalars['Boolean']>;
};

export type HealthCheckType = {
  __typename?: 'HealthCheckType';
  success?: Maybe<Scalars['String']>;
};

export enum HospitalityWorkExperience {
  NO_EXPERIENCE = 'NO_EXPERIENCE',
  LESS_THAN_2_MONTHS = 'LESS_THAN_2_MONTHS',
  MORE_THAN_2_MONTHS = 'MORE_THAN_2_MONTHS',
}

export type Hotspot = {
  __typename?: 'Hotspot';
  id: Scalars['ID'];
  startTime?: Maybe<Scalars['AWSDateTime']>;
  endTime?: Maybe<Scalars['AWSDateTime']>;
  hotspotBusynessLevel?: Maybe<Scalars['String']>;
  city_id?: Maybe<Scalars['ID']>;
};

export type HotspotTimeBusynessFilter = {
  cityId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  idIcontains?: Maybe<Scalars['String']>;
  city_id?: Maybe<Scalars['ID']>;
  city_id_isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_isnull?: Maybe<Scalars['Boolean']>;
  createdAt_gt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_gte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  createdAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_isnull?: Maybe<Scalars['Boolean']>;
  modifiedAt_gt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_gte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  startTime?: Maybe<Scalars['AWSDateTime']>;
  startTime_lt?: Maybe<Scalars['AWSDateTime']>;
  startTime_lte?: Maybe<Scalars['AWSDateTime']>;
  startTime_isnull?: Maybe<Scalars['Boolean']>;
  startTime_gt?: Maybe<Scalars['AWSDateTime']>;
  startTime_gte?: Maybe<Scalars['AWSDateTime']>;
  startTime_iendswith?: Maybe<Scalars['AWSDateTime']>;
  startTime_istartswith?: Maybe<Scalars['AWSDateTime']>;
  endTime?: Maybe<Scalars['AWSDateTime']>;
  endTime_lt?: Maybe<Scalars['AWSDateTime']>;
  endTime_lte?: Maybe<Scalars['AWSDateTime']>;
  endTime_isnull?: Maybe<Scalars['Boolean']>;
  endTime_gt?: Maybe<Scalars['AWSDateTime']>;
  endTime_gte?: Maybe<Scalars['AWSDateTime']>;
  endTime_iendswith?: Maybe<Scalars['AWSDateTime']>;
  endTime_istartswith?: Maybe<Scalars['AWSDateTime']>;
  busynessLevel?: Maybe<Scalars['String']>;
  busynessLevel_lt?: Maybe<Scalars['String']>;
  busynessLevel_search?: Maybe<Scalars['String']>;
  busynessLevel_lte?: Maybe<Scalars['String']>;
  busynessLevel_isnull?: Maybe<Scalars['Boolean']>;
  busynessLevel_gt?: Maybe<Scalars['String']>;
  busynessLevel_gte?: Maybe<Scalars['String']>;
  busynessLevel_iendswith?: Maybe<Scalars['String']>;
  busynessLevel_istartswith?: Maybe<Scalars['String']>;
  busynessLevelOverride?: Maybe<Scalars['String']>;
  busynessLevelOverride_lt?: Maybe<Scalars['String']>;
  busynessLevelOverride_search?: Maybe<Scalars['String']>;
  busynessLevelOverride_lte?: Maybe<Scalars['String']>;
  busynessLevelOverride_isnull?: Maybe<Scalars['Boolean']>;
  busynessLevelOverride_gt?: Maybe<Scalars['String']>;
  busynessLevelOverride_gte?: Maybe<Scalars['String']>;
  busynessLevelOverride_iendswith?: Maybe<Scalars['String']>;
  busynessLevelOverride_istartswith?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  modifiedAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  startTime_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  endTime_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  busynessLevel_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  busynessLevelOverride_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type InitiatePartnerFileUploadInput = {
  partnerNodeId: Scalars['ID'];
  uploadType: PartnerFileUploadType;
  filename: Scalars['String'];
  contentType: Scalars['String'];
};

export type InitiateShareCodeScreeningInput = {
  stinterId: Scalars['ID'];
  dateOfBirth: Scalars['AWSDate'];
  shareCode: Scalars['String'];
};

export enum InputError {
  INVALID_ID = 'INVALID_ID',
  INVALID_BASE64 = 'INVALID_BASE64',
  INVALID_INPUTS = 'INVALID_INPUTS',
}

export type IntegerRangeInput = {
  min?: Maybe<Scalars['Int']>;
  max?: Maybe<Scalars['Int']>;
};

export type LabourTemplate = {
  __typename?: 'LabourTemplate';
  partnerNodeId: Scalars['ID'];
  dayOfWeek: WeekDay;
  demandRange: ForecastedDemandRange;
  shifts: Array<Shift>;
  usualStaffedHours: Scalars['Float'];
};

export type Language = {
  __typename?: 'Language';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  modifiedAt: Scalars['AWSDateTime'];
  deletedAt?: Maybe<Scalars['AWSDateTime']>;
  ref: Scalars['ID'];
  name: Scalars['String'];
};

export type LanguageFilter = {
  name?: Maybe<Scalars['String']>;
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  idIcontains?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_isnull?: Maybe<Scalars['Boolean']>;
  createdAt_gt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_gte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  createdAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_isnull?: Maybe<Scalars['Boolean']>;
  modifiedAt_gt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_gte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  name_lt?: Maybe<Scalars['String']>;
  name_search?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_isnull?: Maybe<Scalars['Boolean']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_iendswith?: Maybe<Scalars['String']>;
  name_istartswith?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  modifiedAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ListMetadata = {
  __typename?: 'ListMetadata';
  count?: Maybe<Scalars['Int']>;
  hasNext?: Maybe<Scalars['Boolean']>;
  hasPrev?: Maybe<Scalars['Boolean']>;
  totalObjects?: Maybe<Scalars['Int']>;
};

export enum LocationSettings {
  NEVER = 'NEVER',
  ALWAYS = 'ALWAYS',
  USING = 'USING',
  UNDETERMINED = 'UNDETERMINED',
  DENIED = 'DENIED',
}

export type LoginAdmin = {
  __typename?: 'LoginAdmin';
  payload: Scalars['String'];
  refreshExpiresIn: Scalars['Int'];
  token: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type LoginEmployer = {
  __typename?: 'LoginEmployer';
  payload: Scalars['String'];
  refreshExpiresIn: Scalars['Int'];
  token: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type LoginMobileUser = {
  __typename?: 'LoginMobileUser';
  payload: Scalars['String'];
  refreshExpiresIn: Scalars['Int'];
  token: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type LoginStudent = {
  __typename?: 'LoginStudent';
  payload: Scalars['String'];
  refreshExpiresIn: Scalars['Int'];
  token: Scalars['String'];
  refreshToken: Scalars['String'];
};

export enum ManualCheckInReason {
  MANUAL_CHECK_IN_NO_QR_CODE = 'MANUAL_CHECK_IN_NO_QR_CODE',
  MANUAL_CHECK_IN_FORGOT_TO_SCAN_QR_CODE = 'MANUAL_CHECK_IN_FORGOT_TO_SCAN_QR_CODE',
  MANUAL_CHECK_IN_QR_CODE_NOT_WORKING = 'MANUAL_CHECK_IN_QR_CODE_NOT_WORKING',
  MANUAL_CHECK_IN_QR_CODE_SCANNED_AT_THE_WRONG_TIME = 'MANUAL_CHECK_IN_QR_CODE_SCANNED_AT_THE_WRONG_TIME',
}

export type MarkInviteAsAppliedInput = {
  stinterId: Scalars['ID'];
  partnerPoolId: Scalars['ID'];
};

export type MarkPartnerPoolInvitesAsSeenByStinterInput = {
  stinterProfileId: Scalars['ID'];
};

export enum MarketingChannel {
  WORD_OF_MOUTH = 'WORD_OF_MOUTH',
  SOCIAL_MEDIA = 'SOCIAL_MEDIA',
  WEB_SEARCH = 'WEB_SEARCH',
  STINT_FLYER = 'STINT_FLYER',
  BILLBOARD = 'BILLBOARD',
  JOB_BOARD = 'JOB_BOARD',
  STINT_BUSINESS = 'STINT_BUSINESS',
  UNIVERSITY_FAIR = 'UNIVERSITY_FAIR',
  STUDENT_UNION = 'STUDENT_UNION',
  EMAIL = 'EMAIL',
  OTHER = 'OTHER',
}

export type MemberRemovalReason = {
  __typename?: 'MemberRemovalReason';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  moreInfoRequired: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  isStinterReason: Scalars['Boolean'];
  isStintHqReason: Scalars['Boolean'];
  isSiteReason: Scalars['Boolean'];
};

export enum MemberRemovalRemovedBy {
  STINT_HQ = 'STINT_HQ',
  SITE = 'SITE',
  STINTER = 'STINTER',
}

export type Mutation = {
  __typename?: 'Mutation';
  createUniversity?: Maybe<University>;
  updateUniversity?: Maybe<University>;
  createTag?: Maybe<Tag>;
  updateTag?: Maybe<Tag>;
  createStudentSavedPlace?: Maybe<StudentSavedPlace>;
  updateStudentSavedPlace?: Maybe<StudentSavedPlace>;
  deleteStudentSavedPlace?: Maybe<StudentSavedPlace>;
  upsertStintUserAvailability?: Maybe<Array<Maybe<StintUserAvailability>>>;
  deleteStintUserAvailability?: Maybe<StintUserAvailability>;
  createStintTemplate: StintTemplate;
  updateStintTemplate: StintTemplate;
  createStintTemplateGroup: StintTemplateGroup;
  updateStintTemplateGroup: StintTemplateGroup;
  setStudentHasSeenDashboard?: Maybe<StintUser>;
  confirmRightToWorkDocUpload?: Maybe<ConfirmRightToWorkDocUpload>;
  updateStinterMarketingConsent?: Maybe<StudentProfile>;
  updateStinterMarketingChannel?: Maybe<Stinter>;
  createStintTask?: Maybe<StintTask>;
  updateStintTask?: Maybe<StintTask>;
  deleteStintTask?: Maybe<StintTask>;
  createStint?: Maybe<Stint>;
  createStints?: Maybe<Array<Maybe<Stint>>>;
  updateStintTimes?: Maybe<Stint>;
  updateActualLeaveTime?: Maybe<Stint>;
  cancelStintAsStinter?: Maybe<Stint>;
  acceptStint?: Maybe<Stint>;
  acceptAvailableStint?: Maybe<Stint>;
  updateStintopedia?: Maybe<Stintopedia>;
  recordOfferSeenEvent?: Maybe<SuccessType>;
  updateStint?: Maybe<Stint>;
  deleteStint?: Maybe<Stint>;
  checkInStint?: Maybe<CheckInStint>;
  confirmAttendStint?: Maybe<Stint>;
  overrideStintCheckIn?: Maybe<OverrideStintCheckIn>;
  hasStintStartingSoon?: Maybe<HasStintStartingSoon>;
  updateStintUser?: Maybe<StintUser>;
  verifyStudentUser?: Maybe<SuccessType>;
  updateMe?: Maybe<StintUser>;
  cancelStints?: Maybe<CancelStints>;
  enableGranularLocationTracking?: Maybe<StintJourney>;
  updateStintJourney?: Maybe<StintJourney>;
  setDoNotRemindAboutGeotracking?: Maybe<SuccessType>;
  manualGeotrackingStatusUpdate?: Maybe<StintJourney>;
  resolveStintJourneys?: Maybe<Array<Maybe<StintJourney>>>;
  qualifyForTask: StinterCompetencyTask;
  upsertVideoAssessmentInfo: VideoAssessmentInfo;
  createWorkExperience?: Maybe<WorkExperience>;
  searchGooglePlaces?: Maybe<GooglePlacesPredictionsType>;
  createStintWeekTemplate?: Maybe<StintWeekTemplate>;
  updateStintWeekTemplate?: Maybe<StintWeekTemplate>;
  deleteStintWeekTemplate?: Maybe<StintWeekTemplate>;
  bookStintWeekTemplate?: Maybe<StintWeekTemplate>;
  setDefaultStintWeekTemplate: Array<StintWeekTemplate>;
  clearDefaultStintWeekTemplate: Array<StintWeekTemplate>;
  initiatePersonaScreening: PersonaInquiry;
  initiateShareCodeScreening: Stinter;
  createRegion?: Maybe<Region>;
  updateRegion?: Maybe<Region>;
  deleteRegion?: Maybe<Region>;
  createPostalCode?: Maybe<PostalCode>;
  updatePostalCode?: Maybe<PostalCode>;
  deletePostalCode?: Maybe<PostalCode>;
  createPoolMemberFeedback: PoolMemberFeedback;
  addStinterCommitment: StinterCommitment;
  updateStinterCommitment: StinterCommitment;
  addStintersToStintTemplatePool: StintTemplate;
  removeStintersFromStintTemplatePool: StintTemplate;
  removeStinterFromStintTemplatePool: StintTemplate;
  approveTrialStinterForStintTemplatePool: StintTemplate;
  addStintersToPartnerPool: PartnerPool;
  createPartnerPool: PartnerPool;
  removeStintersFromPartnerPool: PartnerPool;
  removeAllStintersFromPartnerPool: PartnerPool;
  updatePartnerPool: PartnerPool;
  markPartnerPoolInvitesAsSeenByStinter: StinterProfile;
  markInviteAsApplied?: Maybe<PartnerPoolInvite>;
  createStripeDebit?: Maybe<StripeDebit>;
  createStripeCard?: Maybe<StripeCard>;
  deleteStripeCard?: Maybe<StripeCard>;
  createStripeSetupIntent?: Maybe<SetupIntent>;
  createStripeCheckoutSession?: Maybe<CheckoutSession>;
  updatePartnerSettings: PartnerSettings;
  createAccountNode?: Maybe<PartnerAccountProfile>;
  createGroupNode?: Maybe<PartnerGroupProfile>;
  createSiteNode?: Maybe<PartnerSiteProfile>;
  updateAccountNode?: Maybe<PartnerAccountProfile>;
  updateGroupNode?: Maybe<PartnerGroupProfile>;
  updatePartnerNodeParent?: Maybe<PartnerNode>;
  updateSiteNode?: Maybe<PartnerSiteProfile>;
  regenerateSiteQRCode: PartnerSiteProfile;
  createPartnerMember: PartnerMember;
  updatePartnerMember: PartnerMember;
  deletePartnerMember: DeletePartnerMemberResponse;
  updatePartnerContact?: Maybe<PartnerContact>;
  createPartnerBrand?: Maybe<PartnerBrand>;
  updatePartnerBrand?: Maybe<PartnerBrand>;
  createCompaniesHouseInformation?: Maybe<CompaniesHouseInformation>;
  updateCompaniesHouseInformation?: Maybe<CompaniesHouseInformation>;
  createNotification?: Maybe<Notification>;
  createAppLifecycleEvent?: Maybe<AppLifecycleEvent>;
  acknowledgeNotificationOpened?: Maybe<NotificationRecipient>;
  updateEditableSetting?: Maybe<EditableSetting>;
  initiatePartnerFileUpload: FileUpload;
  createOffence?: Maybe<Offence>;
  adminCreateAppeal?: Maybe<Appeal>;
  upsertDeviceInfo?: Maybe<UpsertDeviceInfo>;
  createCountry?: Maybe<Country>;
  updateCountry?: Maybe<Country>;
  deleteCountry?: Maybe<Country>;
  createCity?: Maybe<City>;
  updateCity?: Maybe<City>;
  deleteCity?: Maybe<City>;
  updateBusiness?: Maybe<Business>;
  employerCancelStint?: Maybe<Stint>;
  triggerAutoscheduler: SiteSchedule;
  updateScheduleShifts: Schedule;
  upsertLabourTemplate: LabourTemplate;
  upsertDemandThresholds: DemandThresholds;
  updateDemandForecastShifts: DemandForecast;
  upsertDemandForecast: DemandForecast;
  createTopTip?: Maybe<TopTip>;
  updateTopTip?: Maybe<TopTip>;
  deleteTopTip?: Maybe<TopTip>;
  createEmploymentException?: Maybe<EmploymentException>;
  updateEmploymentException?: Maybe<EmploymentException>;
  deleteEmploymentException?: Maybe<EmploymentException>;
  setPassword?: Maybe<SetPassword>;
  sendOtpEmail?: Maybe<SendOTPEmail>;
  sendOtpSms?: Maybe<SendOTPSMS>;
  sendOtpSmsV2?: Maybe<SendOTPSMSV2>;
  verifyEmail?: Maybe<VerifyEmail>;
  verifyPhonenumber?: Maybe<VerifyPhonenumber>;
  forgotPassword?: Maybe<ForgotPassword>;
  loginAdmin?: Maybe<LoginAdmin>;
  loginEmployer?: Maybe<LoginEmployer>;
  loginStudent?: Maybe<LoginStudent>;
  /** @deprecated Replaced in favor of loginStudent, loginEmployer */
  loginMobileUser?: Maybe<LoginMobileUser>;
  /** @deprecated Replaced in favor of loginAdmin, loginStudent, loginEmployer */
  tokenAuth?: Maybe<ObtainJSONWebToken>;
  verifyToken?: Maybe<Verify>;
  refreshToken?: Maybe<Refresh>;
  resetPassword?: Maybe<ResetPassword>;
  confirmEmployeeDisclaimer: EmployeeDisclaimerInfo;
  createAgreement: Agreement;
  updateAgreement: Agreement;
  checkAddressAvailability?: Maybe<CheckAddressAvailability>;
  requestToBeForgotten?: Maybe<RequestToBeForgottenResult>;
  newUpdateMyProfile?: Maybe<UpdateMyProfile>;
  updateMyProfilePicture?: Maybe<UpdateMyProfilePicture>;
  bulkUpdateCanSeeSurge?: Maybe<SuccessType>;
  createStinterRTWStatusWithIDSP: Scalars['ID'];
  createStinterRTWStatusWithShareCode: Scalars['ID'];
  createStintStudentReview: StintStudentReview;
};

export type MutationcreateUniversityArgs = {
  cityId?: Maybe<Scalars['ID']>;
  domain: Scalars['String'];
  group?: Maybe<Scalars['String']>;
  imageOneX?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type MutationupdateUniversityArgs = {
  cityId?: Maybe<Scalars['ID']>;
  domain?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  imageOneX?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type MutationcreateTagArgs = {
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type MutationupdateTagArgs = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type MutationcreateStudentSavedPlaceArgs = {
  displayAddress: Scalars['String'];
  name: Scalars['String'];
  placeId: Scalars['String'];
};

export type MutationupdateStudentSavedPlaceArgs = {
  displayAddress: Scalars['String'];
  name: Scalars['String'];
  placeId: Scalars['String'];
  id: Scalars['ID'];
};

export type MutationdeleteStudentSavedPlaceArgs = {
  id: Scalars['ID'];
};

export type MutationupsertStintUserAvailabilityArgs = {
  cityId: Scalars['ID'];
  dayAvailability: Array<Maybe<AvailabilityInput>>;
  inputType?: Maybe<StudentAvailabilityInputType>;
  override?: Maybe<Scalars['Boolean']>;
  studentSavedPlacesIds: Array<Maybe<Scalars['ID']>>;
};

export type MutationdeleteStintUserAvailabilityArgs = {
  id: Scalars['ID'];
};

export type MutationcreateStintTemplateArgs = {
  input?: Maybe<CreateStintTemplateInput>;
};

export type MutationupdateStintTemplateArgs = {
  input?: Maybe<UpdateStintTemplateInput>;
};

export type MutationcreateStintTemplateGroupArgs = {
  input: CreateStintTemplateGroupInput;
};

export type MutationupdateStintTemplateGroupArgs = {
  input: UpdateStintTemplateGroupInput;
};

export type MutationconfirmRightToWorkDocUploadArgs = {
  userId: Scalars['ID'];
};

export type MutationupdateStinterMarketingConsentArgs = {
  updateInputs: UpdateStinterMarketingConsentInput;
};

export type MutationupdateStinterMarketingChannelArgs = {
  input: UpdateStinterMarketingChannelInput;
};

export type MutationcreateStintTaskArgs = {
  businessCategoryId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  roleId?: Maybe<Scalars['ID']>;
  taskType?: Maybe<Scalars['String']>;
};

export type MutationupdateStintTaskArgs = {
  name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  taskType?: Maybe<Scalars['String']>;
  topTips?: Maybe<Array<Maybe<TaskTopTipInput>>>;
};

export type MutationdeleteStintTaskArgs = {
  id: Scalars['ID'];
};

export type MutationcreateStintArgs = {
  createInputs: CreateStintInput;
};

export type MutationcreateStintsArgs = {
  createInputs: CreateStintsInput;
};

export type MutationupdateStintTimesArgs = {
  updateInputs: UpdateStintTimesInput;
};

export type MutationupdateActualLeaveTimeArgs = {
  updateInputs: UpdateActualLeaveTimeInput;
};

export type MutationcancelStintAsStinterArgs = {
  input: CancelStintAsStinterInput;
};

export type MutationacceptStintArgs = {
  lastModifiedAt?: Maybe<Scalars['AWSDateTime']>;
  id: Scalars['ID'];
};

export type MutationacceptAvailableStintArgs = {
  acceptanceMethod?: Maybe<AcceptanceMethod>;
  lastModifiedAt?: Maybe<Scalars['AWSDateTime']>;
  id: Scalars['ID'];
};

export type MutationupdateStintopediaArgs = {
  aboutUs?: Maybe<Scalars['String']>;
  accessToAnnualPlAccounts?: Maybe<Scalars['Boolean']>;
  accessToRotaExamples?: Maybe<Scalars['Boolean']>;
  accessToTransactionSalesData?: Maybe<Scalars['Boolean']>;
  agreedTakePartInMarketingInitiatives?: Maybe<Scalars['Boolean']>;
  agreedToContinuePostingIfWeSeeDesireResults?: Maybe<Scalars['Boolean']>;
  agreedToOperateStaffingRota?: Maybe<Scalars['Boolean']>;
  agreedToReferFriendsIfFinancialMetricsImproved?: Maybe<Scalars['Boolean']>;
  bookedOnboardingMeeting?: Maybe<Scalars['Boolean']>;
  businessId: Scalars['ID'];
  businessLogo?: Maybe<Scalars['String']>;
  characteristicIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  donts?: Maybe<Array<Maybe<Scalars['String']>>>;
  dos?: Maybe<Array<Maybe<Scalars['String']>>>;
  emoji?: Maybe<Scalars['String']>;
  exceptionIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  facebook?: Maybe<Scalars['String']>;
  generalPhoto1?: Maybe<Scalars['String']>;
  generalPhoto2?: Maybe<Scalars['String']>;
  generalPhoto3?: Maybe<Scalars['String']>;
  generalPhoto4?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  linkedin?: Maybe<Scalars['String']>;
  onboardingComplete?: Maybe<Scalars['Boolean']>;
  photoOfEntrance?: Maybe<Scalars['String']>;
  preferredLanguageIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  prepVideoUrl?: Maybe<Scalars['String']>;
  qrCodeLocation?: Maybe<Scalars['String']>;
  teamStructure?: Maybe<Scalars['String']>;
  usingStintCorrectly?: Maybe<Scalars['Boolean']>;
  website?: Maybe<Scalars['String']>;
};

export type MutationrecordOfferSeenEventArgs = {
  eventType: OfferSeenType;
  ids: Array<Maybe<Scalars['ID']>>;
};

export type MutationupdateStintArgs = {
  cancellationFee?: Maybe<Scalars['Float']>;
  cancellationFeeOverride?: Maybe<Scalars['Boolean']>;
  checkInDate?: Maybe<Scalars['AWSDateTime']>;
  controlCheck?: Maybe<Scalars['Boolean']>;
  dateFrom?: Maybe<Scalars['AWSDateTime']>;
  dateTo?: Maybe<Scalars['AWSDateTime']>;
  displayAddress?: Maybe<Scalars['String']>;
  exceptions?: Maybe<Array<Maybe<StintTemplateExceptionInput>>>;
  isBestEffort?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  overrideBaseFee?: Maybe<Scalars['Float']>;
  placeId?: Maybe<Scalars['String']>;
  primaryTasks?: Maybe<Array<Maybe<StintTemplateTaskInput>>>;
  secondaryTasks?: Maybe<Array<Maybe<StintTemplateTaskInput>>>;
  status?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  studentBaseFeeOverrideGrossAmount?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['ID']>;
  studentSurgeBaseFeeGrossAmount?: Maybe<Scalars['Float']>;
  studentUnassignedReasons?: Maybe<Array<Maybe<StudentUnassignedReasons>>>;
  topTips?: Maybe<Array<Maybe<StintTemplateTopTipInput>>>;
  uniform?: Maybe<Array<Maybe<StintUniform>>>;
};

export type MutationdeleteStintArgs = {
  id: Scalars['ID'];
};

export type MutationcheckInStintArgs = {
  qrCodeId: Scalars['ID'];
  stintId: Scalars['ID'];
};

export type MutationconfirmAttendStintArgs = {
  stintId: Scalars['ID'];
};

export type MutationoverrideStintCheckInArgs = {
  checkInDate: Scalars['AWSDateTime'];
  differentStartTimeReasons?: Maybe<Array<Maybe<DifferentStartTimeReason>>>;
  manualCheckInReasons?: Maybe<Array<Maybe<ManualCheckInReason>>>;
  id: Scalars['ID'];
};

export type MutationupdateStintUserArgs = {
  dateOfBirth?: Maybe<Scalars['AWSDate']>;
  deactivated?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  exceptions?: Maybe<Array<Maybe<StintTemplateExceptionInput>>>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<PaymentMethodInput>;
  permanentDeactivated?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  secondaryEmail?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  studentProfile?: Maybe<StudentProfileInput>;
  universityId?: Maybe<Scalars['ID']>;
};

export type MutationverifyStudentUserArgs = {
  studentUserIds: Array<Maybe<Scalars['ID']>>;
};

export type MutationupdateMeArgs = {
  currentCityId?: Maybe<Scalars['ID']>;
};

export type MutationcancelStintsArgs = {
  stintRefs: Array<Maybe<Scalars['ID']>>;
};

export type MutationupdateStintJourneyArgs = {
  checkInDate?: Maybe<Scalars['AWSDateTime']>;
  differentStartTimeReasons?: Maybe<Array<Maybe<DifferentStartTimeReason>>>;
  estimatedTimeOfArrival?: Maybe<Scalars['AWSDateTime']>;
  estimatedTimeOfDeparture?: Maybe<Scalars['AWSDateTime']>;
  internalNotes?: Maybe<Scalars['String']>;
  lateNote?: Maybe<Scalars['String']>;
  statusResolved?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};

export type MutationsetDoNotRemindAboutGeotrackingArgs = {
  stintId: Scalars['ID'];
};

export type MutationmanualGeotrackingStatusUpdateArgs = {
  id: Scalars['ID'];
};

export type MutationresolveStintJourneysArgs = {
  stintJourneyIds: Array<Scalars['ID']>;
};

export type MutationqualifyForTaskArgs = {
  input: QualifyForTaskInput;
};

export type MutationupsertVideoAssessmentInfoArgs = {
  input: UpsertVideoAssessmentInfoInput;
};

export type MutationcreateWorkExperienceArgs = {
  createInputs: CreateWorkExperienceInput;
};

export type MutationsearchGooglePlacesArgs = {
  returnType?: Maybe<GooglePlacesReturnType>;
  searchTerm: Scalars['String'];
};

export type MutationcreateStintWeekTemplateArgs = {
  businessId: Scalars['ID'];
  displayAddress?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  peekTimes?: Maybe<Array<Maybe<Scalars['String']>>>;
  placeId?: Maybe<Scalars['String']>;
  sessions: Array<Maybe<StintWeekTemplateSessionInput>>;
};

export type MutationupdateStintWeekTemplateArgs = {
  displayAddress?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  placeId?: Maybe<Scalars['String']>;
  sessions: Array<Maybe<StintWeekTemplateSessionInput>>;
  id: Scalars['ID'];
};

export type MutationdeleteStintWeekTemplateArgs = {
  id: Scalars['ID'];
};

export type MutationbookStintWeekTemplateArgs = {
  selectedWeeks: Array<Maybe<WeekSelectionInput>>;
  id: Scalars['ID'];
};

export type MutationsetDefaultStintWeekTemplateArgs = {
  input: SetDefaultStintWeekTemplateInput;
};

export type MutationclearDefaultStintWeekTemplateArgs = {
  updateInputs: ClearDefaultStintWeekTemplateInput;
};

export type MutationinitiateShareCodeScreeningArgs = {
  input: InitiateShareCodeScreeningInput;
};

export type MutationcreateRegionArgs = {
  countryId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type MutationupdateRegionArgs = {
  countryId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  id: Scalars['ID'];
};

export type MutationdeleteRegionArgs = {
  id: Scalars['ID'];
};

export type MutationcreatePostalCodeArgs = {
  city_id: Scalars['ID'];
  postalCode: Scalars['String'];
};

export type MutationupdatePostalCodeArgs = {
  city_id?: Maybe<Scalars['ID']>;
  postalCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type MutationdeletePostalCodeArgs = {
  id: Scalars['ID'];
};

export type MutationcreatePoolMemberFeedbackArgs = {
  input: CreatePoolMemberFeedbackInput;
};

export type MutationaddStinterCommitmentArgs = {
  input: AddStinterCommitmentInput;
};

export type MutationupdateStinterCommitmentArgs = {
  input: UpdateStinterCommitmentInput;
};

export type MutationaddStintersToStintTemplatePoolArgs = {
  input: AddStintersToStintTemplatePoolInput;
};

export type MutationremoveStintersFromStintTemplatePoolArgs = {
  input: RemoveStintersFromStintTemplatePoolInput;
};

export type MutationremoveStinterFromStintTemplatePoolArgs = {
  input: RemoveStinterFromStintTemplatePoolInput;
};

export type MutationapproveTrialStinterForStintTemplatePoolArgs = {
  input: ApproveTrialStinterForStintTemplatePoolInput;
};

export type MutationaddStintersToPartnerPoolArgs = {
  input: AddStintersToPartnerPoolInput;
};

export type MutationcreatePartnerPoolArgs = {
  input: CreatePartnerPoolInput;
};

export type MutationremoveStintersFromPartnerPoolArgs = {
  input: RemoveStintersFromPartnerPoolInput;
};

export type MutationremoveAllStintersFromPartnerPoolArgs = {
  input: RemoveAllStintersFromPartnerPoolInput;
};

export type MutationupdatePartnerPoolArgs = {
  input: UpdatePartnerPoolInput;
};

export type MutationmarkPartnerPoolInvitesAsSeenByStinterArgs = {
  input: MarkPartnerPoolInvitesAsSeenByStinterInput;
};

export type MutationmarkInviteAsAppliedArgs = {
  input: MarkInviteAsAppliedInput;
};

export type MutationcreateStripeDebitArgs = {
  businessId: Scalars['ID'];
  isPrimaryMethod: Scalars['Boolean'];
  sessionId: Scalars['String'];
};

export type MutationcreateStripeCardArgs = {
  businessId: Scalars['ID'];
  intentId: Scalars['String'];
  isPrimaryMethod: Scalars['Boolean'];
};

export type MutationdeleteStripeCardArgs = {
  id: Scalars['ID'];
};

export type MutationcreateStripeSetupIntentArgs = {
  businessId?: Maybe<Scalars['ID']>;
};

export type MutationcreateStripeCheckoutSessionArgs = {
  businessId: Scalars['ID'];
  createInputs?: Maybe<CreateCheckoutSessionInput>;
};

export type MutationupdatePartnerSettingsArgs = {
  input: UpdatePartnerSettingsInput;
};

export type MutationcreateAccountNodeArgs = {
  createInputs: CreateAccountNodeInput;
};

export type MutationcreateGroupNodeArgs = {
  createInputs: CreateGroupNodeInput;
};

export type MutationcreateSiteNodeArgs = {
  createInputs: CreateSiteNodeInput;
};

export type MutationupdateAccountNodeArgs = {
  updateInputs: UpdateAccountNodeInput;
};

export type MutationupdateGroupNodeArgs = {
  updateInputs: UpdateGroupNodeInput;
};

export type MutationupdatePartnerNodeParentArgs = {
  updateInputs: UpdatePartnerNodeParentInput;
};

export type MutationupdateSiteNodeArgs = {
  updateInputs: UpdateSiteNodeInput;
};

export type MutationregenerateSiteQRCodeArgs = {
  input: RegenerateSiteQRCodeInput;
};

export type MutationcreatePartnerMemberArgs = {
  input: CreatePartnerMemberInput;
};

export type MutationupdatePartnerMemberArgs = {
  input: UpdatePartnerMemberInput;
};

export type MutationdeletePartnerMemberArgs = {
  deleteInputs: DeletePartnerMemberInput;
};

export type MutationupdatePartnerContactArgs = {
  updateInputs: UpdatePartnerContactInput;
};

export type MutationcreatePartnerBrandArgs = {
  createInputs: CreatePartnerBrandInput;
};

export type MutationupdatePartnerBrandArgs = {
  updateInputs: UpdatePartnerBrandInput;
};

export type MutationcreateCompaniesHouseInformationArgs = {
  createInputs: CreateCompaniesHouseInformationInput;
};

export type MutationupdateCompaniesHouseInformationArgs = {
  updateInputs: UpdateCompaniesHouseInformationInput;
};

export type MutationcreateNotificationArgs = {
  message: Scalars['String'];
  sendPushNotification?: Maybe<Scalars['Boolean']>;
  stint_id?: Maybe<Scalars['ID']>;
  student_ids: Array<Maybe<Scalars['String']>>;
  title: Scalars['String'];
};

export type MutationcreateAppLifecycleEventArgs = {
  businessId?: Maybe<Scalars['ID']>;
  eventType: AppLifecycleEventEventTypes;
};

export type MutationacknowledgeNotificationOpenedArgs = {
  notificationId: Scalars['ID'];
};

export type MutationupdateEditableSettingArgs = {
  advancedOperationalWindowEndTime?: Maybe<Scalars['AWSTime']>;
  advancedOperationalWindowStartTime?: Maybe<Scalars['AWSTime']>;
  automatedTestingEnabled?: Maybe<Scalars['Boolean']>;
  availabilityMatchingBufferHours?: Maybe<Scalars['Int']>;
  availabilityMatchingMaxOffersFriday?: Maybe<Scalars['Int']>;
  availabilityMatchingMaxOffersMonday?: Maybe<Scalars['Int']>;
  availabilityMatchingMaxOffersPerDay?: Maybe<Scalars['Int']>;
  availabilityMatchingMaxOffersSaturday?: Maybe<Scalars['Int']>;
  availabilityMatchingMaxOffersSunday?: Maybe<Scalars['Int']>;
  availabilityMatchingMaxOffersThursday?: Maybe<Scalars['Int']>;
  availabilityMatchingMaxOffersTuesday?: Maybe<Scalars['Int']>;
  availabilityMatchingMaxOffersWednesday?: Maybe<Scalars['Int']>;
  availabilityMatchingNotificationForNoOffersEnabled?: Maybe<
    Scalars['Boolean']
  >;
  baseFee?: Maybe<Scalars['Float']>;
  baseFeeStudent?: Maybe<Scalars['Float']>;
  etdCalculationAfterMiddayOffsetInMinutes?: Maybe<Scalars['Int']>;
  etdCalculationBeforeMiddayOffsetInMinutes?: Maybe<Scalars['Int']>;
  featureFlagEtdNotificationsEnabled?: Maybe<Scalars['Boolean']>;
  featureFlagStintSecondStartReminderEnabled?: Maybe<Scalars['Boolean']>;
  featureFlagStudentGoingToBeLateSmsEnabled?: Maybe<Scalars['Boolean']>;
  geoTrackingBufferTime?: Maybe<Scalars['Int']>;
  matchingAlgoNoOfferMaxCountPerTimeWindow?: Maybe<Scalars['Int']>;
  matchingAlgoNoOfferMessage?: Maybe<Scalars['String']>;
  matchingAlgoNoOfferTimeWindowSpan?: Maybe<Scalars['Int']>;
  matchingAlgoNoOfferTitle?: Maybe<Scalars['String']>;
  matchingAlgoNotificationDelayInMinutes?: Maybe<Scalars['Int']>;
  notificationBlockerWindowHours?: Maybe<Scalars['Float']>;
  notificationTopUpThresholdInHours?: Maybe<Scalars['Int']>;
  predictionLeadDays?: Maybe<Scalars['Int']>;
  sendingStudentLeaveReminderAtRouteTimeEnabled?: Maybe<Scalars['Boolean']>;
  serverDatetime?: Maybe<Scalars['AWSDateTime']>;
  stintOfferExpiryThresholdInHours?: Maybe<Scalars['Int']>;
  studentCancellationOnHoldEmailEnabled?: Maybe<Scalars['Boolean']>;
};

export type MutationinitiatePartnerFileUploadArgs = {
  input: InitiatePartnerFileUploadInput;
};

export type MutationcreateOffenceArgs = {
  createInputs: CreateOffenceInput;
};

export type MutationadminCreateAppealArgs = {
  createInputs: AdminCreateAppealInput;
};

export type MutationupsertDeviceInfoArgs = {
  appVersion?: Maybe<Scalars['String']>;
  hasNotificationsEnabled?: Maybe<Scalars['Boolean']>;
  locationSetting?: Maybe<LocationSettings>;
  platform?: Maybe<Scalars['String']>;
  uniqueId: Scalars['String'];
};

export type MutationcreateCountryArgs = {
  name: Scalars['String'];
  regions: Scalars['String'];
  shortCode: Scalars['String'];
};

export type MutationupdateCountryArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  regions?: Maybe<Scalars['String']>;
  shortCode?: Maybe<Scalars['String']>;
};

export type MutationdeleteCountryArgs = {
  id: Scalars['ID'];
};

export type MutationcreateCityArgs = {
  baseFee: Scalars['Float'];
  baseFeeStudent: Scalars['Float'];
  isActive?: Maybe<Scalars['Boolean']>;
  isAvailabilityEnabled?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  regionId: Scalars['ID'];
  repeatStintersEnabled?: Maybe<Scalars['Boolean']>;
  sessionUrl?: Maybe<Scalars['AWSURL']>;
  shortCode: Scalars['String'];
};

export type MutationupdateCityArgs = {
  baseFee?: Maybe<Scalars['Float']>;
  baseFeeStudent?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  isAvailabilityEnabled?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  postalCodeIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  regionId?: Maybe<Scalars['ID']>;
  repeatStintersEnabled?: Maybe<Scalars['Boolean']>;
  sessionUrl?: Maybe<Scalars['AWSURL']>;
  shortCode?: Maybe<Scalars['String']>;
};

export type MutationdeleteCityArgs = {
  id: Scalars['ID'];
};

export type MutationupdateBusinessArgs = {
  id: Scalars['ID'];
  businessLogo?: Maybe<Scalars['String']>;
  controlCheck?: Maybe<Scalars['Boolean']>;
  displayAddress?: Maybe<Scalars['String']>;
  exemptFromPaymentDetails?: Maybe<Scalars['Boolean']>;
  integrated?: Maybe<Scalars['Boolean']>;
  internalRanking?: Maybe<Scalars['Int']>;
  isBlockedByFinance?: Maybe<Scalars['Boolean']>;
  ltdName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  onboardingRanking?: Maybe<Scalars['Int']>;
  phoneNumber?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  rateOverride?: Maybe<BusinessRateOverrideInput>;
  repeatStintersEnabled?: Maybe<Scalars['Boolean']>;
  status?: Maybe<BusinessStatusChoices>;
  stintopedia?: Maybe<StintopediaInput>;
  unlockedLastMinuteStints?: Maybe<Scalars['Boolean']>;
};

export type MutationemployerCancelStintArgs = {
  id: Scalars['ID'];
};

export type MutationtriggerAutoschedulerArgs = {
  input: TriggerAutoschedulerInput;
};

export type MutationupdateScheduleShiftsArgs = {
  input: UpdateScheduleShiftsInput;
};

export type MutationupsertLabourTemplateArgs = {
  input: UpsertLabourTemplateInput;
};

export type MutationupsertDemandThresholdsArgs = {
  input: UpsertDemandThresholdsInput;
};

export type MutationupdateDemandForecastShiftsArgs = {
  input: UpdateDemandForecastShiftsInput;
};

export type MutationupsertDemandForecastArgs = {
  input: UpsertDemandForecastInput;
};

export type MutationcreateTopTipArgs = {
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  taskId?: Maybe<Scalars['ID']>;
};

export type MutationupdateTopTipArgs = {
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  taskId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
};

export type MutationdeleteTopTipArgs = {
  id: Scalars['ID'];
};

export type MutationcreateEmploymentExceptionArgs = {
  category?: Maybe<EmploymentExceptionCategoryInputType>;
  name: Scalars['String'];
};

export type MutationupdateEmploymentExceptionArgs = {
  category?: Maybe<EmploymentExceptionCategoryInputType>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type MutationdeleteEmploymentExceptionArgs = {
  id: Scalars['ID'];
};

export type MutationsetPasswordArgs = {
  password: Scalars['String'];
};

export type MutationsendOtpEmailArgs = {
  email: Scalars['String'];
};

export type MutationsendOtpSmsArgs = {
  phoneNumber: Scalars['String'];
};

export type MutationsendOtpSmsV2Args = {
  userId: Scalars['String'];
};

export type MutationverifyEmailArgs = {
  email: Scalars['String'];
  otp: Scalars['String'];
};

export type MutationverifyPhonenumberArgs = {
  otp: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type MutationforgotPasswordArgs = {
  email: Scalars['String'];
};

export type MutationloginAdminArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MutationloginEmployerArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MutationloginStudentArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MutationloginMobileUserArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MutationtokenAuthArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MutationverifyTokenArgs = {
  token?: Maybe<Scalars['String']>;
};

export type MutationrefreshTokenArgs = {
  refreshToken?: Maybe<Scalars['String']>;
};

export type MutationresetPasswordArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
};

export type MutationconfirmEmployeeDisclaimerArgs = {
  input: ConfirmEmployeeDisclaimerInput;
};

export type MutationcreateAgreementArgs = {
  input: CreateAgreementInput;
};

export type MutationupdateAgreementArgs = {
  input: UpdateAgreementInput;
};

export type MutationcheckAddressAvailabilityArgs = {
  displayAddress?: Maybe<Scalars['String']>;
  placeId: Scalars['String'];
};

export type MutationrequestToBeForgottenArgs = {
  deactivateIfUnforgettable?: Maybe<Scalars['Boolean']>;
  input?: Maybe<RequestToBeForgottenInput>;
};

export type MutationnewUpdateMyProfileArgs = {
  currentPassword?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  newPassword?: Maybe<Scalars['String']>;
  profilePhoto?: Maybe<Scalars['String']>;
};

export type MutationupdateMyProfilePictureArgs = {
  photo?: Maybe<Scalars['String']>;
};

export type MutationbulkUpdateCanSeeSurgeArgs = {
  canSeeSurge: Scalars['Boolean'];
  userFile: Scalars['String'];
};

export type MutationcreateStinterRTWStatusWithIDSPArgs = {
  input?: Maybe<StinterRTWIDSPInput>;
};

export type MutationcreateStinterRTWStatusWithShareCodeArgs = {
  input: StinterRTWShareCodeInput;
};

export type MutationcreateStintStudentReviewArgs = {
  input: StintStudentReviewInput;
};

export type MyEmployerProfile = {
  __typename?: 'MyEmployerProfile';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  selectedBusiness?: Maybe<Business>;
  myPartnerAccount?: Maybe<MyPartnerAccount>;
  selectedSite?: Maybe<PartnerSiteProfile>;
};

export type MyPartnerAccount = {
  __typename?: 'MyPartnerAccount';
  id: Scalars['ID'];
  name: Scalars['String'];
  partnerPermissions: Array<Maybe<PartnerPermission>>;
};

export type Node = {
  id: Scalars['ID'];
};

export enum NodeType {
  ACCOUNT = 'ACCOUNT',
  GROUP = 'GROUP',
  SITE = 'SITE',
}

export type Notification = {
  __typename?: 'Notification';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  modifiedAt: Scalars['AWSDateTime'];
  deletedAt?: Maybe<Scalars['AWSDateTime']>;
  ref: Scalars['ID'];
  title: Scalars['String'];
  message: Scalars['String'];
  sendPushNotification: Scalars['Boolean'];
  sendTextMessage: Scalars['Boolean'];
  status: NotificationStatus;
  subscribeSuccessCount?: Maybe<Scalars['Int']>;
  subscribeFailureCount?: Maybe<Scalars['Int']>;
  errorMessage?: Maybe<Scalars['String']>;
  notificationType?: Maybe<Scalars['String']>;
  isSilent: Scalars['Boolean'];
  extraData?: Maybe<Scalars['AWSJSON']>;
  sendType: NotificationSendType;
  relatedObjects?: Maybe<Scalars['AWSJSON']>;
  recipients: Array<NotificationRecipient>;
  stint?: Maybe<Stint>;
  business?: Maybe<Business>;
  recipientsCount?: Maybe<Scalars['Int']>;
  openedCount?: Maybe<Scalars['Int']>;
};

export type NotificationFilter = {
  title?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  idIcontains?: Maybe<Scalars['String']>;
  recipient_id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_isnull?: Maybe<Scalars['Boolean']>;
  createdAt_gt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_gte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  createdAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_isnull?: Maybe<Scalars['Boolean']>;
  modifiedAt_gt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_gte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  title_lt?: Maybe<Scalars['String']>;
  title_search?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_isnull?: Maybe<Scalars['Boolean']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_iendswith?: Maybe<Scalars['String']>;
  title_istartswith?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  message_lt?: Maybe<Scalars['String']>;
  message_search?: Maybe<Scalars['String']>;
  message_lte?: Maybe<Scalars['String']>;
  message_isnull?: Maybe<Scalars['Boolean']>;
  message_gt?: Maybe<Scalars['String']>;
  message_gte?: Maybe<Scalars['String']>;
  message_iendswith?: Maybe<Scalars['String']>;
  message_istartswith?: Maybe<Scalars['String']>;
  sendPushNotification?: Maybe<Scalars['Boolean']>;
  sendPushNotification_lt?: Maybe<Scalars['Boolean']>;
  sendPushNotification_lte?: Maybe<Scalars['Boolean']>;
  sendPushNotification_isnull?: Maybe<Scalars['Boolean']>;
  sendPushNotification_gt?: Maybe<Scalars['Boolean']>;
  sendPushNotification_gte?: Maybe<Scalars['Boolean']>;
  sendPushNotification_iendswith?: Maybe<Scalars['Boolean']>;
  sendPushNotification_istartswith?: Maybe<Scalars['Boolean']>;
  sendTextMessage?: Maybe<Scalars['Boolean']>;
  sendTextMessage_lt?: Maybe<Scalars['Boolean']>;
  sendTextMessage_lte?: Maybe<Scalars['Boolean']>;
  sendTextMessage_isnull?: Maybe<Scalars['Boolean']>;
  sendTextMessage_gt?: Maybe<Scalars['Boolean']>;
  sendTextMessage_gte?: Maybe<Scalars['Boolean']>;
  sendTextMessage_iendswith?: Maybe<Scalars['Boolean']>;
  sendTextMessage_istartswith?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  status_lt?: Maybe<Scalars['String']>;
  status_search?: Maybe<Scalars['String']>;
  status_lte?: Maybe<Scalars['String']>;
  status_isnull?: Maybe<Scalars['Boolean']>;
  status_gt?: Maybe<Scalars['String']>;
  status_gte?: Maybe<Scalars['String']>;
  status_iendswith?: Maybe<Scalars['String']>;
  status_istartswith?: Maybe<Scalars['String']>;
  subscribeSuccessCount?: Maybe<Scalars['Float']>;
  subscribeSuccessCount_lt?: Maybe<Scalars['Float']>;
  subscribeSuccessCount_lte?: Maybe<Scalars['Float']>;
  subscribeSuccessCount_isnull?: Maybe<Scalars['Boolean']>;
  subscribeSuccessCount_gt?: Maybe<Scalars['Float']>;
  subscribeSuccessCount_gte?: Maybe<Scalars['Float']>;
  subscribeSuccessCount_iendswith?: Maybe<Scalars['Float']>;
  subscribeSuccessCount_istartswith?: Maybe<Scalars['Float']>;
  subscribeFailureCount?: Maybe<Scalars['Float']>;
  subscribeFailureCount_lt?: Maybe<Scalars['Float']>;
  subscribeFailureCount_lte?: Maybe<Scalars['Float']>;
  subscribeFailureCount_isnull?: Maybe<Scalars['Boolean']>;
  subscribeFailureCount_gt?: Maybe<Scalars['Float']>;
  subscribeFailureCount_gte?: Maybe<Scalars['Float']>;
  subscribeFailureCount_iendswith?: Maybe<Scalars['Float']>;
  subscribeFailureCount_istartswith?: Maybe<Scalars['Float']>;
  errorMessage?: Maybe<Scalars['String']>;
  errorMessage_lt?: Maybe<Scalars['String']>;
  errorMessage_search?: Maybe<Scalars['String']>;
  errorMessage_lte?: Maybe<Scalars['String']>;
  errorMessage_isnull?: Maybe<Scalars['Boolean']>;
  errorMessage_gt?: Maybe<Scalars['String']>;
  errorMessage_gte?: Maybe<Scalars['String']>;
  errorMessage_iendswith?: Maybe<Scalars['String']>;
  errorMessage_istartswith?: Maybe<Scalars['String']>;
  notificationType?: Maybe<Scalars['String']>;
  notificationType_lt?: Maybe<Scalars['String']>;
  notificationType_search?: Maybe<Scalars['String']>;
  notificationType_lte?: Maybe<Scalars['String']>;
  notificationType_isnull?: Maybe<Scalars['Boolean']>;
  notificationType_gt?: Maybe<Scalars['String']>;
  notificationType_gte?: Maybe<Scalars['String']>;
  notificationType_iendswith?: Maybe<Scalars['String']>;
  notificationType_istartswith?: Maybe<Scalars['String']>;
  isSilent?: Maybe<Scalars['Boolean']>;
  isSilent_lt?: Maybe<Scalars['Boolean']>;
  isSilent_lte?: Maybe<Scalars['Boolean']>;
  isSilent_isnull?: Maybe<Scalars['Boolean']>;
  isSilent_gt?: Maybe<Scalars['Boolean']>;
  isSilent_gte?: Maybe<Scalars['Boolean']>;
  isSilent_iendswith?: Maybe<Scalars['Boolean']>;
  isSilent_istartswith?: Maybe<Scalars['Boolean']>;
  sendType?: Maybe<Scalars['String']>;
  sendType_lt?: Maybe<Scalars['String']>;
  sendType_search?: Maybe<Scalars['String']>;
  sendType_lte?: Maybe<Scalars['String']>;
  sendType_isnull?: Maybe<Scalars['Boolean']>;
  sendType_gt?: Maybe<Scalars['String']>;
  sendType_gte?: Maybe<Scalars['String']>;
  sendType_iendswith?: Maybe<Scalars['String']>;
  sendType_istartswith?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  tokens_ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  modifiedAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  message_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  sendPushNotification_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  sendTextMessage_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  status_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  subscribeSuccessCount_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  subscribeFailureCount_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  errorMessage_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  notificationType_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isSilent_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  sendType_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type NotificationRecipient = {
  __typename?: 'NotificationRecipient';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  modifiedAt: Scalars['AWSDateTime'];
  deletedAt?: Maybe<Scalars['AWSDateTime']>;
  ref: Scalars['ID'];
  recipient?: Maybe<StintUser>;
  notification?: Maybe<Notification>;
  sentAt?: Maybe<Scalars['AWSDateTime']>;
  openedAt?: Maybe<Scalars['AWSDate']>;
  notification_id?: Maybe<Scalars['ID']>;
  recipient_id?: Maybe<Scalars['ID']>;
};

export enum NotificationSendType {
  SEND_PUSH_NOTIFICATION = 'SEND_PUSH_NOTIFICATION',
  SEND_TEXT_MESSAGE = 'SEND_TEXT_MESSAGE',
}

export enum NotificationStatus {
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  PARTIALLY_SENT = 'PARTIALLY_SENT',
  SENT = 'SENT',
}

export type ObtainJSONWebToken = {
  __typename?: 'ObtainJSONWebToken';
  payload: Scalars['String'];
  refreshExpiresIn: Scalars['Int'];
  token: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type Offence = Node & {
  __typename?: 'Offence';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  reason: OffenceReason;
  strikesAwarded: Scalars['Int'];
  stint?: Maybe<Stint>;
  createdBy?: Maybe<StintUser>;
  appealStatus: OffenceAppealStatus;
  business?: Maybe<Business>;
  isRedemption: Scalars['Boolean'];
  appeals: Array<Appeal>;
};

export enum OffenceAppealStatus {
  NOT_APPEALED = 'NOT_APPEALED',
  PENDING = 'PENDING',
  UNSUCCESSFUL = 'UNSUCCESSFUL',
  SUCCESSFUL = 'SUCCESSFUL',
}

export enum OffenceReason {
  LEAVING_EARLY = 'LEAVING_EARLY',
  LATENESS = 'LATENESS',
  UNIFORM = 'UNIFORM',
  CANCELLATION_THRESHOLD_1_V2 = 'CANCELLATION_THRESHOLD_1_V2',
  CANCELLATION_THRESHOLD_2_V2 = 'CANCELLATION_THRESHOLD_2_V2',
  CANCELLATION_THRESHOLD_3_V2 = 'CANCELLATION_THRESHOLD_3_V2',
  NO_SHOW = 'NO_SHOW',
  NO_CONFIRMATION = 'NO_CONFIRMATION',
  UNRESPONSIVE_STANDBY = 'UNRESPONSIVE_STANDBY',
  GROSS_MISCONDUCT = 'GROSS_MISCONDUCT',
  PERFORMANCE = 'PERFORMANCE',
  PERFORMANCE_SENT_HOME = 'PERFORMANCE_SENT_HOME',
  UNAUTHORISED_DEPARTURE = 'UNAUTHORISED_DEPARTURE',
  ONE_STRIKE_REDEEMED = 'ONE_STRIKE_REDEEMED',
  TWO_STRIKE_REDEEMED = 'TWO_STRIKE_REDEEMED',
  THREE_STRIKE_REDEEMED = 'THREE_STRIKE_REDEEMED',
  ONE_LEGACY_STRIKE = 'ONE_LEGACY_STRIKE',
  TWO_LEGACY_STRIKES = 'TWO_LEGACY_STRIKES',
  THREE_LEGACY_STRIKES = 'THREE_LEGACY_STRIKES',
  LEGACY_CANCELLATION = 'LEGACY_CANCELLATION',
  CANCELLATION_THRESHOLD_1 = 'CANCELLATION_THRESHOLD_1',
  CANCELLATION_THRESHOLD_2 = 'CANCELLATION_THRESHOLD_2',
  CANCELLATION_THRESHOLD_3 = 'CANCELLATION_THRESHOLD_3',
}

export type OffenceTypeConnection = {
  __typename?: 'OffenceTypeConnection';
  pageInfo: PageInfo;
  edges: Array<OffenceTypeEdge>;
};

export type OffenceTypeEdge = {
  __typename?: 'OffenceTypeEdge';
  node: Offence;
  cursor: Scalars['String'];
};

export enum OfferSeenType {
  STINT_OFFER_SCREEN_OFFER_SEEN = 'STINT_OFFER_SCREEN_OFFER_SEEN',
  STINT_DASHBOARD_OFFER_SEEN = 'STINT_DASHBOARD_OFFER_SEEN',
  STINT_OFFER_SCREEN_OFFER_VIEWED = 'STINT_OFFER_SCREEN_OFFER_VIEWED',
  STINT_DASHBOARD_OFFER_VIEWED = 'STINT_DASHBOARD_OFFER_VIEWED',
}

export type OverrideStintCheckIn = {
  __typename?: 'OverrideStintCheckIn';
  stint?: Maybe<Stint>;
  business?: Maybe<Business>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
  endCursor?: Maybe<Scalars['String']>;
};

export type PageInfoType = {
  __typename?: 'PageInfoType';
  page?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  hasNext?: Maybe<Scalars['Boolean']>;
  hasPrev?: Maybe<Scalars['Boolean']>;
  totalObjects?: Maybe<Scalars['Int']>;
};

export type PaginatedPartnerNode = {
  __typename?: 'PaginatedPartnerNode';
  paginationInfo: PaginationInfo;
  objects?: Maybe<Array<Maybe<PartnerNode>>>;
};

export type Pagination = {
  total: Scalars['Int'];
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginationInfo = {
  __typename?: 'PaginationInfo';
  page: Scalars['Int'];
  pages: Scalars['Int'];
  hasNext: Scalars['Boolean'];
  hasPrev: Scalars['Boolean'];
  totalObjects?: Maybe<Scalars['Int']>;
};

export type PartnerAccountProfile = {
  __typename?: 'PartnerAccountProfile';
  id: Scalars['ID'];
  name: Scalars['String'];
  nodeType: NodeType;
  partnerBrand?: Maybe<PartnerBrand>;
  partnerMembers: Array<PartnerMember>;
  employeeDisclaimer?: Maybe<Agreement>;
  stintTemplates: StintTemplateTypeConnection;
  companiesHouseInformation: CompaniesHouseInformation;
  stintTemplateGroups: Array<StintTemplateGroup>;
};

export type PartnerAccountProfilepartnerMembersArgs = {
  input?: Maybe<GetPartnerMembersInput>;
};

export type PartnerAccountProfilestintTemplatesArgs = {
  input: GetStintTemplatesInput;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PartnerBrand = {
  __typename?: 'PartnerBrand';
  id: Scalars['ID'];
  logoUrl: Scalars['String'];
  primaryPartnerNodeId: Scalars['ID'];
};

export enum PartnerBrandError {
  DUPLICATE_PARTNER_NODE_ID = 'DUPLICATE_PARTNER_NODE_ID',
  PARTNER_NODE_ALREADY_HAS_PARTNER_BRAND = 'PARTNER_NODE_ALREADY_HAS_PARTNER_BRAND',
}

export type PartnerBrandInput = {
  logoBase64: Scalars['String'];
};

export type PartnerContact = {
  __typename?: 'PartnerContact';
  id: Scalars['ID'];
  primaryPhoneNumber: Scalars['String'];
  secondaryPhoneNumbers: Array<Scalars['String']>;
  emailAddresses: Array<Scalars['String']>;
};

export enum PartnerContactError {
  DUPLICATE_PARTNER_NODE_ID = 'DUPLICATE_PARTNER_NODE_ID',
  INVALID_EMAIL_ADDRESSES = 'INVALID_EMAIL_ADDRESSES',
  INVALID_PRIMARY_PHONE_NUMBER = 'INVALID_PRIMARY_PHONE_NUMBER',
  INVALID_SECONDARY_PHONE_NUMBERS = 'INVALID_SECONDARY_PHONE_NUMBERS',
}

export type PartnerContactInput = {
  primaryPhoneNumber: Scalars['String'];
  secondaryPhoneNumbers: Array<Scalars['String']>;
  emailAddresses: Array<Scalars['String']>;
};

export enum PartnerFileUploadType {
  EMPLOYEE_DISCLAIMER = 'EMPLOYEE_DISCLAIMER',
}

export type PartnerFinanceDetails = {
  __typename?: 'PartnerFinanceDetails';
  paymentMethod?: Maybe<PaymentMethod>;
  isBlockedByFinance: Scalars['Boolean'];
  rates: SiteRates;
  activeCard?: Maybe<StripeCard>;
  activeDebit?: Maybe<StripeDebit>;
};

export type PartnerGroupProfile = {
  __typename?: 'PartnerGroupProfile';
  id: Scalars['ID'];
  name: Scalars['String'];
  nodeType: NodeType;
  partnerBrand?: Maybe<PartnerBrand>;
  partnerMembers: Array<PartnerMember>;
  employeeDisclaimer?: Maybe<Agreement>;
  stintTemplates: StintTemplateTypeConnection;
  parentId: Scalars['ID'];
  companiesHouseInformation?: Maybe<CompaniesHouseInformation>;
  accountNodeId: Scalars['ID'];
};

export type PartnerGroupProfilepartnerMembersArgs = {
  input?: Maybe<GetPartnerMembersInput>;
};

export type PartnerGroupProfilestintTemplatesArgs = {
  input: GetStintTemplatesInput;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PartnerMember = {
  __typename?: 'PartnerMember';
  id: Scalars['ID'];
  role: PartnerRole;
  profile: PartnerMemberProfile;
  primaryPartnerNodeId: Scalars['ID'];
  employerId?: Maybe<Scalars['ID']>;
  employmentInfo?: Maybe<EmploymentInfo>;
  siteWorkingPreferences?: Maybe<SiteWorkingPreferences>;
};

export type PartnerMembersiteWorkingPreferencesArgs = {
  siteId: Scalars['ID'];
};

export enum PartnerMemberError {
  DUPLICATE_PARTNER_MEMBER = 'DUPLICATE_PARTNER_MEMBER',
  INVALID_PROFILE = 'INVALID_PROFILE',
  NOT_PRIMARY_PARTNER_NODE = 'NOT_PRIMARY_PARTNER_NODE',
  PROFILE_ALREADY_A_MEMBER_OF_PARTNER_NODE = 'PROFILE_ALREADY_A_MEMBER_OF_PARTNER_NODE',
  SITE_DOES_NOT_HAVE_BUSINESS = 'SITE_DOES_NOT_HAVE_BUSINESS',
  UNKNOWN_PARTNER_ROLE = 'UNKNOWN_PARTNER_ROLE',
  UNKNOWN_STINT_GROUP_NAME = 'UNKNOWN_STINT_GROUP_NAME',
}

export type PartnerMemberOverrideInput = {
  partnerMemberId: Scalars['ID'];
  partnerMemberProfileId: Scalars['ID'];
  expectedHours: Scalars['Int'];
  workingPreferences: WorkingPreferencesInput;
};

export type PartnerMemberProfile = Node & {
  __typename?: 'PartnerMemberProfile';
  id: Scalars['ID'];
  email: Scalars['String'];
  fullname: Scalars['String'];
  employerId?: Maybe<Scalars['ID']>;
  employmentInfo?: Maybe<EmploymentInfo>;
};

export type PartnerMemberProfileemploymentInfoArgs = {
  partnerNodeId: Scalars['ID'];
};

export type PartnerMemberProfileTypeConnection = {
  __typename?: 'PartnerMemberProfileTypeConnection';
  pageInfo: PageInfo;
  edges: Array<PartnerMemberProfileTypeEdge>;
};

export type PartnerMemberProfileTypeEdge = {
  __typename?: 'PartnerMemberProfileTypeEdge';
  node: PartnerMemberProfile;
  cursor: Scalars['String'];
};

export type PartnerMembersFilterInput = {
  email?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<PartnerRole>>;
};

export type PartnerNode = Node & {
  __typename?: 'PartnerNode';
  id: Scalars['ID'];
  name: Scalars['String'];
  nodeType: NodeType;
  parentId?: Maybe<Scalars['ID']>;
  partnerBrand?: Maybe<PartnerBrand>;
};

export enum PartnerNodeError {
  ADDRESS_ERROR = 'ADDRESS_ERROR',
  CITY_ERROR = 'CITY_ERROR',
  ALREADY_PARENT = 'ALREADY_PARENT',
  DUPLICATE_BUSINESS_REF = 'DUPLICATE_BUSINESS_REF',
  EMPLOYER_ALREADY_MEMBER = 'EMPLOYER_ALREADY_MEMBER',
  INCORRECT_NODE_TYPE = 'INCORRECT_NODE_TYPE',
  INVALID_NAME = 'INVALID_NAME',
  INVALID_NODE_TYPE = 'INVALID_NODE_TYPE',
  PARENT_ALREADY_HAS_PARTNER_BRAND = 'PARENT_ALREADY_HAS_PARTNER_BRAND',
  SITE_NODE_CANNOT_BE_PARENT = 'SITE_NODE_CANNOT_BE_PARENT',
}

export enum PartnerNodeOrderField {
  id = 'id',
  name = 'name',
}

export type PartnerNodeTypeConnection = {
  __typename?: 'PartnerNodeTypeConnection';
  pageInfo: PageInfo;
  edges: Array<PartnerNodeTypeEdge>;
};

export type PartnerNodeTypeEdge = {
  __typename?: 'PartnerNodeTypeEdge';
  node: PartnerNode;
  cursor: Scalars['String'];
};

export type PartnerNodesFilterInput = {
  rootNodeId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  nodeTypes?: Maybe<Array<NodeType>>;
};

export type PartnerNodesInput = {
  orderBy?: Maybe<PartnerNodesOrderInput>;
  filter?: Maybe<PartnerNodesFilterInput>;
};

export enum PartnerNodesOrderField {
  NAME = 'NAME',
}

export type PartnerNodesOrderInput = {
  field: PartnerNodesOrderField;
  order: SortOrder;
};

export enum PartnerPermission {
  CREATE_PARTNER_NODE = 'CREATE_PARTNER_NODE',
  READ_PARTNER_NODE = 'READ_PARTNER_NODE',
  UPDATE_PARTNER_NODE = 'UPDATE_PARTNER_NODE',
  CREATE_PARTNER_MEMBER = 'CREATE_PARTNER_MEMBER',
  DELETE_PARTNER_MEMBER = 'DELETE_PARTNER_MEMBER',
  READ_PARTNER_MEMBER = 'READ_PARTNER_MEMBER',
  UPDATE_PARTNER_MEMBER = 'UPDATE_PARTNER_MEMBER',
  CREATE_PARTNER_BRAND = 'CREATE_PARTNER_BRAND',
  UPDATE_PARTNER_BRAND = 'UPDATE_PARTNER_BRAND',
  UPDATE_PARTNER_CONTACT = 'UPDATE_PARTNER_CONTACT',
  CREATE_COMPANIES_HOUSE_INFORMATION = 'CREATE_COMPANIES_HOUSE_INFORMATION',
  UPDATE_COMPANIES_HOUSE_INFORMATION = 'UPDATE_COMPANIES_HOUSE_INFORMATION',
  CREATE_STINT = 'CREATE_STINT',
  READ_STINT = 'READ_STINT',
  UPDATE_STINT = 'UPDATE_STINT',
  CREATE_STINT_TEMPLATE = 'CREATE_STINT_TEMPLATE',
  READ_STINT_TEMPLATE = 'READ_STINT_TEMPLATE',
  UPDATE_STINT_TEMPLATE = 'UPDATE_STINT_TEMPLATE',
  CLEAR_DEFAULT_STINT_WEEK_TEMPLATE = 'CLEAR_DEFAULT_STINT_WEEK_TEMPLATE',
  SET_DEFAULT_STINT_WEEK_TEMPLATE = 'SET_DEFAULT_STINT_WEEK_TEMPLATE',
  SET_TRAINED_STINTERS = 'SET_TRAINED_STINTERS',
  CREATE_PARTNER_POOL = 'CREATE_PARTNER_POOL',
  READ_PARTNER_POOL = 'READ_PARTNER_POOL',
  UPDATE_PARTNER_POOL = 'UPDATE_PARTNER_POOL',
  CREATE_AGREEMENT = 'CREATE_AGREEMENT',
  UPDATE_AGREEMENT = 'UPDATE_AGREEMENT',
  READ_AGREEMENT = 'READ_AGREEMENT',
  INITIATE_PARTNER_FILE_UPLOAD = 'INITIATE_PARTNER_FILE_UPLOAD',
  CREATE_PAYMENT_METHOD = 'CREATE_PAYMENT_METHOD',
  UPDATE_PAYMENT_METHOD = 'UPDATE_PAYMENT_METHOD',
  READ_PAYMENT_METHOD = 'READ_PAYMENT_METHOD',
  READ_PARTNER_TO_STINTER_REVIEWS = 'READ_PARTNER_TO_STINTER_REVIEWS',
  CREATE_POOL_MEMBER_FEEDBACK = 'CREATE_POOL_MEMBER_FEEDBACK',
  READ_POOL_MEMBER_FEEDBACK = 'READ_POOL_MEMBER_FEEDBACK',
  USE_BUSINESS_INTELLIGENCE = 'USE_BUSINESS_INTELLIGENCE',
  HAS_PARTNER_ACCESS = 'HAS_PARTNER_ACCESS',
}

export type PartnerPool = Node & {
  __typename?: 'PartnerPool';
  id: Scalars['ID'];
  formUrl: Scalars['String'];
  perks?: Maybe<Scalars['String']>;
  partnerNode: PartnerNode;
  invites: PartnerPoolInviteTypeConnection;
  hasApplied: Scalars['Boolean'];
};

export type PartnerPoolinvitesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PartnerPoolInvite = Node & {
  __typename?: 'PartnerPoolInvite';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  hasBeenSeen: Scalars['Boolean'];
  stinter: StinterProfile;
  hasApplied: Scalars['Boolean'];
};

export type PartnerPoolInviteTypeConnection = {
  __typename?: 'PartnerPoolInviteTypeConnection';
  pageInfo: PageInfo;
  edges: Array<PartnerPoolInviteTypeEdge>;
};

export type PartnerPoolInviteTypeEdge = {
  __typename?: 'PartnerPoolInviteTypeEdge';
  node: PartnerPoolInvite;
  cursor: Scalars['String'];
};

export enum PartnerRole {
  OWNER = 'OWNER',
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
  VIEWER = 'VIEWER',
  MANAGER = 'MANAGER',
  SHIFT_MANAGER = 'SHIFT_MANAGER',
  TEAM_MEMBER = 'TEAM_MEMBER',
}

export type PartnerSettings = {
  __typename?: 'PartnerSettings';
  id: Scalars['ID'];
  controlCheckEnabled: Scalars['Boolean'];
  repeatStintersEnabled: Scalars['Boolean'];
  waterfallBotGroup: WaterfallBotGroup;
  businessIntelligenceSettings: BusinessIntelligenceSettings;
  autoPostingEnabled: Scalars['Boolean'];
  partnerNodeId: Scalars['ID'];
  labourToolsEnabled: Scalars['Boolean'];
};

export type PartnerSiteProfile = {
  __typename?: 'PartnerSiteProfile';
  id: Scalars['ID'];
  name: Scalars['String'];
  parentId: Scalars['ID'];
  partnerUniqueId?: Maybe<Scalars['String']>;
  nodeType: NodeType;
  businessId: Scalars['ID'];
  partnerBrand?: Maybe<PartnerBrand>;
  partnerMembers: Array<PartnerMember>;
  employeeDisclaimer?: Maybe<Agreement>;
  partnerContact: PartnerContact;
  address: Address;
  businessLogoUrl: Scalars['String'];
  partnerPermissions: Array<Maybe<PartnerPermission>>;
  partnerPool?: Maybe<PartnerPool>;
  accountNodeId: Scalars['ID'];
  autoPostingInfo?: Maybe<AutoPostingInfo>;
  averageRating?: Maybe<Scalars['Float']>;
  trainingVideoId?: Maybe<Scalars['String']>;
  partnerFinance: PartnerFinanceDetails;
  settings: PartnerSettings;
  checkInDetails: SiteCheckInDetails;
  trialPoolMembers: StintTemplatePoolMemberTypeConnection;
  stintTemplates: StintTemplateTypeConnection;
  upcomingDemandForecasts: Array<DemandForecast>;
  siteSchedule?: Maybe<SiteSchedule>;
  partnerToStinterReviews: PartnerToStinterReviewConnection;
};

export type PartnerSiteProfilepartnerMembersArgs = {
  input?: Maybe<GetPartnerMembersInput>;
};

export type PartnerSiteProfiletrialPoolMembersArgs = {
  input?: Maybe<StintTemplateTrialPoolMembersInput>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PartnerSiteProfilestintTemplatesArgs = {
  input: GetStintTemplatesInput;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PartnerSiteProfilesiteScheduleArgs = {
  weekCommencing: Scalars['AWSDate'];
};

export type PartnerSiteProfilepartnerToStinterReviewsArgs = {
  input?: Maybe<PartnerToStinterReviewsInput>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PartnerToStinterReview = Node & {
  __typename?: 'PartnerToStinterReview';
  id: Scalars['ID'];
  stinter: StintUser;
  stint: Stint;
};

export type PartnerToStinterReviewConnection = {
  __typename?: 'PartnerToStinterReviewConnection';
  pageInfo: PageInfo;
  edges: Array<PartnerToStinterReviewEdge>;
};

export type PartnerToStinterReviewEdge = {
  __typename?: 'PartnerToStinterReviewEdge';
  node: PartnerToStinterReview;
  cursor: Scalars['String'];
};

export type PartnerToStinterReviewsInput = {
  showTeamStinters?: Maybe<Scalars['Boolean']>;
};

export enum PaymentMethod {
  CARD = 'CARD',
  CREDIT = 'CREDIT',
  DIRECT_DEBIT = 'DIRECT_DEBIT',
}

export type PaymentMethodFilter = {
  accountName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  idIcontains?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_isnull?: Maybe<Scalars['Boolean']>;
  createdAt_gt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_gte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  createdAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_isnull?: Maybe<Scalars['Boolean']>;
  modifiedAt_gt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_gte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  isPrimaryMethod?: Maybe<Scalars['Boolean']>;
  isPrimaryMethod_lt?: Maybe<Scalars['Boolean']>;
  isPrimaryMethod_lte?: Maybe<Scalars['Boolean']>;
  isPrimaryMethod_isnull?: Maybe<Scalars['Boolean']>;
  isPrimaryMethod_gt?: Maybe<Scalars['Boolean']>;
  isPrimaryMethod_gte?: Maybe<Scalars['Boolean']>;
  isPrimaryMethod_iendswith?: Maybe<Scalars['Boolean']>;
  isPrimaryMethod_istartswith?: Maybe<Scalars['Boolean']>;
  accountNumber?: Maybe<Scalars['String']>;
  accountNumber_lt?: Maybe<Scalars['String']>;
  accountNumber_search?: Maybe<Scalars['String']>;
  accountNumber_lte?: Maybe<Scalars['String']>;
  accountNumber_isnull?: Maybe<Scalars['Boolean']>;
  accountNumber_gt?: Maybe<Scalars['String']>;
  accountNumber_gte?: Maybe<Scalars['String']>;
  accountNumber_iendswith?: Maybe<Scalars['String']>;
  accountNumber_istartswith?: Maybe<Scalars['String']>;
  accountName_lt?: Maybe<Scalars['String']>;
  accountName_search?: Maybe<Scalars['String']>;
  accountName_lte?: Maybe<Scalars['String']>;
  accountName_isnull?: Maybe<Scalars['Boolean']>;
  accountName_gt?: Maybe<Scalars['String']>;
  accountName_gte?: Maybe<Scalars['String']>;
  accountName_iendswith?: Maybe<Scalars['String']>;
  accountName_istartswith?: Maybe<Scalars['String']>;
  sortCode?: Maybe<Scalars['String']>;
  sortCode_lt?: Maybe<Scalars['String']>;
  sortCode_search?: Maybe<Scalars['String']>;
  sortCode_lte?: Maybe<Scalars['String']>;
  sortCode_isnull?: Maybe<Scalars['Boolean']>;
  sortCode_gt?: Maybe<Scalars['String']>;
  sortCode_gte?: Maybe<Scalars['String']>;
  sortCode_iendswith?: Maybe<Scalars['String']>;
  sortCode_istartswith?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  modifiedAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  isPrimaryMethod_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  accountNumber_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  accountName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  sortCode_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PaymentMethodInput = {
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  sortCode?: Maybe<Scalars['String']>;
};

export type PeriodicTask = {
  __typename?: 'PeriodicTask';
  id: Scalars['ID'];
  name: Scalars['String'];
  task: Scalars['String'];
  args: Scalars['String'];
  kwargs: Scalars['String'];
  queue?: Maybe<Scalars['String']>;
  exchange?: Maybe<Scalars['String']>;
  routingKey?: Maybe<Scalars['String']>;
  headers: Scalars['String'];
  priority?: Maybe<Scalars['Int']>;
  expires?: Maybe<Scalars['AWSDateTime']>;
  expireSeconds?: Maybe<Scalars['Int']>;
  oneOff: Scalars['Boolean'];
  startTime?: Maybe<Scalars['AWSDateTime']>;
  enabled: Scalars['Boolean'];
  lastRunAt?: Maybe<Scalars['AWSDateTime']>;
  totalRunCount: Scalars['Int'];
  dateChanged: Scalars['AWSDateTime'];
  description: Scalars['String'];
};

export type PersonaInquiry = {
  __typename?: 'PersonaInquiry';
  id: Scalars['String'];
  sessionToken?: Maybe<Scalars['String']>;
};

export type PoolMemberFeedback = Node & {
  __typename?: 'PoolMemberFeedback';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  member: StintTemplatePoolMember;
  submittedBy: StintUser;
  comment: Scalars['String'];
};

export type PoolMemberFeedbackTypeConnection = {
  __typename?: 'PoolMemberFeedbackTypeConnection';
  pageInfo: PageInfo;
  edges: Array<PoolMemberFeedbackTypeEdge>;
};

export type PoolMemberFeedbackTypeEdge = {
  __typename?: 'PoolMemberFeedbackTypeEdge';
  node: PoolMemberFeedback;
  cursor: Scalars['String'];
};

export enum PostMethod {
  ONE_OFF = 'ONE_OFF',
  FROM_WEEK_TEMPLATE = 'FROM_WEEK_TEMPLATE',
}

export type PostalCode = {
  __typename?: 'PostalCode';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  modifiedAt: Scalars['AWSDateTime'];
  deletedAt?: Maybe<Scalars['AWSDateTime']>;
  ref: Scalars['ID'];
  city?: Maybe<City>;
  postalCode: Scalars['String'];
  city_id?: Maybe<Scalars['ID']>;
};

export type PostalCodeFilter = {
  q?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  idIcontains?: Maybe<Scalars['String']>;
  city_id?: Maybe<Scalars['ID']>;
  city_id_isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_isnull?: Maybe<Scalars['Boolean']>;
  createdAt_gt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_gte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  createdAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_isnull?: Maybe<Scalars['Boolean']>;
  modifiedAt_gt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_gte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  postalCode?: Maybe<Scalars['String']>;
  postalCode_lt?: Maybe<Scalars['String']>;
  postalCode_search?: Maybe<Scalars['String']>;
  postalCode_lte?: Maybe<Scalars['String']>;
  postalCode_isnull?: Maybe<Scalars['Boolean']>;
  postalCode_gt?: Maybe<Scalars['String']>;
  postalCode_gte?: Maybe<Scalars['String']>;
  postalCode_iendswith?: Maybe<Scalars['String']>;
  postalCode_istartswith?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  modifiedAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  postalCode_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QualifyForTaskInput = {
  taskId: Scalars['ID'];
  stinterId: Scalars['ID'];
  stintTemplateId: Scalars['ID'];
};

export type Query = {
  __typename?: 'Query';
  studentUser?: Maybe<UserRepresentationType>;
  allStudentUsers?: Maybe<Array<Maybe<UserRepresentationType>>>;
  _allStudentUserMeta?: Maybe<ListMetadata>;
  employerUser?: Maybe<UserRepresentationType>;
  allEmployerUsers?: Maybe<Array<Maybe<UserRepresentationType>>>;
  _allEmployerUserMeta?: Maybe<ListMetadata>;
  adminUser?: Maybe<UserRepresentationType>;
  allAdminUsers?: Maybe<Array<Maybe<UserRepresentationType>>>;
  _allAdminUserMeta?: Maybe<ListMetadata>;
  universities: UniversityTypeConnection;
  trialMemberApprovedReasons: Array<TrialMemberApprovedReason>;
  tag?: Maybe<Tag>;
  studentSavedPlaces?: Maybe<StudentSavedPlaceListType>;
  stintUser?: Maybe<StintUser>;
  me?: Maybe<StintUser>;
  countStudentsCanSeeSurge?: Maybe<Scalars['Int']>;
  stintUserAvailabilities?: Maybe<StintUserAvailabilityListType>;
  stintTemplates?: Maybe<StintTemplateTypeListType>;
  stintTemplate?: Maybe<StintTemplate>;
  stintTemplateGroup: StintTemplateGroup;
  trainedStints?: Maybe<StintNodeTypeConnection>;
  stintTaskApproved?: Maybe<StintTaskListType>;
  myStinterProfile: StinterProfile;
  calendarStints?: Maybe<CalendarStintListType>;
  repeatStints: StintNodeTypeConnection;
  allAvailableStints: StintNodeTypeConnection;
  stint?: Maybe<Stint>;
  availableStints?: Maybe<StintListType>;
  stintOffers?: Maybe<StintOfferListType>;
  stinterWorkExperience?: Maybe<WorkExperience>;
  stintToConfirmByStudent?: Maybe<Stint>;
  stintWeekTemplates?: Maybe<StintWeekTemplateListType>;
  stintWeekTemplate?: Maybe<StintWeekTemplate>;
  region?: Maybe<Region>;
  postalCode?: Maybe<PostalCode>;
  partnerPoolInvites: PartnerPoolInviteTypeConnection;
  poolMemberRemovalReasons: Array<MemberRemovalReason>;
  siteProfile: PartnerSiteProfile;
  partnerTree?: Maybe<PartnerNodeTypeConnection>;
  partnerNodes: PartnerNodeTypeConnection;
  partnerMember: PartnerMember;
  groupProfile: PartnerGroupProfile;
  availablePartnerMemberProfiles: PartnerMemberProfileTypeConnection;
  accountProfile: PartnerAccountProfile;
  accountNodes?: Maybe<PaginatedPartnerNode>;
  healthCheck?: Maybe<HealthCheckType>;
  stinterOffences?: Maybe<OffenceTypeConnection>;
  offence?: Maybe<Offence>;
  myOffences?: Maybe<StinterOffenceTypeConnection>;
  cities?: Maybe<CityListType>;
  city?: Maybe<City>;
  business?: Maybe<Business>;
  businesses?: Maybe<BusinessListType>;
  myEmployerProfile?: Maybe<MyEmployerProfile>;
  employmentExceptions?: Maybe<EmploymentExceptionListType>;
  employmentException?: Maybe<EmploymentException>;
  stinter?: Maybe<Stinter>;
  users: StintUserTypeConnection;
  _debug?: Maybe<DjangoDebug>;
  Address?: Maybe<Address>;
  allAddresses?: Maybe<Array<Maybe<Address>>>;
  _allAddressesMeta?: Maybe<ListMetadata>;
  Business?: Maybe<Business>;
  allBusinesses?: Maybe<Array<Maybe<Business>>>;
  _allBusinessesMeta?: Maybe<ListMetadata>;
  BusinessCreditControlEvent?: Maybe<BusinessCreditControlEvent>;
  allBusinessCreditControlEvents?: Maybe<
    Array<Maybe<BusinessCreditControlEvent>>
  >;
  _allBusinessCreditControlEventsMeta?: Maybe<ListMetadata>;
  CardPaymentMethod?: Maybe<CardPaymentMethod>;
  allCardPaymentMethods?: Maybe<Array<Maybe<CardPaymentMethod>>>;
  _allCardPaymentMethodsMeta?: Maybe<ListMetadata>;
  Characteristic?: Maybe<Characteristic>;
  allCharacteristics?: Maybe<Array<Maybe<Characteristic>>>;
  _allCharacteristicsMeta?: Maybe<ListMetadata>;
  City?: Maybe<City>;
  allCities?: Maybe<Array<Maybe<City>>>;
  _allCitiesMeta?: Maybe<ListMetadata>;
  Country?: Maybe<Country>;
  allCountries?: Maybe<Array<Maybe<Country>>>;
  _allCountriesMeta?: Maybe<ListMetadata>;
  DeviceLocation?: Maybe<DeviceLocation>;
  allDeviceLocations?: Maybe<Array<Maybe<DeviceLocation>>>;
  _allDeviceLocationsMeta?: Maybe<ListMetadata>;
  DirectDebitPaymentMethod?: Maybe<DirectDebitPaymentMethod>;
  allDirectDebitPaymentMethods?: Maybe<Array<Maybe<DirectDebitPaymentMethod>>>;
  _allDirectDebitPaymentMethodsMeta?: Maybe<ListMetadata>;
  EditableSetting?: Maybe<EditableSetting>;
  allEditableSettings?: Maybe<Array<Maybe<EditableSetting>>>;
  _allEditableSettingsMeta?: Maybe<ListMetadata>;
  Employer?: Maybe<StintUser>;
  allEmployers?: Maybe<Array<Maybe<StintUser>>>;
  _allEmployersMeta?: Maybe<ListMetadata>;
  EmploymentException?: Maybe<EmploymentException>;
  allEmploymentExceptions?: Maybe<Array<Maybe<EmploymentException>>>;
  _allEmploymentExceptionsMeta?: Maybe<ListMetadata>;
  Hotspot?: Maybe<Hotspot>;
  allHotspots?: Maybe<Array<Maybe<Hotspot>>>;
  _allHotspotsMeta?: Maybe<ListMetadata>;
  Language?: Maybe<Language>;
  allLanguages?: Maybe<Array<Maybe<Language>>>;
  _allLanguagesMeta?: Maybe<ListMetadata>;
  Notification?: Maybe<Notification>;
  allNotifications?: Maybe<Array<Maybe<Notification>>>;
  _allNotificationsMeta?: Maybe<ListMetadata>;
  PeriodicTask?: Maybe<PeriodicTask>;
  allPeriodicTasks?: Maybe<Array<Maybe<PeriodicTask>>>;
  _allPeriodicTasksMeta?: Maybe<ListMetadata>;
  PostalCode?: Maybe<PostalCode>;
  allPostalCodes?: Maybe<Array<Maybe<PostalCode>>>;
  _allPostalCodesMeta?: Maybe<ListMetadata>;
  Region?: Maybe<Region>;
  allRegions?: Maybe<Array<Maybe<Region>>>;
  _allRegionsMeta?: Maybe<ListMetadata>;
  Stint?: Maybe<Stint>;
  allStints?: Maybe<Array<Maybe<Stint>>>;
  _allStintsMeta?: Maybe<ListMetadata>;
  StintJourney?: Maybe<StintJourney>;
  allStintJourneys?: Maybe<Array<Maybe<StintJourney>>>;
  _allStintJourneysMeta?: Maybe<ListMetadata>;
  StintOffer?: Maybe<StintOffer>;
  allStintOffers?: Maybe<Array<Maybe<StintOffer>>>;
  _allStintOffersMeta?: Maybe<ListMetadata>;
  StintTask?: Maybe<StintTask>;
  allStintTasks?: Maybe<Array<Maybe<StintTask>>>;
  _allStintTasksMeta?: Maybe<ListMetadata>;
  StintTemplate?: Maybe<StintTemplate>;
  allStintTemplates?: Maybe<Array<Maybe<StintTemplate>>>;
  _allStintTemplatesMeta?: Maybe<ListMetadata>;
  StintUser?: Maybe<StintUser>;
  allStintUsers?: Maybe<Array<Maybe<StintUser>>>;
  _allStintUsersMeta?: Maybe<ListMetadata>;
  StintWeekTemplate?: Maybe<StintWeekTemplate>;
  allStintWeekTemplates?: Maybe<Array<Maybe<StintWeekTemplate>>>;
  _allStintWeekTemplatesMeta?: Maybe<ListMetadata>;
  Stintopedia?: Maybe<Stintopedia>;
  allStintopedias?: Maybe<Array<Maybe<Stintopedia>>>;
  _allStintopediasMeta?: Maybe<ListMetadata>;
  StripeCard?: Maybe<StripeCard>;
  allStripeCards?: Maybe<Array<Maybe<StripeCard>>>;
  _allStripeCardsMeta?: Maybe<ListMetadata>;
  StripeDebit?: Maybe<StripeDebit>;
  allStripeDebits?: Maybe<Array<Maybe<StripeDebit>>>;
  _allStripeDebitsMeta?: Maybe<ListMetadata>;
  StudentOrEmployerUser?: Maybe<StintUser>;
  allStudentOrEmployerUsers?: Maybe<Array<Maybe<StintUser>>>;
  _allStudentOrEmployerUsersMeta?: Maybe<ListMetadata>;
  StudentReview?: Maybe<StudentReview>;
  allStudentReviews?: Maybe<Array<Maybe<StudentReview>>>;
  _allStudentReviewsMeta?: Maybe<ListMetadata>;
  Tag?: Maybe<Tag>;
  allTags?: Maybe<Array<Maybe<Tag>>>;
  _allTagsMeta?: Maybe<ListMetadata>;
  TopTip?: Maybe<TopTip>;
  allTopTips?: Maybe<Array<Maybe<TopTip>>>;
  _allTopTipsMeta?: Maybe<ListMetadata>;
  University?: Maybe<University>;
  allUniversities?: Maybe<Array<Maybe<University>>>;
  _allUniversitiesMeta?: Maybe<ListMetadata>;
  getStinterRightToWorkStatus?: Maybe<StinterRTWStatus>;
  getStinterRightToWorkScreening?: Maybe<StinterRTWScreening>;
  listStinterRightToWorkStatus: StinterRightToWorkStatusPagination;
  listStinterRightToWorkScreening: StinterRightToWorkScreeningPagination;
  listStinterRightToWorkStinterScreening: Array<StinterRTWScreening>;
  listStinterRightToWorkAvailableOptions: Array<StinterRTWProcessType>;
  listStintStudentReviews?: Maybe<StintStudentReviewPage>;
  listStintStudentReviewsOutstanding?: Maybe<StintObjectPage>;
};

export type QuerystudentUserArgs = {
  userId: Scalars['ID'];
};

export type QueryallStudentUsersArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
};

export type Query_allStudentUserMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
};

export type QueryemployerUserArgs = {
  userId: Scalars['ID'];
};

export type QueryallEmployerUsersArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
};

export type Query_allEmployerUserMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
};

export type QueryadminUserArgs = {
  userId: Scalars['ID'];
};

export type QueryallAdminUsersArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
};

export type Query_allAdminUserMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
};

export type QueryuniversitiesArgs = {
  input: UniversitiesInput;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QuerytagArgs = {
  id: Scalars['ID'];
};

export type QuerystudentSavedPlacesArgs = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  cityId?: Maybe<Scalars['ID']>;
};

export type QuerystintUserArgs = {
  id: Scalars['ID'];
};

export type QuerystintUserAvailabilitiesArgs = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  cityId?: Maybe<Scalars['ID']>;
};

export type QuerystintTemplatesArgs = {
  businessId?: Maybe<Scalars['ID']>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
};

export type QuerystintTemplateArgs = {
  id: Scalars['ID'];
};

export type QuerystintTemplateGroupArgs = {
  id: Scalars['ID'];
};

export type QuerytrainedStintsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QuerystintTaskApprovedArgs = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
};

export type QuerycalendarStintsArgs = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  status_In?: Maybe<Scalars['String']>;
  startDateFrom?: Maybe<Scalars['AWSDateTime']>;
  startDateTo?: Maybe<Scalars['AWSDateTime']>;
  endDateFrom?: Maybe<Scalars['AWSDateTime']>;
  endDateTo?: Maybe<Scalars['AWSDateTime']>;
};

export type QueryrepeatStintsArgs = {
  filterInputs?: Maybe<StintFilterInput>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
};

export type QueryallAvailableStintsArgs = {
  filterInputs?: Maybe<StintFilterInput>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
};

export type QuerystintArgs = {
  id: Scalars['ID'];
};

export type QueryavailableStintsArgs = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type QuerystintOffersArgs = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
};

export type QuerystintWeekTemplatesArgs = {
  businessId: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
};

export type QuerystintWeekTemplateArgs = {
  id: Scalars['ID'];
};

export type QueryregionArgs = {
  id: Scalars['ID'];
};

export type QuerypostalCodeArgs = {
  id: Scalars['ID'];
};

export type QuerypartnerPoolInvitesArgs = {
  input: GetPartnerPoolInvitesInputType;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QuerysiteProfileArgs = {
  partnerNodeId: Scalars['ID'];
};

export type QuerypartnerTreeArgs = {
  partnerNodeId: Scalars['ID'];
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QuerypartnerNodesArgs = {
  input: PartnerNodesInput;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QuerypartnerMemberArgs = {
  input: GetPartnerMemberInput;
};

export type QuerygroupProfileArgs = {
  partnerNodeId: Scalars['ID'];
};

export type QueryavailablePartnerMemberProfilesArgs = {
  input: AvailablePartnerMemberProfilesInput;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryaccountProfileArgs = {
  partnerNodeId: Scalars['ID'];
};

export type QueryaccountNodesArgs = {
  filter?: Maybe<FilterInput>;
  sortField?: Maybe<PartnerNodeOrderField>;
  sortOrder?: Maybe<SortOrder>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type QuerystinterOffencesArgs = {
  stinterProfileId: Scalars['ID'];
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryoffenceArgs = {
  offenceId: Scalars['ID'];
};

export type QuerymyOffencesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QuerycitiesArgs = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAvailabilityEnabled?: Maybe<Scalars['Boolean']>;
};

export type QuerycityArgs = {
  id: Scalars['ID'];
};

export type QuerybusinessArgs = {
  id: Scalars['ID'];
};

export type QuerybusinessesArgs = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type QueryemploymentExceptionsArgs = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
};

export type QueryemploymentExceptionArgs = {
  id: Scalars['ID'];
};

export type QuerystinterArgs = {
  stinterId: Scalars['ID'];
};

export type QueryusersArgs = {
  input: UsersInput;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryAddressArgs = {
  id: Scalars['ID'];
};

export type QueryallAddressesArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<AddressFilter>;
};

export type Query_allAddressesMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<AddressFilter>;
};

export type QueryBusinessArgs = {
  id: Scalars['ID'];
};

export type QueryallBusinessesArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<BusinessFilter>;
};

export type Query_allBusinessesMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<BusinessFilter>;
};

export type QueryBusinessCreditControlEventArgs = {
  id: Scalars['ID'];
};

export type QueryallBusinessCreditControlEventsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<BusinessCreditControlEventFilter>;
};

export type Query_allBusinessCreditControlEventsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<BusinessCreditControlEventFilter>;
};

export type QueryCardPaymentMethodArgs = {
  id: Scalars['ID'];
};

export type QueryallCardPaymentMethodsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<CardPaymentMethodFilter>;
};

export type Query_allCardPaymentMethodsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<CardPaymentMethodFilter>;
};

export type QueryCharacteristicArgs = {
  id: Scalars['ID'];
};

export type QueryallCharacteristicsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<CharacteristicFilter>;
};

export type Query_allCharacteristicsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<CharacteristicFilter>;
};

export type QueryCityArgs = {
  id: Scalars['ID'];
};

export type QueryallCitiesArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<CityFilter>;
};

export type Query_allCitiesMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<CityFilter>;
};

export type QueryCountryArgs = {
  id: Scalars['ID'];
};

export type QueryallCountriesArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<CountryFilter>;
};

export type Query_allCountriesMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<CountryFilter>;
};

export type QueryDeviceLocationArgs = {
  id: Scalars['ID'];
};

export type QueryallDeviceLocationsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<DeviceLocationFilter>;
};

export type Query_allDeviceLocationsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<DeviceLocationFilter>;
};

export type QueryDirectDebitPaymentMethodArgs = {
  id: Scalars['ID'];
};

export type QueryallDirectDebitPaymentMethodsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<PaymentMethodFilter>;
};

export type Query_allDirectDebitPaymentMethodsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<PaymentMethodFilter>;
};

export type QueryEditableSettingArgs = {
  id: Scalars['ID'];
};

export type QueryallEditableSettingsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
};

export type Query_allEditableSettingsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
};

export type QueryEmployerArgs = {
  id: Scalars['ID'];
};

export type QueryallEmployersArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<StintUserFilter>;
};

export type Query_allEmployersMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<StintUserFilter>;
};

export type QueryEmploymentExceptionArgs = {
  id: Scalars['ID'];
};

export type QueryallEmploymentExceptionsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<EmploymentExceptionFilter>;
};

export type Query_allEmploymentExceptionsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<EmploymentExceptionFilter>;
};

export type QueryHotspotArgs = {
  id: Scalars['ID'];
};

export type QueryallHotspotsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<HotspotTimeBusynessFilter>;
};

export type Query_allHotspotsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<HotspotTimeBusynessFilter>;
};

export type QueryLanguageArgs = {
  id: Scalars['ID'];
};

export type QueryallLanguagesArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<LanguageFilter>;
};

export type Query_allLanguagesMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<LanguageFilter>;
};

export type QueryNotificationArgs = {
  id: Scalars['ID'];
};

export type QueryallNotificationsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<NotificationFilter>;
};

export type Query_allNotificationsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<NotificationFilter>;
};

export type QueryPeriodicTaskArgs = {
  id: Scalars['ID'];
};

export type QueryallPeriodicTasksArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
};

export type Query_allPeriodicTasksMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
};

export type QueryPostalCodeArgs = {
  id: Scalars['ID'];
};

export type QueryallPostalCodesArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<PostalCodeFilter>;
};

export type Query_allPostalCodesMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<PostalCodeFilter>;
};

export type QueryRegionArgs = {
  id: Scalars['ID'];
};

export type QueryallRegionsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<RegionFilter>;
};

export type Query_allRegionsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<RegionFilter>;
};

export type QueryStintArgs = {
  id: Scalars['ID'];
};

export type QueryallStintsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<StintFilter>;
};

export type Query_allStintsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<StintFilter>;
};

export type QueryStintJourneyArgs = {
  id: Scalars['ID'];
};

export type QueryallStintJourneysArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<StintJourneyFilter>;
};

export type Query_allStintJourneysMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<StintJourneyFilter>;
};

export type QueryStintOfferArgs = {
  id: Scalars['ID'];
};

export type QueryallStintOffersArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<StintOfferToStudentFilter>;
};

export type Query_allStintOffersMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<StintOfferToStudentFilter>;
};

export type QueryStintTaskArgs = {
  id: Scalars['ID'];
};

export type QueryallStintTasksArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<StintTaskFilter>;
};

export type Query_allStintTasksMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<StintTaskFilter>;
};

export type QueryStintTemplateArgs = {
  id: Scalars['ID'];
};

export type QueryallStintTemplatesArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<StintTemplateFilter>;
};

export type Query_allStintTemplatesMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<StintTemplateFilter>;
};

export type QueryStintUserArgs = {
  id: Scalars['ID'];
};

export type QueryallStintUsersArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<StintUserFilter>;
};

export type Query_allStintUsersMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<StintUserFilter>;
};

export type QueryStintWeekTemplateArgs = {
  id: Scalars['ID'];
};

export type QueryallStintWeekTemplatesArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<StintWeekTemplateFilter>;
};

export type Query_allStintWeekTemplatesMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<StintWeekTemplateFilter>;
};

export type QueryStintopediaArgs = {
  id: Scalars['ID'];
};

export type QueryallStintopediasArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<StintopediaFilter>;
};

export type Query_allStintopediasMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<StintopediaFilter>;
};

export type QueryStripeCardArgs = {
  id: Scalars['ID'];
};

export type QueryallStripeCardsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<StripeCardFilter>;
};

export type Query_allStripeCardsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<StripeCardFilter>;
};

export type QueryStripeDebitArgs = {
  id: Scalars['ID'];
};

export type QueryallStripeDebitsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<StripeDebitFilter>;
};

export type Query_allStripeDebitsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<StripeDebitFilter>;
};

export type QueryStudentOrEmployerUserArgs = {
  id: Scalars['ID'];
};

export type QueryallStudentOrEmployerUsersArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<StintUserFilter>;
};

export type Query_allStudentOrEmployerUsersMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<StintUserFilter>;
};

export type QueryStudentReviewArgs = {
  id: Scalars['ID'];
};

export type QueryallStudentReviewsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<StudentReviewFilter>;
};

export type Query_allStudentReviewsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<StudentReviewFilter>;
};

export type QueryTagArgs = {
  id: Scalars['ID'];
};

export type QueryallTagsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<TagFilter>;
};

export type Query_allTagsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<TagFilter>;
};

export type QueryTopTipArgs = {
  id: Scalars['ID'];
};

export type QueryallTopTipsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<TopTipFilter>;
};

export type Query_allTopTipsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<TopTipFilter>;
};

export type QueryUniversityArgs = {
  id: Scalars['ID'];
};

export type QueryallUniversitiesArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<UniversityFilter>;
};

export type Query_allUniversitiesMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<UniversityFilter>;
};

export type QuerygetStinterRightToWorkStatusArgs = {
  stinterID?: Maybe<Scalars['ID']>;
};

export type QuerygetStinterRightToWorkScreeningArgs = {
  stinterID?: Maybe<Scalars['ID']>;
  screeeningID: Scalars['ID'];
};

export type QuerylistStinterRightToWorkStatusArgs = {
  filter?: Maybe<StinterRightToWorkStatusFilter>;
  limit?: Maybe<Scalars['Int']>;
  next?: Maybe<Scalars['String']>;
};

export type QuerylistStinterRightToWorkScreeningArgs = {
  filter?: Maybe<StinterRightToWorkScreeningFilter>;
  limit?: Maybe<Scalars['Int']>;
  next?: Maybe<Scalars['String']>;
};

export type QuerylistStinterRightToWorkStinterScreeningArgs = {
  stinterID?: Maybe<Scalars['ID']>;
};

export type QuerylistStintStudentReviewsArgs = {
  filter: StintStudentReviewFilter;
  next?: Maybe<Scalars['String']>;
};

export type QuerylistStintStudentReviewsOutstandingArgs = {
  next?: Maybe<Scalars['String']>;
};

export type RTW = {
  __typename?: 'RTW';
  screenings: Array<RTWScreening>;
};

export type RTWScreening = {
  __typename?: 'RTWScreening';
  screeningId: Scalars['String'];
  submittedAt: Scalars['AWSDateTime'];
  status: RTWStatus;
  screeningType: RTWScreeningType;
  shareCode?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['AWSDate']>;
  expiresAt?: Maybe<Scalars['AWSDateTime']>;
  termTimeWeeklyCap?: Maybe<Scalars['Float']>;
};

export enum RTWScreeningType {
  IDSP = 'IDSP',
  SHARE_CODE = 'SHARE_CODE',
}

export enum RTWStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  SUBMITTED = 'SUBMITTED',
  APPROVED = 'APPROVED',
  FAILED = 'FAILED',
  EXPIRED = 'EXPIRED',
}

export type RateOverride = {
  __typename?: 'RateOverride';
  id: Scalars['ID'];
  rate?: Maybe<Scalars['Float']>;
};

export type Refresh = {
  __typename?: 'Refresh';
  payload: Scalars['String'];
  refreshExpiresIn: Scalars['Int'];
  token: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type RegenerateSiteQRCodeInput = {
  siteId: Scalars['ID'];
};

export type Region = {
  __typename?: 'Region';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  modifiedAt: Scalars['AWSDateTime'];
  deletedAt?: Maybe<Scalars['AWSDateTime']>;
  ref: Scalars['ID'];
  country?: Maybe<Country>;
  name?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['ID']>;
};

export type RegionFilter = {
  name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  idIcontains?: Maybe<Scalars['String']>;
  country_id?: Maybe<Scalars['ID']>;
  country_id_isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_isnull?: Maybe<Scalars['Boolean']>;
  createdAt_gt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_gte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  createdAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_isnull?: Maybe<Scalars['Boolean']>;
  modifiedAt_gt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_gte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  name_lt?: Maybe<Scalars['String']>;
  name_search?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_isnull?: Maybe<Scalars['Boolean']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_iendswith?: Maybe<Scalars['String']>;
  name_istartswith?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  modifiedAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RemoveAllStintersFromPartnerPoolInput = {
  id: Scalars['ID'];
  partnerNodeId: Scalars['ID'];
};

export type RemoveStinterFromStintTemplatePoolInput = {
  stintTemplateId: Scalars['ID'];
  partnerNodeId: Scalars['ID'];
  stintTemplatePoolMemberId: Scalars['ID'];
  reasons: Array<Scalars['ID']>;
  removedBy: MemberRemovalRemovedBy;
  moreInfo?: Maybe<Scalars['String']>;
};

export type RemoveStintersFromPartnerPoolInput = {
  id: Scalars['ID'];
  partnerNodeId: Scalars['ID'];
  partnerPoolInviteIds: Array<Scalars['ID']>;
};

export type RemoveStintersFromStintTemplatePoolInput = {
  stintTemplateId: Scalars['ID'];
  partnerNodeId: Scalars['ID'];
  stintTemplatePoolMemberIds: Array<Scalars['ID']>;
};

export enum RequestToBeForgottenFailureReason {
  PERFORMED_STINT = 'PERFORMED_STINT',
  EMPLOYER_STATUS = 'EMPLOYER_STATUS',
}

export type RequestToBeForgottenInput = {
  userId: Scalars['ID'];
  actionDate?: Maybe<Scalars['AWSDateTime']>;
  deactivateIfUnforgettable?: Maybe<Scalars['Boolean']>;
};

export type RequestToBeForgottenResult = {
  __typename?: 'RequestToBeForgottenResult';
  success: Scalars['Boolean'];
  failureReason?: Maybe<RequestToBeForgottenFailureReason>;
  scheduledAt?: Maybe<Scalars['AWSDateTime']>;
};

export type ResetPassword = {
  __typename?: 'ResetPassword';
  token?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
};

export enum ReviewReviewType {
  BUSINESS = 'BUSINESS',
  STUDENT = 'STUDENT',
  INTERNAL = 'INTERNAL',
  PHANTOM = 'PHANTOM',
}

export type Schedule = {
  __typename?: 'Schedule';
  shifts: Array<Shift>;
  labourPercentage: Scalars['String'];
  expectedForecast: Scalars['Int'];
};

export enum ScheduleStatus {
  GENERATING = 'GENERATING',
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR',
}

export type SendOTPEmail = {
  __typename?: 'SendOTPEmail';
  success?: Maybe<Scalars['Boolean']>;
};

export type SendOTPSMS = {
  __typename?: 'SendOTPSMS';
  success?: Maybe<Scalars['Boolean']>;
};

export type SendOTPSMSV2 = {
  __typename?: 'SendOTPSMSV2';
  success?: Maybe<Scalars['Boolean']>;
};

export enum ServiceError {
  BAD_REQUEST = 'BAD_REQUEST',
  UNAUTHORIZED = 'UNAUTHORIZED',
  UNEXPECTED_RESPONSE = 'UNEXPECTED_RESPONSE',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

export type SetDefaultStintWeekTemplateInput = {
  templateId: Scalars['ID'];
  businessId: Scalars['ID'];
};

export type SetPassword = {
  __typename?: 'SetPassword';
  success?: Maybe<Scalars['Boolean']>;
};

export type SetupIntent = {
  __typename?: 'SetupIntent';
  id?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
};

export type Shift = {
  __typename?: 'Shift';
  shiftType: ShiftType;
  startTime: Scalars['AWSDateTime'];
  endTime: Scalars['AWSDateTime'];
  assignedPartnerMemberProfileId?: Maybe<Scalars['ID']>;
};

export type ShiftInput = {
  shiftType: ShiftType;
  startTime: Scalars['AWSDateTime'];
  endTime: Scalars['AWSDateTime'];
  assignedPartnerMemberProfileId?: Maybe<Scalars['ID']>;
};

export enum ShiftType {
  MANAGER = 'MANAGER',
  STAFF = 'STAFF',
  STINT = 'STINT',
}

export type SiteCheckInDetails = {
  __typename?: 'SiteCheckInDetails';
  id: Scalars['ID'];
  qrCodeId: Scalars['ID'];
  qrCodeLocation?: Maybe<Scalars['String']>;
  photoOfEntranceUrl?: Maybe<Scalars['String']>;
};

export type SiteRates = {
  __typename?: 'SiteRates';
  stinterHourlyRate: Scalars['Float'];
  siteHourlyFee: Scalars['Float'];
  stintHourlyFee: Scalars['Float'];
};

export type SiteSchedule = {
  __typename?: 'SiteSchedule';
  siteId: Scalars['ID'];
  weekCommencing: Scalars['AWSDate'];
  weekSchedule?: Maybe<WeekSchedule>;
  coreTeam: Array<CoreTeamMember>;
  status: ScheduleStatus;
};

export type SiteWorkingPreferences = {
  __typename?: 'SiteWorkingPreferences';
  id: Scalars['ID'];
  workingPreferences: WorkingPreferences;
  profileId: Scalars['ID'];
  siteId: Scalars['ID'];
};

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type Stint = {
  __typename?: 'Stint';
  id: Scalars['ID'];
  name: Scalars['String'];
  trained: Scalars['Boolean'];
  dateFrom?: Maybe<Scalars['AWSDateTime']>;
  dateTo?: Maybe<Scalars['AWSDateTime']>;
  status?: Maybe<Scalars['Int']>;
  postMethod?: Maybe<PostMethod>;
  createdAt: Scalars['AWSDateTime'];
  modifiedAt: Scalars['AWSDateTime'];
  primaryTasks?: Maybe<Array<Maybe<StintTask>>>;
  secondaryTasks?: Maybe<Array<Maybe<StintTask>>>;
  exceptions?: Maybe<Array<Maybe<EmploymentException>>>;
  topTips?: Maybe<Array<Maybe<TopTip>>>;
  business?: Maybe<Business>;
  uniform?: Maybe<Array<Maybe<Scalars['String']>>>;
  notes?: Maybe<Scalars['String']>;
  dateAccepted?: Maybe<Scalars['AWSDateTime']>;
  dateToProposed?: Maybe<Scalars['AWSDateTime']>;
  lastModified?: Maybe<Scalars['AWSDateTime']>;
  checkInDate?: Maybe<Scalars['AWSDateTime']>;
  checkOutDate?: Maybe<Scalars['AWSDateTime']>;
  manualCheckInReasons?: Maybe<Array<Maybe<Scalars['String']>>>;
  qrCodeCheckInDate?: Maybe<Scalars['AWSDateTime']>;
  address?: Maybe<Address>;
  city?: Maybe<City>;
  locationAddress?: Maybe<Scalars['String']>;
  locationName?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  invoiceStatus?: Maybe<StintInvoiceStatus>;
  isDirectDebit: Scalars['Boolean'];
  isFreeStint: Scalars['Boolean'];
  paymentReference?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['Int']>;
  preAuthorizedAmount?: Maybe<Scalars['Int']>;
  selectedPaymentMethod?: Maybe<DirectDebitPaymentMethod>;
  selectedCardPaymentMethod?: Maybe<CardPaymentMethod>;
  employerCostWithoutVat?: Maybe<Scalars['Float']>;
  cancellationFee?: Maybe<Scalars['Float']>;
  student?: Maybe<StintUser>;
  studentIsLate: Scalars['Boolean'];
  studentRunningLateHalfwayCheck: Scalars['Boolean'];
  eta?: Maybe<Scalars['Float']>;
  cancelledAt?: Maybe<Scalars['AWSDateTime']>;
  cancelledBy?: Maybe<StintUser>;
  nextAutomationBatchAt?: Maybe<Scalars['AWSDateTime']>;
  isAutomationComplete: Scalars['Boolean'];
  automationLastTriggeredAt?: Maybe<Scalars['AWSDateTime']>;
  automationAttempts?: Maybe<Scalars['Int']>;
  uniformInstructions?: Maybe<Scalars['String']>;
  trainingInstructions?: Maybe<Scalars['String']>;
  healthAndSafety?: Maybe<Scalars['String']>;
  opsNotifiedUnassigned: Scalars['Boolean'];
  opsNotifiedLocationOff: Scalars['Boolean'];
  quantityStudentsForDraftStint?: Maybe<Scalars['Int']>;
  quantityRepeatDaysForDraftStint?: Maybe<Scalars['Int']>;
  repeatDaysForDraftStint?: Maybe<Array<Maybe<Scalars['AWSDate']>>>;
  averageOwnerRating?: Maybe<Scalars['Float']>;
  controlCheck: Scalars['Boolean'];
  createdByUser?: Maybe<StintUser>;
  differentStartTimeReasons?: Maybe<Array<Maybe<Scalars['String']>>>;
  pspReference?: Maybe<Scalars['String']>;
  stintCategory?: Maybe<Scalars['String']>;
  stintTemplate?: Maybe<StintTemplate>;
  isBestEffort: Scalars['Boolean'];
  acceptanceMethod?: Maybe<StintAcceptanceMethod>;
  actualLeaveTime?: Maybe<Scalars['AWSDateTime']>;
  actualLeaveTimeLoggedByUserRef?: Maybe<Scalars['ID']>;
  actualLeaveTimeLastModifiedAt?: Maybe<Scalars['AWSDateTime']>;
  stintOffersToStudent: Array<StintOffer>;
  role?: Maybe<Scalars['String']>;
  stinterPay: StinterPay;
  stintRates: StintRates;
  businessStintCount?: Maybe<Scalars['String']>;
  postedAt?: Maybe<Scalars['String']>;
  stintJourney?: Maybe<StintJourney>;
  studentReviewRating?: Maybe<Scalars['Int']>;
  ownerReviewRating?: Maybe<Scalars['Float']>;
  studentUnassignedEvents?: Maybe<StudentUnassignedEventListType>;
  automated?: Maybe<Scalars['Boolean']>;
  placeId?: Maybe<Scalars['String']>;
  displayAddress?: Maybe<Scalars['String']>;
  cancelledTimeAhead?: Maybe<Scalars['String']>;
  distanceToStintAddress?: Maybe<Scalars['Float']>;
  footerStatus?: Maybe<StintFooterTypeStatus>;
  prepVideoUrl?: Maybe<Scalars['String']>;
  prepVideoId?: Maybe<Scalars['String']>;
  businessReview?: Maybe<BusinessReview>;
  stintTimesDisplay?: Maybe<Scalars['String']>;
  employeeDisclaimerInfo?: Maybe<EmployeeDisclaimerInfo>;
  site: PartnerSiteProfile;
  stinterExperience?: Maybe<StinterExperience>;
  stinterPoolExperience?: Maybe<StinterExperience>;
  studentReview?: Maybe<StudentReview>;
  internalReview?: Maybe<StudentReview>;
  checkedIn?: Maybe<Scalars['Boolean']>;
  hasStudentReview?: Maybe<Scalars['Boolean']>;
  hasBusinessReview?: Maybe<Scalars['Boolean']>;
  business_id?: Maybe<Scalars['ID']>;
  address_id?: Maybe<Scalars['ID']>;
  city_id?: Maybe<Scalars['ID']>;
  selected_payment_method_id?: Maybe<Scalars['ID']>;
  selected_card_payment_method_id?: Maybe<Scalars['ID']>;
  student_id?: Maybe<Scalars['ID']>;
  business_role_id?: Maybe<Scalars['ID']>;
  business_category_id?: Maybe<Scalars['ID']>;
  cancelled_by_id?: Maybe<Scalars['ID']>;
  created_by_user_id?: Maybe<Scalars['ID']>;
  stint_template_id?: Maybe<Scalars['ID']>;
};

export type StintstudentUnassignedEventsArgs = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
};

export type StintstudentReviewArgs = {
  reviewType?: Maybe<Scalars['String']>;
};

export enum StintAcceptanceMethod {
  MANUAL_ASSIGNMENT = 'MANUAL_ASSIGNMENT',
  MANUAL_OFFER = 'MANUAL_OFFER',
  STINT_MATCHER_OFFER = 'STINT_MATCHER_OFFER',
  WATERFALL_OFFER = 'WATERFALL_OFFER',
  STINT_MATCHER_TOP_UP_OFFER = 'STINT_MATCHER_TOP_UP_OFFER',
  REPEAT_STINTS_CAROUSEL = 'REPEAT_STINTS_CAROUSEL',
  REPEAT_STINTS_LIST = 'REPEAT_STINTS_LIST',
  ALL_STINTS_LIST = 'ALL_STINTS_LIST',
  ALL_STINTS_MAP = 'ALL_STINTS_MAP',
  TRAINED_STINTS_LIST = 'TRAINED_STINTS_LIST',
}

export type StintDurationInput = {
  gte: Scalars['Int'];
  lte: Scalars['Int'];
};

export enum StintError {
  ACTUAL_LEAVE_TIME_BEFORE_START = 'ACTUAL_LEAVE_TIME_BEFORE_START',
  AFTER_CANCELLATION_CUTOFF_TIME = 'AFTER_CANCELLATION_CUTOFF_TIME',
  ALREADY_CANCELLED = 'ALREADY_CANCELLED',
  ALREADY_COMPLETED = 'ALREADY_COMPLETED',
  ALREADY_STARTED = 'ALREADY_STARTED',
  CANNOT_UPDATE_TIMES_FOR_ASSIGNED_STINT = 'CANNOT_UPDATE_TIMES_FOR_ASSIGNED_STINT',
  FROM_WEEK_TEMPLATE_MUST_HAVE_REF = 'FROM_WEEK_TEMPLATE_MUST_HAVE_REF',
  FROM_WEEK_TEMPLATE_MUST_HAVE_VERSION = 'FROM_WEEK_TEMPLATE_MUST_HAVE_VERSION',
  INVALID_ACTUAL_LEAVE_TIME = 'INVALID_ACTUAL_LEAVE_TIME',
  INVALID_ACTUAL_LEAVE_TIME_FROM_END = 'INVALID_ACTUAL_LEAVE_TIME_FROM_END',
  INVALID_BUSINESS_ID = 'INVALID_BUSINESS_ID',
  INVALID_DATE_FROM = 'INVALID_DATE_FROM',
  INVALID_DATE_TO = 'INVALID_DATE_TO',
  INVALID_ID = 'INVALID_ID',
  INVALID_QUANTITY = 'INVALID_QUANTITY',
  INVALID_STINT_END_TIME = 'INVALID_STINT_END_TIME',
  INVALID_STINT_MAX_LENGTH = 'INVALID_STINT_MAX_LENGTH',
  INVALID_STINT_MIN_LENGTH = 'INVALID_STINT_MIN_LENGTH',
  INVALID_STINT_START_TIME = 'INVALID_STINT_START_TIME',
  INVALID_STINT_TEMPLATE_ID = 'INVALID_STINT_TEMPLATE_ID',
  MISSING_MONETARY_VALUE = 'MISSING_MONETARY_VALUE',
  ONE_OFF_MUST_NOT_HAVE_REF = 'ONE_OFF_MUST_NOT_HAVE_REF',
  ONE_OFF_MUST_NOT_HAVE_VERSION = 'ONE_OFF_MUST_NOT_HAVE_VERSION',
  STINT_IS_LOCKED_FOR_UPDATE = 'STINT_IS_LOCKED_FOR_UPDATE',
  STINTER_ACCEPTED_OVER_COMMITMENT = 'STINTER_ACCEPTED_OVER_COMMITMENT',
  STINTER_DOES_NOT_HAVE_A_COMMITMENT = 'STINTER_DOES_NOT_HAVE_A_COMMITMENT',
  UNABLE_TO_UNASSIGN_STINTER_FROM_STINT = 'UNABLE_TO_UNASSIGN_STINTER_FROM_STINT',
  UNKNOWN_VALUE_ERROR = 'UNKNOWN_VALUE_ERROR',
}

export type StintFilter = {
  name?: Maybe<Scalars['String']>;
  controlCheck?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  idIcontains?: Maybe<Scalars['String']>;
  startFrom?: Maybe<Scalars['AWSDate']>;
  only_stints_with_no_student_reviews?: Maybe<Scalars['Boolean']>;
  business_id?: Maybe<Scalars['ID']>;
  business_id_isnull?: Maybe<Scalars['Boolean']>;
  address_id?: Maybe<Scalars['ID']>;
  address_id_isnull?: Maybe<Scalars['Boolean']>;
  city_id?: Maybe<Scalars['ID']>;
  city_id_isnull?: Maybe<Scalars['Boolean']>;
  selectedPaymentMethod_id?: Maybe<Scalars['ID']>;
  selectedPaymentMethod_id_isnull?: Maybe<Scalars['Boolean']>;
  selectedCardPaymentMethod_id?: Maybe<Scalars['ID']>;
  selectedCardPaymentMethod_id_isnull?: Maybe<Scalars['Boolean']>;
  student_id?: Maybe<Scalars['ID']>;
  student_id_isnull?: Maybe<Scalars['Boolean']>;
  businessRole_id?: Maybe<Scalars['ID']>;
  businessRole_id_isnull?: Maybe<Scalars['Boolean']>;
  businessCategory_id?: Maybe<Scalars['ID']>;
  businessCategory_id_isnull?: Maybe<Scalars['Boolean']>;
  cancelledBy_id?: Maybe<Scalars['ID']>;
  cancelledBy_id_isnull?: Maybe<Scalars['Boolean']>;
  createdByUser_id?: Maybe<Scalars['ID']>;
  createdByUser_id_isnull?: Maybe<Scalars['Boolean']>;
  stintTemplate_id?: Maybe<Scalars['ID']>;
  stintTemplate_id_isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_isnull?: Maybe<Scalars['Boolean']>;
  createdAt_gt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_gte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  createdAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_isnull?: Maybe<Scalars['Boolean']>;
  modifiedAt_gt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_gte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  name_lt?: Maybe<Scalars['String']>;
  name_search?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_isnull?: Maybe<Scalars['Boolean']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_iendswith?: Maybe<Scalars['String']>;
  name_istartswith?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  notes_lt?: Maybe<Scalars['String']>;
  notes_search?: Maybe<Scalars['String']>;
  notes_lte?: Maybe<Scalars['String']>;
  notes_isnull?: Maybe<Scalars['Boolean']>;
  notes_gt?: Maybe<Scalars['String']>;
  notes_gte?: Maybe<Scalars['String']>;
  notes_iendswith?: Maybe<Scalars['String']>;
  notes_istartswith?: Maybe<Scalars['String']>;
  trained?: Maybe<Scalars['Boolean']>;
  trained_lt?: Maybe<Scalars['Boolean']>;
  trained_lte?: Maybe<Scalars['Boolean']>;
  trained_isnull?: Maybe<Scalars['Boolean']>;
  trained_gt?: Maybe<Scalars['Boolean']>;
  trained_gte?: Maybe<Scalars['Boolean']>;
  trained_iendswith?: Maybe<Scalars['Boolean']>;
  trained_istartswith?: Maybe<Scalars['Boolean']>;
  dateAccepted?: Maybe<Scalars['AWSDateTime']>;
  dateAccepted_lt?: Maybe<Scalars['AWSDateTime']>;
  dateAccepted_lte?: Maybe<Scalars['AWSDateTime']>;
  dateAccepted_isnull?: Maybe<Scalars['Boolean']>;
  dateAccepted_gt?: Maybe<Scalars['AWSDateTime']>;
  dateAccepted_gte?: Maybe<Scalars['AWSDateTime']>;
  dateAccepted_iendswith?: Maybe<Scalars['AWSDateTime']>;
  dateAccepted_istartswith?: Maybe<Scalars['AWSDateTime']>;
  dateFrom?: Maybe<Scalars['AWSDateTime']>;
  dateFrom_lt?: Maybe<Scalars['AWSDateTime']>;
  dateFrom_lte?: Maybe<Scalars['AWSDateTime']>;
  dateFrom_isnull?: Maybe<Scalars['Boolean']>;
  dateFrom_gt?: Maybe<Scalars['AWSDateTime']>;
  dateFrom_gte?: Maybe<Scalars['AWSDateTime']>;
  dateFrom_iendswith?: Maybe<Scalars['AWSDateTime']>;
  dateFrom_istartswith?: Maybe<Scalars['AWSDateTime']>;
  dateTo?: Maybe<Scalars['AWSDateTime']>;
  dateTo_lt?: Maybe<Scalars['AWSDateTime']>;
  dateTo_lte?: Maybe<Scalars['AWSDateTime']>;
  dateTo_isnull?: Maybe<Scalars['Boolean']>;
  dateTo_gt?: Maybe<Scalars['AWSDateTime']>;
  dateTo_gte?: Maybe<Scalars['AWSDateTime']>;
  dateTo_iendswith?: Maybe<Scalars['AWSDateTime']>;
  dateTo_istartswith?: Maybe<Scalars['AWSDateTime']>;
  dateToProposed?: Maybe<Scalars['AWSDateTime']>;
  dateToProposed_lt?: Maybe<Scalars['AWSDateTime']>;
  dateToProposed_lte?: Maybe<Scalars['AWSDateTime']>;
  dateToProposed_isnull?: Maybe<Scalars['Boolean']>;
  dateToProposed_gt?: Maybe<Scalars['AWSDateTime']>;
  dateToProposed_gte?: Maybe<Scalars['AWSDateTime']>;
  dateToProposed_iendswith?: Maybe<Scalars['AWSDateTime']>;
  dateToProposed_istartswith?: Maybe<Scalars['AWSDateTime']>;
  lastModified?: Maybe<Scalars['AWSDateTime']>;
  lastModified_lt?: Maybe<Scalars['AWSDateTime']>;
  lastModified_lte?: Maybe<Scalars['AWSDateTime']>;
  lastModified_isnull?: Maybe<Scalars['Boolean']>;
  lastModified_gt?: Maybe<Scalars['AWSDateTime']>;
  lastModified_gte?: Maybe<Scalars['AWSDateTime']>;
  lastModified_iendswith?: Maybe<Scalars['AWSDateTime']>;
  lastModified_istartswith?: Maybe<Scalars['AWSDateTime']>;
  checkInDate?: Maybe<Scalars['AWSDateTime']>;
  checkInDate_lt?: Maybe<Scalars['AWSDateTime']>;
  checkInDate_lte?: Maybe<Scalars['AWSDateTime']>;
  checkInDate_isnull?: Maybe<Scalars['Boolean']>;
  checkInDate_gt?: Maybe<Scalars['AWSDateTime']>;
  checkInDate_gte?: Maybe<Scalars['AWSDateTime']>;
  checkInDate_iendswith?: Maybe<Scalars['AWSDateTime']>;
  checkInDate_istartswith?: Maybe<Scalars['AWSDateTime']>;
  checkOutDate?: Maybe<Scalars['AWSDateTime']>;
  checkOutDate_lt?: Maybe<Scalars['AWSDateTime']>;
  checkOutDate_lte?: Maybe<Scalars['AWSDateTime']>;
  checkOutDate_isnull?: Maybe<Scalars['Boolean']>;
  checkOutDate_gt?: Maybe<Scalars['AWSDateTime']>;
  checkOutDate_gte?: Maybe<Scalars['AWSDateTime']>;
  checkOutDate_iendswith?: Maybe<Scalars['AWSDateTime']>;
  checkOutDate_istartswith?: Maybe<Scalars['AWSDateTime']>;
  qrCodeCheckInDate?: Maybe<Scalars['AWSDateTime']>;
  qrCodeCheckInDate_lt?: Maybe<Scalars['AWSDateTime']>;
  qrCodeCheckInDate_lte?: Maybe<Scalars['AWSDateTime']>;
  qrCodeCheckInDate_isnull?: Maybe<Scalars['Boolean']>;
  qrCodeCheckInDate_gt?: Maybe<Scalars['AWSDateTime']>;
  qrCodeCheckInDate_gte?: Maybe<Scalars['AWSDateTime']>;
  qrCodeCheckInDate_iendswith?: Maybe<Scalars['AWSDateTime']>;
  qrCodeCheckInDate_istartswith?: Maybe<Scalars['AWSDateTime']>;
  locationAddress?: Maybe<Scalars['String']>;
  locationAddress_lt?: Maybe<Scalars['String']>;
  locationAddress_search?: Maybe<Scalars['String']>;
  locationAddress_lte?: Maybe<Scalars['String']>;
  locationAddress_isnull?: Maybe<Scalars['Boolean']>;
  locationAddress_gt?: Maybe<Scalars['String']>;
  locationAddress_gte?: Maybe<Scalars['String']>;
  locationAddress_iendswith?: Maybe<Scalars['String']>;
  locationAddress_istartswith?: Maybe<Scalars['String']>;
  locationName?: Maybe<Scalars['String']>;
  locationName_lt?: Maybe<Scalars['String']>;
  locationName_search?: Maybe<Scalars['String']>;
  locationName_lte?: Maybe<Scalars['String']>;
  locationName_isnull?: Maybe<Scalars['Boolean']>;
  locationName_gt?: Maybe<Scalars['String']>;
  locationName_gte?: Maybe<Scalars['String']>;
  locationName_iendswith?: Maybe<Scalars['String']>;
  locationName_istartswith?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  longitude_lt?: Maybe<Scalars['String']>;
  longitude_search?: Maybe<Scalars['String']>;
  longitude_lte?: Maybe<Scalars['String']>;
  longitude_isnull?: Maybe<Scalars['Boolean']>;
  longitude_gt?: Maybe<Scalars['String']>;
  longitude_gte?: Maybe<Scalars['String']>;
  longitude_iendswith?: Maybe<Scalars['String']>;
  longitude_istartswith?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  latitude_lt?: Maybe<Scalars['String']>;
  latitude_search?: Maybe<Scalars['String']>;
  latitude_lte?: Maybe<Scalars['String']>;
  latitude_isnull?: Maybe<Scalars['Boolean']>;
  latitude_gt?: Maybe<Scalars['String']>;
  latitude_gte?: Maybe<Scalars['String']>;
  latitude_iendswith?: Maybe<Scalars['String']>;
  latitude_istartswith?: Maybe<Scalars['String']>;
  invoiceStatus?: Maybe<Scalars['String']>;
  invoiceStatus_lt?: Maybe<Scalars['String']>;
  invoiceStatus_search?: Maybe<Scalars['String']>;
  invoiceStatus_lte?: Maybe<Scalars['String']>;
  invoiceStatus_isnull?: Maybe<Scalars['Boolean']>;
  invoiceStatus_gt?: Maybe<Scalars['String']>;
  invoiceStatus_gte?: Maybe<Scalars['String']>;
  invoiceStatus_iendswith?: Maybe<Scalars['String']>;
  invoiceStatus_istartswith?: Maybe<Scalars['String']>;
  isDirectDebit?: Maybe<Scalars['Boolean']>;
  isDirectDebit_lt?: Maybe<Scalars['Boolean']>;
  isDirectDebit_lte?: Maybe<Scalars['Boolean']>;
  isDirectDebit_isnull?: Maybe<Scalars['Boolean']>;
  isDirectDebit_gt?: Maybe<Scalars['Boolean']>;
  isDirectDebit_gte?: Maybe<Scalars['Boolean']>;
  isDirectDebit_iendswith?: Maybe<Scalars['Boolean']>;
  isDirectDebit_istartswith?: Maybe<Scalars['Boolean']>;
  isFreeStint?: Maybe<Scalars['Boolean']>;
  isFreeStint_lt?: Maybe<Scalars['Boolean']>;
  isFreeStint_lte?: Maybe<Scalars['Boolean']>;
  isFreeStint_isnull?: Maybe<Scalars['Boolean']>;
  isFreeStint_gt?: Maybe<Scalars['Boolean']>;
  isFreeStint_gte?: Maybe<Scalars['Boolean']>;
  isFreeStint_iendswith?: Maybe<Scalars['Boolean']>;
  isFreeStint_istartswith?: Maybe<Scalars['Boolean']>;
  paymentReference?: Maybe<Scalars['String']>;
  paymentReference_lt?: Maybe<Scalars['String']>;
  paymentReference_search?: Maybe<Scalars['String']>;
  paymentReference_lte?: Maybe<Scalars['String']>;
  paymentReference_isnull?: Maybe<Scalars['Boolean']>;
  paymentReference_gt?: Maybe<Scalars['String']>;
  paymentReference_gte?: Maybe<Scalars['String']>;
  paymentReference_iendswith?: Maybe<Scalars['String']>;
  paymentReference_istartswith?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['Float']>;
  paymentStatus_lt?: Maybe<Scalars['Float']>;
  paymentStatus_lte?: Maybe<Scalars['Float']>;
  paymentStatus_isnull?: Maybe<Scalars['Boolean']>;
  paymentStatus_gt?: Maybe<Scalars['Float']>;
  paymentStatus_gte?: Maybe<Scalars['Float']>;
  paymentStatus_iendswith?: Maybe<Scalars['Float']>;
  paymentStatus_istartswith?: Maybe<Scalars['Float']>;
  preAuthorizedAmount?: Maybe<Scalars['Float']>;
  preAuthorizedAmount_lt?: Maybe<Scalars['Float']>;
  preAuthorizedAmount_lte?: Maybe<Scalars['Float']>;
  preAuthorizedAmount_isnull?: Maybe<Scalars['Boolean']>;
  preAuthorizedAmount_gt?: Maybe<Scalars['Float']>;
  preAuthorizedAmount_gte?: Maybe<Scalars['Float']>;
  preAuthorizedAmount_iendswith?: Maybe<Scalars['Float']>;
  preAuthorizedAmount_istartswith?: Maybe<Scalars['Float']>;
  bonusRate?: Maybe<Scalars['Float']>;
  bonusRate_lt?: Maybe<Scalars['Float']>;
  bonusRate_lte?: Maybe<Scalars['Float']>;
  bonusRate_isnull?: Maybe<Scalars['Boolean']>;
  bonusRate_gt?: Maybe<Scalars['Float']>;
  bonusRate_gte?: Maybe<Scalars['Float']>;
  bonusRate_iendswith?: Maybe<Scalars['Float']>;
  bonusRate_istartswith?: Maybe<Scalars['Float']>;
  bonusPay?: Maybe<Scalars['Float']>;
  bonusPay_lt?: Maybe<Scalars['Float']>;
  bonusPay_lte?: Maybe<Scalars['Float']>;
  bonusPay_isnull?: Maybe<Scalars['Boolean']>;
  bonusPay_gt?: Maybe<Scalars['Float']>;
  bonusPay_gte?: Maybe<Scalars['Float']>;
  bonusPay_iendswith?: Maybe<Scalars['Float']>;
  bonusPay_istartswith?: Maybe<Scalars['Float']>;
  bonusApplied?: Maybe<Scalars['Boolean']>;
  bonusApplied_lt?: Maybe<Scalars['Boolean']>;
  bonusApplied_lte?: Maybe<Scalars['Boolean']>;
  bonusApplied_isnull?: Maybe<Scalars['Boolean']>;
  bonusApplied_gt?: Maybe<Scalars['Boolean']>;
  bonusApplied_gte?: Maybe<Scalars['Boolean']>;
  bonusApplied_iendswith?: Maybe<Scalars['Boolean']>;
  bonusApplied_istartswith?: Maybe<Scalars['Boolean']>;
  firebaseRef?: Maybe<Scalars['String']>;
  firebaseRef_lt?: Maybe<Scalars['String']>;
  firebaseRef_search?: Maybe<Scalars['String']>;
  firebaseRef_lte?: Maybe<Scalars['String']>;
  firebaseRef_isnull?: Maybe<Scalars['Boolean']>;
  firebaseRef_gt?: Maybe<Scalars['String']>;
  firebaseRef_gte?: Maybe<Scalars['String']>;
  firebaseRef_iendswith?: Maybe<Scalars['String']>;
  firebaseRef_istartswith?: Maybe<Scalars['String']>;
  firebaseCreatedByBusinessRef?: Maybe<Scalars['String']>;
  firebaseCreatedByBusinessRef_lt?: Maybe<Scalars['String']>;
  firebaseCreatedByBusinessRef_search?: Maybe<Scalars['String']>;
  firebaseCreatedByBusinessRef_lte?: Maybe<Scalars['String']>;
  firebaseCreatedByBusinessRef_isnull?: Maybe<Scalars['Boolean']>;
  firebaseCreatedByBusinessRef_gt?: Maybe<Scalars['String']>;
  firebaseCreatedByBusinessRef_gte?: Maybe<Scalars['String']>;
  firebaseCreatedByBusinessRef_iendswith?: Maybe<Scalars['String']>;
  firebaseCreatedByBusinessRef_istartswith?: Maybe<Scalars['String']>;
  firebaseContactPersonRef?: Maybe<Scalars['String']>;
  firebaseContactPersonRef_lt?: Maybe<Scalars['String']>;
  firebaseContactPersonRef_search?: Maybe<Scalars['String']>;
  firebaseContactPersonRef_lte?: Maybe<Scalars['String']>;
  firebaseContactPersonRef_isnull?: Maybe<Scalars['Boolean']>;
  firebaseContactPersonRef_gt?: Maybe<Scalars['String']>;
  firebaseContactPersonRef_gte?: Maybe<Scalars['String']>;
  firebaseContactPersonRef_iendswith?: Maybe<Scalars['String']>;
  firebaseContactPersonRef_istartswith?: Maybe<Scalars['String']>;
  firebaseStudentRef?: Maybe<Scalars['String']>;
  firebaseStudentRef_lt?: Maybe<Scalars['String']>;
  firebaseStudentRef_search?: Maybe<Scalars['String']>;
  firebaseStudentRef_lte?: Maybe<Scalars['String']>;
  firebaseStudentRef_isnull?: Maybe<Scalars['Boolean']>;
  firebaseStudentRef_gt?: Maybe<Scalars['String']>;
  firebaseStudentRef_gte?: Maybe<Scalars['String']>;
  firebaseStudentRef_iendswith?: Maybe<Scalars['String']>;
  firebaseStudentRef_istartswith?: Maybe<Scalars['String']>;
  studentIsLate?: Maybe<Scalars['Boolean']>;
  studentIsLate_lt?: Maybe<Scalars['Boolean']>;
  studentIsLate_lte?: Maybe<Scalars['Boolean']>;
  studentIsLate_isnull?: Maybe<Scalars['Boolean']>;
  studentIsLate_gt?: Maybe<Scalars['Boolean']>;
  studentIsLate_gte?: Maybe<Scalars['Boolean']>;
  studentIsLate_iendswith?: Maybe<Scalars['Boolean']>;
  studentIsLate_istartswith?: Maybe<Scalars['Boolean']>;
  studentRunningLateHalfwayCheck?: Maybe<Scalars['Boolean']>;
  studentRunningLateHalfwayCheck_lt?: Maybe<Scalars['Boolean']>;
  studentRunningLateHalfwayCheck_lte?: Maybe<Scalars['Boolean']>;
  studentRunningLateHalfwayCheck_isnull?: Maybe<Scalars['Boolean']>;
  studentRunningLateHalfwayCheck_gt?: Maybe<Scalars['Boolean']>;
  studentRunningLateHalfwayCheck_gte?: Maybe<Scalars['Boolean']>;
  studentRunningLateHalfwayCheck_iendswith?: Maybe<Scalars['Boolean']>;
  studentRunningLateHalfwayCheck_istartswith?: Maybe<Scalars['Boolean']>;
  eta?: Maybe<Scalars['Float']>;
  eta_lt?: Maybe<Scalars['Float']>;
  eta_lte?: Maybe<Scalars['Float']>;
  eta_isnull?: Maybe<Scalars['Boolean']>;
  eta_gt?: Maybe<Scalars['Float']>;
  eta_gte?: Maybe<Scalars['Float']>;
  eta_iendswith?: Maybe<Scalars['Float']>;
  eta_istartswith?: Maybe<Scalars['Float']>;
  cancelledAt?: Maybe<Scalars['AWSDateTime']>;
  cancelledAt_lt?: Maybe<Scalars['AWSDateTime']>;
  cancelledAt_lte?: Maybe<Scalars['AWSDateTime']>;
  cancelledAt_isnull?: Maybe<Scalars['Boolean']>;
  cancelledAt_gt?: Maybe<Scalars['AWSDateTime']>;
  cancelledAt_gte?: Maybe<Scalars['AWSDateTime']>;
  cancelledAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  cancelledAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  nextAutomationBatchAt?: Maybe<Scalars['AWSDateTime']>;
  nextAutomationBatchAt_lt?: Maybe<Scalars['AWSDateTime']>;
  nextAutomationBatchAt_lte?: Maybe<Scalars['AWSDateTime']>;
  nextAutomationBatchAt_isnull?: Maybe<Scalars['Boolean']>;
  nextAutomationBatchAt_gt?: Maybe<Scalars['AWSDateTime']>;
  nextAutomationBatchAt_gte?: Maybe<Scalars['AWSDateTime']>;
  nextAutomationBatchAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  nextAutomationBatchAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  isAutomationComplete?: Maybe<Scalars['Boolean']>;
  isAutomationComplete_lt?: Maybe<Scalars['Boolean']>;
  isAutomationComplete_lte?: Maybe<Scalars['Boolean']>;
  isAutomationComplete_isnull?: Maybe<Scalars['Boolean']>;
  isAutomationComplete_gt?: Maybe<Scalars['Boolean']>;
  isAutomationComplete_gte?: Maybe<Scalars['Boolean']>;
  isAutomationComplete_iendswith?: Maybe<Scalars['Boolean']>;
  isAutomationComplete_istartswith?: Maybe<Scalars['Boolean']>;
  automationLastTriggeredAt?: Maybe<Scalars['AWSDateTime']>;
  automationLastTriggeredAt_lt?: Maybe<Scalars['AWSDateTime']>;
  automationLastTriggeredAt_lte?: Maybe<Scalars['AWSDateTime']>;
  automationLastTriggeredAt_isnull?: Maybe<Scalars['Boolean']>;
  automationLastTriggeredAt_gt?: Maybe<Scalars['AWSDateTime']>;
  automationLastTriggeredAt_gte?: Maybe<Scalars['AWSDateTime']>;
  automationLastTriggeredAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  automationLastTriggeredAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  automationAttempts?: Maybe<Scalars['Float']>;
  automationAttempts_lt?: Maybe<Scalars['Float']>;
  automationAttempts_lte?: Maybe<Scalars['Float']>;
  automationAttempts_isnull?: Maybe<Scalars['Boolean']>;
  automationAttempts_gt?: Maybe<Scalars['Float']>;
  automationAttempts_gte?: Maybe<Scalars['Float']>;
  automationAttempts_iendswith?: Maybe<Scalars['Float']>;
  automationAttempts_istartswith?: Maybe<Scalars['Float']>;
  uniformInstructions?: Maybe<Scalars['String']>;
  uniformInstructions_lt?: Maybe<Scalars['String']>;
  uniformInstructions_search?: Maybe<Scalars['String']>;
  uniformInstructions_lte?: Maybe<Scalars['String']>;
  uniformInstructions_isnull?: Maybe<Scalars['Boolean']>;
  uniformInstructions_gt?: Maybe<Scalars['String']>;
  uniformInstructions_gte?: Maybe<Scalars['String']>;
  uniformInstructions_iendswith?: Maybe<Scalars['String']>;
  uniformInstructions_istartswith?: Maybe<Scalars['String']>;
  trainingInstructions?: Maybe<Scalars['String']>;
  trainingInstructions_lt?: Maybe<Scalars['String']>;
  trainingInstructions_search?: Maybe<Scalars['String']>;
  trainingInstructions_lte?: Maybe<Scalars['String']>;
  trainingInstructions_isnull?: Maybe<Scalars['Boolean']>;
  trainingInstructions_gt?: Maybe<Scalars['String']>;
  trainingInstructions_gte?: Maybe<Scalars['String']>;
  trainingInstructions_iendswith?: Maybe<Scalars['String']>;
  trainingInstructions_istartswith?: Maybe<Scalars['String']>;
  healthAndSafety?: Maybe<Scalars['String']>;
  healthAndSafety_lt?: Maybe<Scalars['String']>;
  healthAndSafety_search?: Maybe<Scalars['String']>;
  healthAndSafety_lte?: Maybe<Scalars['String']>;
  healthAndSafety_isnull?: Maybe<Scalars['Boolean']>;
  healthAndSafety_gt?: Maybe<Scalars['String']>;
  healthAndSafety_gte?: Maybe<Scalars['String']>;
  healthAndSafety_iendswith?: Maybe<Scalars['String']>;
  healthAndSafety_istartswith?: Maybe<Scalars['String']>;
  opsNotifiedUnassigned?: Maybe<Scalars['Boolean']>;
  opsNotifiedUnassigned_lt?: Maybe<Scalars['Boolean']>;
  opsNotifiedUnassigned_lte?: Maybe<Scalars['Boolean']>;
  opsNotifiedUnassigned_isnull?: Maybe<Scalars['Boolean']>;
  opsNotifiedUnassigned_gt?: Maybe<Scalars['Boolean']>;
  opsNotifiedUnassigned_gte?: Maybe<Scalars['Boolean']>;
  opsNotifiedUnassigned_iendswith?: Maybe<Scalars['Boolean']>;
  opsNotifiedUnassigned_istartswith?: Maybe<Scalars['Boolean']>;
  opsNotifiedLocationOff?: Maybe<Scalars['Boolean']>;
  opsNotifiedLocationOff_lt?: Maybe<Scalars['Boolean']>;
  opsNotifiedLocationOff_lte?: Maybe<Scalars['Boolean']>;
  opsNotifiedLocationOff_isnull?: Maybe<Scalars['Boolean']>;
  opsNotifiedLocationOff_gt?: Maybe<Scalars['Boolean']>;
  opsNotifiedLocationOff_gte?: Maybe<Scalars['Boolean']>;
  opsNotifiedLocationOff_iendswith?: Maybe<Scalars['Boolean']>;
  opsNotifiedLocationOff_istartswith?: Maybe<Scalars['Boolean']>;
  quantityStudentsForDraftStint?: Maybe<Scalars['Float']>;
  quantityStudentsForDraftStint_lt?: Maybe<Scalars['Float']>;
  quantityStudentsForDraftStint_lte?: Maybe<Scalars['Float']>;
  quantityStudentsForDraftStint_isnull?: Maybe<Scalars['Boolean']>;
  quantityStudentsForDraftStint_gt?: Maybe<Scalars['Float']>;
  quantityStudentsForDraftStint_gte?: Maybe<Scalars['Float']>;
  quantityStudentsForDraftStint_iendswith?: Maybe<Scalars['Float']>;
  quantityStudentsForDraftStint_istartswith?: Maybe<Scalars['Float']>;
  quantityRepeatDaysForDraftStint?: Maybe<Scalars['Float']>;
  quantityRepeatDaysForDraftStint_lt?: Maybe<Scalars['Float']>;
  quantityRepeatDaysForDraftStint_lte?: Maybe<Scalars['Float']>;
  quantityRepeatDaysForDraftStint_isnull?: Maybe<Scalars['Boolean']>;
  quantityRepeatDaysForDraftStint_gt?: Maybe<Scalars['Float']>;
  quantityRepeatDaysForDraftStint_gte?: Maybe<Scalars['Float']>;
  quantityRepeatDaysForDraftStint_iendswith?: Maybe<Scalars['Float']>;
  quantityRepeatDaysForDraftStint_istartswith?: Maybe<Scalars['Float']>;
  averageOwnerRating?: Maybe<Scalars['Float']>;
  averageOwnerRating_lt?: Maybe<Scalars['Float']>;
  averageOwnerRating_lte?: Maybe<Scalars['Float']>;
  averageOwnerRating_isnull?: Maybe<Scalars['Boolean']>;
  averageOwnerRating_gt?: Maybe<Scalars['Float']>;
  averageOwnerRating_gte?: Maybe<Scalars['Float']>;
  averageOwnerRating_iendswith?: Maybe<Scalars['Float']>;
  averageOwnerRating_istartswith?: Maybe<Scalars['Float']>;
  controlCheck_lt?: Maybe<Scalars['Boolean']>;
  controlCheck_lte?: Maybe<Scalars['Boolean']>;
  controlCheck_isnull?: Maybe<Scalars['Boolean']>;
  controlCheck_gt?: Maybe<Scalars['Boolean']>;
  controlCheck_gte?: Maybe<Scalars['Boolean']>;
  controlCheck_iendswith?: Maybe<Scalars['Boolean']>;
  controlCheck_istartswith?: Maybe<Scalars['Boolean']>;
  pspReference?: Maybe<Scalars['String']>;
  pspReference_lt?: Maybe<Scalars['String']>;
  pspReference_search?: Maybe<Scalars['String']>;
  pspReference_lte?: Maybe<Scalars['String']>;
  pspReference_isnull?: Maybe<Scalars['Boolean']>;
  pspReference_gt?: Maybe<Scalars['String']>;
  pspReference_gte?: Maybe<Scalars['String']>;
  pspReference_iendswith?: Maybe<Scalars['String']>;
  pspReference_istartswith?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Float']>;
  status_lt?: Maybe<Scalars['Float']>;
  status_lte?: Maybe<Scalars['Float']>;
  status_isnull?: Maybe<Scalars['Boolean']>;
  status_gt?: Maybe<Scalars['Float']>;
  status_gte?: Maybe<Scalars['Float']>;
  status_iendswith?: Maybe<Scalars['Float']>;
  status_istartswith?: Maybe<Scalars['Float']>;
  stintCategory?: Maybe<Scalars['String']>;
  stintCategory_lt?: Maybe<Scalars['String']>;
  stintCategory_search?: Maybe<Scalars['String']>;
  stintCategory_lte?: Maybe<Scalars['String']>;
  stintCategory_isnull?: Maybe<Scalars['Boolean']>;
  stintCategory_gt?: Maybe<Scalars['String']>;
  stintCategory_gte?: Maybe<Scalars['String']>;
  stintCategory_iendswith?: Maybe<Scalars['String']>;
  stintCategory_istartswith?: Maybe<Scalars['String']>;
  isBestEffort?: Maybe<Scalars['Boolean']>;
  isBestEffort_lt?: Maybe<Scalars['Boolean']>;
  isBestEffort_lte?: Maybe<Scalars['Boolean']>;
  isBestEffort_isnull?: Maybe<Scalars['Boolean']>;
  isBestEffort_gt?: Maybe<Scalars['Boolean']>;
  isBestEffort_gte?: Maybe<Scalars['Boolean']>;
  isBestEffort_iendswith?: Maybe<Scalars['Boolean']>;
  isBestEffort_istartswith?: Maybe<Scalars['Boolean']>;
  acceptanceMethod?: Maybe<Scalars['String']>;
  acceptanceMethod_lt?: Maybe<Scalars['String']>;
  acceptanceMethod_search?: Maybe<Scalars['String']>;
  acceptanceMethod_lte?: Maybe<Scalars['String']>;
  acceptanceMethod_isnull?: Maybe<Scalars['Boolean']>;
  acceptanceMethod_gt?: Maybe<Scalars['String']>;
  acceptanceMethod_gte?: Maybe<Scalars['String']>;
  acceptanceMethod_iendswith?: Maybe<Scalars['String']>;
  acceptanceMethod_istartswith?: Maybe<Scalars['String']>;
  postMethod?: Maybe<Scalars['String']>;
  postMethod_lt?: Maybe<Scalars['String']>;
  postMethod_search?: Maybe<Scalars['String']>;
  postMethod_lte?: Maybe<Scalars['String']>;
  postMethod_isnull?: Maybe<Scalars['Boolean']>;
  postMethod_gt?: Maybe<Scalars['String']>;
  postMethod_gte?: Maybe<Scalars['String']>;
  postMethod_iendswith?: Maybe<Scalars['String']>;
  postMethod_istartswith?: Maybe<Scalars['String']>;
  stintWeekTemplateRef?: Maybe<Scalars['ID']>;
  stintWeekTemplateRef_lt?: Maybe<Scalars['ID']>;
  stintWeekTemplateRef_lte?: Maybe<Scalars['ID']>;
  stintWeekTemplateRef_isnull?: Maybe<Scalars['Boolean']>;
  stintWeekTemplateRef_gt?: Maybe<Scalars['ID']>;
  stintWeekTemplateRef_gte?: Maybe<Scalars['ID']>;
  stintWeekTemplateRef_iendswith?: Maybe<Scalars['ID']>;
  stintWeekTemplateRef_istartswith?: Maybe<Scalars['ID']>;
  stintWeekTemplateVersion?: Maybe<Scalars['Float']>;
  stintWeekTemplateVersion_lt?: Maybe<Scalars['Float']>;
  stintWeekTemplateVersion_lte?: Maybe<Scalars['Float']>;
  stintWeekTemplateVersion_isnull?: Maybe<Scalars['Boolean']>;
  stintWeekTemplateVersion_gt?: Maybe<Scalars['Float']>;
  stintWeekTemplateVersion_gte?: Maybe<Scalars['Float']>;
  stintWeekTemplateVersion_iendswith?: Maybe<Scalars['Float']>;
  stintWeekTemplateVersion_istartswith?: Maybe<Scalars['Float']>;
  actualLeaveTime?: Maybe<Scalars['AWSDateTime']>;
  actualLeaveTime_lt?: Maybe<Scalars['AWSDateTime']>;
  actualLeaveTime_lte?: Maybe<Scalars['AWSDateTime']>;
  actualLeaveTime_isnull?: Maybe<Scalars['Boolean']>;
  actualLeaveTime_gt?: Maybe<Scalars['AWSDateTime']>;
  actualLeaveTime_gte?: Maybe<Scalars['AWSDateTime']>;
  actualLeaveTime_iendswith?: Maybe<Scalars['AWSDateTime']>;
  actualLeaveTime_istartswith?: Maybe<Scalars['AWSDateTime']>;
  actualLeaveTimeLoggedByUserRef?: Maybe<Scalars['ID']>;
  actualLeaveTimeLoggedByUserRef_lt?: Maybe<Scalars['ID']>;
  actualLeaveTimeLoggedByUserRef_lte?: Maybe<Scalars['ID']>;
  actualLeaveTimeLoggedByUserRef_isnull?: Maybe<Scalars['Boolean']>;
  actualLeaveTimeLoggedByUserRef_gt?: Maybe<Scalars['ID']>;
  actualLeaveTimeLoggedByUserRef_gte?: Maybe<Scalars['ID']>;
  actualLeaveTimeLoggedByUserRef_iendswith?: Maybe<Scalars['ID']>;
  actualLeaveTimeLoggedByUserRef_istartswith?: Maybe<Scalars['ID']>;
  actualLeaveTimeLastModifiedAt?: Maybe<Scalars['AWSDateTime']>;
  actualLeaveTimeLastModifiedAt_lt?: Maybe<Scalars['AWSDateTime']>;
  actualLeaveTimeLastModifiedAt_lte?: Maybe<Scalars['AWSDateTime']>;
  actualLeaveTimeLastModifiedAt_isnull?: Maybe<Scalars['Boolean']>;
  actualLeaveTimeLastModifiedAt_gt?: Maybe<Scalars['AWSDateTime']>;
  actualLeaveTimeLastModifiedAt_gte?: Maybe<Scalars['AWSDateTime']>;
  actualLeaveTimeLastModifiedAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  actualLeaveTimeLastModifiedAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  primaryTasks_ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  secondaryTasks_ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  exceptions_ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  topTips_ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  students_ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  automationBatches_ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  modifiedAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  notes_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  trained_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  dateAccepted_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  dateFrom_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  dateTo_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  dateToProposed_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  lastModified_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  checkInDate_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  checkOutDate_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  qrCodeCheckInDate_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  locationAddress_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  locationName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  longitude_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  latitude_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  invoiceStatus_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isDirectDebit_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  isFreeStint_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  paymentReference_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  paymentStatus_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  preAuthorizedAmount_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  bonusRate_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  bonusPay_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  bonusApplied_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  firebaseRef_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  firebaseCreatedByBusinessRef_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  firebaseContactPersonRef_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  firebaseStudentRef_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  studentIsLate_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  studentRunningLateHalfwayCheck_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  eta_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  cancelledAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  nextAutomationBatchAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  isAutomationComplete_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  automationLastTriggeredAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  automationAttempts_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  uniformInstructions_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  trainingInstructions_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  healthAndSafety_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  opsNotifiedUnassigned_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  opsNotifiedLocationOff_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  quantityStudentsForDraftStint_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  quantityRepeatDaysForDraftStint_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  averageOwnerRating_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  controlCheck_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  pspReference_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  status_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  stintCategory_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isBestEffort_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  acceptanceMethod_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  postMethod_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  stintWeekTemplateRef_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  stintWeekTemplateVersion_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  actualLeaveTime_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  actualLeaveTimeLoggedByUserRef_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  actualLeaveTimeLastModifiedAt_in?: Maybe<
    Array<Maybe<Scalars['AWSDateTime']>>
  >;
};

export type StintFilterInput = {
  daysOfWeek?: Maybe<Array<Maybe<DayOfWeek>>>;
  duration?: Maybe<StintDurationInput>;
};

export enum StintFooterTypeStatus {
  STINT_COMPLETED_NOT_REVIEWED = 'STINT_COMPLETED_NOT_REVIEWED',
  STINT_COMPLETED_AND_REVIEWED = 'STINT_COMPLETED_AND_REVIEWED',
  STINT_CANCELLED = 'STINT_CANCELLED',
  STINT_CHECKED_IN = 'STINT_CHECKED_IN',
  STINT_ACCEPTED_AND_CANCEL_ALLOWED = 'STINT_ACCEPTED_AND_CANCEL_ALLOWED',
  STINT_ACCEPTED_AND_CHECKIN_ALLOWED = 'STINT_ACCEPTED_AND_CHECKIN_ALLOWED',
}

export enum StintInvoiceStatus {
  DRAFT = 'DRAFT',
  OPEN = 'OPEN',
  PAID = 'PAID',
  VOID = 'VOID',
  UNCOLLECTIBLE = 'UNCOLLECTIBLE',
}

export type StintJourney = {
  __typename?: 'StintJourney';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  modifiedAt: Scalars['AWSDateTime'];
  deletedAt?: Maybe<Scalars['AWSDateTime']>;
  ref: Scalars['ID'];
  status: StintJourneyStatus;
  statusResolved: Scalars['Boolean'];
  stint?: Maybe<Stint>;
  student: StintUser;
  studentInitialLocation?: Maybe<DeviceLocation>;
  etaCalcLocation?: Maybe<DeviceLocation>;
  stintNotificationReminder24hoursSent: Scalars['Boolean'];
  stintNotificationReminder12hoursSent: Scalars['Boolean'];
  confirmStintNotificationSentAt?: Maybe<Scalars['AWSDateTime']>;
  stintConfirmationNotificationSent: Scalars['Boolean'];
  lastChanceToConfirmStintNotificationSentAt?: Maybe<Scalars['AWSDateTime']>;
  stintConfirmationScreenViewed: Scalars['Boolean'];
  stintConfirmationScreenViewedAt?: Maybe<Scalars['AWSDateTime']>;
  fastestRouteTimeCalculated: Scalars['Boolean'];
  enableGranularTrackingNotificationSent: Scalars['Boolean'];
  lastChanceToEnableGranularTrackingNotificationSent?: Maybe<
    Scalars['AWSDateTime']
  >;
  studentArrivedAt?: Maybe<Scalars['AWSDateTime']>;
  lastChanceToEnableGranularTrackingNotificationAt?: Maybe<
    Scalars['AWSDateTime']
  >;
  estimatedTimeOfDeparture?: Maybe<Scalars['AWSDateTime']>;
  leaveCheckDurationLate?: Maybe<Scalars['Float']>;
  halfwayCheckDurationLate?: Maybe<Scalars['Float']>;
  isGranularLocationTrackingEnabled: Scalars['Boolean'];
  estimatedTimeOfArrival?: Maybe<Scalars['AWSDateTime']>;
  lateNote?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  doNotRemindAboutGeotracking: Scalars['Boolean'];
  statusModifiedAt?: Maybe<Scalars['AWSDateTime']>;
  usingNewLocationMicroservices?: Maybe<Scalars['Boolean']>;
  latestLocationLongitude?: Maybe<Scalars['Float']>;
  latestLocationLatitude?: Maybe<Scalars['Float']>;
  latestLocationRecordedAt?: Maybe<Scalars['AWSDateTime']>;
  business?: Maybe<Business>;
  confirmationScreenAccepted?: Maybe<Scalars['Boolean']>;
  isEtaBasedOnLatestLocation?: Maybe<Scalars['Boolean']>;
  stint_id?: Maybe<Scalars['ID']>;
  student_id?: Maybe<Scalars['ID']>;
  student_initial_location_id?: Maybe<Scalars['ID']>;
  eta_calc_location_id?: Maybe<Scalars['ID']>;
};

export type StintJourneyFilter = {
  startDate?: Maybe<Scalars['AWSDate']>;
  id?: Maybe<Scalars['ID']>;
  idIcontains?: Maybe<Scalars['String']>;
  startTimeFrom?: Maybe<Scalars['AWSTime']>;
  startTimeTo?: Maybe<Scalars['AWSTime']>;
  status_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  statusGroup?: Maybe<Scalars['String']>;
  stint_id?: Maybe<Scalars['ID']>;
  stint_id_isnull?: Maybe<Scalars['Boolean']>;
  student_id?: Maybe<Scalars['ID']>;
  student_id_isnull?: Maybe<Scalars['Boolean']>;
  studentInitialLocation_id?: Maybe<Scalars['ID']>;
  studentInitialLocation_id_isnull?: Maybe<Scalars['Boolean']>;
  etaCalcLocation_id?: Maybe<Scalars['ID']>;
  etaCalcLocation_id_isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_isnull?: Maybe<Scalars['Boolean']>;
  createdAt_gt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_gte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  createdAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_isnull?: Maybe<Scalars['Boolean']>;
  modifiedAt_gt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_gte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  status?: Maybe<Scalars['String']>;
  status_lt?: Maybe<Scalars['String']>;
  status_search?: Maybe<Scalars['String']>;
  status_lte?: Maybe<Scalars['String']>;
  status_isnull?: Maybe<Scalars['Boolean']>;
  status_gt?: Maybe<Scalars['String']>;
  status_gte?: Maybe<Scalars['String']>;
  status_iendswith?: Maybe<Scalars['String']>;
  status_istartswith?: Maybe<Scalars['String']>;
  statusResolved?: Maybe<Scalars['Boolean']>;
  statusResolved_lt?: Maybe<Scalars['Boolean']>;
  statusResolved_lte?: Maybe<Scalars['Boolean']>;
  statusResolved_isnull?: Maybe<Scalars['Boolean']>;
  statusResolved_gt?: Maybe<Scalars['Boolean']>;
  statusResolved_gte?: Maybe<Scalars['Boolean']>;
  statusResolved_iendswith?: Maybe<Scalars['Boolean']>;
  statusResolved_istartswith?: Maybe<Scalars['Boolean']>;
  stintNotificationReminder24HoursSent?: Maybe<Scalars['Boolean']>;
  stintNotificationReminder24HoursSent_lt?: Maybe<Scalars['Boolean']>;
  stintNotificationReminder24HoursSent_lte?: Maybe<Scalars['Boolean']>;
  stintNotificationReminder24HoursSent_isnull?: Maybe<Scalars['Boolean']>;
  stintNotificationReminder24HoursSent_gt?: Maybe<Scalars['Boolean']>;
  stintNotificationReminder24HoursSent_gte?: Maybe<Scalars['Boolean']>;
  stintNotificationReminder24HoursSent_iendswith?: Maybe<Scalars['Boolean']>;
  stintNotificationReminder24HoursSent_istartswith?: Maybe<Scalars['Boolean']>;
  stintNotificationReminder12HoursSent?: Maybe<Scalars['Boolean']>;
  stintNotificationReminder12HoursSent_lt?: Maybe<Scalars['Boolean']>;
  stintNotificationReminder12HoursSent_lte?: Maybe<Scalars['Boolean']>;
  stintNotificationReminder12HoursSent_isnull?: Maybe<Scalars['Boolean']>;
  stintNotificationReminder12HoursSent_gt?: Maybe<Scalars['Boolean']>;
  stintNotificationReminder12HoursSent_gte?: Maybe<Scalars['Boolean']>;
  stintNotificationReminder12HoursSent_iendswith?: Maybe<Scalars['Boolean']>;
  stintNotificationReminder12HoursSent_istartswith?: Maybe<Scalars['Boolean']>;
  confirmStintNotificationSentAt?: Maybe<Scalars['AWSDateTime']>;
  confirmStintNotificationSentAt_lt?: Maybe<Scalars['AWSDateTime']>;
  confirmStintNotificationSentAt_lte?: Maybe<Scalars['AWSDateTime']>;
  confirmStintNotificationSentAt_isnull?: Maybe<Scalars['Boolean']>;
  confirmStintNotificationSentAt_gt?: Maybe<Scalars['AWSDateTime']>;
  confirmStintNotificationSentAt_gte?: Maybe<Scalars['AWSDateTime']>;
  confirmStintNotificationSentAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  confirmStintNotificationSentAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  stintConfirmationNotificationSent?: Maybe<Scalars['Boolean']>;
  stintConfirmationNotificationSent_lt?: Maybe<Scalars['Boolean']>;
  stintConfirmationNotificationSent_lte?: Maybe<Scalars['Boolean']>;
  stintConfirmationNotificationSent_isnull?: Maybe<Scalars['Boolean']>;
  stintConfirmationNotificationSent_gt?: Maybe<Scalars['Boolean']>;
  stintConfirmationNotificationSent_gte?: Maybe<Scalars['Boolean']>;
  stintConfirmationNotificationSent_iendswith?: Maybe<Scalars['Boolean']>;
  stintConfirmationNotificationSent_istartswith?: Maybe<Scalars['Boolean']>;
  lastChanceToConfirmStintNotificationSentAt?: Maybe<Scalars['AWSDateTime']>;
  lastChanceToConfirmStintNotificationSentAt_lt?: Maybe<Scalars['AWSDateTime']>;
  lastChanceToConfirmStintNotificationSentAt_lte?: Maybe<
    Scalars['AWSDateTime']
  >;
  lastChanceToConfirmStintNotificationSentAt_isnull?: Maybe<Scalars['Boolean']>;
  lastChanceToConfirmStintNotificationSentAt_gt?: Maybe<Scalars['AWSDateTime']>;
  lastChanceToConfirmStintNotificationSentAt_gte?: Maybe<
    Scalars['AWSDateTime']
  >;
  lastChanceToConfirmStintNotificationSentAt_iendswith?: Maybe<
    Scalars['AWSDateTime']
  >;
  lastChanceToConfirmStintNotificationSentAt_istartswith?: Maybe<
    Scalars['AWSDateTime']
  >;
  stintConfirmationScreenViewed?: Maybe<Scalars['Boolean']>;
  stintConfirmationScreenViewed_lt?: Maybe<Scalars['Boolean']>;
  stintConfirmationScreenViewed_lte?: Maybe<Scalars['Boolean']>;
  stintConfirmationScreenViewed_isnull?: Maybe<Scalars['Boolean']>;
  stintConfirmationScreenViewed_gt?: Maybe<Scalars['Boolean']>;
  stintConfirmationScreenViewed_gte?: Maybe<Scalars['Boolean']>;
  stintConfirmationScreenViewed_iendswith?: Maybe<Scalars['Boolean']>;
  stintConfirmationScreenViewed_istartswith?: Maybe<Scalars['Boolean']>;
  stintConfirmationScreenViewedAt?: Maybe<Scalars['AWSDateTime']>;
  stintConfirmationScreenViewedAt_lt?: Maybe<Scalars['AWSDateTime']>;
  stintConfirmationScreenViewedAt_lte?: Maybe<Scalars['AWSDateTime']>;
  stintConfirmationScreenViewedAt_isnull?: Maybe<Scalars['Boolean']>;
  stintConfirmationScreenViewedAt_gt?: Maybe<Scalars['AWSDateTime']>;
  stintConfirmationScreenViewedAt_gte?: Maybe<Scalars['AWSDateTime']>;
  stintConfirmationScreenViewedAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  stintConfirmationScreenViewedAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  fastestRouteTimeCalculated?: Maybe<Scalars['Boolean']>;
  fastestRouteTimeCalculated_lt?: Maybe<Scalars['Boolean']>;
  fastestRouteTimeCalculated_lte?: Maybe<Scalars['Boolean']>;
  fastestRouteTimeCalculated_isnull?: Maybe<Scalars['Boolean']>;
  fastestRouteTimeCalculated_gt?: Maybe<Scalars['Boolean']>;
  fastestRouteTimeCalculated_gte?: Maybe<Scalars['Boolean']>;
  fastestRouteTimeCalculated_iendswith?: Maybe<Scalars['Boolean']>;
  fastestRouteTimeCalculated_istartswith?: Maybe<Scalars['Boolean']>;
  enableGranularTrackingNotificationSent?: Maybe<Scalars['Boolean']>;
  enableGranularTrackingNotificationSent_lt?: Maybe<Scalars['Boolean']>;
  enableGranularTrackingNotificationSent_lte?: Maybe<Scalars['Boolean']>;
  enableGranularTrackingNotificationSent_isnull?: Maybe<Scalars['Boolean']>;
  enableGranularTrackingNotificationSent_gt?: Maybe<Scalars['Boolean']>;
  enableGranularTrackingNotificationSent_gte?: Maybe<Scalars['Boolean']>;
  enableGranularTrackingNotificationSent_iendswith?: Maybe<Scalars['Boolean']>;
  enableGranularTrackingNotificationSent_istartswith?: Maybe<
    Scalars['Boolean']
  >;
  lastChanceToEnableGranularTrackingNotificationSent?: Maybe<
    Scalars['AWSDateTime']
  >;
  lastChanceToEnableGranularTrackingNotificationSent_lt?: Maybe<
    Scalars['AWSDateTime']
  >;
  lastChanceToEnableGranularTrackingNotificationSent_lte?: Maybe<
    Scalars['AWSDateTime']
  >;
  lastChanceToEnableGranularTrackingNotificationSent_isnull?: Maybe<
    Scalars['Boolean']
  >;
  lastChanceToEnableGranularTrackingNotificationSent_gt?: Maybe<
    Scalars['AWSDateTime']
  >;
  lastChanceToEnableGranularTrackingNotificationSent_gte?: Maybe<
    Scalars['AWSDateTime']
  >;
  lastChanceToEnableGranularTrackingNotificationSent_iendswith?: Maybe<
    Scalars['AWSDateTime']
  >;
  lastChanceToEnableGranularTrackingNotificationSent_istartswith?: Maybe<
    Scalars['AWSDateTime']
  >;
  studentArrivedAt?: Maybe<Scalars['AWSDateTime']>;
  studentArrivedAt_lt?: Maybe<Scalars['AWSDateTime']>;
  studentArrivedAt_lte?: Maybe<Scalars['AWSDateTime']>;
  studentArrivedAt_isnull?: Maybe<Scalars['Boolean']>;
  studentArrivedAt_gt?: Maybe<Scalars['AWSDateTime']>;
  studentArrivedAt_gte?: Maybe<Scalars['AWSDateTime']>;
  studentArrivedAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  studentArrivedAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  lastChanceToEnableGranularTrackingNotificationAt?: Maybe<
    Scalars['AWSDateTime']
  >;
  lastChanceToEnableGranularTrackingNotificationAt_lt?: Maybe<
    Scalars['AWSDateTime']
  >;
  lastChanceToEnableGranularTrackingNotificationAt_lte?: Maybe<
    Scalars['AWSDateTime']
  >;
  lastChanceToEnableGranularTrackingNotificationAt_isnull?: Maybe<
    Scalars['Boolean']
  >;
  lastChanceToEnableGranularTrackingNotificationAt_gt?: Maybe<
    Scalars['AWSDateTime']
  >;
  lastChanceToEnableGranularTrackingNotificationAt_gte?: Maybe<
    Scalars['AWSDateTime']
  >;
  lastChanceToEnableGranularTrackingNotificationAt_iendswith?: Maybe<
    Scalars['AWSDateTime']
  >;
  lastChanceToEnableGranularTrackingNotificationAt_istartswith?: Maybe<
    Scalars['AWSDateTime']
  >;
  estimatedTimeOfDeparture?: Maybe<Scalars['AWSDateTime']>;
  estimatedTimeOfDeparture_lt?: Maybe<Scalars['AWSDateTime']>;
  estimatedTimeOfDeparture_lte?: Maybe<Scalars['AWSDateTime']>;
  estimatedTimeOfDeparture_isnull?: Maybe<Scalars['Boolean']>;
  estimatedTimeOfDeparture_gt?: Maybe<Scalars['AWSDateTime']>;
  estimatedTimeOfDeparture_gte?: Maybe<Scalars['AWSDateTime']>;
  estimatedTimeOfDeparture_iendswith?: Maybe<Scalars['AWSDateTime']>;
  estimatedTimeOfDeparture_istartswith?: Maybe<Scalars['AWSDateTime']>;
  leaveCheckDurationLate?: Maybe<Scalars['String']>;
  leaveCheckDurationLate_lt?: Maybe<Scalars['String']>;
  leaveCheckDurationLate_lte?: Maybe<Scalars['String']>;
  leaveCheckDurationLate_isnull?: Maybe<Scalars['Boolean']>;
  leaveCheckDurationLate_gt?: Maybe<Scalars['String']>;
  leaveCheckDurationLate_gte?: Maybe<Scalars['String']>;
  leaveCheckDurationLate_iendswith?: Maybe<Scalars['String']>;
  leaveCheckDurationLate_istartswith?: Maybe<Scalars['String']>;
  halfwayCheckDurationLate?: Maybe<Scalars['String']>;
  halfwayCheckDurationLate_lt?: Maybe<Scalars['String']>;
  halfwayCheckDurationLate_lte?: Maybe<Scalars['String']>;
  halfwayCheckDurationLate_isnull?: Maybe<Scalars['Boolean']>;
  halfwayCheckDurationLate_gt?: Maybe<Scalars['String']>;
  halfwayCheckDurationLate_gte?: Maybe<Scalars['String']>;
  halfwayCheckDurationLate_iendswith?: Maybe<Scalars['String']>;
  halfwayCheckDurationLate_istartswith?: Maybe<Scalars['String']>;
  isGranularLocationTrackingEnabled?: Maybe<Scalars['Boolean']>;
  isGranularLocationTrackingEnabled_lt?: Maybe<Scalars['Boolean']>;
  isGranularLocationTrackingEnabled_lte?: Maybe<Scalars['Boolean']>;
  isGranularLocationTrackingEnabled_isnull?: Maybe<Scalars['Boolean']>;
  isGranularLocationTrackingEnabled_gt?: Maybe<Scalars['Boolean']>;
  isGranularLocationTrackingEnabled_gte?: Maybe<Scalars['Boolean']>;
  isGranularLocationTrackingEnabled_iendswith?: Maybe<Scalars['Boolean']>;
  isGranularLocationTrackingEnabled_istartswith?: Maybe<Scalars['Boolean']>;
  estimatedTimeOfArrival?: Maybe<Scalars['AWSDateTime']>;
  estimatedTimeOfArrival_lt?: Maybe<Scalars['AWSDateTime']>;
  estimatedTimeOfArrival_lte?: Maybe<Scalars['AWSDateTime']>;
  estimatedTimeOfArrival_isnull?: Maybe<Scalars['Boolean']>;
  estimatedTimeOfArrival_gt?: Maybe<Scalars['AWSDateTime']>;
  estimatedTimeOfArrival_gte?: Maybe<Scalars['AWSDateTime']>;
  estimatedTimeOfArrival_iendswith?: Maybe<Scalars['AWSDateTime']>;
  estimatedTimeOfArrival_istartswith?: Maybe<Scalars['AWSDateTime']>;
  lateNote?: Maybe<Scalars['String']>;
  lateNote_lt?: Maybe<Scalars['String']>;
  lateNote_search?: Maybe<Scalars['String']>;
  lateNote_lte?: Maybe<Scalars['String']>;
  lateNote_isnull?: Maybe<Scalars['Boolean']>;
  lateNote_gt?: Maybe<Scalars['String']>;
  lateNote_gte?: Maybe<Scalars['String']>;
  lateNote_iendswith?: Maybe<Scalars['String']>;
  lateNote_istartswith?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  internalNotes_lt?: Maybe<Scalars['String']>;
  internalNotes_search?: Maybe<Scalars['String']>;
  internalNotes_lte?: Maybe<Scalars['String']>;
  internalNotes_isnull?: Maybe<Scalars['Boolean']>;
  internalNotes_gt?: Maybe<Scalars['String']>;
  internalNotes_gte?: Maybe<Scalars['String']>;
  internalNotes_iendswith?: Maybe<Scalars['String']>;
  internalNotes_istartswith?: Maybe<Scalars['String']>;
  doNotRemindAboutGeotracking?: Maybe<Scalars['Boolean']>;
  doNotRemindAboutGeotracking_lt?: Maybe<Scalars['Boolean']>;
  doNotRemindAboutGeotracking_lte?: Maybe<Scalars['Boolean']>;
  doNotRemindAboutGeotracking_isnull?: Maybe<Scalars['Boolean']>;
  doNotRemindAboutGeotracking_gt?: Maybe<Scalars['Boolean']>;
  doNotRemindAboutGeotracking_gte?: Maybe<Scalars['Boolean']>;
  doNotRemindAboutGeotracking_iendswith?: Maybe<Scalars['Boolean']>;
  doNotRemindAboutGeotracking_istartswith?: Maybe<Scalars['Boolean']>;
  statusModifiedAt?: Maybe<Scalars['AWSDateTime']>;
  statusModifiedAt_lt?: Maybe<Scalars['AWSDateTime']>;
  statusModifiedAt_lte?: Maybe<Scalars['AWSDateTime']>;
  statusModifiedAt_isnull?: Maybe<Scalars['Boolean']>;
  statusModifiedAt_gt?: Maybe<Scalars['AWSDateTime']>;
  statusModifiedAt_gte?: Maybe<Scalars['AWSDateTime']>;
  statusModifiedAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  statusModifiedAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  usingNewLocationMicroservices?: Maybe<Scalars['Boolean']>;
  usingNewLocationMicroservices_lt?: Maybe<Scalars['Boolean']>;
  usingNewLocationMicroservices_lte?: Maybe<Scalars['Boolean']>;
  usingNewLocationMicroservices_isnull?: Maybe<Scalars['Boolean']>;
  usingNewLocationMicroservices_gt?: Maybe<Scalars['Boolean']>;
  usingNewLocationMicroservices_gte?: Maybe<Scalars['Boolean']>;
  usingNewLocationMicroservices_iendswith?: Maybe<Scalars['Boolean']>;
  usingNewLocationMicroservices_istartswith?: Maybe<Scalars['Boolean']>;
  latestLocationLongitude?: Maybe<Scalars['Float']>;
  latestLocationLongitude_lt?: Maybe<Scalars['Float']>;
  latestLocationLongitude_lte?: Maybe<Scalars['Float']>;
  latestLocationLongitude_isnull?: Maybe<Scalars['Boolean']>;
  latestLocationLongitude_gt?: Maybe<Scalars['Float']>;
  latestLocationLongitude_gte?: Maybe<Scalars['Float']>;
  latestLocationLongitude_iendswith?: Maybe<Scalars['Float']>;
  latestLocationLongitude_istartswith?: Maybe<Scalars['Float']>;
  latestLocationLatitude?: Maybe<Scalars['Float']>;
  latestLocationLatitude_lt?: Maybe<Scalars['Float']>;
  latestLocationLatitude_lte?: Maybe<Scalars['Float']>;
  latestLocationLatitude_isnull?: Maybe<Scalars['Boolean']>;
  latestLocationLatitude_gt?: Maybe<Scalars['Float']>;
  latestLocationLatitude_gte?: Maybe<Scalars['Float']>;
  latestLocationLatitude_iendswith?: Maybe<Scalars['Float']>;
  latestLocationLatitude_istartswith?: Maybe<Scalars['Float']>;
  latestLocationRecordedAt?: Maybe<Scalars['AWSDateTime']>;
  latestLocationRecordedAt_lt?: Maybe<Scalars['AWSDateTime']>;
  latestLocationRecordedAt_lte?: Maybe<Scalars['AWSDateTime']>;
  latestLocationRecordedAt_isnull?: Maybe<Scalars['Boolean']>;
  latestLocationRecordedAt_gt?: Maybe<Scalars['AWSDateTime']>;
  latestLocationRecordedAt_gte?: Maybe<Scalars['AWSDateTime']>;
  latestLocationRecordedAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  latestLocationRecordedAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  city_id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  business_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  student_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  modifiedAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  statusResolved_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  stintNotificationReminder24HoursSent_in?: Maybe<
    Array<Maybe<Scalars['Boolean']>>
  >;
  stintNotificationReminder12HoursSent_in?: Maybe<
    Array<Maybe<Scalars['Boolean']>>
  >;
  confirmStintNotificationSentAt_in?: Maybe<
    Array<Maybe<Scalars['AWSDateTime']>>
  >;
  stintConfirmationNotificationSent_in?: Maybe<
    Array<Maybe<Scalars['Boolean']>>
  >;
  lastChanceToConfirmStintNotificationSentAt_in?: Maybe<
    Array<Maybe<Scalars['AWSDateTime']>>
  >;
  stintConfirmationScreenViewed_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  stintConfirmationScreenViewedAt_in?: Maybe<
    Array<Maybe<Scalars['AWSDateTime']>>
  >;
  fastestRouteTimeCalculated_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  enableGranularTrackingNotificationSent_in?: Maybe<
    Array<Maybe<Scalars['Boolean']>>
  >;
  lastChanceToEnableGranularTrackingNotificationSent_in?: Maybe<
    Array<Maybe<Scalars['AWSDateTime']>>
  >;
  studentArrivedAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  lastChanceToEnableGranularTrackingNotificationAt_in?: Maybe<
    Array<Maybe<Scalars['AWSDateTime']>>
  >;
  estimatedTimeOfDeparture_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  leaveCheckDurationLate_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  halfwayCheckDurationLate_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isGranularLocationTrackingEnabled_in?: Maybe<
    Array<Maybe<Scalars['Boolean']>>
  >;
  estimatedTimeOfArrival_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  lateNote_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  internalNotes_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  doNotRemindAboutGeotracking_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  statusModifiedAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  usingNewLocationMicroservices_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  latestLocationLongitude_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  latestLocationLatitude_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  latestLocationRecordedAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
};

export enum StintJourneyStatus {
  NO_CONFIRMATION = 'NO_CONFIRMATION',
  TRACKING_OFF = 'TRACKING_OFF',
  HAS_NOT_LEFT = 'HAS_NOT_LEFT',
  HAS_NOT_ARRIVED = 'HAS_NOT_ARRIVED',
  GOING_TO_BE_LATE = 'GOING_TO_BE_LATE',
  ARRIVED = 'ARRIVED',
  CHECKED_IN = 'CHECKED_IN',
  ON_TRACK = 'ON_TRACK',
}

export type StintListType = {
  __typename?: 'StintListType';
  pageInfo: PageInfoType;
  objects?: Maybe<Array<Maybe<Stint>>>;
};

export type StintNode = Node & {
  __typename?: 'StintNode';
  id: Scalars['ID'];
  name: Scalars['String'];
  trained: Scalars['Boolean'];
  dateFrom?: Maybe<Scalars['AWSDateTime']>;
  dateTo?: Maybe<Scalars['AWSDateTime']>;
  status?: Maybe<Scalars['Int']>;
  createdAt: Scalars['AWSDateTime'];
  modifiedAt: Scalars['AWSDateTime'];
  primaryTasks: Array<StintTask>;
  secondaryTasks: Array<StintTask>;
  exceptions: Array<EmploymentException>;
  topTips: Array<TopTip>;
  business?: Maybe<Business>;
  uniform?: Maybe<Array<Maybe<Scalars['String']>>>;
  notes?: Maybe<Scalars['String']>;
  dateAccepted?: Maybe<Scalars['AWSDateTime']>;
  dateToProposed?: Maybe<Scalars['AWSDateTime']>;
  lastModified?: Maybe<Scalars['AWSDateTime']>;
  checkInDate?: Maybe<Scalars['AWSDateTime']>;
  checkOutDate?: Maybe<Scalars['AWSDateTime']>;
  manualCheckInReasons?: Maybe<Array<Maybe<Scalars['String']>>>;
  qrCodeCheckInDate?: Maybe<Scalars['AWSDateTime']>;
  address?: Maybe<Address>;
  city?: Maybe<City>;
  locationAddress?: Maybe<Scalars['String']>;
  locationName?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  invoiceStatus?: Maybe<StintInvoiceStatus>;
  isDirectDebit: Scalars['Boolean'];
  isFreeStint: Scalars['Boolean'];
  paymentReference?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['Int']>;
  preAuthorizedAmount?: Maybe<Scalars['Int']>;
  selectedPaymentMethod?: Maybe<DirectDebitPaymentMethod>;
  selectedCardPaymentMethod?: Maybe<CardPaymentMethod>;
  student?: Maybe<StintUser>;
  studentIsLate: Scalars['Boolean'];
  studentRunningLateHalfwayCheck: Scalars['Boolean'];
  eta?: Maybe<Scalars['Float']>;
  cancelledAt?: Maybe<Scalars['AWSDateTime']>;
  cancelledBy?: Maybe<StintUser>;
  nextAutomationBatchAt?: Maybe<Scalars['AWSDateTime']>;
  isAutomationComplete: Scalars['Boolean'];
  automationLastTriggeredAt?: Maybe<Scalars['AWSDateTime']>;
  automationAttempts?: Maybe<Scalars['Int']>;
  uniformInstructions?: Maybe<Scalars['String']>;
  trainingInstructions?: Maybe<Scalars['String']>;
  healthAndSafety?: Maybe<Scalars['String']>;
  opsNotifiedUnassigned: Scalars['Boolean'];
  opsNotifiedLocationOff: Scalars['Boolean'];
  quantityStudentsForDraftStint?: Maybe<Scalars['Int']>;
  quantityRepeatDaysForDraftStint?: Maybe<Scalars['Int']>;
  repeatDaysForDraftStint?: Maybe<Array<Maybe<Scalars['AWSDate']>>>;
  averageOwnerRating?: Maybe<Scalars['Float']>;
  controlCheck: Scalars['Boolean'];
  createdByUser?: Maybe<StintUser>;
  differentStartTimeReasons?: Maybe<Array<Maybe<Scalars['String']>>>;
  pspReference?: Maybe<Scalars['String']>;
  stintCategory?: Maybe<Scalars['String']>;
  stintTemplate?: Maybe<StintTemplate>;
  isBestEffort: Scalars['Boolean'];
  acceptanceMethod?: Maybe<StintAcceptanceMethod>;
  stintOffersToStudent: Array<StintOffer>;
  deletedAt?: Maybe<Scalars['AWSDateTime']>;
  ref: Scalars['ID'];
  businessreviewSet: Array<BusinessReview>;
  stintjourneySet: Array<StintJourney>;
  studentreviewSet: Array<StudentReview>;
  studentunassignedeventSet: Array<StudentUnassignedEvent>;
  offenceSet: StinterOffenceTypeConnection;
  role?: Maybe<Scalars['String']>;
  distanceToStintAddress?: Maybe<Scalars['Float']>;
  stinterPay: StinterPay;
  site: PartnerSiteProfile;
};

export type StintNodeoffenceSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type StintNodeTypeConnection = {
  __typename?: 'StintNodeTypeConnection';
  pageInfo: PageInfo;
  edges: Array<StintNodeTypeEdge>;
};

export type StintNodeTypeEdge = {
  __typename?: 'StintNodeTypeEdge';
  node: StintNode;
  cursor: Scalars['String'];
};

export type StintObject = {
  __typename?: 'StintObject';
  id: Scalars['ID'];
  studentReview?: Maybe<StintStudentReviewAttribute>;
};

export type StintObjectPage = Pagination & {
  __typename?: 'StintObjectPage';
  items?: Maybe<Array<StintObject>>;
  total: Scalars['Int'];
  nextToken?: Maybe<Scalars['String']>;
};

export type StintOffer = {
  __typename?: 'StintOffer';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  deletedAt?: Maybe<Scalars['AWSDateTime']>;
  ref: Scalars['ID'];
  student?: Maybe<StintUser>;
  stint?: Maybe<Stint>;
  status: StintOfferToStudentStatus;
  offerMadeAt: Scalars['AWSDateTime'];
  offerAcceptedAt?: Maybe<Scalars['AWSDateTime']>;
  offerReadAt?: Maybe<Scalars['AWSDateTime']>;
  offerType: StintOfferToStudentOfferType;
  offerStintCancelledAt?: Maybe<Scalars['AWSDateTime']>;
  offerSeenAt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt: Scalars['AWSDateTime'];
  business?: Maybe<Business>;
  student_id?: Maybe<Scalars['ID']>;
  stint_id?: Maybe<Scalars['ID']>;
};

export type StintOfferListType = {
  __typename?: 'StintOfferListType';
  pageInfo: PageInfoType;
  objects?: Maybe<Array<Maybe<StintOffer>>>;
};

export type StintOfferToStudentFilter = {
  status?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  idIcontains?: Maybe<Scalars['String']>;
  student_id?: Maybe<Scalars['ID']>;
  student_id_isnull?: Maybe<Scalars['Boolean']>;
  stint_id?: Maybe<Scalars['ID']>;
  stint_id_isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_isnull?: Maybe<Scalars['Boolean']>;
  createdAt_gt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_gte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  createdAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  status_lt?: Maybe<Scalars['String']>;
  status_search?: Maybe<Scalars['String']>;
  status_lte?: Maybe<Scalars['String']>;
  status_isnull?: Maybe<Scalars['Boolean']>;
  status_gt?: Maybe<Scalars['String']>;
  status_gte?: Maybe<Scalars['String']>;
  status_iendswith?: Maybe<Scalars['String']>;
  status_istartswith?: Maybe<Scalars['String']>;
  offerMadeAt?: Maybe<Scalars['AWSDateTime']>;
  offerMadeAt_lt?: Maybe<Scalars['AWSDateTime']>;
  offerMadeAt_lte?: Maybe<Scalars['AWSDateTime']>;
  offerMadeAt_isnull?: Maybe<Scalars['Boolean']>;
  offerMadeAt_gt?: Maybe<Scalars['AWSDateTime']>;
  offerMadeAt_gte?: Maybe<Scalars['AWSDateTime']>;
  offerMadeAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  offerMadeAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  offerAcceptedAt?: Maybe<Scalars['AWSDateTime']>;
  offerAcceptedAt_lt?: Maybe<Scalars['AWSDateTime']>;
  offerAcceptedAt_lte?: Maybe<Scalars['AWSDateTime']>;
  offerAcceptedAt_isnull?: Maybe<Scalars['Boolean']>;
  offerAcceptedAt_gt?: Maybe<Scalars['AWSDateTime']>;
  offerAcceptedAt_gte?: Maybe<Scalars['AWSDateTime']>;
  offerAcceptedAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  offerAcceptedAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  offerReadAt?: Maybe<Scalars['AWSDateTime']>;
  offerReadAt_lt?: Maybe<Scalars['AWSDateTime']>;
  offerReadAt_lte?: Maybe<Scalars['AWSDateTime']>;
  offerReadAt_isnull?: Maybe<Scalars['Boolean']>;
  offerReadAt_gt?: Maybe<Scalars['AWSDateTime']>;
  offerReadAt_gte?: Maybe<Scalars['AWSDateTime']>;
  offerReadAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  offerReadAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  offerType?: Maybe<Scalars['String']>;
  offerType_lt?: Maybe<Scalars['String']>;
  offerType_search?: Maybe<Scalars['String']>;
  offerType_lte?: Maybe<Scalars['String']>;
  offerType_isnull?: Maybe<Scalars['Boolean']>;
  offerType_gt?: Maybe<Scalars['String']>;
  offerType_gte?: Maybe<Scalars['String']>;
  offerType_iendswith?: Maybe<Scalars['String']>;
  offerType_istartswith?: Maybe<Scalars['String']>;
  offerStintCancelledAt?: Maybe<Scalars['AWSDateTime']>;
  offerStintCancelledAt_lt?: Maybe<Scalars['AWSDateTime']>;
  offerStintCancelledAt_lte?: Maybe<Scalars['AWSDateTime']>;
  offerStintCancelledAt_isnull?: Maybe<Scalars['Boolean']>;
  offerStintCancelledAt_gt?: Maybe<Scalars['AWSDateTime']>;
  offerStintCancelledAt_gte?: Maybe<Scalars['AWSDateTime']>;
  offerStintCancelledAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  offerStintCancelledAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  offerSeenAt?: Maybe<Scalars['AWSDateTime']>;
  offerSeenAt_lt?: Maybe<Scalars['AWSDateTime']>;
  offerSeenAt_lte?: Maybe<Scalars['AWSDateTime']>;
  offerSeenAt_isnull?: Maybe<Scalars['Boolean']>;
  offerSeenAt_gt?: Maybe<Scalars['AWSDateTime']>;
  offerSeenAt_gte?: Maybe<Scalars['AWSDateTime']>;
  offerSeenAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  offerSeenAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_isnull?: Maybe<Scalars['Boolean']>;
  modifiedAt_gt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_gte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  status_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  offerMadeAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  offerAcceptedAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  offerReadAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  offerType_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  offerStintCancelledAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  offerSeenAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  modifiedAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
};

export enum StintOfferToStudentOfferType {
  STINTMATCHER = 'STINTMATCHER',
  STINTMATCHER_TOP_UP = 'STINTMATCHER_TOP_UP',
  WATERFALL = 'WATERFALL',
  PUSHNOTIF = 'PUSHNOTIF',
  AVAILABILITY_MATCHING = 'AVAILABILITY_MATCHING',
  AUTOMATION = 'AUTOMATION',
}

export enum StintOfferToStudentStatus {
  OPEN = 'OPEN',
  READ = 'READ',
  ACCEPTED = 'ACCEPTED',
  UNACCEPTED = 'UNACCEPTED',
  REPLACED = 'REPLACED',
  EXPIRED = 'EXPIRED',
  MISSED = 'MISSED',
  CANCELLED = 'CANCELLED',
  STUDENT_CANCELLED_STINT = 'STUDENT_CANCELLED_STINT',
  CANCELLED_ON_STUDENT_DEACTIVATION = 'CANCELLED_ON_STUDENT_DEACTIVATION',
}

export type StintRates = {
  __typename?: 'StintRates';
  baseFeeOverride?: Maybe<Scalars['String']>;
  totalFeeWithVat?: Maybe<Scalars['String']>;
  cancellationFee?: Maybe<Scalars['String']>;
  cancellationFeeApplied?: Maybe<Scalars['Boolean']>;
  baseRateOverride?: Maybe<Scalars['String']>;
  basePay?: Maybe<Scalars['String']>;
  surgeRate?: Maybe<Scalars['String']>;
  surgePay?: Maybe<Scalars['String']>;
  surgeApplied?: Maybe<Scalars['Boolean']>;
  bonusRate?: Maybe<Scalars['String']>;
  bonusPay?: Maybe<Scalars['String']>;
  bonusApplied?: Maybe<Scalars['Boolean']>;
};

export enum StintSessionDay {
  DAY_MONDAY = 'DAY_MONDAY',
  DAY_TUESDAY = 'DAY_TUESDAY',
  DAY_WEDNESDAY = 'DAY_WEDNESDAY',
  DAY_THURSDAY = 'DAY_THURSDAY',
  DAY_FRIDAY = 'DAY_FRIDAY',
  DAY_SATURDAY = 'DAY_SATURDAY',
  DAY_SUNDAY = 'DAY_SUNDAY',
}

export type StintSessionTemplate = {
  __typename?: 'StintSessionTemplate';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  modifiedAt: Scalars['AWSDateTime'];
  deletedAt?: Maybe<Scalars['AWSDateTime']>;
  ref: Scalars['ID'];
  peekTime?: Maybe<Scalars['String']>;
  startTime: Scalars['AWSTime'];
  endTime: Scalars['AWSTime'];
  day: StintSessionTemplateDay;
  stintTemplateStudents: Array<StintTemplateStudents>;
  isActive: Scalars['Boolean'];
  studentsPerStintTemplate?: Maybe<Array<Maybe<StintTemplateStudents>>>;
  isCustom?: Maybe<Scalars['Boolean']>;
};

export enum StintSessionTemplateDay {
  DAY_MONDAY = 'DAY_MONDAY',
  DAY_TUESDAY = 'DAY_TUESDAY',
  DAY_WEDNESDAY = 'DAY_WEDNESDAY',
  DAY_THURSDAY = 'DAY_THURSDAY',
  DAY_FRIDAY = 'DAY_FRIDAY',
  DAY_SATURDAY = 'DAY_SATURDAY',
  DAY_SUNDAY = 'DAY_SUNDAY',
}

export type StintStudentReview = {
  __typename?: 'StintStudentReview';
  id: Scalars['ID'];
  comment: Scalars['String'];
  mark: Scalars['Int'];
  student?: Maybe<StudentObject>;
  stint: StintObject;
};

export type StintStudentReviewAttribute = {
  __typename?: 'StintStudentReviewAttribute';
  id: Scalars['ID'];
  comment: Scalars['String'];
  mark: Scalars['Int'];
};

export type StintStudentReviewFilter = {
  studentId?: Maybe<Scalars['ID']>;
  stintId?: Maybe<Scalars['ID']>;
  mark?: Maybe<IntegerRangeInput>;
  comment?: Maybe<Scalars['String']>;
};

export type StintStudentReviewInput = {
  stintId: Scalars['ID'];
  mark: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
};

export type StintStudentReviewPage = Pagination & {
  __typename?: 'StintStudentReviewPage';
  items?: Maybe<Array<StintStudentReview>>;
  total: Scalars['Int'];
  nextToken?: Maybe<Scalars['String']>;
};

export type StintTask = {
  __typename?: 'StintTask';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  modifiedAt: Scalars['AWSDateTime'];
  deletedAt?: Maybe<Scalars['AWSDateTime']>;
  ref: Scalars['ID'];
  sortOrder?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  alternativeName: Scalars['String'];
  taskType: StintTaskTaskType;
  totalNumberPostedTask?: Maybe<Scalars['Int']>;
  totalNumberBusinessesPosted?: Maybe<Scalars['Int']>;
  averageStudentRatingPosted?: Maybe<Scalars['Float']>;
  averageBusinessRatingPosted?: Maybe<Scalars['Float']>;
  isDefault: Scalars['Boolean'];
  topTips?: Maybe<Array<Maybe<TopTip>>>;
  role_id?: Maybe<Scalars['ID']>;
  business_category_id?: Maybe<Scalars['ID']>;
};

export type StintTaskFilter = {
  search?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  idIcontains?: Maybe<Scalars['String']>;
  q?: Maybe<Scalars['String']>;
  role_id?: Maybe<Scalars['ID']>;
  role_id_isnull?: Maybe<Scalars['Boolean']>;
  businessCategory_id?: Maybe<Scalars['ID']>;
  businessCategory_id_isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_isnull?: Maybe<Scalars['Boolean']>;
  createdAt_gt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_gte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  createdAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_isnull?: Maybe<Scalars['Boolean']>;
  modifiedAt_gt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_gte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  sortOrder?: Maybe<Scalars['Float']>;
  sortOrder_lt?: Maybe<Scalars['Float']>;
  sortOrder_lte?: Maybe<Scalars['Float']>;
  sortOrder_isnull?: Maybe<Scalars['Boolean']>;
  sortOrder_gt?: Maybe<Scalars['Float']>;
  sortOrder_gte?: Maybe<Scalars['Float']>;
  sortOrder_iendswith?: Maybe<Scalars['Float']>;
  sortOrder_istartswith?: Maybe<Scalars['Float']>;
  name_lt?: Maybe<Scalars['String']>;
  name_search?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_isnull?: Maybe<Scalars['Boolean']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_iendswith?: Maybe<Scalars['String']>;
  name_istartswith?: Maybe<Scalars['String']>;
  alternativeName?: Maybe<Scalars['String']>;
  alternativeName_lt?: Maybe<Scalars['String']>;
  alternativeName_search?: Maybe<Scalars['String']>;
  alternativeName_lte?: Maybe<Scalars['String']>;
  alternativeName_isnull?: Maybe<Scalars['Boolean']>;
  alternativeName_gt?: Maybe<Scalars['String']>;
  alternativeName_gte?: Maybe<Scalars['String']>;
  alternativeName_iendswith?: Maybe<Scalars['String']>;
  alternativeName_istartswith?: Maybe<Scalars['String']>;
  taskType?: Maybe<Scalars['String']>;
  taskType_lt?: Maybe<Scalars['String']>;
  taskType_search?: Maybe<Scalars['String']>;
  taskType_lte?: Maybe<Scalars['String']>;
  taskType_isnull?: Maybe<Scalars['Boolean']>;
  taskType_gt?: Maybe<Scalars['String']>;
  taskType_gte?: Maybe<Scalars['String']>;
  taskType_iendswith?: Maybe<Scalars['String']>;
  taskType_istartswith?: Maybe<Scalars['String']>;
  totalNumberPostedTask?: Maybe<Scalars['Float']>;
  totalNumberPostedTask_lt?: Maybe<Scalars['Float']>;
  totalNumberPostedTask_lte?: Maybe<Scalars['Float']>;
  totalNumberPostedTask_isnull?: Maybe<Scalars['Boolean']>;
  totalNumberPostedTask_gt?: Maybe<Scalars['Float']>;
  totalNumberPostedTask_gte?: Maybe<Scalars['Float']>;
  totalNumberPostedTask_iendswith?: Maybe<Scalars['Float']>;
  totalNumberPostedTask_istartswith?: Maybe<Scalars['Float']>;
  totalNumberBusinessesPosted?: Maybe<Scalars['Float']>;
  totalNumberBusinessesPosted_lt?: Maybe<Scalars['Float']>;
  totalNumberBusinessesPosted_lte?: Maybe<Scalars['Float']>;
  totalNumberBusinessesPosted_isnull?: Maybe<Scalars['Boolean']>;
  totalNumberBusinessesPosted_gt?: Maybe<Scalars['Float']>;
  totalNumberBusinessesPosted_gte?: Maybe<Scalars['Float']>;
  totalNumberBusinessesPosted_iendswith?: Maybe<Scalars['Float']>;
  totalNumberBusinessesPosted_istartswith?: Maybe<Scalars['Float']>;
  averageStudentRatingPosted?: Maybe<Scalars['Float']>;
  averageStudentRatingPosted_lt?: Maybe<Scalars['Float']>;
  averageStudentRatingPosted_lte?: Maybe<Scalars['Float']>;
  averageStudentRatingPosted_isnull?: Maybe<Scalars['Boolean']>;
  averageStudentRatingPosted_gt?: Maybe<Scalars['Float']>;
  averageStudentRatingPosted_gte?: Maybe<Scalars['Float']>;
  averageStudentRatingPosted_iendswith?: Maybe<Scalars['Float']>;
  averageStudentRatingPosted_istartswith?: Maybe<Scalars['Float']>;
  averageBusinessRatingPosted?: Maybe<Scalars['Float']>;
  averageBusinessRatingPosted_lt?: Maybe<Scalars['Float']>;
  averageBusinessRatingPosted_lte?: Maybe<Scalars['Float']>;
  averageBusinessRatingPosted_isnull?: Maybe<Scalars['Boolean']>;
  averageBusinessRatingPosted_gt?: Maybe<Scalars['Float']>;
  averageBusinessRatingPosted_gte?: Maybe<Scalars['Float']>;
  averageBusinessRatingPosted_iendswith?: Maybe<Scalars['Float']>;
  averageBusinessRatingPosted_istartswith?: Maybe<Scalars['Float']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDefault_lt?: Maybe<Scalars['Boolean']>;
  isDefault_lte?: Maybe<Scalars['Boolean']>;
  isDefault_isnull?: Maybe<Scalars['Boolean']>;
  isDefault_gt?: Maybe<Scalars['Boolean']>;
  isDefault_gte?: Maybe<Scalars['Boolean']>;
  isDefault_iendswith?: Maybe<Scalars['Boolean']>;
  isDefault_istartswith?: Maybe<Scalars['Boolean']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  modifiedAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  sortOrder_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  alternativeName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  taskType_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  totalNumberPostedTask_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  totalNumberBusinessesPosted_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  averageStudentRatingPosted_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  averageBusinessRatingPosted_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  isDefault_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
};

export type StintTaskInput = {
  name: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
};

export type StintTaskListType = {
  __typename?: 'StintTaskListType';
  pageInfo: PageInfoType;
  objects?: Maybe<Array<Maybe<StintTask>>>;
};

export enum StintTaskTaskType {
  STINT_APPROVED = 'STINT_APPROVED',
  CUSTOM = 'CUSTOM',
}

export type StintTemplate = Node & {
  __typename?: 'StintTemplate';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  modifiedAt: Scalars['AWSDateTime'];
  deletedAt?: Maybe<Scalars['AWSDateTime']>;
  ref: Scalars['ID'];
  name: Scalars['String'];
  primaryTasks?: Maybe<Array<Maybe<StintTask>>>;
  secondaryTasks?: Maybe<Array<Maybe<StintTask>>>;
  exceptions?: Maybe<Array<Maybe<EmploymentException>>>;
  topTips?: Maybe<Array<Maybe<TopTip>>>;
  business?: Maybe<Business>;
  uniform?: Maybe<Array<Maybe<Scalars['String']>>>;
  notes?: Maybe<Scalars['String']>;
  trained: Scalars['Boolean'];
  uniformReminderEnabled: Scalars['Boolean'];
  prepVideoUrl?: Maybe<Scalars['String']>;
  prepVideoId?: Maybe<Scalars['String']>;
  controlledRelease: Scalars['Boolean'];
  bonusRate?: Maybe<Scalars['String']>;
  poolMember: StintTemplatePoolMember;
  poolMembers: StintTemplatePoolMemberTypeConnection;
  stintTemplateGroupId?: Maybe<Scalars['ID']>;
  business_id?: Maybe<Scalars['ID']>;
};

export type StintTemplatepoolMemberArgs = {
  id: Scalars['ID'];
};

export type StintTemplatepoolMembersArgs = {
  input: StintTemplatePoolMembersInput;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum StintTemplateError {
  INAVALID_BONUS_RATE = 'INAVALID_BONUS_RATE',
  INVALID_BONUS_RATE = 'INVALID_BONUS_RATE',
  INVALID_NAME = 'INVALID_NAME',
  CANNOT_SET_STINT_TEMPLATE_GROUP_IF_NOT_TRAINED = 'CANNOT_SET_STINT_TEMPLATE_GROUP_IF_NOT_TRAINED',
}

export type StintTemplateExceptionInput = {
  name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type StintTemplateFilter = {
  name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  idIcontains?: Maybe<Scalars['String']>;
  business_id?: Maybe<Scalars['ID']>;
  business_id_isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_isnull?: Maybe<Scalars['Boolean']>;
  createdAt_gt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_gte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  createdAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_isnull?: Maybe<Scalars['Boolean']>;
  modifiedAt_gt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_gte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  name_lt?: Maybe<Scalars['String']>;
  name_search?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_isnull?: Maybe<Scalars['Boolean']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_iendswith?: Maybe<Scalars['String']>;
  name_istartswith?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  notes_lt?: Maybe<Scalars['String']>;
  notes_search?: Maybe<Scalars['String']>;
  notes_lte?: Maybe<Scalars['String']>;
  notes_isnull?: Maybe<Scalars['Boolean']>;
  notes_gt?: Maybe<Scalars['String']>;
  notes_gte?: Maybe<Scalars['String']>;
  notes_iendswith?: Maybe<Scalars['String']>;
  notes_istartswith?: Maybe<Scalars['String']>;
  trained?: Maybe<Scalars['Boolean']>;
  trained_lt?: Maybe<Scalars['Boolean']>;
  trained_lte?: Maybe<Scalars['Boolean']>;
  trained_isnull?: Maybe<Scalars['Boolean']>;
  trained_gt?: Maybe<Scalars['Boolean']>;
  trained_gte?: Maybe<Scalars['Boolean']>;
  trained_iendswith?: Maybe<Scalars['Boolean']>;
  trained_istartswith?: Maybe<Scalars['Boolean']>;
  uniformReminderEnabled?: Maybe<Scalars['Boolean']>;
  uniformReminderEnabled_lt?: Maybe<Scalars['Boolean']>;
  uniformReminderEnabled_lte?: Maybe<Scalars['Boolean']>;
  uniformReminderEnabled_isnull?: Maybe<Scalars['Boolean']>;
  uniformReminderEnabled_gt?: Maybe<Scalars['Boolean']>;
  uniformReminderEnabled_gte?: Maybe<Scalars['Boolean']>;
  uniformReminderEnabled_iendswith?: Maybe<Scalars['Boolean']>;
  uniformReminderEnabled_istartswith?: Maybe<Scalars['Boolean']>;
  prepVideoUrl?: Maybe<Scalars['String']>;
  prepVideoUrl_lt?: Maybe<Scalars['String']>;
  prepVideoUrl_search?: Maybe<Scalars['String']>;
  prepVideoUrl_lte?: Maybe<Scalars['String']>;
  prepVideoUrl_isnull?: Maybe<Scalars['Boolean']>;
  prepVideoUrl_gt?: Maybe<Scalars['String']>;
  prepVideoUrl_gte?: Maybe<Scalars['String']>;
  prepVideoUrl_iendswith?: Maybe<Scalars['String']>;
  prepVideoUrl_istartswith?: Maybe<Scalars['String']>;
  prepVideoId?: Maybe<Scalars['String']>;
  prepVideoId_lt?: Maybe<Scalars['String']>;
  prepVideoId_search?: Maybe<Scalars['String']>;
  prepVideoId_lte?: Maybe<Scalars['String']>;
  prepVideoId_isnull?: Maybe<Scalars['Boolean']>;
  prepVideoId_gt?: Maybe<Scalars['String']>;
  prepVideoId_gte?: Maybe<Scalars['String']>;
  prepVideoId_iendswith?: Maybe<Scalars['String']>;
  prepVideoId_istartswith?: Maybe<Scalars['String']>;
  controlledRelease?: Maybe<Scalars['Boolean']>;
  controlledRelease_lt?: Maybe<Scalars['Boolean']>;
  controlledRelease_lte?: Maybe<Scalars['Boolean']>;
  controlledRelease_isnull?: Maybe<Scalars['Boolean']>;
  controlledRelease_gt?: Maybe<Scalars['Boolean']>;
  controlledRelease_gte?: Maybe<Scalars['Boolean']>;
  controlledRelease_iendswith?: Maybe<Scalars['Boolean']>;
  controlledRelease_istartswith?: Maybe<Scalars['Boolean']>;
  bonusRate?: Maybe<Scalars['Float']>;
  bonusRate_lt?: Maybe<Scalars['Float']>;
  bonusRate_lte?: Maybe<Scalars['Float']>;
  bonusRate_isnull?: Maybe<Scalars['Boolean']>;
  bonusRate_gt?: Maybe<Scalars['Float']>;
  bonusRate_gte?: Maybe<Scalars['Float']>;
  bonusRate_iendswith?: Maybe<Scalars['Float']>;
  bonusRate_istartswith?: Maybe<Scalars['Float']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  primaryTasks_ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  secondaryTasks_ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  exceptions_ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  topTips_ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  modifiedAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  notes_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  trained_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  uniformReminderEnabled_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  prepVideoUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  prepVideoId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  controlledRelease_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  bonusRate_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type StintTemplateGroup = Node & {
  __typename?: 'StintTemplateGroup';
  id: Scalars['ID'];
  name: Scalars['String'];
  stintTemplates: Array<StintTemplate>;
  partnerNodeId: Scalars['ID'];
  stintTemplateIds: Array<Scalars['ID']>;
  stintTemplateCount: Scalars['Int'];
};

export type StintTemplatePool = {
  __typename?: 'StintTemplatePool';
  stintTemplate: StintTemplate;
};

export type StintTemplatePoolMember = Node & {
  __typename?: 'StintTemplatePoolMember';
  id: Scalars['ID'];
  stintTemplate: StintTemplate;
  stinter: StinterProfile;
  active: Scalars['Boolean'];
  trial: Scalars['Boolean'];
  completedStintsCount: Scalars['Int'];
  feedback: PoolMemberFeedbackTypeConnection;
};

export type StintTemplatePoolMemberfeedbackArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type StintTemplatePoolMemberCommitment = Node & {
  __typename?: 'StintTemplatePoolMemberCommitment';
  id: Scalars['ID'];
  stintsPerWeek: Scalars['Int'];
  startDate: Scalars['AWSDate'];
  endDate: Scalars['AWSDate'];
  numberOfWeeks: Scalars['Int'];
  stintTemplate: StintTemplate;
  nextWeekAcceptedStintsCount?: Maybe<Scalars['Int']>;
};

export enum StintTemplatePoolMemberError {
  DUPLICATE_STINT_TEMPLATE_POOL_MEMBER = 'DUPLICATE_STINT_TEMPLATE_POOL_MEMBER',
  INVALID_PARTNER_NODE_ID = 'INVALID_PARTNER_NODE_ID',
  NO_REASONS_PROVIDED = 'NO_REASONS_PROVIDED',
  MORE_INFO_REQUIRED = 'MORE_INFO_REQUIRED',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

export type StintTemplatePoolMemberFilterInput = {
  stinterName?: Maybe<Scalars['String']>;
};

export type StintTemplatePoolMemberTypeConnection = {
  __typename?: 'StintTemplatePoolMemberTypeConnection';
  pageInfo: PageInfo;
  edges: Array<StintTemplatePoolMemberTypeEdge>;
};

export type StintTemplatePoolMemberTypeEdge = {
  __typename?: 'StintTemplatePoolMemberTypeEdge';
  node: StintTemplatePoolMember;
  cursor: Scalars['String'];
};

export type StintTemplatePoolMembersInput = {
  orderBy?: Maybe<StintTemplatePoolMembersOrderInput>;
  filterBy?: Maybe<StintTemplatePoolMemberFilterInput>;
};

export enum StintTemplatePoolMembersOrderField {
  DAYS_LEFT = 'DAYS_LEFT',
}

export type StintTemplatePoolMembersOrderInput = {
  field: StintTemplatePoolMembersOrderField;
  order: SortOrder;
};

export type StintTemplateStudents = {
  __typename?: 'StintTemplateStudents';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  modifiedAt: Scalars['AWSDateTime'];
  deletedAt?: Maybe<Scalars['AWSDateTime']>;
  ref: Scalars['ID'];
  stintTemplate?: Maybe<StintTemplate>;
  studentsCount?: Maybe<Scalars['Int']>;
  stint_template_id?: Maybe<Scalars['ID']>;
};

export type StintTemplateTaskInput = {
  name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type StintTemplateTopTipInput = {
  name: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
};

export type StintTemplateTrialPoolMemberFilterInput = {
  stintTemplateId?: Maybe<Scalars['ID']>;
};

export type StintTemplateTrialPoolMembersInput = {
  filterBy?: Maybe<StintTemplateTrialPoolMemberFilterInput>;
};

export type StintTemplateTypeConnection = {
  __typename?: 'StintTemplateTypeConnection';
  pageInfo: PageInfo;
  edges: Array<StintTemplateTypeEdge>;
};

export type StintTemplateTypeEdge = {
  __typename?: 'StintTemplateTypeEdge';
  node: StintTemplate;
  cursor: Scalars['String'];
};

export type StintTemplateTypeListType = {
  __typename?: 'StintTemplateTypeListType';
  pageInfo: PageInfoType;
  objects?: Maybe<Array<Maybe<StintTemplate>>>;
};

export type StintTemplatesFilterInput = {
  name?: Maybe<Scalars['String']>;
  trained?: Maybe<Scalars['Boolean']>;
};

export enum StintTemplatesOrderField {
  NAME = 'NAME',
}

export type StintTemplatesOrderInput = {
  field: StintTemplatesOrderField;
  order: SortOrder;
};

export enum StintUniform {
  WHITE_FORMAL_SHIRT = 'WHITE_FORMAL_SHIRT',
  BLACK_FORMAL_SHIRT = 'BLACK_FORMAL_SHIRT',
  BLACK_T_SHIRT = 'BLACK_T_SHIRT',
  BLACK_FORMAL_TROUSERS = 'BLACK_FORMAL_TROUSERS',
  BLACK_JEANS = 'BLACK_JEANS',
  DARK_TRAINERS = 'DARK_TRAINERS',
  BLACK_FORMAL_SHOES = 'BLACK_FORMAL_SHOES',
  BLACK_FORMAL_SOCKS = 'BLACK_FORMAL_SOCKS',
}

export type StintUser = Node & {
  __typename?: 'StintUser';
  id: Scalars['ID'];
  lastLogin?: Maybe<Scalars['AWSDateTime']>;
  isSuperuser: Scalars['Boolean'];
  createdAt: Scalars['AWSDateTime'];
  modifiedAt: Scalars['AWSDateTime'];
  email?: Maybe<Scalars['String']>;
  userType?: Maybe<StintUserUserType>;
  isStaff?: Maybe<Scalars['String']>;
  accountStatus: StintUserAccountStatus;
  isActive: Scalars['Boolean'];
  deactivated: Scalars['Boolean'];
  permanentDeactivated: Scalars['Boolean'];
  hasValidPassword: Scalars['Boolean'];
  isEmailVerified: Scalars['Boolean'];
  isVerifiedByStint: Scalars['Boolean'];
  verifiedByStintAt?: Maybe<Scalars['AWSDateTime']>;
  isOnWaitingList: Scalars['Boolean'];
  hasSeenDashboard?: Maybe<Scalars['AWSDateTime']>;
  /** @deprecated Drop in favor of DeviceInfo.appVersion */
  appVersion?: Maybe<Scalars['String']>;
  acceptedUserAgreementAt?: Maybe<Scalars['AWSDateTime']>;
  secondaryEmail?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  fullname?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['AWSDate']>;
  gender?: Maybe<StintUserGender>;
  profilePhoto: Scalars['String'];
  profilePhotoFile?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Float']>;
  canSeeSurge?: Maybe<Scalars['Boolean']>;
  hasNeverAddedAvailability?: Maybe<Scalars['Boolean']>;
  city?: Maybe<City>;
  currentCity?: Maybe<City>;
  studentReviews: Array<StudentReview>;
  employerProfile?: Maybe<EmployerProfile>;
  notifications: Array<NotificationRecipient>;
  stints: StintNodeTypeConnection;
  stintCancellations: StintNodeTypeConnection;
  stintCreatedByUser: StintNodeTypeConnection;
  studentProfile?: Maybe<StudentProfile>;
  availability: Array<StintUserAvailability>;
  stintOffersToStudent: Array<StintOffer>;
  businessSalesRep: Array<Business>;
  businessAccountManager: Array<Business>;
  userProfile?: Maybe<UserProfile>;
  isMe?: Maybe<Scalars['Boolean']>;
  isStudent?: Maybe<Scalars['Boolean']>;
  isEmployer?: Maybe<Scalars['Boolean']>;
  profilePhotoUrl?: Maybe<Scalars['String']>;
  /** @deprecated Handled by LaunchDarkly Instead */
  canForceUpdate?: Maybe<Scalars['Boolean']>;
  canSeeDashboard?: Maybe<Scalars['Boolean']>;
  locationSetting?: Maybe<LocationSettings>;
  hasNotificationsEnabled?: Maybe<Scalars['Boolean']>;
  /** @deprecated Please use currentCity instead. */
  latestAvailabilityCity?: Maybe<City>;
  studentSavedPlaces?: Maybe<Array<Maybe<StudentSavedPlace>>>;
  totalAmountEarned?: Maybe<Scalars['Float']>;
  otp?: Maybe<Scalars['String']>;
  userCurrentCity?: Maybe<City>;
  exceptions?: Maybe<Array<Maybe<EmploymentException>>>;
  publicRating: Scalars['String'];
  city_id?: Maybe<Scalars['ID']>;
  current_city_id?: Maybe<Scalars['ID']>;
};

export type StintUserstintsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type StintUserstintCancellationsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type StintUserstintCreatedByUserArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum StintUserAccountStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  SUSPENDED = 'SUSPENDED',
}

export type StintUserAvailability = {
  __typename?: 'StintUserAvailability';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  modifiedAt: Scalars['AWSDateTime'];
  deletedAt?: Maybe<Scalars['AWSDateTime']>;
  ref: Scalars['ID'];
  city?: Maybe<City>;
  studentSavedPlaces?: Maybe<Array<Maybe<StudentSavedPlace>>>;
  firebaseRef?: Maybe<Scalars['String']>;
  user: StintUser;
  address?: Maybe<Scalars['String']>;
  dateFrom: Scalars['AWSDateTime'];
  dateTo: Scalars['AWSDateTime'];
  disabled: Scalars['Boolean'];
  repeat?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  inputType?: Maybe<StintUserAvailabilityInputType>;
  city_id?: Maybe<Scalars['ID']>;
  user_id?: Maybe<Scalars['ID']>;
};

export enum StintUserAvailabilityInputType {
  CREATED_WITH_BEST_TIMES_CONSOLE = 'CREATED_WITH_BEST_TIMES_CONSOLE',
  CREATED_WITH_MY_AVAILABILITY = 'CREATED_WITH_MY_AVAILABILITY',
}

export type StintUserAvailabilityListType = {
  __typename?: 'StintUserAvailabilityListType';
  pageInfo: PageInfoType;
  objects?: Maybe<Array<Maybe<StintUserAvailability>>>;
};

export type StintUserFilter = {
  q?: Maybe<Scalars['String']>;
  fullname?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  idIcontains?: Maybe<Scalars['String']>;
  allUserEmails?: Maybe<Scalars['String']>;
  user_fullname_search?: Maybe<Scalars['String']>;
  city_id?: Maybe<Scalars['ID']>;
  city_id_isnull?: Maybe<Scalars['Boolean']>;
  currentCity_id?: Maybe<Scalars['ID']>;
  currentCity_id_isnull?: Maybe<Scalars['Boolean']>;
  lastLogin?: Maybe<Scalars['AWSDateTime']>;
  lastLogin_lt?: Maybe<Scalars['AWSDateTime']>;
  lastLogin_lte?: Maybe<Scalars['AWSDateTime']>;
  lastLogin_isnull?: Maybe<Scalars['Boolean']>;
  lastLogin_gt?: Maybe<Scalars['AWSDateTime']>;
  lastLogin_gte?: Maybe<Scalars['AWSDateTime']>;
  lastLogin_iendswith?: Maybe<Scalars['AWSDateTime']>;
  lastLogin_istartswith?: Maybe<Scalars['AWSDateTime']>;
  isSuperuser?: Maybe<Scalars['Boolean']>;
  isSuperuser_lt?: Maybe<Scalars['Boolean']>;
  isSuperuser_lte?: Maybe<Scalars['Boolean']>;
  isSuperuser_isnull?: Maybe<Scalars['Boolean']>;
  isSuperuser_gt?: Maybe<Scalars['Boolean']>;
  isSuperuser_gte?: Maybe<Scalars['Boolean']>;
  isSuperuser_iendswith?: Maybe<Scalars['Boolean']>;
  isSuperuser_istartswith?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_isnull?: Maybe<Scalars['Boolean']>;
  createdAt_gt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_gte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  createdAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_isnull?: Maybe<Scalars['Boolean']>;
  modifiedAt_gt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_gte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  email?: Maybe<Scalars['String']>;
  email_lt?: Maybe<Scalars['String']>;
  email_search?: Maybe<Scalars['String']>;
  email_lte?: Maybe<Scalars['String']>;
  email_isnull?: Maybe<Scalars['Boolean']>;
  email_gt?: Maybe<Scalars['String']>;
  email_gte?: Maybe<Scalars['String']>;
  email_iendswith?: Maybe<Scalars['String']>;
  email_istartswith?: Maybe<Scalars['String']>;
  userType?: Maybe<Scalars['String']>;
  userType_lt?: Maybe<Scalars['String']>;
  userType_search?: Maybe<Scalars['String']>;
  userType_lte?: Maybe<Scalars['String']>;
  userType_isnull?: Maybe<Scalars['Boolean']>;
  userType_gt?: Maybe<Scalars['String']>;
  userType_gte?: Maybe<Scalars['String']>;
  userType_iendswith?: Maybe<Scalars['String']>;
  userType_istartswith?: Maybe<Scalars['String']>;
  isStaff?: Maybe<Scalars['Boolean']>;
  isStaff_lt?: Maybe<Scalars['Boolean']>;
  isStaff_lte?: Maybe<Scalars['Boolean']>;
  isStaff_isnull?: Maybe<Scalars['Boolean']>;
  isStaff_gt?: Maybe<Scalars['Boolean']>;
  isStaff_gte?: Maybe<Scalars['Boolean']>;
  isStaff_iendswith?: Maybe<Scalars['Boolean']>;
  isStaff_istartswith?: Maybe<Scalars['Boolean']>;
  accountStatus?: Maybe<Scalars['String']>;
  accountStatus_lt?: Maybe<Scalars['String']>;
  accountStatus_search?: Maybe<Scalars['String']>;
  accountStatus_lte?: Maybe<Scalars['String']>;
  accountStatus_isnull?: Maybe<Scalars['Boolean']>;
  accountStatus_gt?: Maybe<Scalars['String']>;
  accountStatus_gte?: Maybe<Scalars['String']>;
  accountStatus_iendswith?: Maybe<Scalars['String']>;
  accountStatus_istartswith?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isActive_lt?: Maybe<Scalars['Boolean']>;
  isActive_lte?: Maybe<Scalars['Boolean']>;
  isActive_isnull?: Maybe<Scalars['Boolean']>;
  isActive_gt?: Maybe<Scalars['Boolean']>;
  isActive_gte?: Maybe<Scalars['Boolean']>;
  isActive_iendswith?: Maybe<Scalars['Boolean']>;
  isActive_istartswith?: Maybe<Scalars['Boolean']>;
  deactivated?: Maybe<Scalars['Boolean']>;
  deactivated_lt?: Maybe<Scalars['Boolean']>;
  deactivated_lte?: Maybe<Scalars['Boolean']>;
  deactivated_isnull?: Maybe<Scalars['Boolean']>;
  deactivated_gt?: Maybe<Scalars['Boolean']>;
  deactivated_gte?: Maybe<Scalars['Boolean']>;
  deactivated_iendswith?: Maybe<Scalars['Boolean']>;
  deactivated_istartswith?: Maybe<Scalars['Boolean']>;
  permanentDeactivated?: Maybe<Scalars['Boolean']>;
  permanentDeactivated_lt?: Maybe<Scalars['Boolean']>;
  permanentDeactivated_lte?: Maybe<Scalars['Boolean']>;
  permanentDeactivated_isnull?: Maybe<Scalars['Boolean']>;
  permanentDeactivated_gt?: Maybe<Scalars['Boolean']>;
  permanentDeactivated_gte?: Maybe<Scalars['Boolean']>;
  permanentDeactivated_iendswith?: Maybe<Scalars['Boolean']>;
  permanentDeactivated_istartswith?: Maybe<Scalars['Boolean']>;
  hasValidPassword?: Maybe<Scalars['Boolean']>;
  hasValidPassword_lt?: Maybe<Scalars['Boolean']>;
  hasValidPassword_lte?: Maybe<Scalars['Boolean']>;
  hasValidPassword_isnull?: Maybe<Scalars['Boolean']>;
  hasValidPassword_gt?: Maybe<Scalars['Boolean']>;
  hasValidPassword_gte?: Maybe<Scalars['Boolean']>;
  hasValidPassword_iendswith?: Maybe<Scalars['Boolean']>;
  hasValidPassword_istartswith?: Maybe<Scalars['Boolean']>;
  isEmailVerified?: Maybe<Scalars['Boolean']>;
  isEmailVerified_lt?: Maybe<Scalars['Boolean']>;
  isEmailVerified_lte?: Maybe<Scalars['Boolean']>;
  isEmailVerified_isnull?: Maybe<Scalars['Boolean']>;
  isEmailVerified_gt?: Maybe<Scalars['Boolean']>;
  isEmailVerified_gte?: Maybe<Scalars['Boolean']>;
  isEmailVerified_iendswith?: Maybe<Scalars['Boolean']>;
  isEmailVerified_istartswith?: Maybe<Scalars['Boolean']>;
  isVerifiedByStint?: Maybe<Scalars['Boolean']>;
  isVerifiedByStint_lt?: Maybe<Scalars['Boolean']>;
  isVerifiedByStint_lte?: Maybe<Scalars['Boolean']>;
  isVerifiedByStint_isnull?: Maybe<Scalars['Boolean']>;
  isVerifiedByStint_gt?: Maybe<Scalars['Boolean']>;
  isVerifiedByStint_gte?: Maybe<Scalars['Boolean']>;
  isVerifiedByStint_iendswith?: Maybe<Scalars['Boolean']>;
  isVerifiedByStint_istartswith?: Maybe<Scalars['Boolean']>;
  verifiedByStintAt?: Maybe<Scalars['AWSDateTime']>;
  verifiedByStintAt_lt?: Maybe<Scalars['AWSDateTime']>;
  verifiedByStintAt_lte?: Maybe<Scalars['AWSDateTime']>;
  verifiedByStintAt_isnull?: Maybe<Scalars['Boolean']>;
  verifiedByStintAt_gt?: Maybe<Scalars['AWSDateTime']>;
  verifiedByStintAt_gte?: Maybe<Scalars['AWSDateTime']>;
  verifiedByStintAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  verifiedByStintAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  isOnWaitingList?: Maybe<Scalars['Boolean']>;
  isOnWaitingList_lt?: Maybe<Scalars['Boolean']>;
  isOnWaitingList_lte?: Maybe<Scalars['Boolean']>;
  isOnWaitingList_isnull?: Maybe<Scalars['Boolean']>;
  isOnWaitingList_gt?: Maybe<Scalars['Boolean']>;
  isOnWaitingList_gte?: Maybe<Scalars['Boolean']>;
  isOnWaitingList_iendswith?: Maybe<Scalars['Boolean']>;
  isOnWaitingList_istartswith?: Maybe<Scalars['Boolean']>;
  hasSeenDashboard?: Maybe<Scalars['AWSDateTime']>;
  hasSeenDashboard_lt?: Maybe<Scalars['AWSDateTime']>;
  hasSeenDashboard_lte?: Maybe<Scalars['AWSDateTime']>;
  hasSeenDashboard_isnull?: Maybe<Scalars['Boolean']>;
  hasSeenDashboard_gt?: Maybe<Scalars['AWSDateTime']>;
  hasSeenDashboard_gte?: Maybe<Scalars['AWSDateTime']>;
  hasSeenDashboard_iendswith?: Maybe<Scalars['AWSDateTime']>;
  hasSeenDashboard_istartswith?: Maybe<Scalars['AWSDateTime']>;
  appVersion?: Maybe<Scalars['String']>;
  appVersion_lt?: Maybe<Scalars['String']>;
  appVersion_search?: Maybe<Scalars['String']>;
  appVersion_lte?: Maybe<Scalars['String']>;
  appVersion_isnull?: Maybe<Scalars['Boolean']>;
  appVersion_gt?: Maybe<Scalars['String']>;
  appVersion_gte?: Maybe<Scalars['String']>;
  appVersion_iendswith?: Maybe<Scalars['String']>;
  appVersion_istartswith?: Maybe<Scalars['String']>;
  acceptedUserAgreementAt?: Maybe<Scalars['AWSDateTime']>;
  acceptedUserAgreementAt_lt?: Maybe<Scalars['AWSDateTime']>;
  acceptedUserAgreementAt_lte?: Maybe<Scalars['AWSDateTime']>;
  acceptedUserAgreementAt_isnull?: Maybe<Scalars['Boolean']>;
  acceptedUserAgreementAt_gt?: Maybe<Scalars['AWSDateTime']>;
  acceptedUserAgreementAt_gte?: Maybe<Scalars['AWSDateTime']>;
  acceptedUserAgreementAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  acceptedUserAgreementAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  secondaryEmail?: Maybe<Scalars['String']>;
  secondaryEmail_lt?: Maybe<Scalars['String']>;
  secondaryEmail_search?: Maybe<Scalars['String']>;
  secondaryEmail_lte?: Maybe<Scalars['String']>;
  secondaryEmail_isnull?: Maybe<Scalars['Boolean']>;
  secondaryEmail_gt?: Maybe<Scalars['String']>;
  secondaryEmail_gte?: Maybe<Scalars['String']>;
  secondaryEmail_iendswith?: Maybe<Scalars['String']>;
  secondaryEmail_istartswith?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  title_lt?: Maybe<Scalars['String']>;
  title_search?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_isnull?: Maybe<Scalars['Boolean']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_iendswith?: Maybe<Scalars['String']>;
  title_istartswith?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  firstName_lt?: Maybe<Scalars['String']>;
  firstName_search?: Maybe<Scalars['String']>;
  firstName_lte?: Maybe<Scalars['String']>;
  firstName_isnull?: Maybe<Scalars['Boolean']>;
  firstName_gt?: Maybe<Scalars['String']>;
  firstName_gte?: Maybe<Scalars['String']>;
  firstName_iendswith?: Maybe<Scalars['String']>;
  firstName_istartswith?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  lastName_lt?: Maybe<Scalars['String']>;
  lastName_search?: Maybe<Scalars['String']>;
  lastName_lte?: Maybe<Scalars['String']>;
  lastName_isnull?: Maybe<Scalars['Boolean']>;
  lastName_gt?: Maybe<Scalars['String']>;
  lastName_gte?: Maybe<Scalars['String']>;
  lastName_iendswith?: Maybe<Scalars['String']>;
  lastName_istartswith?: Maybe<Scalars['String']>;
  fullname_lt?: Maybe<Scalars['String']>;
  fullname_search?: Maybe<Scalars['String']>;
  fullname_lte?: Maybe<Scalars['String']>;
  fullname_isnull?: Maybe<Scalars['Boolean']>;
  fullname_gt?: Maybe<Scalars['String']>;
  fullname_gte?: Maybe<Scalars['String']>;
  fullname_iendswith?: Maybe<Scalars['String']>;
  fullname_istartswith?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumber_lt?: Maybe<Scalars['String']>;
  phoneNumber_search?: Maybe<Scalars['String']>;
  phoneNumber_lte?: Maybe<Scalars['String']>;
  phoneNumber_isnull?: Maybe<Scalars['Boolean']>;
  phoneNumber_gt?: Maybe<Scalars['String']>;
  phoneNumber_gte?: Maybe<Scalars['String']>;
  phoneNumber_iendswith?: Maybe<Scalars['String']>;
  phoneNumber_istartswith?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['AWSDate']>;
  dateOfBirth_lt?: Maybe<Scalars['AWSDate']>;
  dateOfBirth_lte?: Maybe<Scalars['AWSDate']>;
  dateOfBirth_isnull?: Maybe<Scalars['Boolean']>;
  dateOfBirth_gt?: Maybe<Scalars['AWSDate']>;
  dateOfBirth_gte?: Maybe<Scalars['AWSDate']>;
  dateOfBirth_iendswith?: Maybe<Scalars['AWSDate']>;
  dateOfBirth_istartswith?: Maybe<Scalars['AWSDate']>;
  gender?: Maybe<Scalars['String']>;
  gender_lt?: Maybe<Scalars['String']>;
  gender_search?: Maybe<Scalars['String']>;
  gender_lte?: Maybe<Scalars['String']>;
  gender_isnull?: Maybe<Scalars['Boolean']>;
  gender_gt?: Maybe<Scalars['String']>;
  gender_gte?: Maybe<Scalars['String']>;
  gender_iendswith?: Maybe<Scalars['String']>;
  gender_istartswith?: Maybe<Scalars['String']>;
  profilePhoto?: Maybe<Scalars['String']>;
  profilePhoto_lt?: Maybe<Scalars['String']>;
  profilePhoto_search?: Maybe<Scalars['String']>;
  profilePhoto_lte?: Maybe<Scalars['String']>;
  profilePhoto_isnull?: Maybe<Scalars['Boolean']>;
  profilePhoto_gt?: Maybe<Scalars['String']>;
  profilePhoto_gte?: Maybe<Scalars['String']>;
  profilePhoto_iendswith?: Maybe<Scalars['String']>;
  profilePhoto_istartswith?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Float']>;
  rating_lt?: Maybe<Scalars['Float']>;
  rating_lte?: Maybe<Scalars['Float']>;
  rating_isnull?: Maybe<Scalars['Boolean']>;
  rating_gt?: Maybe<Scalars['Float']>;
  rating_gte?: Maybe<Scalars['Float']>;
  rating_iendswith?: Maybe<Scalars['Float']>;
  rating_istartswith?: Maybe<Scalars['Float']>;
  canSeeSurge?: Maybe<Scalars['Boolean']>;
  canSeeSurge_lt?: Maybe<Scalars['Boolean']>;
  canSeeSurge_lte?: Maybe<Scalars['Boolean']>;
  canSeeSurge_isnull?: Maybe<Scalars['Boolean']>;
  canSeeSurge_gt?: Maybe<Scalars['Boolean']>;
  canSeeSurge_gte?: Maybe<Scalars['Boolean']>;
  canSeeSurge_iendswith?: Maybe<Scalars['Boolean']>;
  canSeeSurge_istartswith?: Maybe<Scalars['Boolean']>;
  hasNeverAddedAvailability?: Maybe<Scalars['Boolean']>;
  hasNeverAddedAvailability_lt?: Maybe<Scalars['Boolean']>;
  hasNeverAddedAvailability_lte?: Maybe<Scalars['Boolean']>;
  hasNeverAddedAvailability_isnull?: Maybe<Scalars['Boolean']>;
  hasNeverAddedAvailability_gt?: Maybe<Scalars['Boolean']>;
  hasNeverAddedAvailability_gte?: Maybe<Scalars['Boolean']>;
  hasNeverAddedAvailability_iendswith?: Maybe<Scalars['Boolean']>;
  hasNeverAddedAvailability_istartswith?: Maybe<Scalars['Boolean']>;
  firebaseRef?: Maybe<Scalars['String']>;
  firebaseRef_lt?: Maybe<Scalars['String']>;
  firebaseRef_search?: Maybe<Scalars['String']>;
  firebaseRef_lte?: Maybe<Scalars['String']>;
  firebaseRef_isnull?: Maybe<Scalars['Boolean']>;
  firebaseRef_gt?: Maybe<Scalars['String']>;
  firebaseRef_gte?: Maybe<Scalars['String']>;
  firebaseRef_iendswith?: Maybe<Scalars['String']>;
  firebaseRef_istartswith?: Maybe<Scalars['String']>;
  firebaseUserRef?: Maybe<Scalars['String']>;
  firebaseUserRef_lt?: Maybe<Scalars['String']>;
  firebaseUserRef_search?: Maybe<Scalars['String']>;
  firebaseUserRef_lte?: Maybe<Scalars['String']>;
  firebaseUserRef_isnull?: Maybe<Scalars['Boolean']>;
  firebaseUserRef_gt?: Maybe<Scalars['String']>;
  firebaseUserRef_gte?: Maybe<Scalars['String']>;
  firebaseUserRef_iendswith?: Maybe<Scalars['String']>;
  firebaseUserRef_istartswith?: Maybe<Scalars['String']>;
  firebaseSelectedBusinessRef?: Maybe<Scalars['String']>;
  firebaseSelectedBusinessRef_lt?: Maybe<Scalars['String']>;
  firebaseSelectedBusinessRef_search?: Maybe<Scalars['String']>;
  firebaseSelectedBusinessRef_lte?: Maybe<Scalars['String']>;
  firebaseSelectedBusinessRef_isnull?: Maybe<Scalars['Boolean']>;
  firebaseSelectedBusinessRef_gt?: Maybe<Scalars['String']>;
  firebaseSelectedBusinessRef_gte?: Maybe<Scalars['String']>;
  firebaseSelectedBusinessRef_iendswith?: Maybe<Scalars['String']>;
  firebaseSelectedBusinessRef_istartswith?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  groups_ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  userPermissions_ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  studentReviews_ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  studentEndorsements_ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  lastLogin_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  isSuperuser_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  modifiedAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  email_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  userType_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isStaff_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  accountStatus_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isActive_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  deactivated_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  permanentDeactivated_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  hasValidPassword_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  isEmailVerified_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  isVerifiedByStint_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  verifiedByStintAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  isOnWaitingList_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  hasSeenDashboard_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  appVersion_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  acceptedUserAgreementAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  secondaryEmail_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  firstName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  fullname_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  phoneNumber_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateOfBirth_in?: Maybe<Array<Maybe<Scalars['AWSDate']>>>;
  gender_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  profilePhoto_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  rating_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  canSeeSurge_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  hasNeverAddedAvailability_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  firebaseRef_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  firebaseUserRef_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  firebaseSelectedBusinessRef_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum StintUserGender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  TRANSGENDER = 'TRANSGENDER',
  NON_BINARY = 'NON_BINARY',
  PREFER_NOT_RESPOND = 'PREFER_NOT_RESPOND',
  OTHER = 'OTHER',
}

export type StintUserTypeConnection = {
  __typename?: 'StintUserTypeConnection';
  pageInfo: PageInfo;
  edges: Array<StintUserTypeEdge>;
};

export type StintUserTypeEdge = {
  __typename?: 'StintUserTypeEdge';
  node: StintUser;
  cursor: Scalars['String'];
};

export enum StintUserUserType {
  STUDENT = 'STUDENT',
  EMPLOYER = 'EMPLOYER',
  OWNER = 'OWNER',
  ADMIN = 'ADMIN',
  STAFF = 'STAFF',
  SUPERADMIN = 'SUPERADMIN',
}

export type StintWeekTemplate = {
  __typename?: 'StintWeekTemplate';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  modifiedAt: Scalars['AWSDateTime'];
  name: Scalars['String'];
  days?: Maybe<Array<Maybe<StintSessionTemplate>>>;
  business?: Maybe<Business>;
  address?: Maybe<Address>;
  timezone?: Maybe<Scalars['String']>;
  automaticPosting: Scalars['Boolean'];
  automaticPostingLastUpdatedBy?: Maybe<StintUser>;
  isDefault: Scalars['Boolean'];
  placeId?: Maybe<Scalars['String']>;
  displayAddress?: Maybe<Scalars['String']>;
  stints?: Maybe<Array<Maybe<Stint>>>;
  business_id?: Maybe<Scalars['ID']>;
  address_id?: Maybe<Scalars['ID']>;
  automatic_posting_last_updated_by_id?: Maybe<Scalars['ID']>;
};

export enum StintWeekTemplateError {
  INVALID_START_TIME = 'INVALID_START_TIME',
  INVALID_END_TIME = 'INVALID_END_TIME',
  INVALID_SESSION_MIN_LENGTH = 'INVALID_SESSION_MIN_LENGTH',
  INVALID_SESSION_MAX_LENGTH = 'INVALID_SESSION_MAX_LENGTH',
}

export type StintWeekTemplateFilter = {
  name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  idIcontains?: Maybe<Scalars['String']>;
  business_id?: Maybe<Scalars['ID']>;
  business_id_isnull?: Maybe<Scalars['Boolean']>;
  address_id?: Maybe<Scalars['ID']>;
  address_id_isnull?: Maybe<Scalars['Boolean']>;
  automaticPostingLastUpdatedBy_id?: Maybe<Scalars['ID']>;
  automaticPostingLastUpdatedBy_id_isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_isnull?: Maybe<Scalars['Boolean']>;
  createdAt_gt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_gte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  createdAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_isnull?: Maybe<Scalars['Boolean']>;
  modifiedAt_gt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_gte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  name_lt?: Maybe<Scalars['String']>;
  name_search?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_isnull?: Maybe<Scalars['Boolean']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_iendswith?: Maybe<Scalars['String']>;
  name_istartswith?: Maybe<Scalars['String']>;
  visible?: Maybe<Scalars['Boolean']>;
  visible_lt?: Maybe<Scalars['Boolean']>;
  visible_lte?: Maybe<Scalars['Boolean']>;
  visible_isnull?: Maybe<Scalars['Boolean']>;
  visible_gt?: Maybe<Scalars['Boolean']>;
  visible_gte?: Maybe<Scalars['Boolean']>;
  visible_iendswith?: Maybe<Scalars['Boolean']>;
  visible_istartswith?: Maybe<Scalars['Boolean']>;
  automaticPosting?: Maybe<Scalars['Boolean']>;
  automaticPosting_lt?: Maybe<Scalars['Boolean']>;
  automaticPosting_lte?: Maybe<Scalars['Boolean']>;
  automaticPosting_isnull?: Maybe<Scalars['Boolean']>;
  automaticPosting_gt?: Maybe<Scalars['Boolean']>;
  automaticPosting_gte?: Maybe<Scalars['Boolean']>;
  automaticPosting_iendswith?: Maybe<Scalars['Boolean']>;
  automaticPosting_istartswith?: Maybe<Scalars['Boolean']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDefault_lt?: Maybe<Scalars['Boolean']>;
  isDefault_lte?: Maybe<Scalars['Boolean']>;
  isDefault_isnull?: Maybe<Scalars['Boolean']>;
  isDefault_gt?: Maybe<Scalars['Boolean']>;
  isDefault_gte?: Maybe<Scalars['Boolean']>;
  isDefault_iendswith?: Maybe<Scalars['Boolean']>;
  isDefault_istartswith?: Maybe<Scalars['Boolean']>;
  version?: Maybe<Scalars['Float']>;
  version_lt?: Maybe<Scalars['Float']>;
  version_lte?: Maybe<Scalars['Float']>;
  version_isnull?: Maybe<Scalars['Boolean']>;
  version_gt?: Maybe<Scalars['Float']>;
  version_gte?: Maybe<Scalars['Float']>;
  version_iendswith?: Maybe<Scalars['Float']>;
  version_istartswith?: Maybe<Scalars['Float']>;
  releaseDay?: Maybe<Scalars['String']>;
  releaseDay_lt?: Maybe<Scalars['String']>;
  releaseDay_search?: Maybe<Scalars['String']>;
  releaseDay_lte?: Maybe<Scalars['String']>;
  releaseDay_isnull?: Maybe<Scalars['Boolean']>;
  releaseDay_gt?: Maybe<Scalars['String']>;
  releaseDay_gte?: Maybe<Scalars['String']>;
  releaseDay_iendswith?: Maybe<Scalars['String']>;
  releaseDay_istartswith?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  days_ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  modifiedAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  visible_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  automaticPosting_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  isDefault_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  version_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  releaseDay_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type StintWeekTemplateListType = {
  __typename?: 'StintWeekTemplateListType';
  pageInfo: PageInfoType;
  objects?: Maybe<Array<Maybe<StintWeekTemplate>>>;
};

export type StintWeekTemplateSessionInput = {
  startTime?: Maybe<Scalars['AWSTime']>;
  endTime?: Maybe<Scalars['AWSTime']>;
  day: StintSessionDay;
  isActive: Scalars['Boolean'];
  stintTemplates?: Maybe<Array<Maybe<StintWeekTemplateStintTypeInput>>>;
};

export type StintWeekTemplateStintTypeInput = {
  studentsCount: Scalars['Int'];
  stintTemplateId: Scalars['ID'];
};

export type Stinter = {
  __typename?: 'Stinter';
  id: Scalars['ID'];
  deactivated: Scalars['Boolean'];
  isVerifiedByStint: Scalars['Boolean'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['AWSDate']>;
  email: Scalars['String'];
  hasNeverAddedAvailability: Scalars['Boolean'];
  hasNotificationsEnabled?: Maybe<Scalars['Boolean']>;
  profilePhotoUrl?: Maybe<Scalars['String']>;
  locationSetting?: Maybe<LocationSettings>;
  publicRating: Scalars['String'];
  totalAmountEarned: Scalars['Float'];
  userCurrentCity?: Maybe<City>;
  createdAt: Scalars['AWSDateTime'];
  profileId: Scalars['ID'];
  gender?: Maybe<StudentProfileGender>;
  employeeStatement?: Maybe<EmployeeStatement>;
  marketingConsentAccepted?: Maybe<Scalars['Boolean']>;
  studentLoanPlan: StudentProfileStudentLoanPlan;
  primaryPaymentMethod?: Maybe<DirectDebitPaymentMethod>;
  addresses: Array<Address>;
  stintCount: Scalars['Int'];
  nationalInsuranceNumber?: Maybe<Scalars['String']>;
  emergencyContactName?: Maybe<Scalars['String']>;
  emergencyContactNumber?: Maybe<Scalars['String']>;
  emergencyContactRelation?: Maybe<Scalars['String']>;
  employmentExceptions: Array<EmploymentException>;
  marketingChannel?: Maybe<MarketingChannel>;
  strikesAwarded: Scalars['Int'];
  trained: Scalars['Boolean'];
  partnerPoolData: StinterPartnerPoolData;
  videoAssessmentInfo?: Maybe<VideoAssessmentInfo>;
  commitments: Array<StinterCommitment>;
  rightToWork: RTW;
};

export type StinterCommitment = Node & {
  __typename?: 'StinterCommitment';
  id: Scalars['ID'];
  stintsPerWeek: Scalars['Int'];
  startDate: Scalars['AWSDate'];
  endDate: Scalars['AWSDate'];
  numberOfWeeks: Scalars['Int'];
  poolMemberships: Array<StintTemplatePoolMember>;
  weeks: Array<CommitmentWeek>;
};

export type StinterCommitmentweeksArgs = {
  input: CommitmentWeeksInput;
};

export enum StinterCommitmentError {
  INACTIVE_STINT_TEMPLATE_POOL_MEMBER = 'INACTIVE_STINT_TEMPLATE_POOL_MEMBER',
  INVALID_NUMBER_OF_WEEKS = 'INVALID_NUMBER_OF_WEEKS',
  INVALID_START_DATE_WEEKDAY = 'INVALID_START_DATE_WEEKDAY',
  INVALID_STINTS_PER_WEEK = 'INVALID_STINTS_PER_WEEK',
  INVALID_STINT_TEMPLATE = 'INVALID_STINT_TEMPLATE',
  OVERLAPPING_COMMITMENT = 'OVERLAPPING_COMMITMENT',
  COMMITMENT_ALREADY_ENDED = 'COMMITMENT_ALREADY_ENDED',
}

export type StinterCompetencyTask = {
  __typename?: 'StinterCompetencyTask';
  id: Scalars['ID'];
  name: Scalars['String'];
  passed: Scalars['Boolean'];
};

export type StinterExperience = {
  __typename?: 'StinterExperience';
  experienceLevel: StinterExperienceLevel;
  completedStintCountWithinPartnerAccount: Scalars['Int'];
  completedStintCountWithinStintTemplateGroup: Scalars['Int'];
  siteNamesWhereStinterInPool: Array<Scalars['String']>;
  tasks: Array<StinterCompetencyTask>;
};

export enum StinterExperienceLevel {
  POOL_TRIAL = 'POOL_TRIAL',
  POOL_MEMBER = 'POOL_MEMBER',
  OTHER_POOL_EXPERIENCE = 'OTHER_POOL_EXPERIENCE',
  PARTNER_EXPERIENCE = 'PARTNER_EXPERIENCE',
  NO_PARTNER_EXPERIENCE = 'NO_PARTNER_EXPERIENCE',
}

export type StinterObject = {
  __typename?: 'StinterObject';
  id: Scalars['String'];
  rightToWork: StinterRTWStatus;
};

export type StinterOffence = Node & {
  __typename?: 'StinterOffence';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  reason: OffenceReason;
  strikesAwarded: Scalars['Int'];
  stint?: Maybe<Stint>;
  appealStatus: OffenceAppealStatus;
  business?: Maybe<Business>;
  isRedemption: Scalars['Boolean'];
};

export type StinterOffenceTypeConnection = {
  __typename?: 'StinterOffenceTypeConnection';
  pageInfo: PageInfo;
  edges: Array<StinterOffenceTypeEdge>;
};

export type StinterOffenceTypeEdge = {
  __typename?: 'StinterOffenceTypeEdge';
  node: StinterOffence;
  cursor: Scalars['String'];
};

export type StinterPartnerPoolData = {
  __typename?: 'StinterPartnerPoolData';
  hasPartnerPoolInvites: Scalars['Boolean'];
  hasNewPartnerPoolInvites: Scalars['Boolean'];
  partnerPools: Array<PartnerPool>;
  /** @deprecated Deprecated in favor of new Commitments design */
  nextWeekCommitments: Array<StintTemplatePoolMemberCommitment>;
  stintTemplatePools: Array<StintTemplatePool>;
  hasPoolWithBonus: Scalars['Boolean'];
};

export type StinterPay = {
  __typename?: 'StinterPay';
  baseRate?: Maybe<Scalars['String']>;
  basePay?: Maybe<Scalars['String']>;
  bonusRate?: Maybe<Scalars['String']>;
  bonusPay?: Maybe<Scalars['String']>;
};

export type StinterProfile = {
  __typename?: 'StinterProfile';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  gender?: Maybe<StudentProfileGender>;
  stintCount?: Maybe<Scalars['Int']>;
  nationalInsuranceNumber: Scalars['String'];
  addresses: Array<Address>;
  employeeStatement?: Maybe<EmployeeStatement>;
  studentLoanPlan: StudentProfileStudentLoanPlan;
  employmentExceptions: Array<EmploymentException>;
  emergencyContactName?: Maybe<Scalars['String']>;
  emergencyContactNumber?: Maybe<Scalars['String']>;
  emergencyContactRelation?: Maybe<Scalars['String']>;
  marketingConsentAccepted?: Maybe<Scalars['Boolean']>;
  marketingChannel?: Maybe<MarketingChannel>;
  strikesAwarded?: Maybe<Scalars['Int']>;
  partnerPoolData: StinterPartnerPoolData;
  primaryPaymentMethod?: Maybe<DirectDebitPaymentMethod>;
  stinterId: Scalars['ID'];
  deactivated: Scalars['Boolean'];
  isVerifiedByStint: Scalars['Boolean'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['AWSDate']>;
  email?: Maybe<Scalars['String']>;
  totalAmountEarned?: Maybe<Scalars['Float']>;
  userCurrentCity?: Maybe<City>;
  hasNeverAddedAvailability?: Maybe<Scalars['Boolean']>;
  hasNotificationsEnabled?: Maybe<Scalars['Boolean']>;
  profilePhotoUrl?: Maybe<Scalars['String']>;
  locationSetting?: Maybe<LocationSettings>;
  publicRating: Scalars['String'];
  trained: Scalars['Boolean'];
};

export type StinterRTWIDSPInput = {
  stinterID?: Maybe<Scalars['ID']>;
};

export enum StinterRTWProcessType {
  IDSP = 'IDSP',
  SHARECODE = 'SHARECODE',
}

export enum StinterRTWResultCode {
  INVALID_CODE_OR_DOB = 'INVALID_CODE_OR_DOB',
  NO_RIGHT_TO_WORK = 'NO_RIGHT_TO_WORK',
  EXPIRED_CODE = 'EXPIRED_CODE',
  EXPIRED = 'EXPIRED',
  UNKNOWN = 'UNKNOWN',
}

export type StinterRTWScreening = {
  __typename?: 'StinterRTWScreening';
  stinter: StinterObject;
  id: Scalars['ID'];
  type: StinterRTWProcessType;
  status: StinterRTWScreeningState;
  updatedAt: Scalars['AWSDateTime'];
  error?: Maybe<StinterRTWResultCode>;
  isLegacyVendor?: Maybe<Scalars['Boolean']>;
};

export enum StinterRTWScreeningState {
  COMPLETED = 'COMPLETED',
  PROCESSING = 'PROCESSING',
  SCHEDULED = 'SCHEDULED',
  IN_PROGRESS = 'IN_PROGRESS',
}

export type StinterRTWShareCodeInput = {
  code: Scalars['String'];
  stinterID?: Maybe<Scalars['ID']>;
  dob: Scalars['AWSDate'];
};

export enum StinterRTWState {
  MISSING = 'MISSING',
  APPROVED = 'APPROVED',
  PROCESSING = 'PROCESSING',
}

export type StinterRTWStatus = {
  __typename?: 'StinterRTWStatus';
  until?: Maybe<Scalars['AWSDate']>;
  /** Some RTW Visas have a beginning for when they are valid from */
  start?: Maybe<Scalars['AWSDate']>;
  weeklyCap?: Maybe<Scalars['Int']>;
  approvedAt: Scalars['AWSDateTime'];
};

export type StinterRTWStinterStatus = {
  __typename?: 'StinterRTWStinterStatus';
  id: Scalars['ID'];
  stinter: StinterObject;
  state: StinterRTWState;
  updatedAt: Scalars['AWSDateTime'];
};

export type StinterRightToWorkScreeningFilter = {
  stinterID?: Maybe<Scalars['ID']>;
  state?: Maybe<StinterRTWScreeningState>;
};

export type StinterRightToWorkScreeningPagination = {
  __typename?: 'StinterRightToWorkScreeningPagination';
  items: Array<StinterRTWScreening>;
  next?: Maybe<Scalars['String']>;
};

export type StinterRightToWorkStatusFilter = {
  state?: Maybe<StinterRTWState>;
};

export type StinterRightToWorkStatusPagination = {
  __typename?: 'StinterRightToWorkStatusPagination';
  items: Array<StinterRTWStinterStatus>;
  next?: Maybe<Scalars['String']>;
};

export type Stintopedia = StintopediaInterface & {
  __typename?: 'Stintopedia';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  modifiedAt: Scalars['AWSDateTime'];
  deletedAt?: Maybe<Scalars['AWSDateTime']>;
  ref: Scalars['ID'];
  business?: Maybe<Business>;
  website?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  linkedin?: Maybe<Scalars['String']>;
  aboutUs?: Maybe<Scalars['String']>;
  dos: Array<Maybe<Scalars['String']>>;
  donts: Array<Maybe<Scalars['String']>>;
  internalNotes?: Maybe<Scalars['String']>;
  bookedOnboardingMeeting?: Maybe<Scalars['Boolean']>;
  usingStintCorrectly?: Maybe<Scalars['Boolean']>;
  emoji?: Maybe<Scalars['String']>;
  characteristics: Array<Characteristic>;
  employmentExceptions: Array<EmploymentException>;
  teamStructure?: Maybe<Scalars['String']>;
  qrCodeLocation?: Maybe<Scalars['String']>;
  photoOfEntrance?: Maybe<Scalars['String']>;
  generalPhoto1?: Maybe<Scalars['String']>;
  generalPhoto2?: Maybe<Scalars['String']>;
  generalPhoto3?: Maybe<Scalars['String']>;
  generalPhoto4?: Maybe<Scalars['String']>;
  lastEditBy?: Maybe<StintUser>;
  lastEditTime?: Maybe<Scalars['AWSDateTime']>;
  agreedTakePartInMarketingInitiatives: Scalars['Boolean'];
  agreedToReferFriendsIfFinancialMetricsImproved: Scalars['Boolean'];
  agreedToOperateStaffingRota: Scalars['Boolean'];
  accessToTransactionSalesData: Scalars['Boolean'];
  accessToRotaExamples: Scalars['Boolean'];
  accessToAnnualPlAccounts: Scalars['Boolean'];
  agreedToContinuePostingIfWeSeeDesireResults: Scalars['Boolean'];
  onboardingComplete: Scalars['Boolean'];
  stintopediaPreferredLanguages?: Maybe<Scalars['String']>;
  preferredLanguages: Array<Language>;
  prepVideoUrl?: Maybe<Scalars['String']>;
  prepVideoId?: Maybe<Scalars['String']>;
  businessLogo?: Maybe<Scalars['String']>;
  characteristicIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  exceptionIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  preferredLanguageIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  last_edit_by_id?: Maybe<Scalars['ID']>;
};

export type StintopediaFilter = {
  website?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  idIcontains?: Maybe<Scalars['String']>;
  business_id?: Maybe<Scalars['ID']>;
  business_id_isnull?: Maybe<Scalars['Boolean']>;
  lastEditBy_id?: Maybe<Scalars['ID']>;
  lastEditBy_id_isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_isnull?: Maybe<Scalars['Boolean']>;
  createdAt_gt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_gte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  createdAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_isnull?: Maybe<Scalars['Boolean']>;
  modifiedAt_gt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_gte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  website_lt?: Maybe<Scalars['String']>;
  website_search?: Maybe<Scalars['String']>;
  website_lte?: Maybe<Scalars['String']>;
  website_isnull?: Maybe<Scalars['Boolean']>;
  website_gt?: Maybe<Scalars['String']>;
  website_gte?: Maybe<Scalars['String']>;
  website_iendswith?: Maybe<Scalars['String']>;
  website_istartswith?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  instagram_lt?: Maybe<Scalars['String']>;
  instagram_search?: Maybe<Scalars['String']>;
  instagram_lte?: Maybe<Scalars['String']>;
  instagram_isnull?: Maybe<Scalars['Boolean']>;
  instagram_gt?: Maybe<Scalars['String']>;
  instagram_gte?: Maybe<Scalars['String']>;
  instagram_iendswith?: Maybe<Scalars['String']>;
  instagram_istartswith?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  facebook_lt?: Maybe<Scalars['String']>;
  facebook_search?: Maybe<Scalars['String']>;
  facebook_lte?: Maybe<Scalars['String']>;
  facebook_isnull?: Maybe<Scalars['Boolean']>;
  facebook_gt?: Maybe<Scalars['String']>;
  facebook_gte?: Maybe<Scalars['String']>;
  facebook_iendswith?: Maybe<Scalars['String']>;
  facebook_istartswith?: Maybe<Scalars['String']>;
  linkedin?: Maybe<Scalars['String']>;
  linkedin_lt?: Maybe<Scalars['String']>;
  linkedin_search?: Maybe<Scalars['String']>;
  linkedin_lte?: Maybe<Scalars['String']>;
  linkedin_isnull?: Maybe<Scalars['Boolean']>;
  linkedin_gt?: Maybe<Scalars['String']>;
  linkedin_gte?: Maybe<Scalars['String']>;
  linkedin_iendswith?: Maybe<Scalars['String']>;
  linkedin_istartswith?: Maybe<Scalars['String']>;
  aboutUs?: Maybe<Scalars['String']>;
  aboutUs_lt?: Maybe<Scalars['String']>;
  aboutUs_search?: Maybe<Scalars['String']>;
  aboutUs_lte?: Maybe<Scalars['String']>;
  aboutUs_isnull?: Maybe<Scalars['Boolean']>;
  aboutUs_gt?: Maybe<Scalars['String']>;
  aboutUs_gte?: Maybe<Scalars['String']>;
  aboutUs_iendswith?: Maybe<Scalars['String']>;
  aboutUs_istartswith?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  internalNotes_lt?: Maybe<Scalars['String']>;
  internalNotes_search?: Maybe<Scalars['String']>;
  internalNotes_lte?: Maybe<Scalars['String']>;
  internalNotes_isnull?: Maybe<Scalars['Boolean']>;
  internalNotes_gt?: Maybe<Scalars['String']>;
  internalNotes_gte?: Maybe<Scalars['String']>;
  internalNotes_iendswith?: Maybe<Scalars['String']>;
  internalNotes_istartswith?: Maybe<Scalars['String']>;
  bookedOnboardingMeeting?: Maybe<Scalars['Boolean']>;
  bookedOnboardingMeeting_lt?: Maybe<Scalars['Boolean']>;
  bookedOnboardingMeeting_lte?: Maybe<Scalars['Boolean']>;
  bookedOnboardingMeeting_isnull?: Maybe<Scalars['Boolean']>;
  bookedOnboardingMeeting_gt?: Maybe<Scalars['Boolean']>;
  bookedOnboardingMeeting_gte?: Maybe<Scalars['Boolean']>;
  bookedOnboardingMeeting_iendswith?: Maybe<Scalars['Boolean']>;
  bookedOnboardingMeeting_istartswith?: Maybe<Scalars['Boolean']>;
  usingStintCorrectly?: Maybe<Scalars['Boolean']>;
  usingStintCorrectly_lt?: Maybe<Scalars['Boolean']>;
  usingStintCorrectly_lte?: Maybe<Scalars['Boolean']>;
  usingStintCorrectly_isnull?: Maybe<Scalars['Boolean']>;
  usingStintCorrectly_gt?: Maybe<Scalars['Boolean']>;
  usingStintCorrectly_gte?: Maybe<Scalars['Boolean']>;
  usingStintCorrectly_iendswith?: Maybe<Scalars['Boolean']>;
  usingStintCorrectly_istartswith?: Maybe<Scalars['Boolean']>;
  emoji?: Maybe<Scalars['String']>;
  emoji_lt?: Maybe<Scalars['String']>;
  emoji_search?: Maybe<Scalars['String']>;
  emoji_lte?: Maybe<Scalars['String']>;
  emoji_isnull?: Maybe<Scalars['Boolean']>;
  emoji_gt?: Maybe<Scalars['String']>;
  emoji_gte?: Maybe<Scalars['String']>;
  emoji_iendswith?: Maybe<Scalars['String']>;
  emoji_istartswith?: Maybe<Scalars['String']>;
  teamStructure?: Maybe<Scalars['String']>;
  teamStructure_lt?: Maybe<Scalars['String']>;
  teamStructure_search?: Maybe<Scalars['String']>;
  teamStructure_lte?: Maybe<Scalars['String']>;
  teamStructure_isnull?: Maybe<Scalars['Boolean']>;
  teamStructure_gt?: Maybe<Scalars['String']>;
  teamStructure_gte?: Maybe<Scalars['String']>;
  teamStructure_iendswith?: Maybe<Scalars['String']>;
  teamStructure_istartswith?: Maybe<Scalars['String']>;
  qrCodeLocation?: Maybe<Scalars['String']>;
  qrCodeLocation_lt?: Maybe<Scalars['String']>;
  qrCodeLocation_search?: Maybe<Scalars['String']>;
  qrCodeLocation_lte?: Maybe<Scalars['String']>;
  qrCodeLocation_isnull?: Maybe<Scalars['Boolean']>;
  qrCodeLocation_gt?: Maybe<Scalars['String']>;
  qrCodeLocation_gte?: Maybe<Scalars['String']>;
  qrCodeLocation_iendswith?: Maybe<Scalars['String']>;
  qrCodeLocation_istartswith?: Maybe<Scalars['String']>;
  lastEditTime?: Maybe<Scalars['AWSDateTime']>;
  lastEditTime_lt?: Maybe<Scalars['AWSDateTime']>;
  lastEditTime_lte?: Maybe<Scalars['AWSDateTime']>;
  lastEditTime_isnull?: Maybe<Scalars['Boolean']>;
  lastEditTime_gt?: Maybe<Scalars['AWSDateTime']>;
  lastEditTime_gte?: Maybe<Scalars['AWSDateTime']>;
  lastEditTime_iendswith?: Maybe<Scalars['AWSDateTime']>;
  lastEditTime_istartswith?: Maybe<Scalars['AWSDateTime']>;
  agreedTakePartInMarketingInitiatives?: Maybe<Scalars['Boolean']>;
  agreedTakePartInMarketingInitiatives_lt?: Maybe<Scalars['Boolean']>;
  agreedTakePartInMarketingInitiatives_lte?: Maybe<Scalars['Boolean']>;
  agreedTakePartInMarketingInitiatives_isnull?: Maybe<Scalars['Boolean']>;
  agreedTakePartInMarketingInitiatives_gt?: Maybe<Scalars['Boolean']>;
  agreedTakePartInMarketingInitiatives_gte?: Maybe<Scalars['Boolean']>;
  agreedTakePartInMarketingInitiatives_iendswith?: Maybe<Scalars['Boolean']>;
  agreedTakePartInMarketingInitiatives_istartswith?: Maybe<Scalars['Boolean']>;
  agreedToReferFriendsIfFinancialMetricsImproved?: Maybe<Scalars['Boolean']>;
  agreedToReferFriendsIfFinancialMetricsImproved_lt?: Maybe<Scalars['Boolean']>;
  agreedToReferFriendsIfFinancialMetricsImproved_lte?: Maybe<
    Scalars['Boolean']
  >;
  agreedToReferFriendsIfFinancialMetricsImproved_isnull?: Maybe<
    Scalars['Boolean']
  >;
  agreedToReferFriendsIfFinancialMetricsImproved_gt?: Maybe<Scalars['Boolean']>;
  agreedToReferFriendsIfFinancialMetricsImproved_gte?: Maybe<
    Scalars['Boolean']
  >;
  agreedToReferFriendsIfFinancialMetricsImproved_iendswith?: Maybe<
    Scalars['Boolean']
  >;
  agreedToReferFriendsIfFinancialMetricsImproved_istartswith?: Maybe<
    Scalars['Boolean']
  >;
  agreedToOperateStaffingRota?: Maybe<Scalars['Boolean']>;
  agreedToOperateStaffingRota_lt?: Maybe<Scalars['Boolean']>;
  agreedToOperateStaffingRota_lte?: Maybe<Scalars['Boolean']>;
  agreedToOperateStaffingRota_isnull?: Maybe<Scalars['Boolean']>;
  agreedToOperateStaffingRota_gt?: Maybe<Scalars['Boolean']>;
  agreedToOperateStaffingRota_gte?: Maybe<Scalars['Boolean']>;
  agreedToOperateStaffingRota_iendswith?: Maybe<Scalars['Boolean']>;
  agreedToOperateStaffingRota_istartswith?: Maybe<Scalars['Boolean']>;
  accessToTransactionSalesData?: Maybe<Scalars['Boolean']>;
  accessToTransactionSalesData_lt?: Maybe<Scalars['Boolean']>;
  accessToTransactionSalesData_lte?: Maybe<Scalars['Boolean']>;
  accessToTransactionSalesData_isnull?: Maybe<Scalars['Boolean']>;
  accessToTransactionSalesData_gt?: Maybe<Scalars['Boolean']>;
  accessToTransactionSalesData_gte?: Maybe<Scalars['Boolean']>;
  accessToTransactionSalesData_iendswith?: Maybe<Scalars['Boolean']>;
  accessToTransactionSalesData_istartswith?: Maybe<Scalars['Boolean']>;
  accessToRotaExamples?: Maybe<Scalars['Boolean']>;
  accessToRotaExamples_lt?: Maybe<Scalars['Boolean']>;
  accessToRotaExamples_lte?: Maybe<Scalars['Boolean']>;
  accessToRotaExamples_isnull?: Maybe<Scalars['Boolean']>;
  accessToRotaExamples_gt?: Maybe<Scalars['Boolean']>;
  accessToRotaExamples_gte?: Maybe<Scalars['Boolean']>;
  accessToRotaExamples_iendswith?: Maybe<Scalars['Boolean']>;
  accessToRotaExamples_istartswith?: Maybe<Scalars['Boolean']>;
  accessToAnnualPlAccounts?: Maybe<Scalars['Boolean']>;
  accessToAnnualPlAccounts_lt?: Maybe<Scalars['Boolean']>;
  accessToAnnualPlAccounts_lte?: Maybe<Scalars['Boolean']>;
  accessToAnnualPlAccounts_isnull?: Maybe<Scalars['Boolean']>;
  accessToAnnualPlAccounts_gt?: Maybe<Scalars['Boolean']>;
  accessToAnnualPlAccounts_gte?: Maybe<Scalars['Boolean']>;
  accessToAnnualPlAccounts_iendswith?: Maybe<Scalars['Boolean']>;
  accessToAnnualPlAccounts_istartswith?: Maybe<Scalars['Boolean']>;
  agreedToContinuePostingIfWeSeeDesireResults?: Maybe<Scalars['Boolean']>;
  agreedToContinuePostingIfWeSeeDesireResults_lt?: Maybe<Scalars['Boolean']>;
  agreedToContinuePostingIfWeSeeDesireResults_lte?: Maybe<Scalars['Boolean']>;
  agreedToContinuePostingIfWeSeeDesireResults_isnull?: Maybe<
    Scalars['Boolean']
  >;
  agreedToContinuePostingIfWeSeeDesireResults_gt?: Maybe<Scalars['Boolean']>;
  agreedToContinuePostingIfWeSeeDesireResults_gte?: Maybe<Scalars['Boolean']>;
  agreedToContinuePostingIfWeSeeDesireResults_iendswith?: Maybe<
    Scalars['Boolean']
  >;
  agreedToContinuePostingIfWeSeeDesireResults_istartswith?: Maybe<
    Scalars['Boolean']
  >;
  onboardingComplete?: Maybe<Scalars['Boolean']>;
  onboardingComplete_lt?: Maybe<Scalars['Boolean']>;
  onboardingComplete_lte?: Maybe<Scalars['Boolean']>;
  onboardingComplete_isnull?: Maybe<Scalars['Boolean']>;
  onboardingComplete_gt?: Maybe<Scalars['Boolean']>;
  onboardingComplete_gte?: Maybe<Scalars['Boolean']>;
  onboardingComplete_iendswith?: Maybe<Scalars['Boolean']>;
  onboardingComplete_istartswith?: Maybe<Scalars['Boolean']>;
  stintopediaPreferredLanguages?: Maybe<Scalars['String']>;
  stintopediaPreferredLanguages_lt?: Maybe<Scalars['String']>;
  stintopediaPreferredLanguages_search?: Maybe<Scalars['String']>;
  stintopediaPreferredLanguages_lte?: Maybe<Scalars['String']>;
  stintopediaPreferredLanguages_isnull?: Maybe<Scalars['Boolean']>;
  stintopediaPreferredLanguages_gt?: Maybe<Scalars['String']>;
  stintopediaPreferredLanguages_gte?: Maybe<Scalars['String']>;
  stintopediaPreferredLanguages_iendswith?: Maybe<Scalars['String']>;
  stintopediaPreferredLanguages_istartswith?: Maybe<Scalars['String']>;
  prepVideoUrl?: Maybe<Scalars['String']>;
  prepVideoUrl_lt?: Maybe<Scalars['String']>;
  prepVideoUrl_search?: Maybe<Scalars['String']>;
  prepVideoUrl_lte?: Maybe<Scalars['String']>;
  prepVideoUrl_isnull?: Maybe<Scalars['Boolean']>;
  prepVideoUrl_gt?: Maybe<Scalars['String']>;
  prepVideoUrl_gte?: Maybe<Scalars['String']>;
  prepVideoUrl_iendswith?: Maybe<Scalars['String']>;
  prepVideoUrl_istartswith?: Maybe<Scalars['String']>;
  prepVideoId?: Maybe<Scalars['String']>;
  prepVideoId_lt?: Maybe<Scalars['String']>;
  prepVideoId_search?: Maybe<Scalars['String']>;
  prepVideoId_lte?: Maybe<Scalars['String']>;
  prepVideoId_isnull?: Maybe<Scalars['Boolean']>;
  prepVideoId_gt?: Maybe<Scalars['String']>;
  prepVideoId_gte?: Maybe<Scalars['String']>;
  prepVideoId_iendswith?: Maybe<Scalars['String']>;
  prepVideoId_istartswith?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  characteristics_ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  employmentExceptions_ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  studentExceptions_ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  preferredLanguages_ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  modifiedAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  website_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  instagram_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  facebook_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  linkedin_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  aboutUs_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  internalNotes_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  bookedOnboardingMeeting_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  usingStintCorrectly_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  emoji_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  teamStructure_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  qrCodeLocation_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastEditTime_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  agreedTakePartInMarketingInitiatives_in?: Maybe<
    Array<Maybe<Scalars['Boolean']>>
  >;
  agreedToReferFriendsIfFinancialMetricsImproved_in?: Maybe<
    Array<Maybe<Scalars['Boolean']>>
  >;
  agreedToOperateStaffingRota_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  accessToTransactionSalesData_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  accessToRotaExamples_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  accessToAnnualPlAccounts_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  agreedToContinuePostingIfWeSeeDesireResults_in?: Maybe<
    Array<Maybe<Scalars['Boolean']>>
  >;
  onboardingComplete_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  stintopediaPreferredLanguages_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  prepVideoUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  prepVideoId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type StintopediaInput = {
  bookedOnboardingMeeting?: Maybe<Scalars['Boolean']>;
  usingStintCorrectly?: Maybe<Scalars['Boolean']>;
};

export type StintopediaInterface = {
  businessLogo?: Maybe<Scalars['String']>;
  characteristicIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  exceptionIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  generalPhoto1?: Maybe<Scalars['String']>;
  generalPhoto2?: Maybe<Scalars['String']>;
  generalPhoto3?: Maybe<Scalars['String']>;
  generalPhoto4?: Maybe<Scalars['String']>;
  photoOfEntrance?: Maybe<Scalars['String']>;
  preferredLanguageIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type StripeCard = {
  __typename?: 'StripeCard';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  deletedAt?: Maybe<Scalars['AWSDateTime']>;
  ref: Scalars['ID'];
  isPrimaryMethod: Scalars['Boolean'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  modifiedAt: Scalars['AWSDateTime'];
  nameOnCard?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  lastFourDigits?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  business?: Maybe<Business>;
  business_id?: Maybe<Scalars['ID']>;
};

export type StripeCardFilter = {
  businessId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  idIcontains?: Maybe<Scalars['String']>;
  stripebasePtr_id?: Maybe<Scalars['ID']>;
  stripebasePtr_id_isnull?: Maybe<Scalars['Boolean']>;
  business_id?: Maybe<Scalars['ID']>;
  business_id_isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_isnull?: Maybe<Scalars['Boolean']>;
  createdAt_gt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_gte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  createdAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  isPrimaryMethod?: Maybe<Scalars['Boolean']>;
  isPrimaryMethod_lt?: Maybe<Scalars['Boolean']>;
  isPrimaryMethod_lte?: Maybe<Scalars['Boolean']>;
  isPrimaryMethod_isnull?: Maybe<Scalars['Boolean']>;
  isPrimaryMethod_gt?: Maybe<Scalars['Boolean']>;
  isPrimaryMethod_gte?: Maybe<Scalars['Boolean']>;
  isPrimaryMethod_iendswith?: Maybe<Scalars['Boolean']>;
  isPrimaryMethod_istartswith?: Maybe<Scalars['Boolean']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePaymentMethodId_lt?: Maybe<Scalars['String']>;
  stripePaymentMethodId_search?: Maybe<Scalars['String']>;
  stripePaymentMethodId_lte?: Maybe<Scalars['String']>;
  stripePaymentMethodId_isnull?: Maybe<Scalars['Boolean']>;
  stripePaymentMethodId_gt?: Maybe<Scalars['String']>;
  stripePaymentMethodId_gte?: Maybe<Scalars['String']>;
  stripePaymentMethodId_iendswith?: Maybe<Scalars['String']>;
  stripePaymentMethodId_istartswith?: Maybe<Scalars['String']>;
  modifiedAt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_isnull?: Maybe<Scalars['Boolean']>;
  modifiedAt_gt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_gte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  nameOnCard?: Maybe<Scalars['String']>;
  nameOnCard_lt?: Maybe<Scalars['String']>;
  nameOnCard_search?: Maybe<Scalars['String']>;
  nameOnCard_lte?: Maybe<Scalars['String']>;
  nameOnCard_isnull?: Maybe<Scalars['Boolean']>;
  nameOnCard_gt?: Maybe<Scalars['String']>;
  nameOnCard_gte?: Maybe<Scalars['String']>;
  nameOnCard_iendswith?: Maybe<Scalars['String']>;
  nameOnCard_istartswith?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_lt?: Maybe<Scalars['String']>;
  description_search?: Maybe<Scalars['String']>;
  description_lte?: Maybe<Scalars['String']>;
  description_isnull?: Maybe<Scalars['Boolean']>;
  description_gt?: Maybe<Scalars['String']>;
  description_gte?: Maybe<Scalars['String']>;
  description_iendswith?: Maybe<Scalars['String']>;
  description_istartswith?: Maybe<Scalars['String']>;
  lastFourDigits?: Maybe<Scalars['String']>;
  lastFourDigits_lt?: Maybe<Scalars['String']>;
  lastFourDigits_search?: Maybe<Scalars['String']>;
  lastFourDigits_lte?: Maybe<Scalars['String']>;
  lastFourDigits_isnull?: Maybe<Scalars['Boolean']>;
  lastFourDigits_gt?: Maybe<Scalars['String']>;
  lastFourDigits_gte?: Maybe<Scalars['String']>;
  lastFourDigits_iendswith?: Maybe<Scalars['String']>;
  lastFourDigits_istartswith?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  brand_lt?: Maybe<Scalars['String']>;
  brand_search?: Maybe<Scalars['String']>;
  brand_lte?: Maybe<Scalars['String']>;
  brand_isnull?: Maybe<Scalars['Boolean']>;
  brand_gt?: Maybe<Scalars['String']>;
  brand_gte?: Maybe<Scalars['String']>;
  brand_iendswith?: Maybe<Scalars['String']>;
  brand_istartswith?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  isPrimaryMethod_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  stripePaymentMethodId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  modifiedAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  nameOnCard_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastFourDigits_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  brand_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type StripeDebit = {
  __typename?: 'StripeDebit';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  deletedAt?: Maybe<Scalars['AWSDateTime']>;
  ref: Scalars['ID'];
  isPrimaryMethod: Scalars['Boolean'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  modifiedAt: Scalars['AWSDateTime'];
  accountNumber?: Maybe<Scalars['String']>;
  sortCode?: Maybe<Scalars['String']>;
  business?: Maybe<Business>;
  business_id?: Maybe<Scalars['ID']>;
};

export type StripeDebitFilter = {
  businessId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  idIcontains?: Maybe<Scalars['String']>;
  stripebasePtr_id?: Maybe<Scalars['ID']>;
  stripebasePtr_id_isnull?: Maybe<Scalars['Boolean']>;
  business_id?: Maybe<Scalars['ID']>;
  business_id_isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_isnull?: Maybe<Scalars['Boolean']>;
  createdAt_gt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_gte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  createdAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  isPrimaryMethod?: Maybe<Scalars['Boolean']>;
  isPrimaryMethod_lt?: Maybe<Scalars['Boolean']>;
  isPrimaryMethod_lte?: Maybe<Scalars['Boolean']>;
  isPrimaryMethod_isnull?: Maybe<Scalars['Boolean']>;
  isPrimaryMethod_gt?: Maybe<Scalars['Boolean']>;
  isPrimaryMethod_gte?: Maybe<Scalars['Boolean']>;
  isPrimaryMethod_iendswith?: Maybe<Scalars['Boolean']>;
  isPrimaryMethod_istartswith?: Maybe<Scalars['Boolean']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePaymentMethodId_lt?: Maybe<Scalars['String']>;
  stripePaymentMethodId_search?: Maybe<Scalars['String']>;
  stripePaymentMethodId_lte?: Maybe<Scalars['String']>;
  stripePaymentMethodId_isnull?: Maybe<Scalars['Boolean']>;
  stripePaymentMethodId_gt?: Maybe<Scalars['String']>;
  stripePaymentMethodId_gte?: Maybe<Scalars['String']>;
  stripePaymentMethodId_iendswith?: Maybe<Scalars['String']>;
  stripePaymentMethodId_istartswith?: Maybe<Scalars['String']>;
  modifiedAt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_isnull?: Maybe<Scalars['Boolean']>;
  modifiedAt_gt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_gte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  accountNumber?: Maybe<Scalars['String']>;
  accountNumber_lt?: Maybe<Scalars['String']>;
  accountNumber_search?: Maybe<Scalars['String']>;
  accountNumber_lte?: Maybe<Scalars['String']>;
  accountNumber_isnull?: Maybe<Scalars['Boolean']>;
  accountNumber_gt?: Maybe<Scalars['String']>;
  accountNumber_gte?: Maybe<Scalars['String']>;
  accountNumber_iendswith?: Maybe<Scalars['String']>;
  accountNumber_istartswith?: Maybe<Scalars['String']>;
  sortCode?: Maybe<Scalars['String']>;
  sortCode_lt?: Maybe<Scalars['String']>;
  sortCode_search?: Maybe<Scalars['String']>;
  sortCode_lte?: Maybe<Scalars['String']>;
  sortCode_isnull?: Maybe<Scalars['Boolean']>;
  sortCode_gt?: Maybe<Scalars['String']>;
  sortCode_gte?: Maybe<Scalars['String']>;
  sortCode_iendswith?: Maybe<Scalars['String']>;
  sortCode_istartswith?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  isPrimaryMethod_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  stripePaymentMethodId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  modifiedAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  accountNumber_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  sortCode_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum StudentAvailabilityInputType {
  CREATED_WITH_BEST_TIMES_CONSOLE = 'CREATED_WITH_BEST_TIMES_CONSOLE',
  CREATED_WITH_MY_AVAILABILITY = 'CREATED_WITH_MY_AVAILABILITY',
}

export enum StudentLoanPlan {
  NO_PLAN = 'NO_PLAN',
  PLAN_ONE = 'PLAN_ONE',
  PLAN_TWO = 'PLAN_TWO',
}

export type StudentObject = {
  __typename?: 'StudentObject';
  id: Scalars['ID'];
};

export type StudentProfile = {
  __typename?: 'StudentProfile';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  gender?: Maybe<StudentProfileGender>;
  stintCount?: Maybe<Scalars['Int']>;
  nationalInsuranceNumber: Scalars['String'];
  addresses: Array<Address>;
  employeeStatement?: Maybe<EmployeeStatement>;
  studentLoanPlan: StudentProfileStudentLoanPlan;
  employmentExceptions: Array<EmploymentException>;
  emergencyContactName?: Maybe<Scalars['String']>;
  emergencyContactNumber?: Maybe<Scalars['String']>;
  emergencyContactRelation?: Maybe<Scalars['String']>;
  marketingConsentAccepted?: Maybe<Scalars['Boolean']>;
  modifiedAt: Scalars['AWSDateTime'];
  student: StintUser;
  stintCancelledCount?: Maybe<Scalars['Int']>;
  latestStintAt?: Maybe<Scalars['AWSDateTime']>;
  university?: Maybe<University>;
  degree?: Maybe<Scalars['String']>;
  universityStartYear?: Maybe<Scalars['Int']>;
  universityEndYear?: Maybe<Scalars['Int']>;
  homeTown?: Maybe<City>;
  homeTownVisits?: Maybe<StudentProfileHomeTownVisits>;
  reference?: Maybe<Scalars['String']>;
  passportNumber: Scalars['String'];
  paymentMethods?: Maybe<Array<Maybe<DirectDebitPaymentMethod>>>;
  hasAllergies: Scalars['Boolean'];
  medicalNotes?: Maybe<Scalars['String']>;
  allergyNotes?: Maybe<Scalars['String']>;
  workExperience?: Maybe<Scalars['String']>;
  onDemandUntil?: Maybe<Scalars['AWSDateTime']>;
  hygienic: Scalars['Boolean'];
  articulate?: Maybe<Scalars['Int']>;
  polite?: Maybe<Scalars['Int']>;
  outgoing?: Maybe<Scalars['Int']>;
  energetic: Scalars['Boolean'];
  canGetHandsDirty: Scalars['Boolean'];
  looseAvailability: Scalars['Boolean'];
  preferredBusinessAvailability: Scalars['Boolean'];
  nearbyAvailability: Scalars['Boolean'];
  nearbyAvailabilityPostcode?: Maybe<Scalars['String']>;
  requestedBusinessAvailability: Scalars['Boolean'];
  optInGuaranteedStints: Scalars['Boolean'];
  preferredStintLength?: Maybe<Scalars['String']>;
  preferredStintFrequency?: Maybe<Scalars['String']>;
  muteStintsUntil?: Maybe<Scalars['AWSDateTime']>;
  rtwUploaded?: Maybe<Scalars['Boolean']>;
  rtwUploadedAt?: Maybe<Scalars['AWSDateTime']>;
  marketingConsentCollectedAt?: Maybe<Scalars['AWSDateTime']>;
  primaryPaymentMethod?: Maybe<DirectDebitPaymentMethod>;
  hasCompletedStudentProfile?: Maybe<Scalars['Boolean']>;
  businessDiscountBadges?: Maybe<Array<Maybe<BusinessDiscountBadge>>>;
  strikesAwarded?: Maybe<Scalars['Int']>;
  university_id?: Maybe<Scalars['ID']>;
  home_town_id?: Maybe<Scalars['ID']>;
};

export enum StudentProfileGender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  PREFER_NOT_SAY = 'PREFER_NOT_SAY',
  OTHER = 'OTHER',
}

export enum StudentProfileHomeTownVisits {
  WEEKENDS = 'WEEKENDS',
  OUTSIDE_TERM_TIME = 'OUTSIDE_TERM_TIME',
  SUMMER = 'SUMMER',
  OCCASIONALLY = 'OCCASIONALLY',
  MOST_OF_THE_TIME = 'MOST_OF_THE_TIME',
}

export type StudentProfileInput = {
  emergencyContactName?: Maybe<Scalars['String']>;
  emergencyContactNumber?: Maybe<Scalars['String']>;
  emergencyContactRelation?: Maybe<Scalars['String']>;
  employeeStatement?: Maybe<EmployeeStatement>;
  studentLoanPlan?: Maybe<StudentLoanPlan>;
  universityId?: Maybe<Scalars['ID']>;
};

export enum StudentProfileStudentLoanPlan {
  NO_PLAN = 'NO_PLAN',
  PLAN_ONE = 'PLAN_ONE',
  PLAN_TWO = 'PLAN_TWO',
}

export type StudentReview = {
  __typename?: 'StudentReview';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  modifiedAt: Scalars['AWSDateTime'];
  deletedAt?: Maybe<Scalars['AWSDateTime']>;
  ref: Scalars['ID'];
  reviewType?: Maybe<ReviewReviewType>;
  reviewDate?: Maybe<Scalars['AWSDateTime']>;
  reviewer?: Maybe<StintUser>;
  rating?: Maybe<Scalars['Float']>;
  feedback?: Maybe<Scalars['String']>;
  hidden: Scalars['Boolean'];
  student?: Maybe<StintUser>;
  stint?: Maybe<Stint>;
  tags: Array<Tag>;
  studentreview: Array<StintUser>;
  isStintGuarantee?: Maybe<Scalars['Boolean']>;
  isInternalReview?: Maybe<Scalars['Boolean']>;
  reviewer_id?: Maybe<Scalars['ID']>;
  student_id?: Maybe<Scalars['ID']>;
  stint_id?: Maybe<Scalars['ID']>;
};

export type StudentReviewFilter = {
  rating?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  idIcontains?: Maybe<Scalars['String']>;
  business_id?: Maybe<Scalars['ID']>;
  only_internal_reviews?: Maybe<Scalars['Boolean']>;
  start_from?: Maybe<Scalars['AWSDate']>;
  stint_status?: Maybe<Scalars['Float']>;
  reviewer_id?: Maybe<Scalars['ID']>;
  reviewer_id_isnull?: Maybe<Scalars['Boolean']>;
  reviewPtr_id?: Maybe<Scalars['ID']>;
  reviewPtr_id_isnull?: Maybe<Scalars['Boolean']>;
  student_id?: Maybe<Scalars['ID']>;
  student_id_isnull?: Maybe<Scalars['Boolean']>;
  stint_id?: Maybe<Scalars['ID']>;
  stint_id_isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_isnull?: Maybe<Scalars['Boolean']>;
  createdAt_gt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_gte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  createdAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_isnull?: Maybe<Scalars['Boolean']>;
  modifiedAt_gt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_gte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  reviewType?: Maybe<Scalars['String']>;
  reviewType_lt?: Maybe<Scalars['String']>;
  reviewType_search?: Maybe<Scalars['String']>;
  reviewType_lte?: Maybe<Scalars['String']>;
  reviewType_isnull?: Maybe<Scalars['Boolean']>;
  reviewType_gt?: Maybe<Scalars['String']>;
  reviewType_gte?: Maybe<Scalars['String']>;
  reviewType_iendswith?: Maybe<Scalars['String']>;
  reviewType_istartswith?: Maybe<Scalars['String']>;
  reviewDate?: Maybe<Scalars['AWSDateTime']>;
  reviewDate_lt?: Maybe<Scalars['AWSDateTime']>;
  reviewDate_lte?: Maybe<Scalars['AWSDateTime']>;
  reviewDate_isnull?: Maybe<Scalars['Boolean']>;
  reviewDate_gt?: Maybe<Scalars['AWSDateTime']>;
  reviewDate_gte?: Maybe<Scalars['AWSDateTime']>;
  reviewDate_iendswith?: Maybe<Scalars['AWSDateTime']>;
  reviewDate_istartswith?: Maybe<Scalars['AWSDateTime']>;
  rating_lt?: Maybe<Scalars['Float']>;
  rating_lte?: Maybe<Scalars['Float']>;
  rating_isnull?: Maybe<Scalars['Boolean']>;
  rating_gt?: Maybe<Scalars['Float']>;
  rating_gte?: Maybe<Scalars['Float']>;
  rating_iendswith?: Maybe<Scalars['Float']>;
  rating_istartswith?: Maybe<Scalars['Float']>;
  feedback?: Maybe<Scalars['String']>;
  feedback_lt?: Maybe<Scalars['String']>;
  feedback_search?: Maybe<Scalars['String']>;
  feedback_lte?: Maybe<Scalars['String']>;
  feedback_isnull?: Maybe<Scalars['Boolean']>;
  feedback_gt?: Maybe<Scalars['String']>;
  feedback_gte?: Maybe<Scalars['String']>;
  feedback_iendswith?: Maybe<Scalars['String']>;
  feedback_istartswith?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  hidden_lt?: Maybe<Scalars['Boolean']>;
  hidden_lte?: Maybe<Scalars['Boolean']>;
  hidden_isnull?: Maybe<Scalars['Boolean']>;
  hidden_gt?: Maybe<Scalars['Boolean']>;
  hidden_gte?: Maybe<Scalars['Boolean']>;
  hidden_iendswith?: Maybe<Scalars['Boolean']>;
  hidden_istartswith?: Maybe<Scalars['Boolean']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  business_id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  tags_ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  endorsements_ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  modifiedAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  reviewType_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  reviewDate_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  rating_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  feedback_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  hidden_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
};

export type StudentSavedPlace = {
  __typename?: 'StudentSavedPlace';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  modifiedAt: Scalars['AWSDateTime'];
  deletedAt?: Maybe<Scalars['AWSDateTime']>;
  ref: Scalars['ID'];
  student: StintUser;
  name: Scalars['String'];
  address: Address;
  city?: Maybe<City>;
  sectionName?: Maybe<Scalars['String']>;
  student_id?: Maybe<Scalars['ID']>;
  address_id?: Maybe<Scalars['ID']>;
};

export type StudentSavedPlaceListType = {
  __typename?: 'StudentSavedPlaceListType';
  pageInfo: PageInfoType;
  objects?: Maybe<Array<Maybe<StudentSavedPlace>>>;
};

export type StudentUnassignedEvent = {
  __typename?: 'StudentUnassignedEvent';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  modifiedAt: Scalars['AWSDateTime'];
  deletedAt?: Maybe<Scalars['AWSDateTime']>;
  ref: Scalars['ID'];
  createdBy?: Maybe<StintUser>;
  stint: StintNode;
  student?: Maybe<StintUser>;
  unassignedReasons: Array<Maybe<Scalars['String']>>;
  studentFault: Scalars['Boolean'];
  created_by_id?: Maybe<Scalars['ID']>;
  stint_id?: Maybe<Scalars['ID']>;
  student_id?: Maybe<Scalars['ID']>;
};

export type StudentUnassignedEventListType = {
  __typename?: 'StudentUnassignedEventListType';
  pageInfo: PageInfoType;
  objects?: Maybe<Array<Maybe<StudentUnassignedEvent>>>;
};

export enum StudentUnassignedReasons {
  CRITERIA = 'CRITERIA',
  BAD_RATING = 'BAD_RATING',
  NOT_RATED = 'NOT_RATED',
  BUSINESS_UNHAPPY = 'BUSINESS_UNHAPPY',
  NEW_IMPORTANT_BUSINESS = 'NEW_IMPORTANT_BUSINESS',
  OTHER = 'OTHER',
  WORKING_CAP = 'WORKING_CAP',
  OVERLAPPING_STINTS = 'OVERLAPPING_STINTS',
  ACCEPTED_BY_MISTAKE = 'ACCEPTED_BY_MISTAKE',
  EARLIER_REPLACEMENT_FOUND = 'EARLIER_REPLACEMENT_FOUND',
  EXCEPTIONS = 'EXCEPTIONS',
  STANDBY_ASSIGNED_TO_STINT = 'STANDBY_ASSIGNED_TO_STINT',
  LOCATION = 'LOCATION',
  LEAVE = 'LEAVE',
  ARRIVE = 'ARRIVE',
  PULLED_OUT = 'PULLED_OUT',
  EXPECTED_TOO_LATE = 'EXPECTED_TOO_LATE',
  EXTENUATING = 'EXTENUATING',
  DISCIPLINARY = 'DISCIPLINARY',
  NO_CONFIRM_NIGHT_BEFORE = 'NO_CONFIRM_NIGHT_BEFORE',
  NO_CONFIRM_ON_THE_DAY = 'NO_CONFIRM_ON_THE_DAY',
  GRANULAR_NOT_ENABLED = 'GRANULAR_NOT_ENABLED',
  NO_ASAP_CONFIRMATION = 'NO_ASAP_CONFIRMATION',
  COVID_HOLD = 'COVID_HOLD',
  TEAMS_SWAP = 'TEAMS_SWAP',
  TEAMS_SICK = 'TEAMS_SICK',
  TEAMS_EXTENUATING = 'TEAMS_EXTENUATING',
  TEAMS_CHANGE_OF_SCHEDULE = 'TEAMS_CHANGE_OF_SCHEDULE',
  TEAMS_STINTER_LEFT_TEAM = 'TEAMS_STINTER_LEFT_TEAM',
  TEAMS_STINTER_REMOVED_FROM_TEAM = 'TEAMS_STINTER_REMOVED_FROM_TEAM',
  TEAMS_BRAND_REPEAT_CANCELLATION = 'TEAMS_BRAND_REPEAT_CANCELLATION',
  TEAMS_NO_COMPLIANCE = 'TEAMS_NO_COMPLIANCE',
  TEAMS_OTHER = 'TEAMS_OTHER',
}

export type SuccessType = {
  __typename?: 'SuccessType';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  modifiedAt: Scalars['AWSDateTime'];
  deletedAt?: Maybe<Scalars['AWSDateTime']>;
  ref: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['ID']>;
};

export type TagFilter = {
  name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  idIcontains?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['ID']>;
  group_id_isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_isnull?: Maybe<Scalars['Boolean']>;
  createdAt_gt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_gte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  createdAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_isnull?: Maybe<Scalars['Boolean']>;
  modifiedAt_gt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_gte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  name_lt?: Maybe<Scalars['String']>;
  name_search?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_isnull?: Maybe<Scalars['Boolean']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_iendswith?: Maybe<Scalars['String']>;
  name_istartswith?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_lt?: Maybe<Scalars['String']>;
  description_search?: Maybe<Scalars['String']>;
  description_lte?: Maybe<Scalars['String']>;
  description_isnull?: Maybe<Scalars['Boolean']>;
  description_gt?: Maybe<Scalars['String']>;
  description_gte?: Maybe<Scalars['String']>;
  description_iendswith?: Maybe<Scalars['String']>;
  description_istartswith?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  modifiedAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TaskTopTipInput = {
  name: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
};

export type TopTip = {
  __typename?: 'TopTip';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  modifiedAt: Scalars['AWSDateTime'];
  deletedAt?: Maybe<Scalars['AWSDateTime']>;
  ref: Scalars['ID'];
  task?: Maybe<StintTask>;
  name: Scalars['String'];
  isDefault: Scalars['Boolean'];
  business?: Maybe<Business>;
  task_id?: Maybe<Scalars['ID']>;
  role_id?: Maybe<Scalars['ID']>;
  business_category_id?: Maybe<Scalars['ID']>;
};

export type TopTipFilter = {
  name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  idIcontains?: Maybe<Scalars['String']>;
  role_id?: Maybe<Scalars['ID']>;
  role_id_isnull?: Maybe<Scalars['Boolean']>;
  task_id?: Maybe<Scalars['ID']>;
  task_id_isnull?: Maybe<Scalars['Boolean']>;
  businessCategory_id?: Maybe<Scalars['ID']>;
  businessCategory_id_isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_isnull?: Maybe<Scalars['Boolean']>;
  createdAt_gt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_gte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  createdAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_isnull?: Maybe<Scalars['Boolean']>;
  modifiedAt_gt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_gte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  name_lt?: Maybe<Scalars['String']>;
  name_search?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_isnull?: Maybe<Scalars['Boolean']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_iendswith?: Maybe<Scalars['String']>;
  name_istartswith?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDefault_lt?: Maybe<Scalars['Boolean']>;
  isDefault_lte?: Maybe<Scalars['Boolean']>;
  isDefault_isnull?: Maybe<Scalars['Boolean']>;
  isDefault_gt?: Maybe<Scalars['Boolean']>;
  isDefault_gte?: Maybe<Scalars['Boolean']>;
  isDefault_iendswith?: Maybe<Scalars['Boolean']>;
  isDefault_istartswith?: Maybe<Scalars['Boolean']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  modifiedAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isDefault_in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
};

export type TopTipInput = {
  name: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
};

export type TrialMemberApprovedReason = {
  __typename?: 'TrialMemberApprovedReason';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isVisible: Scalars['Boolean'];
};

export type TriggerAutoschedulerInput = {
  siteId: Scalars['ID'];
  weekCommencing: Scalars['AWSDate'];
  partnerMemberOverrides: Array<PartnerMemberOverrideInput>;
};

export type UniversitiesInput = {
  orderBy?: Maybe<UniversityOrderInput>;
  filter?: Maybe<UniversityFilterInput>;
};

export type University = Node & {
  __typename?: 'University';
  id: Scalars['ID'];
  name: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
  domain: Scalars['String'];
};

export type UniversityFilter = {
  name?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  idIcontains?: Maybe<Scalars['String']>;
  city_id?: Maybe<Scalars['ID']>;
  city_id_isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_lte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_isnull?: Maybe<Scalars['Boolean']>;
  createdAt_gt?: Maybe<Scalars['AWSDateTime']>;
  createdAt_gte?: Maybe<Scalars['AWSDateTime']>;
  createdAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  createdAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_lte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_isnull?: Maybe<Scalars['Boolean']>;
  modifiedAt_gt?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_gte?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_iendswith?: Maybe<Scalars['AWSDateTime']>;
  modifiedAt_istartswith?: Maybe<Scalars['AWSDateTime']>;
  name_lt?: Maybe<Scalars['String']>;
  name_search?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_isnull?: Maybe<Scalars['Boolean']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_iendswith?: Maybe<Scalars['String']>;
  name_istartswith?: Maybe<Scalars['String']>;
  domain_lt?: Maybe<Scalars['String']>;
  domain_search?: Maybe<Scalars['String']>;
  domain_lte?: Maybe<Scalars['String']>;
  domain_isnull?: Maybe<Scalars['Boolean']>;
  domain_gt?: Maybe<Scalars['String']>;
  domain_gte?: Maybe<Scalars['String']>;
  domain_iendswith?: Maybe<Scalars['String']>;
  domain_istartswith?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  group_lt?: Maybe<Scalars['String']>;
  group_search?: Maybe<Scalars['String']>;
  group_lte?: Maybe<Scalars['String']>;
  group_isnull?: Maybe<Scalars['Boolean']>;
  group_gt?: Maybe<Scalars['String']>;
  group_gte?: Maybe<Scalars['String']>;
  group_iendswith?: Maybe<Scalars['String']>;
  group_istartswith?: Maybe<Scalars['String']>;
  firebaseRef?: Maybe<Scalars['String']>;
  firebaseRef_lt?: Maybe<Scalars['String']>;
  firebaseRef_search?: Maybe<Scalars['String']>;
  firebaseRef_lte?: Maybe<Scalars['String']>;
  firebaseRef_isnull?: Maybe<Scalars['Boolean']>;
  firebaseRef_gt?: Maybe<Scalars['String']>;
  firebaseRef_gte?: Maybe<Scalars['String']>;
  firebaseRef_iendswith?: Maybe<Scalars['String']>;
  firebaseRef_istartswith?: Maybe<Scalars['String']>;
  imageOneX?: Maybe<Scalars['String']>;
  imageOneX_lt?: Maybe<Scalars['String']>;
  imageOneX_search?: Maybe<Scalars['String']>;
  imageOneX_lte?: Maybe<Scalars['String']>;
  imageOneX_isnull?: Maybe<Scalars['Boolean']>;
  imageOneX_gt?: Maybe<Scalars['String']>;
  imageOneX_gte?: Maybe<Scalars['String']>;
  imageOneX_iendswith?: Maybe<Scalars['String']>;
  imageOneX_istartswith?: Maybe<Scalars['String']>;
  imageTwoX?: Maybe<Scalars['String']>;
  imageTwoX_lt?: Maybe<Scalars['String']>;
  imageTwoX_search?: Maybe<Scalars['String']>;
  imageTwoX_lte?: Maybe<Scalars['String']>;
  imageTwoX_isnull?: Maybe<Scalars['Boolean']>;
  imageTwoX_gt?: Maybe<Scalars['String']>;
  imageTwoX_gte?: Maybe<Scalars['String']>;
  imageTwoX_iendswith?: Maybe<Scalars['String']>;
  imageTwoX_istartswith?: Maybe<Scalars['String']>;
  imageThreeX?: Maybe<Scalars['String']>;
  imageThreeX_lt?: Maybe<Scalars['String']>;
  imageThreeX_search?: Maybe<Scalars['String']>;
  imageThreeX_lte?: Maybe<Scalars['String']>;
  imageThreeX_isnull?: Maybe<Scalars['Boolean']>;
  imageThreeX_gt?: Maybe<Scalars['String']>;
  imageThreeX_gte?: Maybe<Scalars['String']>;
  imageThreeX_iendswith?: Maybe<Scalars['String']>;
  imageThreeX_istartswith?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  modifiedAt_in?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  domain_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  group_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  firebaseRef_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  imageOneX_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  imageTwoX_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  imageThreeX_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UniversityFilterInput = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export enum UniversityOrderField {
  NAME = 'NAME',
}

export type UniversityOrderInput = {
  field: UniversityOrderField;
  order: SortOrder;
};

export type UniversityTypeConnection = {
  __typename?: 'UniversityTypeConnection';
  pageInfo: PageInfo;
  edges: Array<UniversityTypeEdge>;
};

export type UniversityTypeEdge = {
  __typename?: 'UniversityTypeEdge';
  node: University;
  cursor: Scalars['String'];
};

export type UpdateAccountNodeInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  companiesHouseInformation?: Maybe<CompaniesHouseInformationInput>;
};

export type UpdateActualLeaveTimeInput = {
  id: Scalars['ID'];
  actualLeaveTime: Scalars['AWSDateTime'];
};

export type UpdateAgreementInput = {
  id: Scalars['ID'];
  primaryPartnerNodeId: Scalars['ID'];
  enabled: Scalars['Boolean'];
};

export type UpdateCompaniesHouseInformationInput = {
  id: Scalars['ID'];
  partnerNodeId: Scalars['ID'];
  number: Scalars['String'];
  address: Scalars['String'];
  name: Scalars['String'];
};

export type UpdateDemandForecastShiftsInput = {
  partnerNodeId: Scalars['ID'];
  forecastDate: Scalars['AWSDateTime'];
  shifts: Array<ShiftInput>;
};

export type UpdateGroupNodeInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateMyProfile = {
  __typename?: 'UpdateMyProfile';
  userProfile?: Maybe<StintUser>;
};

export type UpdateMyProfilePicture = {
  __typename?: 'UpdateMyProfilePicture';
  userProfile?: Maybe<StintUser>;
};

export type UpdatePartnerBrandInput = {
  id: Scalars['ID'];
  primaryPartnerNodeId: Scalars['ID'];
  logoBase64: Scalars['String'];
};

export type UpdatePartnerContactInput = {
  id: Scalars['ID'];
  partnerNodeId: Scalars['ID'];
  primaryPhoneNumber: Scalars['String'];
  secondaryPhoneNumbers: Array<Scalars['String']>;
  emailAddresses: Array<Scalars['String']>;
};

export type UpdatePartnerMemberInput = {
  id: Scalars['ID'];
  partnerNodeId: Scalars['ID'];
  role?: Maybe<PartnerRole>;
  employmentInfo?: Maybe<UpsertEmploymentInfoInput>;
  siteWorkingPreferences?: Maybe<WorkingPreferencesInput>;
};

export type UpdatePartnerNodeParentInput = {
  id: Scalars['ID'];
  parentNodeId: Scalars['ID'];
};

export type UpdatePartnerPoolInput = {
  id: Scalars['ID'];
  partnerNodeId: Scalars['ID'];
  formUrl: Scalars['String'];
  perks?: Maybe<Scalars['String']>;
};

export type UpdatePartnerSettingsInput = {
  partnerNodeId: Scalars['ID'];
  businessIntelligenceSettings: BusinessIntelligenceSettingsInput;
  controlCheckEnabled: Scalars['Boolean'];
  repeatStintersEnabled: Scalars['Boolean'];
  waterfallBotGroup: WaterfallBotGroup;
};

export type UpdateScheduleShiftsInput = {
  siteId: Scalars['ID'];
  weekCommencing: Scalars['AWSDate'];
  dayOfWeek: WeekDay;
  shifts: Array<ShiftInput>;
};

export type UpdateSiteNodeInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  partnerUniqueId?: Maybe<Scalars['String']>;
  partnerContact?: Maybe<PartnerContactInput>;
  address?: Maybe<AddressInput>;
};

export type UpdateStintTemplateGroupInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  stintTemplateIds: Array<Scalars['ID']>;
};

export type UpdateStintTemplateInput = {
  id: Scalars['ID'];
  partnerNodeId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  uniform?: Maybe<Array<StintUniform>>;
  prepVideoUrl?: Maybe<Scalars['String']>;
  uniformReminderEnabled?: Maybe<Scalars['Boolean']>;
  trained?: Maybe<Scalars['Boolean']>;
  primaryTasks?: Maybe<Array<StintTaskInput>>;
  secondaryTasks?: Maybe<Array<StintTaskInput>>;
  topTips?: Maybe<Array<TopTipInput>>;
  employmentExceptions?: Maybe<Array<Scalars['ID']>>;
  controlledRelease?: Maybe<Scalars['Boolean']>;
  bonusRate?: Maybe<Scalars['String']>;
  stintTemplateGroupId?: Maybe<Scalars['ID']>;
};

export type UpdateStintTimesInput = {
  id: Scalars['ID'];
  businessId: Scalars['ID'];
  dateFrom: Scalars['AWSDateTime'];
  dateTo: Scalars['AWSDateTime'];
};

export type UpdateStinterCommitmentInput = {
  id: Scalars['ID'];
  startDate?: Maybe<Scalars['AWSDate']>;
  numberOfWeeks?: Maybe<Scalars['Int']>;
  stintsPerWeek?: Maybe<Scalars['Int']>;
  additionalStintTemplateIds?: Maybe<Array<Scalars['ID']>>;
};

export type UpdateStinterMarketingChannelInput = {
  stinterId: Scalars['ID'];
  marketingChannel: MarketingChannel;
};

export type UpdateStinterMarketingConsentInput = {
  stinterProfileId: Scalars['ID'];
  marketingConsentAccepted: Scalars['Boolean'];
};

export type UpsertDemandForecastInput = {
  partnerNodeId: Scalars['ID'];
  forecastDate: Scalars['AWSDateTime'];
  expectedForecast: Scalars['Int'];
};

export type UpsertDemandThresholdsInput = {
  partnerNodeId: Scalars['ID'];
  dayOfWeek: WeekDay;
  demandThresholds: Array<Scalars['Int']>;
};

export type UpsertDeviceInfo = {
  __typename?: 'UpsertDeviceInfo';
  deviceInfo?: Maybe<DeviceInfo>;
};

export type UpsertEmploymentInfoInput = {
  expectedHours: Scalars['Int'];
  jobTitle: Scalars['String'];
  payRate: Scalars['String'];
  contractType: ContractType;
};

export type UpsertLabourTemplateInput = {
  partnerNodeId: Scalars['ID'];
  dayOfWeek: WeekDay;
  demandRange: ForecastedDemandRange;
  shifts: Array<ShiftInput>;
  usualStaffedHours: Scalars['Float'];
};

export type UpsertVideoAssessmentInfoInput = {
  stinterId: Scalars['ID'];
  assessmentOutcome: VideoAssessmentOutcome;
  assessmentId?: Maybe<Scalars['ID']>;
};

export type UserAttributeType = {
  __typename?: 'UserAttributeType';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  modifiedAt: Scalars['AWSDateTime'];
  deletedAt?: Maybe<Scalars['AWSDateTime']>;
  ref: Scalars['ID'];
  user: StintUser;
  enablePushNotifications: Scalars['Boolean'];
  enableSmsNotifications: Scalars['Boolean'];
  enableEmailNotifications: Scalars['Boolean'];
};

export type UserRepresentationType = {
  __typename?: 'UserRepresentationType';
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Array<Maybe<UserAttributeType>>>;
  user?: Maybe<StintUser>;
};

export enum UserType {
  STINTER = 'STINTER',
  EMPLOYER = 'EMPLOYER',
  STAFF = 'STAFF',
  UNKNOWN = 'UNKNOWN',
}

export type UsersFilterInput = {
  email?: Maybe<Scalars['String']>;
  fullname?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  userType?: Maybe<UserType>;
};

export type UsersInput = {
  orderBy?: Maybe<UsersOrderInput>;
  filter?: Maybe<UsersFilterInput>;
};

export enum UsersOrderField {
  EMAIL = 'EMAIL',
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
}

export type UsersOrderInput = {
  field: UsersOrderField;
  order: SortOrder;
};

export enum ValidOffenceReason {
  LEAVING_EARLY = 'LEAVING_EARLY',
  LATENESS = 'LATENESS',
  UNIFORM = 'UNIFORM',
  CANCELLATION_THRESHOLD_1_V2 = 'CANCELLATION_THRESHOLD_1_V2',
  CANCELLATION_THRESHOLD_2_V2 = 'CANCELLATION_THRESHOLD_2_V2',
  CANCELLATION_THRESHOLD_3_V2 = 'CANCELLATION_THRESHOLD_3_V2',
  NO_SHOW = 'NO_SHOW',
  NO_CONFIRMATION = 'NO_CONFIRMATION',
  UNRESPONSIVE_STANDBY = 'UNRESPONSIVE_STANDBY',
  GROSS_MISCONDUCT = 'GROSS_MISCONDUCT',
  PERFORMANCE = 'PERFORMANCE',
  PERFORMANCE_SENT_HOME = 'PERFORMANCE_SENT_HOME',
  UNAUTHORISED_DEPARTURE = 'UNAUTHORISED_DEPARTURE',
  ONE_STRIKE_REDEEMED = 'ONE_STRIKE_REDEEMED',
  TWO_STRIKE_REDEEMED = 'TWO_STRIKE_REDEEMED',
  THREE_STRIKE_REDEEMED = 'THREE_STRIKE_REDEEMED',
}

export type Verify = {
  __typename?: 'Verify';
  payload: Scalars['String'];
};

export type VerifyEmail = {
  __typename?: 'VerifyEmail';
  token?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
};

export type VerifyPhonenumber = {
  __typename?: 'VerifyPhonenumber';
  token?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
};

export type VideoAssessmentInfo = {
  __typename?: 'VideoAssessmentInfo';
  id: Scalars['ID'];
  assessedAt: Scalars['AWSDateTime'];
  outcome: VideoAssessmentOutcome;
  assessorName: Scalars['String'];
};

export enum VideoAssessmentOutcome {
  CUSTOMER_FACING_GREAT = 'CUSTOMER_FACING_GREAT',
  CUSTOMER_FACING_GOOD = 'CUSTOMER_FACING_GOOD',
  BACK_OF_HOUSE = 'BACK_OF_HOUSE',
  NOT_FIT_FOR_STINT = 'NOT_FIT_FOR_STINT',
  NEEDS_REVIEW = 'NEEDS_REVIEW',
}

export enum WaterfallBotGroup {
  UNSPECIFIED = 'UNSPECIFIED',
  GROUP_ONE = 'GROUP_ONE',
  GROUP_TWO = 'GROUP_TWO',
  GROUP_THREE = 'GROUP_THREE',
  GROUP_FOUR = 'GROUP_FOUR',
  GROUP_FIVE = 'GROUP_FIVE',
  GROUP_SIX = 'GROUP_SIX',
  GROUP_SEVEN = 'GROUP_SEVEN',
  GROUP_EIGHT = 'GROUP_EIGHT',
  GROUP_NINE = 'GROUP_NINE',
  GROUP_TEN = 'GROUP_TEN',
  GROUP_ELEVEN = 'GROUP_ELEVEN',
  GROUP_TWELVE = 'GROUP_TWELVE',
  GROUP_THIRTEEN = 'GROUP_THIRTEEN',
  GROUP_FOURTEEN = 'GROUP_FOURTEEN',
  GROUP_FIFTEEN = 'GROUP_FIFTEEN',
}

export enum WeekDay {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export type WeekSchedule = {
  __typename?: 'WeekSchedule';
  monday: Schedule;
  tuesday: Schedule;
  wednesday: Schedule;
  thursday: Schedule;
  friday: Schedule;
  saturday: Schedule;
  sunday: Schedule;
};

export type WeekSelectionInput = {
  startDate: Scalars['AWSDateTime'];
  endDate: Scalars['AWSDateTime'];
  selectedDays: Array<Maybe<StintSessionDay>>;
};

export type WorkExperience = Node & {
  __typename?: 'WorkExperience';
  id: Scalars['ID'];
  hospitalityWorkExperience: HospitalityWorkExperience;
};

export enum WorkingPreference {
  PREFERRED = 'PREFERRED',
  NO_PREFERENCE = 'NO_PREFERENCE',
  EARLY_SHIFTS = 'EARLY_SHIFTS',
  LATE_SHIFTS = 'LATE_SHIFTS',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
}

export type WorkingPreferences = {
  __typename?: 'WorkingPreferences';
  monday: WorkingPreference;
  tuesday: WorkingPreference;
  wednesday: WorkingPreference;
  thursday: WorkingPreference;
  friday: WorkingPreference;
  saturday: WorkingPreference;
  sunday: WorkingPreference;
};

export type WorkingPreferencesInput = {
  monday: WorkingPreference;
  tuesday: WorkingPreference;
  wednesday: WorkingPreference;
  thursday: WorkingPreference;
  friday: WorkingPreference;
  saturday: WorkingPreference;
  sunday: WorkingPreference;
};

export type BulkUpdateCanSeeSurgeMutationVariables = Exact<{
  canSeeSurge: Scalars['Boolean'];
  userFile: Scalars['String'];
}>;

export type BulkUpdateCanSeeSurgeMutation = { __typename?: 'Mutation' } & {
  bulkUpdateCanSeeSurge?: Maybe<
    { __typename?: 'SuccessType' } & Pick<SuccessType, 'message' | 'success'>
  >;
};

export type ManualGeotrackingStatusUpdateMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ManualGeotrackingStatusUpdateMutation = {
  __typename?: 'Mutation';
} & {
  manualGeotrackingStatusUpdate?: Maybe<
    { __typename?: 'StintJourney' } & Pick<StintJourney, 'id'>
  >;
};

export type SearchGooglePlacesMutationVariables = Exact<{
  searchTerm: Scalars['String'];
  returnType?: Maybe<GooglePlacesReturnType>;
}>;

export type SearchGooglePlacesMutation = { __typename?: 'Mutation' } & {
  searchGooglePlaces?: Maybe<
    { __typename?: 'GooglePlacesPredictionsType' } & {
      predictions?: Maybe<
        Array<
          Maybe<
            { __typename?: 'GooglePlaceType' } & Pick<
              GooglePlaceType,
              'placeId' | 'displayAddress'
            >
          >
        >
      >;
    }
  >;
};

export type sendOtpEmailMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type sendOtpEmailMutation = { __typename?: 'Mutation' } & {
  sendOtpEmail?: Maybe<
    { __typename: 'SendOTPEmail' } & Pick<SendOTPEmail, 'success'>
  >;
};

export type SendOtpSmsV2MutationVariables = Exact<{
  userId: Scalars['String'];
}>;

export type SendOtpSmsV2Mutation = { __typename?: 'Mutation' } & {
  sendOtpSmsV2?: Maybe<
    { __typename?: 'SendOTPSMSV2' } & Pick<SendOTPSMSV2, 'success'>
  >;
};

export type verifyStudentUserMutationVariables = Exact<{
  studentUserIds: Array<Maybe<Scalars['ID']>>;
}>;

export type verifyStudentUserMutation = { __typename?: 'Mutation' } & {
  data?: Maybe<{ __typename?: 'SuccessType' } & Pick<SuccessType, 'success'>>;
};

export type CountStudentsCanSeeSurgeQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CountStudentsCanSeeSurgeQuery = { __typename?: 'Query' } & Pick<
  Query,
  'countStudentsCanSeeSurge'
>;

export type stinterRightToWorkStatusQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type stinterRightToWorkStatusQuery = { __typename?: 'Query' } & {
  getStinterRightToWorkStatus?: Maybe<
    { __typename?: 'StinterRTWStatus' } & Pick<
      StinterRTWStatus,
      'until' | 'weeklyCap' | 'approvedAt'
    >
  >;
};

export type UpdateActualLeaveTimeMutationVariables = Exact<{
  updateInputs: UpdateActualLeaveTimeInput;
}>;

export type UpdateActualLeaveTimeMutation = { __typename?: 'Mutation' } & {
  data?: Maybe<
    { __typename?: 'Stint' } & Pick<Stint, 'id' | 'actualLeaveTime'>
  >;
};

export type AdminAddressFieldsFragment = { __typename?: 'Address' } & Pick<
  Address,
  'id' | 'name' | 'placeId'
>;

export type allAddressesQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<AddressFilter>;
}>;

export type allAddressesQuery = { __typename?: 'Query' } & {
  items?: Maybe<
    Array<Maybe<{ __typename?: 'Address' } & AdminAddressFieldsFragment>>
  >;
  total?: Maybe<{ __typename: 'ListMetadata' } & Pick<ListMetadata, 'count'>>;
};

export type AddressQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type AddressQuery = { __typename?: 'Query' } & {
  data?: Maybe<{ __typename?: 'Address' } & AdminAddressFieldsFragment>;
};

export type AdminCreateAppealMutationVariables = Exact<{
  createInputs: AdminCreateAppealInput;
}>;

export type AdminCreateAppealMutation = { __typename?: 'Mutation' } & {
  data?: Maybe<
    { __typename?: 'Appeal' } & Pick<Appeal, 'id' | 'decisionAt' | 'status'>
  >;
};

export type AdminBusinessFieldsFragment = { __typename?: 'Business' } & Pick<
  Business,
  | 'id'
  | 'averageRating'
  | 'createdAt'
  | 'ltdName'
  | 'displayAddress'
  | 'exemptFromPaymentDetails'
  | 'integrated'
  | 'name'
  | 'logo'
  | 'repeatStintersEnabled'
  | 'phoneNumber'
  | 'placeId'
  | 'qrCodeId'
  | 'status'
  | 'unlockedLastMinuteStints'
  | 'controlCheck'
  | 'isBlockedByFinance'
> & {
    address?: Maybe<
      { __typename?: 'Address' } & Pick<Address, 'postalCode'> & {
          cityFk?: Maybe<{ __typename?: 'City' } & Pick<City, 'id' | 'name'>>;
        }
    >;
    city?: Maybe<
      { __typename?: 'City' } & Pick<City, 'id' | 'name' | 'baseFee'>
    >;
    rateOverride?: Maybe<
      { __typename?: 'RateOverride' } & Pick<RateOverride, 'rate'>
    >;
    salesRepresentitive?: Maybe<
      { __typename?: 'StintUser' } & Pick<
        StintUser,
        'id' | 'email' | 'firstName' | 'lastName'
      >
    >;
    stintopedia?: Maybe<
      { __typename?: 'Stintopedia' } & Pick<
        Stintopedia,
        'id' | 'bookedOnboardingMeeting' | 'usingStintCorrectly'
      >
    >;
  };

export type allBusinessesQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<BusinessFilter>;
}>;

export type allBusinessesQuery = { __typename?: 'Query' } & {
  items?: Maybe<
    Array<Maybe<{ __typename?: 'Business' } & AdminBusinessFieldsFragment>>
  >;
  total?: Maybe<{ __typename: 'ListMetadata' } & Pick<ListMetadata, 'count'>>;
};

export type BusinessQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type BusinessQuery = { __typename?: 'Query' } & {
  data?: Maybe<{ __typename?: 'Business' } & AdminBusinessFieldsFragment>;
};

export type updateBusinessMutationVariables = Exact<{
  id: Scalars['ID'];
  businessLogo?: Maybe<Scalars['String']>;
  displayAddress?: Maybe<Scalars['String']>;
  exemptFromPaymentDetails?: Maybe<Scalars['Boolean']>;
  ltdName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  rateOverride?: Maybe<BusinessRateOverrideInput>;
  status?: Maybe<BusinessStatusChoices>;
  integrated?: Maybe<Scalars['Boolean']>;
  unlockedLastMinuteStints?: Maybe<Scalars['Boolean']>;
  controlCheck?: Maybe<Scalars['Boolean']>;
  isBlockedByFinance?: Maybe<Scalars['Boolean']>;
  stintopedia?: Maybe<StintopediaInput>;
  repeatStintersEnabled?: Maybe<Scalars['Boolean']>;
}>;

export type updateBusinessMutation = { __typename?: 'Mutation' } & {
  data?: Maybe<{ __typename?: 'Business' } & AdminBusinessFieldsFragment>;
};

export type AdminCharacteristicFieldsFragment = {
  __typename?: 'Characteristic';
} & Pick<Characteristic, 'id' | 'name'>;

export type allCharacteristicsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<CharacteristicFilter>;
}>;

export type allCharacteristicsQuery = { __typename?: 'Query' } & {
  items?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Characteristic' } & AdminCharacteristicFieldsFragment
      >
    >
  >;
  total?: Maybe<{ __typename: 'ListMetadata' } & Pick<ListMetadata, 'count'>>;
};

export type CharacteristicQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CharacteristicQuery = { __typename?: 'Query' } & {
  data?: Maybe<
    { __typename?: 'Characteristic' } & AdminCharacteristicFieldsFragment
  >;
};

export type AdminCityFieldsFragment = { __typename?: 'City' } & Pick<
  City,
  | 'baseFee'
  | 'baseFeeStudent'
  | 'name'
  | 'id'
  | 'isActive'
  | 'isAvailabilityEnabled'
  | 'sessionUrl'
  | 'shortCode'
  | 'state'
  | 'postalCodeIds'
  | 'regionId'
> & {
    region?: Maybe<
      { __typename?: 'Region' } & Pick<Region, 'name' | 'id'> & {
          country?: Maybe<
            { __typename?: 'Country' } & Pick<Country, 'name' | 'id'>
          >;
        }
    >;
  };

export type allCitiesQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<CityFilter>;
}>;

export type allCitiesQuery = { __typename?: 'Query' } & {
  items?: Maybe<
    Array<Maybe<{ __typename?: 'City' } & AdminCityFieldsFragment>>
  >;
  total?: Maybe<{ __typename: 'ListMetadata' } & Pick<ListMetadata, 'count'>>;
};

export type CityQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CityQuery = { __typename?: 'Query' } & {
  data?: Maybe<{ __typename?: 'City' } & AdminCityFieldsFragment>;
};

export type createCityMutationVariables = Exact<{
  baseFee: Scalars['Float'];
  baseFeeStudent: Scalars['Float'];
  name: Scalars['String'];
  regionId: Scalars['ID'];
  sessionUrl?: Maybe<Scalars['AWSURL']>;
  shortCode: Scalars['String'];
}>;

export type createCityMutation = { __typename?: 'Mutation' } & {
  data?: Maybe<{ __typename?: 'City' } & AdminCityFieldsFragment>;
};

export type updateCityMutationVariables = Exact<{
  baseFee: Scalars['Float'];
  baseFeeStudent: Scalars['Float'];
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  isAvailabilityEnabled?: Maybe<Scalars['Boolean']>;
  sessionUrl?: Maybe<Scalars['AWSURL']>;
  name?: Maybe<Scalars['String']>;
  regionId?: Maybe<Scalars['ID']>;
  shortCode?: Maybe<Scalars['String']>;
  postalCodeIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
}>;

export type updateCityMutation = { __typename?: 'Mutation' } & {
  data?: Maybe<{ __typename?: 'City' } & AdminCityFieldsFragment>;
};

export type AdminCountryFieldsFragment = { __typename?: 'Country' } & Pick<
  Country,
  'id' | 'name' | 'shortCode' | 'regions'
>;

export type allCountriesQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<CountryFilter>;
}>;

export type allCountriesQuery = { __typename?: 'Query' } & {
  items?: Maybe<
    Array<Maybe<{ __typename?: 'Country' } & AdminCountryFieldsFragment>>
  >;
  total?: Maybe<{ __typename: 'ListMetadata' } & Pick<ListMetadata, 'count'>>;
};

export type CountryQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CountryQuery = { __typename?: 'Query' } & {
  data?: Maybe<{ __typename?: 'Country' } & AdminCountryFieldsFragment>;
};

export type updateCountryMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  shortCode?: Maybe<Scalars['String']>;
  regions?: Maybe<Scalars['String']>;
}>;

export type updateCountryMutation = { __typename?: 'Mutation' } & {
  data?: Maybe<{ __typename?: 'Country' } & AdminCountryFieldsFragment>;
};

export type createCountryMutationVariables = Exact<{
  name: Scalars['String'];
  shortCode: Scalars['String'];
  regions: Scalars['String'];
}>;

export type createCountryMutation = { __typename?: 'Mutation' } & {
  data?: Maybe<{ __typename?: 'Country' } & AdminCountryFieldsFragment>;
};

export type AdminDeviceLocationFieldsFragment = {
  __typename?: 'DeviceLocation';
} & Pick<
  DeviceLocation,
  | 'id'
  | 'accuracy'
  | 'batteryLevel'
  | 'recordedAt'
  | 'longitude'
  | 'latitude'
  | 'heading'
  | 'speed'
>;

export type allDeviceLocationsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<DeviceLocationFilter>;
}>;

export type allDeviceLocationsQuery = { __typename?: 'Query' } & {
  items?: Maybe<
    Array<
      Maybe<
        { __typename?: 'DeviceLocation' } & AdminDeviceLocationFieldsFragment
      >
    >
  >;
  total?: Maybe<{ __typename: 'ListMetadata' } & Pick<ListMetadata, 'count'>>;
};

export type DeviceLocationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeviceLocationQuery = { __typename?: 'Query' } & {
  data?: Maybe<
    { __typename?: 'DeviceLocation' } & AdminDeviceLocationFieldsFragment
  >;
};

export type AdminEditableSettingFieldsFragment = {
  __typename?: 'EditableSetting';
} & Pick<
  EditableSetting,
  | 'id'
  | 'predictionLeadDays'
  | 'notificationBlockerWindowHours'
  | 'geoTrackingBufferTime'
  | 'advancedOperationalWindowStartTime'
  | 'advancedOperationalWindowEndTime'
  | 'automatedTestingEnabled'
  | 'isTimeManipulationEnabled'
  | 'serverDatetime'
  | 'featureFlagStudentGoingToBeLateSmsEnabled'
  | 'featureFlagStintSecondStartReminderEnabled'
  | 'availabilityMatchingBufferHours'
  | 'availabilityMatchingMaxOffersMonday'
  | 'availabilityMatchingMaxOffersTuesday'
  | 'availabilityMatchingMaxOffersWednesday'
  | 'availabilityMatchingMaxOffersThursday'
  | 'availabilityMatchingMaxOffersFriday'
  | 'availabilityMatchingMaxOffersSaturday'
  | 'availabilityMatchingMaxOffersSunday'
  | 'availabilityMatchingNotificationForNoOffersEnabled'
  | 'matchingAlgoNoOfferMaxCountPerTimeWindow'
  | 'matchingAlgoNoOfferTimeWindowSpan'
  | 'matchingAlgoNoOfferTitle'
  | 'matchingAlgoNoOfferMessage'
  | 'matchingAlgoNotificationDelayInMinutes'
  | 'featureFlagEtdNotificationsEnabled'
  | 'sendingStudentLeaveReminderAtRouteTimeEnabled'
  | 'studentCancellationOnHoldEmailEnabled'
  | 'etdCalculationBeforeMiddayOffsetInMinutes'
  | 'etdCalculationAfterMiddayOffsetInMinutes'
  | 'stintOfferExpiryThresholdInHours'
  | 'notificationTopUpThresholdInHours'
>;

export type allEditableSettingsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
}>;

export type allEditableSettingsQuery = { __typename?: 'Query' } & {
  items?: Maybe<
    Array<
      Maybe<
        { __typename?: 'EditableSetting' } & AdminEditableSettingFieldsFragment
      >
    >
  >;
  total?: Maybe<{ __typename: 'ListMetadata' } & Pick<ListMetadata, 'count'>>;
};

export type EditableSettingQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type EditableSettingQuery = { __typename?: 'Query' } & {
  data?: Maybe<
    { __typename?: 'EditableSetting' } & AdminEditableSettingFieldsFragment
  >;
};

export type updateEditableSettingMutationVariables = Exact<{
  predictionLeadDays?: Maybe<Scalars['Int']>;
  notificationBlockerWindowHours?: Maybe<Scalars['Float']>;
  geoTrackingBufferTime?: Maybe<Scalars['Int']>;
  advancedOperationalWindowStartTime?: Maybe<Scalars['AWSTime']>;
  advancedOperationalWindowEndTime?: Maybe<Scalars['AWSTime']>;
  automatedTestingEnabled?: Maybe<Scalars['Boolean']>;
  serverDatetime?: Maybe<Scalars['AWSDateTime']>;
  featureFlagStudentGoingToBeLateSmsEnabled?: Maybe<Scalars['Boolean']>;
  featureFlagStintSecondStartReminderEnabled?: Maybe<Scalars['Boolean']>;
  availabilityMatchingBufferHours?: Maybe<Scalars['Int']>;
  availabilityMatchingMaxOffersMonday?: Maybe<Scalars['Int']>;
  availabilityMatchingMaxOffersTuesday?: Maybe<Scalars['Int']>;
  availabilityMatchingMaxOffersWednesday?: Maybe<Scalars['Int']>;
  availabilityMatchingMaxOffersThursday?: Maybe<Scalars['Int']>;
  availabilityMatchingMaxOffersFriday?: Maybe<Scalars['Int']>;
  availabilityMatchingMaxOffersSaturday?: Maybe<Scalars['Int']>;
  availabilityMatchingMaxOffersSunday?: Maybe<Scalars['Int']>;
  availabilityMatchingNotificationForNoOffersEnabled?: Maybe<
    Scalars['Boolean']
  >;
  matchingAlgoNoOfferMaxCountPerTimeWindow?: Maybe<Scalars['Int']>;
  matchingAlgoNoOfferTimeWindowSpan?: Maybe<Scalars['Int']>;
  matchingAlgoNoOfferTitle?: Maybe<Scalars['String']>;
  matchingAlgoNoOfferMessage?: Maybe<Scalars['String']>;
  matchingAlgoNotificationDelayInMinutes?: Maybe<Scalars['Int']>;
  featureFlagEtdNotificationsEnabled?: Maybe<Scalars['Boolean']>;
  sendingStudentLeaveReminderAtRouteTimeEnabled?: Maybe<Scalars['Boolean']>;
  studentCancellationOnHoldEmailEnabled?: Maybe<Scalars['Boolean']>;
  etdCalculationBeforeMiddayOffsetInMinutes?: Maybe<Scalars['Int']>;
  etdCalculationAfterMiddayOffsetInMinutes?: Maybe<Scalars['Int']>;
  stintOfferExpiryThresholdInHours?: Maybe<Scalars['Int']>;
  notificationTopUpThresholdInHours?: Maybe<Scalars['Int']>;
}>;

export type updateEditableSettingMutation = { __typename?: 'Mutation' } & {
  data?: Maybe<
    { __typename?: 'EditableSetting' } & AdminEditableSettingFieldsFragment
  >;
};

export type EmployerUserFieldsFragment = {
  __typename?: 'UserRepresentationType';
} & Pick<
  UserRepresentationType,
  'id' | 'firstName' | 'lastName' | 'email' | 'emailVerified' | 'enabled'
> & {
    user?: Maybe<
      { __typename: 'StintUser' } & Pick<
        StintUser,
        | 'id'
        | 'profilePhotoUrl'
        | 'phoneNumber'
        | 'deactivated'
        | 'permanentDeactivated'
        | 'appVersion'
      >
    >;
  };

export type EmployerUserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type EmployerUserQuery = { __typename?: 'Query' } & {
  data?: Maybe<
    { __typename?: 'UserRepresentationType' } & EmployerUserFieldsFragment
  >;
};

export type allEmployerUsersQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
}>;

export type allEmployerUsersQuery = { __typename?: 'Query' } & {
  items?: Maybe<
    Array<
      Maybe<
        { __typename?: 'UserRepresentationType' } & EmployerUserFieldsFragment
      >
    >
  >;
  total?: Maybe<{ __typename: 'ListMetadata' } & Pick<ListMetadata, 'count'>>;
};

export type AdminEmploymentExceptionFieldsFragment = {
  __typename?: 'EmploymentException';
} & Pick<EmploymentException, 'id' | 'name' | 'category'>;

export type allEmploymentExceptionsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<EmploymentExceptionFilter>;
}>;

export type allEmploymentExceptionsQuery = { __typename?: 'Query' } & {
  items?: Maybe<
    Array<
      Maybe<
        {
          __typename?: 'EmploymentException';
        } & AdminEmploymentExceptionFieldsFragment
      >
    >
  >;
  total?: Maybe<{ __typename: 'ListMetadata' } & Pick<ListMetadata, 'count'>>;
};

export type EmploymentExceptionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type EmploymentExceptionQuery = { __typename?: 'Query' } & {
  data?: Maybe<
    {
      __typename?: 'EmploymentException';
    } & AdminEmploymentExceptionFieldsFragment
  >;
};

export type createEmploymentExceptionMutationVariables = Exact<{
  name: Scalars['String'];
  category?: Maybe<EmploymentExceptionCategoryInputType>;
}>;

export type createEmploymentExceptionMutation = { __typename?: 'Mutation' } & {
  data?: Maybe<
    {
      __typename?: 'EmploymentException';
    } & AdminEmploymentExceptionFieldsFragment
  >;
};

export type updateEmploymentExceptionMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  category?: Maybe<EmploymentExceptionCategoryInputType>;
}>;

export type updateEmploymentExceptionMutation = { __typename?: 'Mutation' } & {
  data?: Maybe<
    {
      __typename?: 'EmploymentException';
    } & AdminEmploymentExceptionFieldsFragment
  >;
};

export type deleteEmploymentExceptionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type deleteEmploymentExceptionMutation = { __typename?: 'Mutation' } & {
  data?: Maybe<
    {
      __typename?: 'EmploymentException';
    } & AdminEmploymentExceptionFieldsFragment
  >;
};

export type AdminLanguageFieldsFragment = { __typename?: 'Language' } & Pick<
  Language,
  'id' | 'name'
>;

export type allLanguagesQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<LanguageFilter>;
}>;

export type allLanguagesQuery = { __typename?: 'Query' } & {
  items?: Maybe<
    Array<Maybe<{ __typename?: 'Language' } & AdminLanguageFieldsFragment>>
  >;
  total?: Maybe<{ __typename: 'ListMetadata' } & Pick<ListMetadata, 'count'>>;
};

export type LanguageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type LanguageQuery = { __typename?: 'Query' } & {
  data?: Maybe<{ __typename?: 'Language' } & AdminLanguageFieldsFragment>;
};

export type allNotificationStudentOrEmployerUsersQueryVariables = Exact<{
  filter?: Maybe<StintUserFilter>;
}>;

export type allNotificationStudentOrEmployerUsersQuery = {
  __typename?: 'Query';
} & {
  total?: Maybe<{ __typename: 'ListMetadata' } & Pick<ListMetadata, 'count'>>;
};

export type AdminNotificationFieldsFragment = {
  __typename?: 'Notification';
} & Pick<
  Notification,
  | 'id'
  | 'createdAt'
  | 'status'
  | 'errorMessage'
  | 'title'
  | 'message'
  | 'sendTextMessage'
  | 'sendPushNotification'
  | 'recipientsCount'
  | 'openedCount'
  | 'notificationType'
> & {
    stint?: Maybe<{ __typename?: 'Stint' } & Pick<Stint, 'id' | 'name'>>;
    business?: Maybe<
      { __typename?: 'Business' } & Pick<Business, 'id' | 'name'>
    >;
  };

export type allNotificationsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<NotificationFilter>;
}>;

export type allNotificationsQuery = { __typename?: 'Query' } & {
  items?: Maybe<
    Array<
      Maybe<{ __typename?: 'Notification' } & AdminNotificationFieldsFragment>
    >
  >;
  total?: Maybe<{ __typename: 'ListMetadata' } & Pick<ListMetadata, 'count'>>;
};

export type NotificationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type NotificationQuery = { __typename?: 'Query' } & {
  data?: Maybe<
    { __typename?: 'Notification' } & AdminNotificationFieldsFragment
  >;
};

export type createNotificationMutationVariables = Exact<{
  title: Scalars['String'];
  message: Scalars['String'];
  stint_id?: Maybe<Scalars['ID']>;
  student_ids: Array<Maybe<Scalars['String']>>;
  sendPushNotification?: Maybe<Scalars['Boolean']>;
}>;

export type createNotificationMutation = { __typename?: 'Mutation' } & {
  data?: Maybe<
    { __typename?: 'Notification' } & AdminNotificationFieldsFragment
  >;
};

export type OffenceBasicFieldsFragment = { __typename?: 'Offence' } & Pick<
  Offence,
  'id' | 'appealStatus' | 'reason' | 'strikesAwarded'
> & {
    business?: Maybe<
      { __typename?: 'Business' } & Pick<Business, 'id' | 'name'>
    >;
    stint?: Maybe<
      { __typename?: 'Stint' } & Pick<Stint, 'id' | 'stintTimesDisplay'>
    >;
  };

export type CreateOffenceMutationVariables = Exact<{
  createInputs: CreateOffenceInput;
}>;

export type CreateOffenceMutation = { __typename?: 'Mutation' } & {
  data?: Maybe<
    { __typename?: 'Offence' } & Pick<
      Offence,
      'id' | 'appealStatus' | 'createdAt' | 'reason' | 'strikesAwarded'
    > & {
        business?: Maybe<
          { __typename?: 'Business' } & Pick<Business, 'id' | 'name'>
        >;
        stint?: Maybe<
          { __typename?: 'Stint' } & Pick<Stint, 'id' | 'stintTimesDisplay'>
        >;
        createdBy?: Maybe<
          { __typename?: 'StintUser' } & Pick<StintUser, 'id' | 'fullname'>
        >;
      }
  >;
};

export type OffenceQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type OffenceQuery = { __typename?: 'Query' } & {
  data?: Maybe<
    { __typename?: 'Offence' } & Pick<Offence, 'createdAt' | 'reason'> & {
        createdBy?: Maybe<
          { __typename?: 'StintUser' } & Pick<StintUser, 'id' | 'fullname'>
        >;
        appeals: Array<
          { __typename?: 'Appeal' } & Pick<
            Appeal,
            'id' | 'status' | 'date' | 'notes' | 'decisionAt'
          > & {
              decisionBy?: Maybe<
                { __typename?: 'StintUser' } & Pick<
                  StintUser,
                  'id' | 'fullname'
                >
              >;
            }
        >;
      } & OffenceBasicFieldsFragment
  >;
};

export type stinterOffencesQueryVariables = Exact<{
  id: Scalars['ID'];
  perPage: Scalars['Int'];
}>;

export type stinterOffencesQuery = { __typename?: 'Query' } & {
  data?: Maybe<
    { __typename?: 'OffenceTypeConnection' } & {
      pageInfo: { __typename?: 'PageInfo' } & Pick<
        PageInfo,
        'hasNextPage' | 'endCursor'
      >;
      edges: Array<
        { __typename?: 'OffenceTypeEdge' } & {
          node: { __typename?: 'Offence' } & OffenceBasicFieldsFragment;
        }
      >;
    }
  >;
};

export type AdminPeriodicTaskFieldsFragment = {
  __typename?: 'PeriodicTask';
} & Pick<
  PeriodicTask,
  | 'id'
  | 'name'
  | 'lastRunAt'
  | 'enabled'
  | 'priority'
  | 'startTime'
  | 'totalRunCount'
  | 'dateChanged'
>;

export type allPeriodicTasksQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
}>;

export type allPeriodicTasksQuery = { __typename?: 'Query' } & {
  items?: Maybe<
    Array<
      Maybe<{ __typename?: 'PeriodicTask' } & AdminPeriodicTaskFieldsFragment>
    >
  >;
  total?: Maybe<{ __typename: 'ListMetadata' } & Pick<ListMetadata, 'count'>>;
};

export type PeriodicTaskQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type PeriodicTaskQuery = { __typename?: 'Query' } & {
  data?: Maybe<
    { __typename?: 'PeriodicTask' } & AdminPeriodicTaskFieldsFragment
  >;
};

export type AdminPostalCodeFieldsFragment = {
  __typename?: 'PostalCode';
} & Pick<PostalCode, 'postalCode' | 'id' | 'city_id'>;

export type allPostalCodesQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<PostalCodeFilter>;
}>;

export type allPostalCodesQuery = { __typename?: 'Query' } & {
  items?: Maybe<
    Array<Maybe<{ __typename?: 'PostalCode' } & AdminPostalCodeFieldsFragment>>
  >;
  total?: Maybe<{ __typename: 'ListMetadata' } & Pick<ListMetadata, 'count'>>;
};

export type PostalCodeQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type PostalCodeQuery = { __typename?: 'Query' } & {
  data?: Maybe<{ __typename?: 'PostalCode' } & AdminPostalCodeFieldsFragment>;
};

export type createPostalCodeMutationVariables = Exact<{
  postalCode: Scalars['String'];
  city_id: Scalars['ID'];
}>;

export type createPostalCodeMutation = { __typename?: 'Mutation' } & {
  data?: Maybe<{ __typename?: 'PostalCode' } & AdminPostalCodeFieldsFragment>;
};

export type updatePostalCodeMutationVariables = Exact<{
  id: Scalars['ID'];
  postalCode?: Maybe<Scalars['String']>;
  city_id?: Maybe<Scalars['ID']>;
}>;

export type updatePostalCodeMutation = { __typename?: 'Mutation' } & {
  data?: Maybe<{ __typename?: 'PostalCode' } & AdminPostalCodeFieldsFragment>;
};

export type AdminRegionFieldsFragment = { __typename?: 'Region' } & Pick<
  Region,
  'id' | 'name' | 'countryId'
> & {
    country?: Maybe<{ __typename?: 'Country' } & Pick<Country, 'name' | 'id'>>;
  };

export type allRegionsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<RegionFilter>;
}>;

export type allRegionsQuery = { __typename?: 'Query' } & {
  items?: Maybe<
    Array<Maybe<{ __typename?: 'Region' } & AdminRegionFieldsFragment>>
  >;
  total?: Maybe<{ __typename: 'ListMetadata' } & Pick<ListMetadata, 'count'>>;
};

export type RegionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type RegionQuery = { __typename?: 'Query' } & {
  data?: Maybe<{ __typename?: 'Region' } & AdminRegionFieldsFragment>;
};

export type createRegionMutationVariables = Exact<{
  name: Scalars['String'];
  countryId: Scalars['ID'];
}>;

export type createRegionMutation = { __typename?: 'Mutation' } & {
  data?: Maybe<{ __typename?: 'Region' } & AdminRegionFieldsFragment>;
};

export type updateRegionMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  countryId?: Maybe<Scalars['ID']>;
}>;

export type updateRegionMutation = { __typename?: 'Mutation' } & {
  data?: Maybe<{ __typename?: 'Region' } & AdminRegionFieldsFragment>;
};

export type RTWStatusFieldsFragment = {
  __typename?: 'StinterRTWStinterStatus';
} & Pick<StinterRTWStinterStatus, 'id' | 'state' | 'updatedAt'> & {
    stinter: { __typename?: 'StinterObject' } & Pick<StinterObject, 'id'>;
  };

export type RTWScreeningFieldsFragment = {
  __typename?: 'StinterRTWScreening';
} & Pick<
  StinterRTWScreening,
  'id' | 'type' | 'status' | 'updatedAt' | 'error'
> & { stinter: { __typename?: 'StinterObject' } & Pick<StinterObject, 'id'> };

export type listStinterRightToWorkStatusQueryVariables = Exact<{
  filter?: Maybe<StinterRightToWorkStatusFilter>;
  perPage?: Maybe<Scalars['Int']>;
  next?: Maybe<Scalars['String']>;
}>;

export type listStinterRightToWorkStatusQuery = { __typename?: 'Query' } & {
  data: { __typename?: 'StinterRightToWorkStatusPagination' } & Pick<
    StinterRightToWorkStatusPagination,
    'next'
  > & {
      items: Array<
        { __typename?: 'StinterRTWStinterStatus' } & RTWStatusFieldsFragment
      >;
    };
};

export type listStinterRightToWorkScreeningQueryVariables = Exact<{
  input?: Maybe<StinterRightToWorkScreeningFilter>;
  perPage?: Maybe<Scalars['Int']>;
  next?: Maybe<Scalars['String']>;
}>;

export type listStinterRightToWorkScreeningQuery = { __typename?: 'Query' } & {
  data: { __typename?: 'StinterRightToWorkScreeningPagination' } & Pick<
    StinterRightToWorkScreeningPagination,
    'next'
  > & {
      items: Array<
        { __typename?: 'StinterRTWScreening' } & RTWScreeningFieldsFragment
      >;
    };
};

export type listStinterRightToWorkStinterScreeningQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type listStinterRightToWorkStinterScreeningQuery = {
  __typename?: 'Query';
} & {
  data: Array<
    { __typename?: 'StinterRTWScreening' } & RTWScreeningFieldsFragment
  >;
};

export type ListStintTemplatesQueryVariables = Exact<{
  businessId: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
}>;

export type ListStintTemplatesQuery = { __typename?: 'Query' } & {
  data?: Maybe<
    { __typename?: 'StintTemplateTypeListType' } & {
      objects?: Maybe<
        Array<
          Maybe<
            { __typename?: 'StintTemplate' } & Pick<
              StintTemplate,
              'id' | 'name'
            >
          >
        >
      >;
    }
  >;
};

export type StaffUserFieldsFragment = {
  __typename?: 'UserRepresentationType';
} & Pick<
  UserRepresentationType,
  'id' | 'username' | 'firstName' | 'lastName' | 'email' | 'enabled'
>;

export type AdminUserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type AdminUserQuery = { __typename?: 'Query' } & {
  data?: Maybe<
    { __typename?: 'UserRepresentationType' } & StaffUserFieldsFragment
  >;
};

export type allAdminUsersQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
}>;

export type allAdminUsersQuery = { __typename?: 'Query' } & {
  items?: Maybe<
    Array<
      Maybe<{ __typename?: 'UserRepresentationType' } & StaffUserFieldsFragment>
    >
  >;
  total?: Maybe<{ __typename: 'ListMetadata' } & Pick<ListMetadata, 'count'>>;
};

export type AdminStintFieldsFragment = { __typename?: 'Stint' } & Pick<
  Stint,
  | 'id'
  | 'name'
  | 'status'
  | 'createdAt'
  | 'dateFrom'
  | 'dateTo'
  | 'checkInDate'
  | 'actualLeaveTime'
  | 'trained'
  | 'controlCheck'
  | 'isBestEffort'
  | 'notes'
  | 'uniform'
  | 'business_id'
  | 'displayAddress'
  | 'student_id'
  | 'cancelledTimeAhead'
  | 'cancelledAt'
  | 'cancelled_by_id'
> & {
    primaryTasks?: Maybe<
      Array<
        Maybe<{ __typename?: 'StintTask' } & Pick<StintTask, 'id' | 'name'>>
      >
    >;
    exceptions?: Maybe<
      Array<
        Maybe<
          { __typename?: 'EmploymentException' } & Pick<
            EmploymentException,
            'id' | 'name'
          >
        >
      >
    >;
    stintRates: { __typename?: 'StintRates' } & Pick<
      StintRates,
      | 'baseFeeOverride'
      | 'totalFeeWithVat'
      | 'cancellationFee'
      | 'cancellationFeeApplied'
      | 'baseRateOverride'
      | 'basePay'
      | 'surgeRate'
      | 'surgePay'
      | 'surgeApplied'
      | 'bonusRate'
      | 'bonusPay'
      | 'bonusApplied'
    >;
  };

export type AdminStintListFieldsFragment = { __typename?: 'Stint' } & Pick<
  Stint,
  | 'id'
  | 'business_id'
  | 'createdAt'
  | 'dateFrom'
  | 'dateTo'
  | 'student_id'
  | 'status'
  | 'isBestEffort'
  | 'controlCheck'
  | 'city_id'
>;

export type allStintsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<StintFilter>;
}>;

export type allStintsQuery = { __typename?: 'Query' } & {
  items?: Maybe<
    Array<Maybe<{ __typename?: 'Stint' } & AdminStintListFieldsFragment>>
  >;
  total?: Maybe<{ __typename: 'ListMetadata' } & Pick<ListMetadata, 'count'>>;
};

export type StintQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type StintQuery = { __typename?: 'Query' } & {
  data?: Maybe<{ __typename?: 'Stint' } & AdminStintFieldsFragment>;
};

export type updateStintMutationVariables = Exact<{
  cancellationFee?: Maybe<Scalars['Float']>;
  cancellationFeeApplied?: Maybe<Scalars['Boolean']>;
  controlCheck?: Maybe<Scalars['Boolean']>;
  dateFrom?: Maybe<Scalars['AWSDateTime']>;
  dateTo?: Maybe<Scalars['AWSDateTime']>;
  checkInDate?: Maybe<Scalars['AWSDateTime']>;
  displayAddress?: Maybe<Scalars['String']>;
  exceptions?: Maybe<Array<Maybe<StintTemplateExceptionInput>>>;
  isBestEffort?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  primaryTasks?: Maybe<Array<Maybe<StintTemplateTaskInput>>>;
  student_id?: Maybe<Scalars['ID']>;
  studentUnassignedReasons?: Maybe<Array<Maybe<StudentUnassignedReasons>>>;
  topTips?: Maybe<Array<Maybe<StintTemplateTopTipInput>>>;
  uniform?: Maybe<Array<Maybe<StintUniform>>>;
  baseFeeOverride?: Maybe<Scalars['Float']>;
  baseRateOverride?: Maybe<Scalars['Float']>;
  surgeRate?: Maybe<Scalars['Float']>;
}>;

export type updateStintMutation = { __typename?: 'Mutation' } & {
  data?: Maybe<{ __typename?: 'Stint' } & AdminStintFieldsFragment>;
};

export type cancelStintsMutationVariables = Exact<{
  stintRefs: Array<Maybe<Scalars['ID']>>;
}>;

export type cancelStintsMutation = { __typename?: 'Mutation' } & {
  data?: Maybe<
    { __typename?: 'CancelStints' } & {
      entries?: Maybe<
        Array<Maybe<{ __typename?: 'Stint' } & Pick<Stint, 'id'>>>
      >;
    }
  >;
};

export type CreateStintsMutationVariables = Exact<{
  createInputs: CreateStintsInput;
}>;

export type CreateStintsMutation = { __typename?: 'Mutation' } & {
  createStints?: Maybe<
    Array<Maybe<{ __typename?: 'Stint' } & Pick<Stint, 'id'>>>
  >;
};

export type AdminStintJourneyFieldsFragment = {
  __typename?: 'StintJourney';
} & Pick<
  StintJourney,
  | 'estimatedTimeOfArrival'
  | 'estimatedTimeOfDeparture'
  | 'id'
  | 'internalNotes'
  | 'lateNote'
  | 'status'
  | 'statusResolved'
  | 'confirmationScreenAccepted'
  | 'isEtaBasedOnLatestLocation'
  | 'isGranularLocationTrackingEnabled'
  | 'usingNewLocationMicroservices'
  | 'student_id'
> & {
    business?: Maybe<
      { __typename?: 'Business' } & Pick<Business, 'id' | 'name'>
    >;
    studentInitialLocation?: Maybe<
      { __typename?: 'DeviceLocation' } & Pick<
        DeviceLocation,
        'longitude' | 'latitude'
      >
    >;
    stint?: Maybe<
      { __typename?: 'Stint' } & Pick<
        Stint,
        | 'longitude'
        | 'latitude'
        | 'business_id'
        | 'checkInDate'
        | 'dateFrom'
        | 'differentStartTimeReasons'
        | 'id'
      > & {
          address?: Maybe<
            { __typename?: 'Address' } & Pick<Address, 'id' | 'name'>
          >;
        }
    >;
  };

export type allStintJourneysQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<StintJourneyFilter>;
}>;

export type allStintJourneysQuery = { __typename?: 'Query' } & {
  items?: Maybe<
    Array<
      Maybe<{ __typename?: 'StintJourney' } & AdminStintJourneyFieldsFragment>
    >
  >;
  total?: Maybe<{ __typename: 'ListMetadata' } & Pick<ListMetadata, 'count'>>;
};

export type StintJourneyQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type StintJourneyQuery = { __typename?: 'Query' } & {
  data?: Maybe<
    { __typename?: 'StintJourney' } & AdminStintJourneyFieldsFragment
  >;
};

export type updateStintJourneyMutationVariables = Exact<{
  id: Scalars['ID'];
  estimatedTimeOfArrival?: Maybe<Scalars['AWSDateTime']>;
  estimatedTimeOfDeparture?: Maybe<Scalars['AWSDateTime']>;
  checkInDate?: Maybe<Scalars['AWSDateTime']>;
  differentStartTimeReasons?: Maybe<Array<Maybe<DifferentStartTimeReason>>>;
  lateNote?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  statusResolved?: Maybe<Scalars['Boolean']>;
}>;

export type updateStintJourneyMutation = { __typename?: 'Mutation' } & {
  data?: Maybe<
    { __typename?: 'StintJourney' } & AdminStintJourneyFieldsFragment
  >;
};

export type resolveStintJourneysMutationVariables = Exact<{
  ids: Array<Scalars['ID']>;
}>;

export type resolveStintJourneysMutation = { __typename?: 'Mutation' } & {
  data?: Maybe<
    Array<
      Maybe<{ __typename?: 'StintJourney' } & AdminStintJourneyFieldsFragment>
    >
  >;
};

export type AdminSingleStintTemplateFieldsFragment = {
  __typename?: 'StintTemplate';
} & Pick<
  StintTemplate,
  | 'id'
  | 'name'
  | 'business_id'
  | 'notes'
  | 'uniform'
  | 'prepVideoUrl'
  | 'trained'
> & {
    primaryTasks?: Maybe<
      Array<
        Maybe<{ __typename?: 'StintTask' } & Pick<StintTask, 'id' | 'name'>>
      >
    >;
    secondaryTasks?: Maybe<
      Array<
        Maybe<{ __typename?: 'StintTask' } & Pick<StintTask, 'id' | 'name'>>
      >
    >;
    exceptions?: Maybe<
      Array<
        Maybe<
          { __typename?: 'EmploymentException' } & Pick<
            EmploymentException,
            'id' | 'name'
          >
        >
      >
    >;
  };

export type AdminStintTemplateFieldsFragment = {
  __typename?: 'StintTemplate';
} & Pick<
  StintTemplate,
  | 'id'
  | 'name'
  | 'business_id'
  | 'notes'
  | 'uniform'
  | 'prepVideoUrl'
  | 'trained'
> & {
    primaryTasks?: Maybe<
      Array<
        Maybe<{ __typename?: 'StintTask' } & Pick<StintTask, 'id' | 'name'>>
      >
    >;
    secondaryTasks?: Maybe<
      Array<
        Maybe<{ __typename?: 'StintTask' } & Pick<StintTask, 'id' | 'name'>>
      >
    >;
    exceptions?: Maybe<
      Array<
        Maybe<
          { __typename?: 'EmploymentException' } & Pick<
            EmploymentException,
            'id' | 'name'
          >
        >
      >
    >;
  };

export type allStintTemplatesQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<StintTemplateFilter>;
}>;

export type allStintTemplatesQuery = { __typename?: 'Query' } & {
  items?: Maybe<
    Array<
      Maybe<{ __typename?: 'StintTemplate' } & AdminStintTemplateFieldsFragment>
    >
  >;
  total?: Maybe<{ __typename: 'ListMetadata' } & Pick<ListMetadata, 'count'>>;
};

export type StintTemplateQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type StintTemplateQuery = { __typename?: 'Query' } & {
  data?: Maybe<
    { __typename?: 'StintTemplate' } & AdminSingleStintTemplateFieldsFragment
  >;
};

export type updateStintTemplateMutationVariables = Exact<{
  input: UpdateStintTemplateInput;
}>;

export type updateStintTemplateMutation = { __typename?: 'Mutation' } & {
  data: {
    __typename?: 'StintTemplate';
  } & AdminSingleStintTemplateFieldsFragment;
};

export type AdminStintopediaFieldsFragment = {
  __typename?: 'Stintopedia';
} & Pick<
  Stintopedia,
  | 'aboutUs'
  | 'bookedOnboardingMeeting'
  | 'businessLogo'
  | 'characteristicIds'
  | 'donts'
  | 'dos'
  | 'emoji'
  | 'exceptionIds'
  | 'facebook'
  | 'generalPhoto1'
  | 'generalPhoto2'
  | 'generalPhoto3'
  | 'generalPhoto4'
  | 'id'
  | 'instagram'
  | 'internalNotes'
  | 'preferredLanguageIds'
  | 'lastEditTime'
  | 'linkedin'
  | 'photoOfEntrance'
  | 'prepVideoUrl'
  | 'qrCodeLocation'
  | 'teamStructure'
  | 'usingStintCorrectly'
  | 'website'
  | 'agreedTakePartInMarketingInitiatives'
  | 'agreedToReferFriendsIfFinancialMetricsImproved'
  | 'agreedToOperateStaffingRota'
  | 'accessToTransactionSalesData'
  | 'accessToRotaExamples'
  | 'accessToAnnualPlAccounts'
  | 'agreedToContinuePostingIfWeSeeDesireResults'
  | 'onboardingComplete'
> & {
    business?: Maybe<
      { __typename?: 'Business' } & Pick<Business, 'id' | 'logo' | 'name'>
    >;
    lastEditBy?: Maybe<
      { __typename?: 'StintUser' } & Pick<
        StintUser,
        'id' | 'email' | 'firstName' | 'lastName'
      >
    >;
  };

export type allStintopediasQueryVariables = Exact<{
  filter?: Maybe<StintopediaFilter>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
}>;

export type allStintopediasQuery = { __typename?: 'Query' } & {
  items?: Maybe<
    Array<
      Maybe<{ __typename?: 'Stintopedia' } & AdminStintopediaFieldsFragment>
    >
  >;
  total?: Maybe<{ __typename: 'ListMetadata' } & Pick<ListMetadata, 'count'>>;
};

export type StintopediaQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type StintopediaQuery = { __typename?: 'Query' } & {
  data?: Maybe<{ __typename?: 'Stintopedia' } & AdminStintopediaFieldsFragment>;
};

export type updateStintopediaMutationVariables = Exact<{
  businessId: Scalars['ID'];
  aboutUs?: Maybe<Scalars['String']>;
  accessToAnnualPlAccounts?: Maybe<Scalars['Boolean']>;
  accessToRotaExamples?: Maybe<Scalars['Boolean']>;
  accessToTransactionSalesData?: Maybe<Scalars['Boolean']>;
  agreedTakePartInMarketingInitiatives?: Maybe<Scalars['Boolean']>;
  agreedToContinuePostingIfWeSeeDesireResults?: Maybe<Scalars['Boolean']>;
  agreedToOperateStaffingRota?: Maybe<Scalars['Boolean']>;
  agreedToReferFriendsIfFinancialMetricsImproved?: Maybe<Scalars['Boolean']>;
  businessLogo?: Maybe<Scalars['String']>;
  characteristicIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  donts?: Maybe<Array<Maybe<Scalars['String']>>>;
  dos?: Maybe<Array<Maybe<Scalars['String']>>>;
  emoji?: Maybe<Scalars['String']>;
  exceptionIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  facebook?: Maybe<Scalars['String']>;
  generalPhoto1?: Maybe<Scalars['String']>;
  generalPhoto2?: Maybe<Scalars['String']>;
  generalPhoto3?: Maybe<Scalars['String']>;
  generalPhoto4?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  linkedin?: Maybe<Scalars['String']>;
  onboardingComplete?: Maybe<Scalars['Boolean']>;
  photoOfEntrance?: Maybe<Scalars['String']>;
  preferredLanguageIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  prepVideoUrl?: Maybe<Scalars['String']>;
  qrCodeLocation?: Maybe<Scalars['String']>;
  teamStructure?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
}>;

export type updateStintopediaMutation = { __typename?: 'Mutation' } & {
  data?: Maybe<{ __typename?: 'Stintopedia' } & AdminStintopediaFieldsFragment>;
};

export type StudentUserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type StudentUserQuery = { __typename?: 'Query' } & {
  mono?: Maybe<
    { __typename?: 'UserRepresentationType' } & Pick<
      UserRepresentationType,
      'id' | 'firstName' | 'lastName' | 'email' | 'emailVerified' | 'enabled'
    > & {
        attributes?: Maybe<
          Array<
            Maybe<
              { __typename?: 'UserAttributeType' } & Pick<
                UserAttributeType,
                'name' | 'value'
              >
            >
          >
        >;
        user?: Maybe<
          { __typename: 'StintUser' } & Pick<
            StintUser,
            | 'id'
            | 'email'
            | 'secondaryEmail'
            | 'locationSetting'
            | 'profilePhotoUrl'
            | 'phoneNumber'
            | 'deactivated'
            | 'isVerifiedByStint'
            | 'permanentDeactivated'
            | 'appVersion'
            | 'dateOfBirth'
            | 'publicRating'
            | 'canSeeSurge'
          > & {
              exceptions?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'EmploymentException' } & Pick<
                      EmploymentException,
                      'id' | 'name'
                    >
                  >
                >
              >;
              studentProfile?: Maybe<
                { __typename: 'StudentProfile' } & Pick<
                  StudentProfile,
                  | 'id'
                  | 'university_id'
                  | 'emergencyContactName'
                  | 'emergencyContactNumber'
                  | 'emergencyContactRelation'
                  | 'employeeStatement'
                  | 'studentLoanPlan'
                  | 'strikesAwarded'
                > & {
                    paymentMethods?: Maybe<
                      Array<
                        Maybe<
                          { __typename: 'DirectDebitPaymentMethod' } & Pick<
                            DirectDebitPaymentMethod,
                            'accountName' | 'accountNumber' | 'sortCode'
                          >
                        >
                      >
                    >;
                  }
              >;
            }
        >;
      }
  >;
  rightToWork?: Maybe<
    { __typename?: 'StinterRTWStatus' } & Pick<
      StinterRTWStatus,
      'until' | 'weeklyCap' | 'approvedAt'
    >
  >;
};

export type allStudentUsersQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
}>;

export type allStudentUsersQuery = { __typename?: 'Query' } & {
  items?: Maybe<
    Array<
      Maybe<
        { __typename?: 'UserRepresentationType' } & Pick<
          UserRepresentationType,
          'id' | 'firstName' | 'lastName' | 'email'
        > & {
            attributes?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'UserAttributeType' } & Pick<
                    UserAttributeType,
                    'name' | 'value'
                  >
                >
              >
            >;
            user?: Maybe<
              { __typename: 'StintUser' } & Pick<
                StintUser,
                'id' | 'dateOfBirth' | 'profilePhotoUrl' | 'appVersion'
              >
            >;
          }
      >
    >
  >;
  total?: Maybe<{ __typename: 'ListMetadata' } & Pick<ListMetadata, 'count'>>;
};

export type AdminStudentReviewFieldsFragment = {
  __typename?: 'StudentReview';
} & Pick<StudentReview, 'id' | 'student_id' | 'rating' | 'isInternalReview'> & {
    stint?: Maybe<
      { __typename?: 'Stint' } & Pick<
        Stint,
        'name' | 'status' | 'dateFrom' | 'dateTo' | 'business_id'
      >
    >;
  };

export type allStudentReviewsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<StudentReviewFilter>;
}>;

export type allStudentReviewsQuery = { __typename?: 'Query' } & {
  items?: Maybe<
    Array<
      Maybe<{ __typename: 'StudentReview' } & AdminStudentReviewFieldsFragment>
    >
  >;
  total?: Maybe<{ __typename: 'ListMetadata' } & Pick<ListMetadata, 'count'>>;
};

export type StudentReviewQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type StudentReviewQuery = { __typename?: 'Query' } & {
  data?: Maybe<
    { __typename: 'StudentReview' } & AdminStudentReviewFieldsFragment
  >;
};

export type AdminTagFieldsFragment = { __typename?: 'Tag' } & Pick<
  Tag,
  'id' | 'name' | 'description' | 'createdAt'
>;

export type allTagsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
}>;

export type allTagsQuery = { __typename?: 'Query' } & {
  items?: Maybe<Array<Maybe<{ __typename?: 'Tag' } & AdminTagFieldsFragment>>>;
  total?: Maybe<{ __typename: 'ListMetadata' } & Pick<ListMetadata, 'count'>>;
};

export type TagQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type TagQuery = { __typename?: 'Query' } & {
  data?: Maybe<{ __typename?: 'Tag' } & AdminTagFieldsFragment>;
};

export type createTagMutationVariables = Exact<{
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
}>;

export type createTagMutation = { __typename?: 'Mutation' } & {
  data?: Maybe<{ __typename?: 'Tag' } & AdminTagFieldsFragment>;
};

export type updateTagMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
}>;

export type updateTagMutation = { __typename?: 'Mutation' } & {
  data?: Maybe<{ __typename?: 'Tag' } & AdminTagFieldsFragment>;
};

export type AdminStintTaskFieldsFragment = { __typename?: 'StintTask' } & Pick<
  StintTask,
  | 'id'
  | 'name'
  | 'taskType'
  | 'totalNumberBusinessesPosted'
  | 'totalNumberPostedTask'
  | 'averageStudentRatingPosted'
  | 'averageBusinessRatingPosted'
> & {
    topTips?: Maybe<
      Array<Maybe<{ __typename?: 'TopTip' } & Pick<TopTip, 'name' | 'id'>>>
    >;
  };

export type allStintTasksQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<StintTaskFilter>;
}>;

export type allStintTasksQuery = { __typename?: 'Query' } & {
  items?: Maybe<
    Array<Maybe<{ __typename?: 'StintTask' } & AdminStintTaskFieldsFragment>>
  >;
  total?: Maybe<{ __typename: 'ListMetadata' } & Pick<ListMetadata, 'count'>>;
};

export type StintTaskQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type StintTaskQuery = { __typename?: 'Query' } & {
  data?: Maybe<{ __typename?: 'StintTask' } & AdminStintTaskFieldsFragment>;
};

export type updateStintTaskMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  taskType?: Maybe<Scalars['String']>;
  topTips?: Maybe<Array<Maybe<TaskTopTipInput>>>;
}>;

export type updateStintTaskMutation = { __typename?: 'Mutation' } & {
  data?: Maybe<{ __typename?: 'StintTask' } & AdminStintTaskFieldsFragment>;
};

export type createStintTaskMutationVariables = Exact<{
  name: Scalars['String'];
  taskType?: Maybe<Scalars['String']>;
}>;

export type createStintTaskMutation = { __typename?: 'Mutation' } & {
  data?: Maybe<{ __typename?: 'StintTask' } & AdminStintTaskFieldsFragment>;
};

export type AdminTopTipFieldsFragment = { __typename?: 'TopTip' } & Pick<
  TopTip,
  'id' | 'name' | 'task_id'
>;

export type allTopTipsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<TopTipFilter>;
}>;

export type allTopTipsQuery = { __typename?: 'Query' } & {
  items?: Maybe<
    Array<Maybe<{ __typename?: 'TopTip' } & AdminTopTipFieldsFragment>>
  >;
  total?: Maybe<{ __typename: 'ListMetadata' } & Pick<ListMetadata, 'count'>>;
};

export type TopTipQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type TopTipQuery = { __typename?: 'Query' } & {
  data?: Maybe<{ __typename?: 'TopTip' } & AdminTopTipFieldsFragment>;
};

export type updateTopTipMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
}>;

export type updateTopTipMutation = { __typename?: 'Mutation' } & {
  data?: Maybe<{ __typename?: 'TopTip' } & AdminTopTipFieldsFragment>;
};

export type createTopTipMutationVariables = Exact<{
  name: Scalars['String'];
  isDefault?: Maybe<Scalars['Boolean']>;
  taskId?: Maybe<Scalars['ID']>;
}>;

export type createTopTipMutation = { __typename?: 'Mutation' } & {
  data?: Maybe<{ __typename?: 'TopTip' } & AdminTopTipFieldsFragment>;
};

export type AdminStintUserFieldsFragment = { __typename?: 'StintUser' } & Pick<
  StintUser,
  | 'id'
  | 'firstName'
  | 'lastName'
  | 'locationSetting'
  | 'userType'
  | 'fullname'
  | 'profilePhotoUrl'
  | 'phoneNumber'
  | 'deactivated'
  | 'isVerifiedByStint'
  | 'isEmailVerified'
  | 'otp'
  | 'permanentDeactivated'
  | 'email'
  | 'secondaryEmail'
  | 'appVersion'
  | 'dateOfBirth'
  | 'isStudent'
  | 'isEmployer'
  | 'publicRating'
  | 'canSeeSurge'
> & {
    city?: Maybe<{ __typename?: 'City' } & Pick<City, 'name'>>;
    currentCity?: Maybe<{ __typename?: 'City' } & Pick<City, 'name' | 'id'>>;
    exceptions?: Maybe<
      Array<
        Maybe<
          { __typename?: 'EmploymentException' } & Pick<
            EmploymentException,
            'id' | 'name'
          >
        >
      >
    >;
    studentProfile?: Maybe<
      { __typename?: 'StudentProfile' } & Pick<
        StudentProfile,
        | 'id'
        | 'university_id'
        | 'emergencyContactName'
        | 'emergencyContactNumber'
        | 'emergencyContactRelation'
        | 'employeeStatement'
        | 'studentLoanPlan'
        | 'strikesAwarded'
      > & {
          paymentMethods?: Maybe<
            Array<
              Maybe<
                { __typename?: 'DirectDebitPaymentMethod' } & Pick<
                  DirectDebitPaymentMethod,
                  'accountName' | 'accountNumber' | 'sortCode'
                >
              >
            >
          >;
        }
    >;
  };

export type allStudentOrEmployerUsersQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<StintUserFilter>;
}>;

export type allStudentOrEmployerUsersQuery = { __typename?: 'Query' } & {
  items?: Maybe<
    Array<
      Maybe<
        { __typename?: 'StintUser' } & Pick<
          StintUser,
          'id' | 'firstName' | 'lastName' | 'fullname' | 'email'
        >
      >
    >
  >;
  total?: Maybe<{ __typename: 'ListMetadata' } & Pick<ListMetadata, 'count'>>;
};

export type StintUserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type StintUserQuery = { __typename?: 'Query' } & {
  mono?: Maybe<{ __typename?: 'StintUser' } & AdminStintUserFieldsFragment>;
  rightToWork?: Maybe<
    { __typename?: 'StinterRTWStatus' } & Pick<
      StinterRTWStatus,
      'until' | 'weeklyCap' | 'approvedAt'
    >
  >;
};

export type updateStintUserMutationVariables = Exact<{
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['AWSDate']>;
  email?: Maybe<Scalars['String']>;
  secondaryEmail?: Maybe<Scalars['String']>;
  exceptions?: Maybe<Array<Maybe<StintTemplateExceptionInput>>>;
  phoneNumber?: Maybe<Scalars['String']>;
  deactivated?: Maybe<Scalars['Boolean']>;
  permanentDeactivated?: Maybe<Scalars['Boolean']>;
  studentProfile?: Maybe<StudentProfileInput>;
  paymentMethod?: Maybe<PaymentMethodInput>;
}>;

export type updateStintUserMutation = { __typename?: 'Mutation' } & {
  data?: Maybe<{ __typename?: 'StintUser' } & AdminStintUserFieldsFragment>;
};

export const AdminAddressFieldsFragmentDoc = gql`
  fragment AdminAddressFields on Address {
    id
    name
    placeId
  }
`;
export const AdminBusinessFieldsFragmentDoc = gql`
  fragment AdminBusinessFields on Business {
    id
    address {
      cityFk {
        id
        name
      }
      postalCode
    }
    city {
      id
      name
      baseFee
    }
    averageRating
    createdAt
    ltdName
    displayAddress
    exemptFromPaymentDetails
    integrated
    name
    logo
    repeatStintersEnabled
    phoneNumber
    placeId
    qrCodeId
    rateOverride {
      rate
    }
    salesRepresentitive {
      id
      email
      firstName
      lastName
    }
    status
    unlockedLastMinuteStints
    controlCheck
    isBlockedByFinance
    stintopedia {
      id
      bookedOnboardingMeeting
      usingStintCorrectly
    }
  }
`;
export const AdminCharacteristicFieldsFragmentDoc = gql`
  fragment AdminCharacteristicFields on Characteristic {
    id
    name
  }
`;
export const AdminCityFieldsFragmentDoc = gql`
  fragment AdminCityFields on City {
    baseFee
    baseFeeStudent
    name
    id
    isActive
    isAvailabilityEnabled
    sessionUrl
    shortCode
    state
    postalCodeIds
    region {
      name
      id
      country {
        name
        id
      }
    }
    regionId
  }
`;
export const AdminCountryFieldsFragmentDoc = gql`
  fragment AdminCountryFields on Country {
    id
    name
    shortCode
    regions
  }
`;
export const AdminDeviceLocationFieldsFragmentDoc = gql`
  fragment AdminDeviceLocationFields on DeviceLocation {
    id
    accuracy
    batteryLevel
    recordedAt
    longitude
    latitude
    heading
    speed
  }
`;
export const AdminEditableSettingFieldsFragmentDoc = gql`
  fragment AdminEditableSettingFields on EditableSetting {
    id
    predictionLeadDays
    notificationBlockerWindowHours
    geoTrackingBufferTime
    advancedOperationalWindowStartTime
    advancedOperationalWindowEndTime
    automatedTestingEnabled
    isTimeManipulationEnabled
    serverDatetime
    featureFlagStudentGoingToBeLateSmsEnabled
    featureFlagStintSecondStartReminderEnabled
    availabilityMatchingBufferHours
    availabilityMatchingMaxOffersMonday
    availabilityMatchingMaxOffersTuesday
    availabilityMatchingMaxOffersWednesday
    availabilityMatchingMaxOffersThursday
    availabilityMatchingMaxOffersFriday
    availabilityMatchingMaxOffersSaturday
    availabilityMatchingMaxOffersSunday
    availabilityMatchingNotificationForNoOffersEnabled
    matchingAlgoNoOfferMaxCountPerTimeWindow
    matchingAlgoNoOfferTimeWindowSpan
    matchingAlgoNoOfferTitle
    matchingAlgoNoOfferMessage
    matchingAlgoNotificationDelayInMinutes
    featureFlagEtdNotificationsEnabled
    sendingStudentLeaveReminderAtRouteTimeEnabled
    studentCancellationOnHoldEmailEnabled
    etdCalculationBeforeMiddayOffsetInMinutes
    etdCalculationAfterMiddayOffsetInMinutes
    stintOfferExpiryThresholdInHours
    notificationTopUpThresholdInHours
  }
`;
export const EmployerUserFieldsFragmentDoc = gql`
  fragment EmployerUserFields on UserRepresentationType {
    id
    firstName
    lastName
    email
    emailVerified
    enabled
    user {
      id
      profilePhotoUrl
      phoneNumber
      deactivated
      permanentDeactivated
      appVersion
      __typename
    }
  }
`;
export const AdminEmploymentExceptionFieldsFragmentDoc = gql`
  fragment AdminEmploymentExceptionFields on EmploymentException {
    id
    name
    category
  }
`;
export const AdminLanguageFieldsFragmentDoc = gql`
  fragment AdminLanguageFields on Language {
    id
    name
  }
`;
export const AdminNotificationFieldsFragmentDoc = gql`
  fragment AdminNotificationFields on Notification {
    id
    createdAt
    status
    errorMessage
    title
    message
    stint {
      id
      name
    }
    business {
      id
      name
    }
    sendTextMessage
    sendPushNotification
    recipientsCount
    openedCount
    notificationType
  }
`;
export const OffenceBasicFieldsFragmentDoc = gql`
  fragment OffenceBasicFields on Offence {
    id
    appealStatus
    business {
      id
      name
    }
    reason
    stint {
      id
      stintTimesDisplay
    }
    strikesAwarded
  }
`;
export const AdminPeriodicTaskFieldsFragmentDoc = gql`
  fragment AdminPeriodicTaskFields on PeriodicTask {
    id
    name
    lastRunAt
    enabled
    priority
    startTime
    totalRunCount
    dateChanged
  }
`;
export const AdminPostalCodeFieldsFragmentDoc = gql`
  fragment AdminPostalCodeFields on PostalCode {
    postalCode
    id
    city_id
  }
`;
export const AdminRegionFieldsFragmentDoc = gql`
  fragment AdminRegionFields on Region {
    id
    name
    country {
      name
      id
    }
    countryId
  }
`;
export const RTWStatusFieldsFragmentDoc = gql`
  fragment RTWStatusFields on StinterRTWStinterStatus {
    id
    stinter {
      id
    }
    state
    updatedAt
  }
`;
export const RTWScreeningFieldsFragmentDoc = gql`
  fragment RTWScreeningFields on StinterRTWScreening {
    stinter {
      id
    }
    id
    type
    status
    updatedAt
    error
  }
`;
export const StaffUserFieldsFragmentDoc = gql`
  fragment StaffUserFields on UserRepresentationType {
    id
    username
    firstName
    lastName
    email
    enabled
  }
`;
export const AdminStintFieldsFragmentDoc = gql`
  fragment AdminStintFields on Stint {
    id
    name
    status
    createdAt
    dateFrom
    dateTo
    checkInDate
    actualLeaveTime
    trained
    controlCheck
    isBestEffort
    notes
    primaryTasks {
      id
      name
    }
    exceptions {
      id
      name
    }
    uniform
    business_id
    displayAddress
    student_id
    stintRates {
      baseFeeOverride
      totalFeeWithVat
      cancellationFee
      cancellationFeeApplied
      baseRateOverride
      basePay
      surgeRate
      surgePay
      surgeApplied
      bonusRate
      bonusPay
      bonusApplied
    }
    cancelledTimeAhead
    cancelledAt
    cancelled_by_id
  }
`;
export const AdminStintListFieldsFragmentDoc = gql`
  fragment AdminStintListFields on Stint {
    id
    business_id
    createdAt
    dateFrom
    dateTo
    student_id
    status
    isBestEffort
    controlCheck
    city_id
  }
`;
export const AdminStintJourneyFieldsFragmentDoc = gql`
  fragment AdminStintJourneyFields on StintJourney {
    estimatedTimeOfArrival
    estimatedTimeOfDeparture
    id
    internalNotes
    business {
      id
      name
    }
    lateNote
    status
    statusResolved
    confirmationScreenAccepted
    studentInitialLocation {
      longitude
      latitude
    }
    isEtaBasedOnLatestLocation
    isGranularLocationTrackingEnabled
    usingNewLocationMicroservices
    stint {
      longitude
      latitude
      business_id
      checkInDate
      dateFrom
      differentStartTimeReasons
      id
      address {
        id
        name
      }
    }
    student_id
  }
`;
export const AdminSingleStintTemplateFieldsFragmentDoc = gql`
  fragment AdminSingleStintTemplateFields on StintTemplate {
    id
    name
    business_id
    notes
    uniform
    primaryTasks {
      id
      name
    }
    secondaryTasks {
      id
      name
    }
    exceptions {
      id
      name
    }
    prepVideoUrl
    trained
  }
`;
export const AdminStintTemplateFieldsFragmentDoc = gql`
  fragment AdminStintTemplateFields on StintTemplate {
    id
    name
    business_id
    notes
    uniform
    primaryTasks {
      id
      name
    }
    secondaryTasks {
      id
      name
    }
    exceptions {
      id
      name
    }
    prepVideoUrl
    trained
  }
`;
export const AdminStintopediaFieldsFragmentDoc = gql`
  fragment AdminStintopediaFields on Stintopedia {
    aboutUs
    bookedOnboardingMeeting
    business {
      id
      logo
      name
    }
    businessLogo
    characteristicIds
    donts
    dos
    emoji
    exceptionIds
    facebook
    generalPhoto1
    generalPhoto2
    generalPhoto3
    generalPhoto4
    id
    instagram
    internalNotes
    preferredLanguageIds
    lastEditBy {
      id
      email
      firstName
      lastName
    }
    lastEditTime
    linkedin
    photoOfEntrance
    prepVideoUrl
    qrCodeLocation
    teamStructure
    usingStintCorrectly
    website
    agreedTakePartInMarketingInitiatives
    agreedToReferFriendsIfFinancialMetricsImproved
    agreedToOperateStaffingRota
    accessToTransactionSalesData
    accessToRotaExamples
    accessToAnnualPlAccounts
    agreedToContinuePostingIfWeSeeDesireResults
    onboardingComplete
  }
`;
export const AdminStudentReviewFieldsFragmentDoc = gql`
  fragment AdminStudentReviewFields on StudentReview {
    id
    stint {
      name
      status
      dateFrom
      dateTo
      business_id
    }
    student_id
    rating
    isInternalReview
  }
`;
export const AdminTagFieldsFragmentDoc = gql`
  fragment AdminTagFields on Tag {
    id
    name
    description
    createdAt
  }
`;
export const AdminStintTaskFieldsFragmentDoc = gql`
  fragment AdminStintTaskFields on StintTask {
    id
    name
    taskType
    totalNumberBusinessesPosted
    totalNumberPostedTask
    averageStudentRatingPosted
    averageBusinessRatingPosted
    topTips {
      name
      id
    }
  }
`;
export const AdminTopTipFieldsFragmentDoc = gql`
  fragment AdminTopTipFields on TopTip {
    id
    name
    task_id
  }
`;
export const AdminStintUserFieldsFragmentDoc = gql`
  fragment AdminStintUserFields on StintUser {
    id
    firstName
    lastName
    locationSetting
    userType
    fullname
    profilePhotoUrl
    phoneNumber
    deactivated
    isVerifiedByStint
    isEmailVerified
    otp
    city {
      name
    }
    currentCity {
      name
      id
    }
    permanentDeactivated
    email
    secondaryEmail
    appVersion
    dateOfBirth
    isStudent
    exceptions {
      id
      name
    }
    isEmployer
    studentProfile {
      id
      university_id
      emergencyContactName
      emergencyContactNumber
      emergencyContactRelation
      employeeStatement
      studentLoanPlan
      strikesAwarded
      paymentMethods {
        accountName
        accountNumber
        sortCode
      }
    }
    publicRating
    canSeeSurge
  }
`;
export const BulkUpdateCanSeeSurgeDocument = gql`
  mutation BulkUpdateCanSeeSurge($canSeeSurge: Boolean!, $userFile: String!) {
    bulkUpdateCanSeeSurge(canSeeSurge: $canSeeSurge, userFile: $userFile) {
      message
      success
    }
  }
`;
export type BulkUpdateCanSeeSurgeMutationFn = ApolloReactCommon.MutationFunction<
  BulkUpdateCanSeeSurgeMutation,
  BulkUpdateCanSeeSurgeMutationVariables
>;
export type BulkUpdateCanSeeSurgeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    BulkUpdateCanSeeSurgeMutation,
    BulkUpdateCanSeeSurgeMutationVariables
  >,
  'mutation'
>;

export const BulkUpdateCanSeeSurgeComponent = (
  props: BulkUpdateCanSeeSurgeComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    BulkUpdateCanSeeSurgeMutation,
    BulkUpdateCanSeeSurgeMutationVariables
  >
    mutation={BulkUpdateCanSeeSurgeDocument}
    {...props}
  />
);

export type BulkUpdateCanSeeSurgeProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    BulkUpdateCanSeeSurgeMutation,
    BulkUpdateCanSeeSurgeMutationVariables
  >;
} &
  TChildProps;
export function withBulkUpdateCanSeeSurge<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    BulkUpdateCanSeeSurgeMutation,
    BulkUpdateCanSeeSurgeMutationVariables,
    BulkUpdateCanSeeSurgeProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    BulkUpdateCanSeeSurgeMutation,
    BulkUpdateCanSeeSurgeMutationVariables,
    BulkUpdateCanSeeSurgeProps<TChildProps, TDataName>
  >(BulkUpdateCanSeeSurgeDocument, {
    alias: 'bulkUpdateCanSeeSurge',
    ...operationOptions,
  });
}

/**
 * __useBulkUpdateCanSeeSurgeMutation__
 *
 * To run a mutation, you first call `useBulkUpdateCanSeeSurgeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateCanSeeSurgeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateCanSeeSurgeMutation, { data, loading, error }] = useBulkUpdateCanSeeSurgeMutation({
 *   variables: {
 *      canSeeSurge: // value for 'canSeeSurge'
 *      userFile: // value for 'userFile'
 *   },
 * });
 */
export function useBulkUpdateCanSeeSurgeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    BulkUpdateCanSeeSurgeMutation,
    BulkUpdateCanSeeSurgeMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    BulkUpdateCanSeeSurgeMutation,
    BulkUpdateCanSeeSurgeMutationVariables
  >(BulkUpdateCanSeeSurgeDocument, baseOptions);
}
export type BulkUpdateCanSeeSurgeMutationHookResult = ReturnType<
  typeof useBulkUpdateCanSeeSurgeMutation
>;
export type BulkUpdateCanSeeSurgeMutationResult = ApolloReactCommon.MutationResult<BulkUpdateCanSeeSurgeMutation>;
export type BulkUpdateCanSeeSurgeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  BulkUpdateCanSeeSurgeMutation,
  BulkUpdateCanSeeSurgeMutationVariables
>;
export const ManualGeotrackingStatusUpdateDocument = gql`
  mutation ManualGeotrackingStatusUpdate($id: ID!) {
    manualGeotrackingStatusUpdate(id: $id) {
      id
    }
  }
`;
export type ManualGeotrackingStatusUpdateMutationFn = ApolloReactCommon.MutationFunction<
  ManualGeotrackingStatusUpdateMutation,
  ManualGeotrackingStatusUpdateMutationVariables
>;
export type ManualGeotrackingStatusUpdateComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ManualGeotrackingStatusUpdateMutation,
    ManualGeotrackingStatusUpdateMutationVariables
  >,
  'mutation'
>;

export const ManualGeotrackingStatusUpdateComponent = (
  props: ManualGeotrackingStatusUpdateComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    ManualGeotrackingStatusUpdateMutation,
    ManualGeotrackingStatusUpdateMutationVariables
  >
    mutation={ManualGeotrackingStatusUpdateDocument}
    {...props}
  />
);

export type ManualGeotrackingStatusUpdateProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    ManualGeotrackingStatusUpdateMutation,
    ManualGeotrackingStatusUpdateMutationVariables
  >;
} &
  TChildProps;
export function withManualGeotrackingStatusUpdate<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ManualGeotrackingStatusUpdateMutation,
    ManualGeotrackingStatusUpdateMutationVariables,
    ManualGeotrackingStatusUpdateProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ManualGeotrackingStatusUpdateMutation,
    ManualGeotrackingStatusUpdateMutationVariables,
    ManualGeotrackingStatusUpdateProps<TChildProps, TDataName>
  >(ManualGeotrackingStatusUpdateDocument, {
    alias: 'manualGeotrackingStatusUpdate',
    ...operationOptions,
  });
}

/**
 * __useManualGeotrackingStatusUpdateMutation__
 *
 * To run a mutation, you first call `useManualGeotrackingStatusUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManualGeotrackingStatusUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manualGeotrackingStatusUpdateMutation, { data, loading, error }] = useManualGeotrackingStatusUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useManualGeotrackingStatusUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ManualGeotrackingStatusUpdateMutation,
    ManualGeotrackingStatusUpdateMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    ManualGeotrackingStatusUpdateMutation,
    ManualGeotrackingStatusUpdateMutationVariables
  >(ManualGeotrackingStatusUpdateDocument, baseOptions);
}
export type ManualGeotrackingStatusUpdateMutationHookResult = ReturnType<
  typeof useManualGeotrackingStatusUpdateMutation
>;
export type ManualGeotrackingStatusUpdateMutationResult = ApolloReactCommon.MutationResult<ManualGeotrackingStatusUpdateMutation>;
export type ManualGeotrackingStatusUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ManualGeotrackingStatusUpdateMutation,
  ManualGeotrackingStatusUpdateMutationVariables
>;
export const SearchGooglePlacesDocument = gql`
  mutation SearchGooglePlaces(
    $searchTerm: String!
    $returnType: GooglePlacesReturnType
  ) {
    searchGooglePlaces(searchTerm: $searchTerm, returnType: $returnType) {
      predictions {
        placeId
        displayAddress
      }
    }
  }
`;
export type SearchGooglePlacesMutationFn = ApolloReactCommon.MutationFunction<
  SearchGooglePlacesMutation,
  SearchGooglePlacesMutationVariables
>;
export type SearchGooglePlacesComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    SearchGooglePlacesMutation,
    SearchGooglePlacesMutationVariables
  >,
  'mutation'
>;

export const SearchGooglePlacesComponent = (
  props: SearchGooglePlacesComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    SearchGooglePlacesMutation,
    SearchGooglePlacesMutationVariables
  >
    mutation={SearchGooglePlacesDocument}
    {...props}
  />
);

export type SearchGooglePlacesProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    SearchGooglePlacesMutation,
    SearchGooglePlacesMutationVariables
  >;
} &
  TChildProps;
export function withSearchGooglePlaces<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SearchGooglePlacesMutation,
    SearchGooglePlacesMutationVariables,
    SearchGooglePlacesProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    SearchGooglePlacesMutation,
    SearchGooglePlacesMutationVariables,
    SearchGooglePlacesProps<TChildProps, TDataName>
  >(SearchGooglePlacesDocument, {
    alias: 'searchGooglePlaces',
    ...operationOptions,
  });
}

/**
 * __useSearchGooglePlacesMutation__
 *
 * To run a mutation, you first call `useSearchGooglePlacesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSearchGooglePlacesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [searchGooglePlacesMutation, { data, loading, error }] = useSearchGooglePlacesMutation({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      returnType: // value for 'returnType'
 *   },
 * });
 */
export function useSearchGooglePlacesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SearchGooglePlacesMutation,
    SearchGooglePlacesMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    SearchGooglePlacesMutation,
    SearchGooglePlacesMutationVariables
  >(SearchGooglePlacesDocument, baseOptions);
}
export type SearchGooglePlacesMutationHookResult = ReturnType<
  typeof useSearchGooglePlacesMutation
>;
export type SearchGooglePlacesMutationResult = ApolloReactCommon.MutationResult<SearchGooglePlacesMutation>;
export type SearchGooglePlacesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SearchGooglePlacesMutation,
  SearchGooglePlacesMutationVariables
>;
export const sendOtpEmailDocument = gql`
  mutation sendOtpEmail($email: String!) {
    sendOtpEmail(email: $email) {
      success
      __typename
    }
  }
`;
export type sendOtpEmailMutationFn = ApolloReactCommon.MutationFunction<
  sendOtpEmailMutation,
  sendOtpEmailMutationVariables
>;
export type sendOtpEmailComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    sendOtpEmailMutation,
    sendOtpEmailMutationVariables
  >,
  'mutation'
>;

export const sendOtpEmailComponent = (props: sendOtpEmailComponentProps) => (
  <ApolloReactComponents.Mutation<
    sendOtpEmailMutation,
    sendOtpEmailMutationVariables
  >
    mutation={sendOtpEmailDocument}
    {...props}
  />
);

export type sendOtpEmailProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    sendOtpEmailMutation,
    sendOtpEmailMutationVariables
  >;
} &
  TChildProps;
export function withsendOtpEmail<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    sendOtpEmailMutation,
    sendOtpEmailMutationVariables,
    sendOtpEmailProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    sendOtpEmailMutation,
    sendOtpEmailMutationVariables,
    sendOtpEmailProps<TChildProps, TDataName>
  >(sendOtpEmailDocument, {
    alias: 'sendOtpEmail',
    ...operationOptions,
  });
}

/**
 * __usesendOtpEmailMutation__
 *
 * To run a mutation, you first call `usesendOtpEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usesendOtpEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendOtpEmailMutation, { data, loading, error }] = usesendOtpEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function usesendOtpEmailMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    sendOtpEmailMutation,
    sendOtpEmailMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    sendOtpEmailMutation,
    sendOtpEmailMutationVariables
  >(sendOtpEmailDocument, baseOptions);
}
export type sendOtpEmailMutationHookResult = ReturnType<
  typeof usesendOtpEmailMutation
>;
export type sendOtpEmailMutationResult = ApolloReactCommon.MutationResult<sendOtpEmailMutation>;
export type sendOtpEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<
  sendOtpEmailMutation,
  sendOtpEmailMutationVariables
>;
export const SendOtpSmsV2Document = gql`
  mutation SendOtpSmsV2($userId: String!) {
    sendOtpSmsV2(userId: $userId) {
      success
    }
  }
`;
export type SendOtpSmsV2MutationFn = ApolloReactCommon.MutationFunction<
  SendOtpSmsV2Mutation,
  SendOtpSmsV2MutationVariables
>;
export type SendOtpSmsV2ComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    SendOtpSmsV2Mutation,
    SendOtpSmsV2MutationVariables
  >,
  'mutation'
>;

export const SendOtpSmsV2Component = (props: SendOtpSmsV2ComponentProps) => (
  <ApolloReactComponents.Mutation<
    SendOtpSmsV2Mutation,
    SendOtpSmsV2MutationVariables
  >
    mutation={SendOtpSmsV2Document}
    {...props}
  />
);

export type SendOtpSmsV2Props<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    SendOtpSmsV2Mutation,
    SendOtpSmsV2MutationVariables
  >;
} &
  TChildProps;
export function withSendOtpSmsV2<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SendOtpSmsV2Mutation,
    SendOtpSmsV2MutationVariables,
    SendOtpSmsV2Props<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    SendOtpSmsV2Mutation,
    SendOtpSmsV2MutationVariables,
    SendOtpSmsV2Props<TChildProps, TDataName>
  >(SendOtpSmsV2Document, {
    alias: 'sendOtpSmsV2',
    ...operationOptions,
  });
}

/**
 * __useSendOtpSmsV2Mutation__
 *
 * To run a mutation, you first call `useSendOtpSmsV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendOtpSmsV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendOtpSmsV2Mutation, { data, loading, error }] = useSendOtpSmsV2Mutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSendOtpSmsV2Mutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SendOtpSmsV2Mutation,
    SendOtpSmsV2MutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    SendOtpSmsV2Mutation,
    SendOtpSmsV2MutationVariables
  >(SendOtpSmsV2Document, baseOptions);
}
export type SendOtpSmsV2MutationHookResult = ReturnType<
  typeof useSendOtpSmsV2Mutation
>;
export type SendOtpSmsV2MutationResult = ApolloReactCommon.MutationResult<SendOtpSmsV2Mutation>;
export type SendOtpSmsV2MutationOptions = ApolloReactCommon.BaseMutationOptions<
  SendOtpSmsV2Mutation,
  SendOtpSmsV2MutationVariables
>;
export const verifyStudentUserDocument = gql`
  mutation verifyStudentUser($studentUserIds: [ID]!) {
    data: verifyStudentUser(studentUserIds: $studentUserIds) {
      success
    }
  }
`;
export type verifyStudentUserMutationFn = ApolloReactCommon.MutationFunction<
  verifyStudentUserMutation,
  verifyStudentUserMutationVariables
>;
export type verifyStudentUserComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    verifyStudentUserMutation,
    verifyStudentUserMutationVariables
  >,
  'mutation'
>;

export const verifyStudentUserComponent = (
  props: verifyStudentUserComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    verifyStudentUserMutation,
    verifyStudentUserMutationVariables
  >
    mutation={verifyStudentUserDocument}
    {...props}
  />
);

export type verifyStudentUserProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    verifyStudentUserMutation,
    verifyStudentUserMutationVariables
  >;
} &
  TChildProps;
export function withverifyStudentUser<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    verifyStudentUserMutation,
    verifyStudentUserMutationVariables,
    verifyStudentUserProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    verifyStudentUserMutation,
    verifyStudentUserMutationVariables,
    verifyStudentUserProps<TChildProps, TDataName>
  >(verifyStudentUserDocument, {
    alias: 'verifyStudentUser',
    ...operationOptions,
  });
}

/**
 * __useverifyStudentUserMutation__
 *
 * To run a mutation, you first call `useverifyStudentUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useverifyStudentUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyStudentUserMutation, { data, loading, error }] = useverifyStudentUserMutation({
 *   variables: {
 *      studentUserIds: // value for 'studentUserIds'
 *   },
 * });
 */
export function useverifyStudentUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    verifyStudentUserMutation,
    verifyStudentUserMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    verifyStudentUserMutation,
    verifyStudentUserMutationVariables
  >(verifyStudentUserDocument, baseOptions);
}
export type verifyStudentUserMutationHookResult = ReturnType<
  typeof useverifyStudentUserMutation
>;
export type verifyStudentUserMutationResult = ApolloReactCommon.MutationResult<verifyStudentUserMutation>;
export type verifyStudentUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  verifyStudentUserMutation,
  verifyStudentUserMutationVariables
>;
export const CountStudentsCanSeeSurgeDocument = gql`
  query CountStudentsCanSeeSurge {
    countStudentsCanSeeSurge
  }
`;
export type CountStudentsCanSeeSurgeComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    CountStudentsCanSeeSurgeQuery,
    CountStudentsCanSeeSurgeQueryVariables
  >,
  'query'
>;

export const CountStudentsCanSeeSurgeComponent = (
  props: CountStudentsCanSeeSurgeComponentProps,
) => (
  <ApolloReactComponents.Query<
    CountStudentsCanSeeSurgeQuery,
    CountStudentsCanSeeSurgeQueryVariables
  >
    query={CountStudentsCanSeeSurgeDocument}
    {...props}
  />
);

export type CountStudentsCanSeeSurgeProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    CountStudentsCanSeeSurgeQuery,
    CountStudentsCanSeeSurgeQueryVariables
  >;
} &
  TChildProps;
export function withCountStudentsCanSeeSurge<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CountStudentsCanSeeSurgeQuery,
    CountStudentsCanSeeSurgeQueryVariables,
    CountStudentsCanSeeSurgeProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CountStudentsCanSeeSurgeQuery,
    CountStudentsCanSeeSurgeQueryVariables,
    CountStudentsCanSeeSurgeProps<TChildProps, TDataName>
  >(CountStudentsCanSeeSurgeDocument, {
    alias: 'countStudentsCanSeeSurge',
    ...operationOptions,
  });
}

/**
 * __useCountStudentsCanSeeSurgeQuery__
 *
 * To run a query within a React component, call `useCountStudentsCanSeeSurgeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountStudentsCanSeeSurgeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountStudentsCanSeeSurgeQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountStudentsCanSeeSurgeQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CountStudentsCanSeeSurgeQuery,
    CountStudentsCanSeeSurgeQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    CountStudentsCanSeeSurgeQuery,
    CountStudentsCanSeeSurgeQueryVariables
  >(CountStudentsCanSeeSurgeDocument, baseOptions);
}
export function useCountStudentsCanSeeSurgeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CountStudentsCanSeeSurgeQuery,
    CountStudentsCanSeeSurgeQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    CountStudentsCanSeeSurgeQuery,
    CountStudentsCanSeeSurgeQueryVariables
  >(CountStudentsCanSeeSurgeDocument, baseOptions);
}
export type CountStudentsCanSeeSurgeQueryHookResult = ReturnType<
  typeof useCountStudentsCanSeeSurgeQuery
>;
export type CountStudentsCanSeeSurgeLazyQueryHookResult = ReturnType<
  typeof useCountStudentsCanSeeSurgeLazyQuery
>;
export type CountStudentsCanSeeSurgeQueryResult = ApolloReactCommon.QueryResult<
  CountStudentsCanSeeSurgeQuery,
  CountStudentsCanSeeSurgeQueryVariables
>;
export const stinterRightToWorkStatusDocument = gql`
  query stinterRightToWorkStatus($id: ID!) {
    getStinterRightToWorkStatus(stinterID: $id) {
      until
      weeklyCap
      approvedAt
    }
  }
`;
export type stinterRightToWorkStatusComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    stinterRightToWorkStatusQuery,
    stinterRightToWorkStatusQueryVariables
  >,
  'query'
> &
  (
    | { variables: stinterRightToWorkStatusQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const stinterRightToWorkStatusComponent = (
  props: stinterRightToWorkStatusComponentProps,
) => (
  <ApolloReactComponents.Query<
    stinterRightToWorkStatusQuery,
    stinterRightToWorkStatusQueryVariables
  >
    query={stinterRightToWorkStatusDocument}
    {...props}
  />
);

export type stinterRightToWorkStatusProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    stinterRightToWorkStatusQuery,
    stinterRightToWorkStatusQueryVariables
  >;
} &
  TChildProps;
export function withstinterRightToWorkStatus<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    stinterRightToWorkStatusQuery,
    stinterRightToWorkStatusQueryVariables,
    stinterRightToWorkStatusProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    stinterRightToWorkStatusQuery,
    stinterRightToWorkStatusQueryVariables,
    stinterRightToWorkStatusProps<TChildProps, TDataName>
  >(stinterRightToWorkStatusDocument, {
    alias: 'stinterRightToWorkStatus',
    ...operationOptions,
  });
}

/**
 * __usestinterRightToWorkStatusQuery__
 *
 * To run a query within a React component, call `usestinterRightToWorkStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `usestinterRightToWorkStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usestinterRightToWorkStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usestinterRightToWorkStatusQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    stinterRightToWorkStatusQuery,
    stinterRightToWorkStatusQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    stinterRightToWorkStatusQuery,
    stinterRightToWorkStatusQueryVariables
  >(stinterRightToWorkStatusDocument, baseOptions);
}
export function usestinterRightToWorkStatusLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    stinterRightToWorkStatusQuery,
    stinterRightToWorkStatusQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    stinterRightToWorkStatusQuery,
    stinterRightToWorkStatusQueryVariables
  >(stinterRightToWorkStatusDocument, baseOptions);
}
export type stinterRightToWorkStatusQueryHookResult = ReturnType<
  typeof usestinterRightToWorkStatusQuery
>;
export type stinterRightToWorkStatusLazyQueryHookResult = ReturnType<
  typeof usestinterRightToWorkStatusLazyQuery
>;
export type stinterRightToWorkStatusQueryResult = ApolloReactCommon.QueryResult<
  stinterRightToWorkStatusQuery,
  stinterRightToWorkStatusQueryVariables
>;
export const UpdateActualLeaveTimeDocument = gql`
  mutation UpdateActualLeaveTime($updateInputs: UpdateActualLeaveTimeInput!) {
    data: updateActualLeaveTime(updateInputs: $updateInputs) {
      id
      actualLeaveTime
    }
  }
`;
export type UpdateActualLeaveTimeMutationFn = ApolloReactCommon.MutationFunction<
  UpdateActualLeaveTimeMutation,
  UpdateActualLeaveTimeMutationVariables
>;
export type UpdateActualLeaveTimeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateActualLeaveTimeMutation,
    UpdateActualLeaveTimeMutationVariables
  >,
  'mutation'
>;

export const UpdateActualLeaveTimeComponent = (
  props: UpdateActualLeaveTimeComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    UpdateActualLeaveTimeMutation,
    UpdateActualLeaveTimeMutationVariables
  >
    mutation={UpdateActualLeaveTimeDocument}
    {...props}
  />
);

export type UpdateActualLeaveTimeProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    UpdateActualLeaveTimeMutation,
    UpdateActualLeaveTimeMutationVariables
  >;
} &
  TChildProps;
export function withUpdateActualLeaveTime<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateActualLeaveTimeMutation,
    UpdateActualLeaveTimeMutationVariables,
    UpdateActualLeaveTimeProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateActualLeaveTimeMutation,
    UpdateActualLeaveTimeMutationVariables,
    UpdateActualLeaveTimeProps<TChildProps, TDataName>
  >(UpdateActualLeaveTimeDocument, {
    alias: 'updateActualLeaveTime',
    ...operationOptions,
  });
}

/**
 * __useUpdateActualLeaveTimeMutation__
 *
 * To run a mutation, you first call `useUpdateActualLeaveTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActualLeaveTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActualLeaveTimeMutation, { data, loading, error }] = useUpdateActualLeaveTimeMutation({
 *   variables: {
 *      updateInputs: // value for 'updateInputs'
 *   },
 * });
 */
export function useUpdateActualLeaveTimeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateActualLeaveTimeMutation,
    UpdateActualLeaveTimeMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpdateActualLeaveTimeMutation,
    UpdateActualLeaveTimeMutationVariables
  >(UpdateActualLeaveTimeDocument, baseOptions);
}
export type UpdateActualLeaveTimeMutationHookResult = ReturnType<
  typeof useUpdateActualLeaveTimeMutation
>;
export type UpdateActualLeaveTimeMutationResult = ApolloReactCommon.MutationResult<UpdateActualLeaveTimeMutation>;
export type UpdateActualLeaveTimeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateActualLeaveTimeMutation,
  UpdateActualLeaveTimeMutationVariables
>;
export const allAddressesDocument = gql`
  query allAddresses(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: AddressFilter
  ) {
    items: allAddresses(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      ...AdminAddressFields
    }
    total: _allAddressesMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
      __typename
    }
  }
  ${AdminAddressFieldsFragmentDoc}
`;
export type allAddressesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    allAddressesQuery,
    allAddressesQueryVariables
  >,
  'query'
>;

export const allAddressesComponent = (props: allAddressesComponentProps) => (
  <ApolloReactComponents.Query<allAddressesQuery, allAddressesQueryVariables>
    query={allAddressesDocument}
    {...props}
  />
);

export type allAddressesProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    allAddressesQuery,
    allAddressesQueryVariables
  >;
} &
  TChildProps;
export function withallAddresses<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    allAddressesQuery,
    allAddressesQueryVariables,
    allAddressesProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    allAddressesQuery,
    allAddressesQueryVariables,
    allAddressesProps<TChildProps, TDataName>
  >(allAddressesDocument, {
    alias: 'allAddresses',
    ...operationOptions,
  });
}

/**
 * __useallAddressesQuery__
 *
 * To run a query within a React component, call `useallAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useallAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useallAddressesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useallAddressesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    allAddressesQuery,
    allAddressesQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    allAddressesQuery,
    allAddressesQueryVariables
  >(allAddressesDocument, baseOptions);
}
export function useallAddressesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    allAddressesQuery,
    allAddressesQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    allAddressesQuery,
    allAddressesQueryVariables
  >(allAddressesDocument, baseOptions);
}
export type allAddressesQueryHookResult = ReturnType<
  typeof useallAddressesQuery
>;
export type allAddressesLazyQueryHookResult = ReturnType<
  typeof useallAddressesLazyQuery
>;
export type allAddressesQueryResult = ApolloReactCommon.QueryResult<
  allAddressesQuery,
  allAddressesQueryVariables
>;
export const AddressDocument = gql`
  query Address($id: ID!) {
    data: Address(id: $id) {
      ...AdminAddressFields
    }
  }
  ${AdminAddressFieldsFragmentDoc}
`;
export type AddressComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    AddressQuery,
    AddressQueryVariables
  >,
  'query'
> &
  ({ variables: AddressQueryVariables; skip?: boolean } | { skip: boolean });

export const AddressComponent = (props: AddressComponentProps) => (
  <ApolloReactComponents.Query<AddressQuery, AddressQueryVariables>
    query={AddressDocument}
    {...props}
  />
);

export type AddressProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AddressQuery,
    AddressQueryVariables
  >;
} &
  TChildProps;
export function withAddress<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AddressQuery,
    AddressQueryVariables,
    AddressProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AddressQuery,
    AddressQueryVariables,
    AddressProps<TChildProps, TDataName>
  >(AddressDocument, {
    alias: 'address',
    ...operationOptions,
  });
}

/**
 * __useAddressQuery__
 *
 * To run a query within a React component, call `useAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddressQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAddressQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AddressQuery,
    AddressQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<AddressQuery, AddressQueryVariables>(
    AddressDocument,
    baseOptions,
  );
}
export function useAddressLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AddressQuery,
    AddressQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<AddressQuery, AddressQueryVariables>(
    AddressDocument,
    baseOptions,
  );
}
export type AddressQueryHookResult = ReturnType<typeof useAddressQuery>;
export type AddressLazyQueryHookResult = ReturnType<typeof useAddressLazyQuery>;
export type AddressQueryResult = ApolloReactCommon.QueryResult<
  AddressQuery,
  AddressQueryVariables
>;
export const AdminCreateAppealDocument = gql`
  mutation AdminCreateAppeal($createInputs: AdminCreateAppealInput!) {
    data: adminCreateAppeal(createInputs: $createInputs) {
      id
      decisionAt
      status
    }
  }
`;
export type AdminCreateAppealMutationFn = ApolloReactCommon.MutationFunction<
  AdminCreateAppealMutation,
  AdminCreateAppealMutationVariables
>;
export type AdminCreateAppealComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AdminCreateAppealMutation,
    AdminCreateAppealMutationVariables
  >,
  'mutation'
>;

export const AdminCreateAppealComponent = (
  props: AdminCreateAppealComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    AdminCreateAppealMutation,
    AdminCreateAppealMutationVariables
  >
    mutation={AdminCreateAppealDocument}
    {...props}
  />
);

export type AdminCreateAppealProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    AdminCreateAppealMutation,
    AdminCreateAppealMutationVariables
  >;
} &
  TChildProps;
export function withAdminCreateAppeal<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AdminCreateAppealMutation,
    AdminCreateAppealMutationVariables,
    AdminCreateAppealProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AdminCreateAppealMutation,
    AdminCreateAppealMutationVariables,
    AdminCreateAppealProps<TChildProps, TDataName>
  >(AdminCreateAppealDocument, {
    alias: 'adminCreateAppeal',
    ...operationOptions,
  });
}

/**
 * __useAdminCreateAppealMutation__
 *
 * To run a mutation, you first call `useAdminCreateAppealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateAppealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateAppealMutation, { data, loading, error }] = useAdminCreateAppealMutation({
 *   variables: {
 *      createInputs: // value for 'createInputs'
 *   },
 * });
 */
export function useAdminCreateAppealMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminCreateAppealMutation,
    AdminCreateAppealMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    AdminCreateAppealMutation,
    AdminCreateAppealMutationVariables
  >(AdminCreateAppealDocument, baseOptions);
}
export type AdminCreateAppealMutationHookResult = ReturnType<
  typeof useAdminCreateAppealMutation
>;
export type AdminCreateAppealMutationResult = ApolloReactCommon.MutationResult<AdminCreateAppealMutation>;
export type AdminCreateAppealMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminCreateAppealMutation,
  AdminCreateAppealMutationVariables
>;
export const allBusinessesDocument = gql`
  query allBusinesses(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: BusinessFilter
  ) {
    items: allBusinesses(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      ...AdminBusinessFields
    }
    total: _allBusinessesMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
      __typename
    }
  }
  ${AdminBusinessFieldsFragmentDoc}
`;
export type allBusinessesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    allBusinessesQuery,
    allBusinessesQueryVariables
  >,
  'query'
>;

export const allBusinessesComponent = (props: allBusinessesComponentProps) => (
  <ApolloReactComponents.Query<allBusinessesQuery, allBusinessesQueryVariables>
    query={allBusinessesDocument}
    {...props}
  />
);

export type allBusinessesProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    allBusinessesQuery,
    allBusinessesQueryVariables
  >;
} &
  TChildProps;
export function withallBusinesses<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    allBusinessesQuery,
    allBusinessesQueryVariables,
    allBusinessesProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    allBusinessesQuery,
    allBusinessesQueryVariables,
    allBusinessesProps<TChildProps, TDataName>
  >(allBusinessesDocument, {
    alias: 'allBusinesses',
    ...operationOptions,
  });
}

/**
 * __useallBusinessesQuery__
 *
 * To run a query within a React component, call `useallBusinessesQuery` and pass it any options that fit your needs.
 * When your component renders, `useallBusinessesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useallBusinessesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useallBusinessesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    allBusinessesQuery,
    allBusinessesQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    allBusinessesQuery,
    allBusinessesQueryVariables
  >(allBusinessesDocument, baseOptions);
}
export function useallBusinessesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    allBusinessesQuery,
    allBusinessesQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    allBusinessesQuery,
    allBusinessesQueryVariables
  >(allBusinessesDocument, baseOptions);
}
export type allBusinessesQueryHookResult = ReturnType<
  typeof useallBusinessesQuery
>;
export type allBusinessesLazyQueryHookResult = ReturnType<
  typeof useallBusinessesLazyQuery
>;
export type allBusinessesQueryResult = ApolloReactCommon.QueryResult<
  allBusinessesQuery,
  allBusinessesQueryVariables
>;
export const BusinessDocument = gql`
  query Business($id: ID!) {
    data: Business(id: $id) {
      ...AdminBusinessFields
    }
  }
  ${AdminBusinessFieldsFragmentDoc}
`;
export type BusinessComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    BusinessQuery,
    BusinessQueryVariables
  >,
  'query'
> &
  ({ variables: BusinessQueryVariables; skip?: boolean } | { skip: boolean });

export const BusinessComponent = (props: BusinessComponentProps) => (
  <ApolloReactComponents.Query<BusinessQuery, BusinessQueryVariables>
    query={BusinessDocument}
    {...props}
  />
);

export type BusinessProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    BusinessQuery,
    BusinessQueryVariables
  >;
} &
  TChildProps;
export function withBusiness<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    BusinessQuery,
    BusinessQueryVariables,
    BusinessProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    BusinessQuery,
    BusinessQueryVariables,
    BusinessProps<TChildProps, TDataName>
  >(BusinessDocument, {
    alias: 'business',
    ...operationOptions,
  });
}

/**
 * __useBusinessQuery__
 *
 * To run a query within a React component, call `useBusinessQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBusinessQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    BusinessQuery,
    BusinessQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<BusinessQuery, BusinessQueryVariables>(
    BusinessDocument,
    baseOptions,
  );
}
export function useBusinessLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    BusinessQuery,
    BusinessQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<BusinessQuery, BusinessQueryVariables>(
    BusinessDocument,
    baseOptions,
  );
}
export type BusinessQueryHookResult = ReturnType<typeof useBusinessQuery>;
export type BusinessLazyQueryHookResult = ReturnType<
  typeof useBusinessLazyQuery
>;
export type BusinessQueryResult = ApolloReactCommon.QueryResult<
  BusinessQuery,
  BusinessQueryVariables
>;
export const updateBusinessDocument = gql`
  mutation updateBusiness(
    $id: ID!
    $businessLogo: String
    $displayAddress: String
    $exemptFromPaymentDetails: Boolean
    $ltdName: String
    $name: String
    $phoneNumber: String
    $placeId: String
    $rateOverride: BusinessRateOverrideInput
    $status: BusinessStatusChoices
    $integrated: Boolean
    $unlockedLastMinuteStints: Boolean
    $controlCheck: Boolean
    $isBlockedByFinance: Boolean
    $stintopedia: StintopediaInput
    $repeatStintersEnabled: Boolean
  ) {
    data: updateBusiness(
      id: $id
      businessLogo: $businessLogo
      displayAddress: $displayAddress
      exemptFromPaymentDetails: $exemptFromPaymentDetails
      ltdName: $ltdName
      name: $name
      phoneNumber: $phoneNumber
      placeId: $placeId
      rateOverride: $rateOverride
      status: $status
      integrated: $integrated
      unlockedLastMinuteStints: $unlockedLastMinuteStints
      controlCheck: $controlCheck
      isBlockedByFinance: $isBlockedByFinance
      stintopedia: $stintopedia
      repeatStintersEnabled: $repeatStintersEnabled
    ) {
      ...AdminBusinessFields
    }
  }
  ${AdminBusinessFieldsFragmentDoc}
`;
export type updateBusinessMutationFn = ApolloReactCommon.MutationFunction<
  updateBusinessMutation,
  updateBusinessMutationVariables
>;
export type updateBusinessComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    updateBusinessMutation,
    updateBusinessMutationVariables
  >,
  'mutation'
>;

export const updateBusinessComponent = (
  props: updateBusinessComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    updateBusinessMutation,
    updateBusinessMutationVariables
  >
    mutation={updateBusinessDocument}
    {...props}
  />
);

export type updateBusinessProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    updateBusinessMutation,
    updateBusinessMutationVariables
  >;
} &
  TChildProps;
export function withupdateBusiness<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    updateBusinessMutation,
    updateBusinessMutationVariables,
    updateBusinessProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    updateBusinessMutation,
    updateBusinessMutationVariables,
    updateBusinessProps<TChildProps, TDataName>
  >(updateBusinessDocument, {
    alias: 'updateBusiness',
    ...operationOptions,
  });
}

/**
 * __useupdateBusinessMutation__
 *
 * To run a mutation, you first call `useupdateBusinessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useupdateBusinessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBusinessMutation, { data, loading, error }] = useupdateBusinessMutation({
 *   variables: {
 *      id: // value for 'id'
 *      businessLogo: // value for 'businessLogo'
 *      displayAddress: // value for 'displayAddress'
 *      exemptFromPaymentDetails: // value for 'exemptFromPaymentDetails'
 *      ltdName: // value for 'ltdName'
 *      name: // value for 'name'
 *      phoneNumber: // value for 'phoneNumber'
 *      placeId: // value for 'placeId'
 *      rateOverride: // value for 'rateOverride'
 *      status: // value for 'status'
 *      integrated: // value for 'integrated'
 *      unlockedLastMinuteStints: // value for 'unlockedLastMinuteStints'
 *      controlCheck: // value for 'controlCheck'
 *      isBlockedByFinance: // value for 'isBlockedByFinance'
 *      stintopedia: // value for 'stintopedia'
 *      repeatStintersEnabled: // value for 'repeatStintersEnabled'
 *   },
 * });
 */
export function useupdateBusinessMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    updateBusinessMutation,
    updateBusinessMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    updateBusinessMutation,
    updateBusinessMutationVariables
  >(updateBusinessDocument, baseOptions);
}
export type updateBusinessMutationHookResult = ReturnType<
  typeof useupdateBusinessMutation
>;
export type updateBusinessMutationResult = ApolloReactCommon.MutationResult<updateBusinessMutation>;
export type updateBusinessMutationOptions = ApolloReactCommon.BaseMutationOptions<
  updateBusinessMutation,
  updateBusinessMutationVariables
>;
export const allCharacteristicsDocument = gql`
  query allCharacteristics(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: CharacteristicFilter
  ) {
    items: allCharacteristics(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      ...AdminCharacteristicFields
    }
    total: _allCharacteristicsMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
      __typename
    }
  }
  ${AdminCharacteristicFieldsFragmentDoc}
`;
export type allCharacteristicsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    allCharacteristicsQuery,
    allCharacteristicsQueryVariables
  >,
  'query'
>;

export const allCharacteristicsComponent = (
  props: allCharacteristicsComponentProps,
) => (
  <ApolloReactComponents.Query<
    allCharacteristicsQuery,
    allCharacteristicsQueryVariables
  >
    query={allCharacteristicsDocument}
    {...props}
  />
);

export type allCharacteristicsProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    allCharacteristicsQuery,
    allCharacteristicsQueryVariables
  >;
} &
  TChildProps;
export function withallCharacteristics<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    allCharacteristicsQuery,
    allCharacteristicsQueryVariables,
    allCharacteristicsProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    allCharacteristicsQuery,
    allCharacteristicsQueryVariables,
    allCharacteristicsProps<TChildProps, TDataName>
  >(allCharacteristicsDocument, {
    alias: 'allCharacteristics',
    ...operationOptions,
  });
}

/**
 * __useallCharacteristicsQuery__
 *
 * To run a query within a React component, call `useallCharacteristicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useallCharacteristicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useallCharacteristicsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useallCharacteristicsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    allCharacteristicsQuery,
    allCharacteristicsQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    allCharacteristicsQuery,
    allCharacteristicsQueryVariables
  >(allCharacteristicsDocument, baseOptions);
}
export function useallCharacteristicsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    allCharacteristicsQuery,
    allCharacteristicsQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    allCharacteristicsQuery,
    allCharacteristicsQueryVariables
  >(allCharacteristicsDocument, baseOptions);
}
export type allCharacteristicsQueryHookResult = ReturnType<
  typeof useallCharacteristicsQuery
>;
export type allCharacteristicsLazyQueryHookResult = ReturnType<
  typeof useallCharacteristicsLazyQuery
>;
export type allCharacteristicsQueryResult = ApolloReactCommon.QueryResult<
  allCharacteristicsQuery,
  allCharacteristicsQueryVariables
>;
export const CharacteristicDocument = gql`
  query Characteristic($id: ID!) {
    data: Characteristic(id: $id) {
      ...AdminCharacteristicFields
    }
  }
  ${AdminCharacteristicFieldsFragmentDoc}
`;
export type CharacteristicComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    CharacteristicQuery,
    CharacteristicQueryVariables
  >,
  'query'
> &
  (
    | { variables: CharacteristicQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const CharacteristicComponent = (
  props: CharacteristicComponentProps,
) => (
  <ApolloReactComponents.Query<
    CharacteristicQuery,
    CharacteristicQueryVariables
  >
    query={CharacteristicDocument}
    {...props}
  />
);

export type CharacteristicProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    CharacteristicQuery,
    CharacteristicQueryVariables
  >;
} &
  TChildProps;
export function withCharacteristic<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CharacteristicQuery,
    CharacteristicQueryVariables,
    CharacteristicProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CharacteristicQuery,
    CharacteristicQueryVariables,
    CharacteristicProps<TChildProps, TDataName>
  >(CharacteristicDocument, {
    alias: 'characteristic',
    ...operationOptions,
  });
}

/**
 * __useCharacteristicQuery__
 *
 * To run a query within a React component, call `useCharacteristicQuery` and pass it any options that fit your needs.
 * When your component renders, `useCharacteristicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCharacteristicQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCharacteristicQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CharacteristicQuery,
    CharacteristicQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    CharacteristicQuery,
    CharacteristicQueryVariables
  >(CharacteristicDocument, baseOptions);
}
export function useCharacteristicLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CharacteristicQuery,
    CharacteristicQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    CharacteristicQuery,
    CharacteristicQueryVariables
  >(CharacteristicDocument, baseOptions);
}
export type CharacteristicQueryHookResult = ReturnType<
  typeof useCharacteristicQuery
>;
export type CharacteristicLazyQueryHookResult = ReturnType<
  typeof useCharacteristicLazyQuery
>;
export type CharacteristicQueryResult = ApolloReactCommon.QueryResult<
  CharacteristicQuery,
  CharacteristicQueryVariables
>;
export const allCitiesDocument = gql`
  query allCities(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: CityFilter
  ) {
    items: allCities(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      ...AdminCityFields
    }
    total: _allCitiesMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
      __typename
    }
  }
  ${AdminCityFieldsFragmentDoc}
`;
export type allCitiesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    allCitiesQuery,
    allCitiesQueryVariables
  >,
  'query'
>;

export const allCitiesComponent = (props: allCitiesComponentProps) => (
  <ApolloReactComponents.Query<allCitiesQuery, allCitiesQueryVariables>
    query={allCitiesDocument}
    {...props}
  />
);

export type allCitiesProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    allCitiesQuery,
    allCitiesQueryVariables
  >;
} &
  TChildProps;
export function withallCities<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    allCitiesQuery,
    allCitiesQueryVariables,
    allCitiesProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    allCitiesQuery,
    allCitiesQueryVariables,
    allCitiesProps<TChildProps, TDataName>
  >(allCitiesDocument, {
    alias: 'allCities',
    ...operationOptions,
  });
}

/**
 * __useallCitiesQuery__
 *
 * To run a query within a React component, call `useallCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useallCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useallCitiesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useallCitiesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    allCitiesQuery,
    allCitiesQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<allCitiesQuery, allCitiesQueryVariables>(
    allCitiesDocument,
    baseOptions,
  );
}
export function useallCitiesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    allCitiesQuery,
    allCitiesQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<allCitiesQuery, allCitiesQueryVariables>(
    allCitiesDocument,
    baseOptions,
  );
}
export type allCitiesQueryHookResult = ReturnType<typeof useallCitiesQuery>;
export type allCitiesLazyQueryHookResult = ReturnType<
  typeof useallCitiesLazyQuery
>;
export type allCitiesQueryResult = ApolloReactCommon.QueryResult<
  allCitiesQuery,
  allCitiesQueryVariables
>;
export const CityDocument = gql`
  query City($id: ID!) {
    data: City(id: $id) {
      ...AdminCityFields
    }
  }
  ${AdminCityFieldsFragmentDoc}
`;
export type CityComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<CityQuery, CityQueryVariables>,
  'query'
> &
  ({ variables: CityQueryVariables; skip?: boolean } | { skip: boolean });

export const CityComponent = (props: CityComponentProps) => (
  <ApolloReactComponents.Query<CityQuery, CityQueryVariables>
    query={CityDocument}
    {...props}
  />
);

export type CityProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<CityQuery, CityQueryVariables>;
} &
  TChildProps;
export function withCity<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CityQuery,
    CityQueryVariables,
    CityProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CityQuery,
    CityQueryVariables,
    CityProps<TChildProps, TDataName>
  >(CityDocument, {
    alias: 'city',
    ...operationOptions,
  });
}

/**
 * __useCityQuery__
 *
 * To run a query within a React component, call `useCityQuery` and pass it any options that fit your needs.
 * When your component renders, `useCityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCityQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CityQuery,
    CityQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<CityQuery, CityQueryVariables>(
    CityDocument,
    baseOptions,
  );
}
export function useCityLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CityQuery,
    CityQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<CityQuery, CityQueryVariables>(
    CityDocument,
    baseOptions,
  );
}
export type CityQueryHookResult = ReturnType<typeof useCityQuery>;
export type CityLazyQueryHookResult = ReturnType<typeof useCityLazyQuery>;
export type CityQueryResult = ApolloReactCommon.QueryResult<
  CityQuery,
  CityQueryVariables
>;
export const createCityDocument = gql`
  mutation createCity(
    $baseFee: Float!
    $baseFeeStudent: Float!
    $name: String!
    $regionId: ID!
    $sessionUrl: AWSURL
    $shortCode: String!
  ) {
    data: createCity(
      baseFee: $baseFee
      baseFeeStudent: $baseFeeStudent
      name: $name
      regionId: $regionId
      sessionUrl: $sessionUrl
      shortCode: $shortCode
    ) {
      ...AdminCityFields
    }
  }
  ${AdminCityFieldsFragmentDoc}
`;
export type createCityMutationFn = ApolloReactCommon.MutationFunction<
  createCityMutation,
  createCityMutationVariables
>;
export type createCityComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    createCityMutation,
    createCityMutationVariables
  >,
  'mutation'
>;

export const createCityComponent = (props: createCityComponentProps) => (
  <ApolloReactComponents.Mutation<
    createCityMutation,
    createCityMutationVariables
  >
    mutation={createCityDocument}
    {...props}
  />
);

export type createCityProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    createCityMutation,
    createCityMutationVariables
  >;
} &
  TChildProps;
export function withcreateCity<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    createCityMutation,
    createCityMutationVariables,
    createCityProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    createCityMutation,
    createCityMutationVariables,
    createCityProps<TChildProps, TDataName>
  >(createCityDocument, {
    alias: 'createCity',
    ...operationOptions,
  });
}

/**
 * __usecreateCityMutation__
 *
 * To run a mutation, you first call `usecreateCityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usecreateCityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCityMutation, { data, loading, error }] = usecreateCityMutation({
 *   variables: {
 *      baseFee: // value for 'baseFee'
 *      baseFeeStudent: // value for 'baseFeeStudent'
 *      name: // value for 'name'
 *      regionId: // value for 'regionId'
 *      sessionUrl: // value for 'sessionUrl'
 *      shortCode: // value for 'shortCode'
 *   },
 * });
 */
export function usecreateCityMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    createCityMutation,
    createCityMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    createCityMutation,
    createCityMutationVariables
  >(createCityDocument, baseOptions);
}
export type createCityMutationHookResult = ReturnType<
  typeof usecreateCityMutation
>;
export type createCityMutationResult = ApolloReactCommon.MutationResult<createCityMutation>;
export type createCityMutationOptions = ApolloReactCommon.BaseMutationOptions<
  createCityMutation,
  createCityMutationVariables
>;
export const updateCityDocument = gql`
  mutation updateCity(
    $baseFee: Float!
    $baseFeeStudent: Float!
    $id: ID!
    $isActive: Boolean
    $isAvailabilityEnabled: Boolean
    $sessionUrl: AWSURL
    $name: String
    $regionId: ID
    $shortCode: String
    $postalCodeIds: [ID]
  ) {
    data: updateCity(
      baseFee: $baseFee
      baseFeeStudent: $baseFeeStudent
      id: $id
      isActive: $isActive
      isAvailabilityEnabled: $isAvailabilityEnabled
      sessionUrl: $sessionUrl
      shortCode: $shortCode
      name: $name
      regionId: $regionId
      postalCodeIds: $postalCodeIds
    ) {
      ...AdminCityFields
    }
  }
  ${AdminCityFieldsFragmentDoc}
`;
export type updateCityMutationFn = ApolloReactCommon.MutationFunction<
  updateCityMutation,
  updateCityMutationVariables
>;
export type updateCityComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    updateCityMutation,
    updateCityMutationVariables
  >,
  'mutation'
>;

export const updateCityComponent = (props: updateCityComponentProps) => (
  <ApolloReactComponents.Mutation<
    updateCityMutation,
    updateCityMutationVariables
  >
    mutation={updateCityDocument}
    {...props}
  />
);

export type updateCityProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    updateCityMutation,
    updateCityMutationVariables
  >;
} &
  TChildProps;
export function withupdateCity<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    updateCityMutation,
    updateCityMutationVariables,
    updateCityProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    updateCityMutation,
    updateCityMutationVariables,
    updateCityProps<TChildProps, TDataName>
  >(updateCityDocument, {
    alias: 'updateCity',
    ...operationOptions,
  });
}

/**
 * __useupdateCityMutation__
 *
 * To run a mutation, you first call `useupdateCityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useupdateCityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCityMutation, { data, loading, error }] = useupdateCityMutation({
 *   variables: {
 *      baseFee: // value for 'baseFee'
 *      baseFeeStudent: // value for 'baseFeeStudent'
 *      id: // value for 'id'
 *      isActive: // value for 'isActive'
 *      isAvailabilityEnabled: // value for 'isAvailabilityEnabled'
 *      sessionUrl: // value for 'sessionUrl'
 *      name: // value for 'name'
 *      regionId: // value for 'regionId'
 *      shortCode: // value for 'shortCode'
 *      postalCodeIds: // value for 'postalCodeIds'
 *   },
 * });
 */
export function useupdateCityMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    updateCityMutation,
    updateCityMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    updateCityMutation,
    updateCityMutationVariables
  >(updateCityDocument, baseOptions);
}
export type updateCityMutationHookResult = ReturnType<
  typeof useupdateCityMutation
>;
export type updateCityMutationResult = ApolloReactCommon.MutationResult<updateCityMutation>;
export type updateCityMutationOptions = ApolloReactCommon.BaseMutationOptions<
  updateCityMutation,
  updateCityMutationVariables
>;
export const allCountriesDocument = gql`
  query allCountries(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: CountryFilter
  ) {
    items: allCountries(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      ...AdminCountryFields
    }
    total: _allCountriesMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
      __typename
    }
  }
  ${AdminCountryFieldsFragmentDoc}
`;
export type allCountriesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    allCountriesQuery,
    allCountriesQueryVariables
  >,
  'query'
>;

export const allCountriesComponent = (props: allCountriesComponentProps) => (
  <ApolloReactComponents.Query<allCountriesQuery, allCountriesQueryVariables>
    query={allCountriesDocument}
    {...props}
  />
);

export type allCountriesProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    allCountriesQuery,
    allCountriesQueryVariables
  >;
} &
  TChildProps;
export function withallCountries<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    allCountriesQuery,
    allCountriesQueryVariables,
    allCountriesProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    allCountriesQuery,
    allCountriesQueryVariables,
    allCountriesProps<TChildProps, TDataName>
  >(allCountriesDocument, {
    alias: 'allCountries',
    ...operationOptions,
  });
}

/**
 * __useallCountriesQuery__
 *
 * To run a query within a React component, call `useallCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useallCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useallCountriesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useallCountriesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    allCountriesQuery,
    allCountriesQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    allCountriesQuery,
    allCountriesQueryVariables
  >(allCountriesDocument, baseOptions);
}
export function useallCountriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    allCountriesQuery,
    allCountriesQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    allCountriesQuery,
    allCountriesQueryVariables
  >(allCountriesDocument, baseOptions);
}
export type allCountriesQueryHookResult = ReturnType<
  typeof useallCountriesQuery
>;
export type allCountriesLazyQueryHookResult = ReturnType<
  typeof useallCountriesLazyQuery
>;
export type allCountriesQueryResult = ApolloReactCommon.QueryResult<
  allCountriesQuery,
  allCountriesQueryVariables
>;
export const CountryDocument = gql`
  query Country($id: ID!) {
    data: Country(id: $id) {
      ...AdminCountryFields
    }
  }
  ${AdminCountryFieldsFragmentDoc}
`;
export type CountryComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    CountryQuery,
    CountryQueryVariables
  >,
  'query'
> &
  ({ variables: CountryQueryVariables; skip?: boolean } | { skip: boolean });

export const CountryComponent = (props: CountryComponentProps) => (
  <ApolloReactComponents.Query<CountryQuery, CountryQueryVariables>
    query={CountryDocument}
    {...props}
  />
);

export type CountryProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    CountryQuery,
    CountryQueryVariables
  >;
} &
  TChildProps;
export function withCountry<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CountryQuery,
    CountryQueryVariables,
    CountryProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CountryQuery,
    CountryQueryVariables,
    CountryProps<TChildProps, TDataName>
  >(CountryDocument, {
    alias: 'country',
    ...operationOptions,
  });
}

/**
 * __useCountryQuery__
 *
 * To run a query within a React component, call `useCountryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCountryQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CountryQuery,
    CountryQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<CountryQuery, CountryQueryVariables>(
    CountryDocument,
    baseOptions,
  );
}
export function useCountryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CountryQuery,
    CountryQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<CountryQuery, CountryQueryVariables>(
    CountryDocument,
    baseOptions,
  );
}
export type CountryQueryHookResult = ReturnType<typeof useCountryQuery>;
export type CountryLazyQueryHookResult = ReturnType<typeof useCountryLazyQuery>;
export type CountryQueryResult = ApolloReactCommon.QueryResult<
  CountryQuery,
  CountryQueryVariables
>;
export const updateCountryDocument = gql`
  mutation updateCountry(
    $id: ID!
    $name: String
    $shortCode: String
    $regions: String
  ) {
    data: updateCountry(
      id: $id
      name: $name
      shortCode: $shortCode
      regions: $regions
    ) {
      ...AdminCountryFields
    }
  }
  ${AdminCountryFieldsFragmentDoc}
`;
export type updateCountryMutationFn = ApolloReactCommon.MutationFunction<
  updateCountryMutation,
  updateCountryMutationVariables
>;
export type updateCountryComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    updateCountryMutation,
    updateCountryMutationVariables
  >,
  'mutation'
>;

export const updateCountryComponent = (props: updateCountryComponentProps) => (
  <ApolloReactComponents.Mutation<
    updateCountryMutation,
    updateCountryMutationVariables
  >
    mutation={updateCountryDocument}
    {...props}
  />
);

export type updateCountryProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    updateCountryMutation,
    updateCountryMutationVariables
  >;
} &
  TChildProps;
export function withupdateCountry<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    updateCountryMutation,
    updateCountryMutationVariables,
    updateCountryProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    updateCountryMutation,
    updateCountryMutationVariables,
    updateCountryProps<TChildProps, TDataName>
  >(updateCountryDocument, {
    alias: 'updateCountry',
    ...operationOptions,
  });
}

/**
 * __useupdateCountryMutation__
 *
 * To run a mutation, you first call `useupdateCountryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useupdateCountryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCountryMutation, { data, loading, error }] = useupdateCountryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      shortCode: // value for 'shortCode'
 *      regions: // value for 'regions'
 *   },
 * });
 */
export function useupdateCountryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    updateCountryMutation,
    updateCountryMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    updateCountryMutation,
    updateCountryMutationVariables
  >(updateCountryDocument, baseOptions);
}
export type updateCountryMutationHookResult = ReturnType<
  typeof useupdateCountryMutation
>;
export type updateCountryMutationResult = ApolloReactCommon.MutationResult<updateCountryMutation>;
export type updateCountryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  updateCountryMutation,
  updateCountryMutationVariables
>;
export const createCountryDocument = gql`
  mutation createCountry(
    $name: String!
    $shortCode: String!
    $regions: String!
  ) {
    data: createCountry(name: $name, shortCode: $shortCode, regions: $regions) {
      ...AdminCountryFields
    }
  }
  ${AdminCountryFieldsFragmentDoc}
`;
export type createCountryMutationFn = ApolloReactCommon.MutationFunction<
  createCountryMutation,
  createCountryMutationVariables
>;
export type createCountryComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    createCountryMutation,
    createCountryMutationVariables
  >,
  'mutation'
>;

export const createCountryComponent = (props: createCountryComponentProps) => (
  <ApolloReactComponents.Mutation<
    createCountryMutation,
    createCountryMutationVariables
  >
    mutation={createCountryDocument}
    {...props}
  />
);

export type createCountryProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    createCountryMutation,
    createCountryMutationVariables
  >;
} &
  TChildProps;
export function withcreateCountry<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    createCountryMutation,
    createCountryMutationVariables,
    createCountryProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    createCountryMutation,
    createCountryMutationVariables,
    createCountryProps<TChildProps, TDataName>
  >(createCountryDocument, {
    alias: 'createCountry',
    ...operationOptions,
  });
}

/**
 * __usecreateCountryMutation__
 *
 * To run a mutation, you first call `usecreateCountryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usecreateCountryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCountryMutation, { data, loading, error }] = usecreateCountryMutation({
 *   variables: {
 *      name: // value for 'name'
 *      shortCode: // value for 'shortCode'
 *      regions: // value for 'regions'
 *   },
 * });
 */
export function usecreateCountryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    createCountryMutation,
    createCountryMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    createCountryMutation,
    createCountryMutationVariables
  >(createCountryDocument, baseOptions);
}
export type createCountryMutationHookResult = ReturnType<
  typeof usecreateCountryMutation
>;
export type createCountryMutationResult = ApolloReactCommon.MutationResult<createCountryMutation>;
export type createCountryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  createCountryMutation,
  createCountryMutationVariables
>;
export const allDeviceLocationsDocument = gql`
  query allDeviceLocations(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: DeviceLocationFilter
  ) {
    items: allDeviceLocations(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      ...AdminDeviceLocationFields
    }
    total: _allDeviceLocationsMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
      __typename
    }
  }
  ${AdminDeviceLocationFieldsFragmentDoc}
`;
export type allDeviceLocationsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    allDeviceLocationsQuery,
    allDeviceLocationsQueryVariables
  >,
  'query'
>;

export const allDeviceLocationsComponent = (
  props: allDeviceLocationsComponentProps,
) => (
  <ApolloReactComponents.Query<
    allDeviceLocationsQuery,
    allDeviceLocationsQueryVariables
  >
    query={allDeviceLocationsDocument}
    {...props}
  />
);

export type allDeviceLocationsProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    allDeviceLocationsQuery,
    allDeviceLocationsQueryVariables
  >;
} &
  TChildProps;
export function withallDeviceLocations<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    allDeviceLocationsQuery,
    allDeviceLocationsQueryVariables,
    allDeviceLocationsProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    allDeviceLocationsQuery,
    allDeviceLocationsQueryVariables,
    allDeviceLocationsProps<TChildProps, TDataName>
  >(allDeviceLocationsDocument, {
    alias: 'allDeviceLocations',
    ...operationOptions,
  });
}

/**
 * __useallDeviceLocationsQuery__
 *
 * To run a query within a React component, call `useallDeviceLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useallDeviceLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useallDeviceLocationsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useallDeviceLocationsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    allDeviceLocationsQuery,
    allDeviceLocationsQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    allDeviceLocationsQuery,
    allDeviceLocationsQueryVariables
  >(allDeviceLocationsDocument, baseOptions);
}
export function useallDeviceLocationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    allDeviceLocationsQuery,
    allDeviceLocationsQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    allDeviceLocationsQuery,
    allDeviceLocationsQueryVariables
  >(allDeviceLocationsDocument, baseOptions);
}
export type allDeviceLocationsQueryHookResult = ReturnType<
  typeof useallDeviceLocationsQuery
>;
export type allDeviceLocationsLazyQueryHookResult = ReturnType<
  typeof useallDeviceLocationsLazyQuery
>;
export type allDeviceLocationsQueryResult = ApolloReactCommon.QueryResult<
  allDeviceLocationsQuery,
  allDeviceLocationsQueryVariables
>;
export const DeviceLocationDocument = gql`
  query DeviceLocation($id: ID!) {
    data: DeviceLocation(id: $id) {
      ...AdminDeviceLocationFields
    }
  }
  ${AdminDeviceLocationFieldsFragmentDoc}
`;
export type DeviceLocationComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    DeviceLocationQuery,
    DeviceLocationQueryVariables
  >,
  'query'
> &
  (
    | { variables: DeviceLocationQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const DeviceLocationComponent = (
  props: DeviceLocationComponentProps,
) => (
  <ApolloReactComponents.Query<
    DeviceLocationQuery,
    DeviceLocationQueryVariables
  >
    query={DeviceLocationDocument}
    {...props}
  />
);

export type DeviceLocationProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    DeviceLocationQuery,
    DeviceLocationQueryVariables
  >;
} &
  TChildProps;
export function withDeviceLocation<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeviceLocationQuery,
    DeviceLocationQueryVariables,
    DeviceLocationProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    DeviceLocationQuery,
    DeviceLocationQueryVariables,
    DeviceLocationProps<TChildProps, TDataName>
  >(DeviceLocationDocument, {
    alias: 'deviceLocation',
    ...operationOptions,
  });
}

/**
 * __useDeviceLocationQuery__
 *
 * To run a query within a React component, call `useDeviceLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceLocationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeviceLocationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    DeviceLocationQuery,
    DeviceLocationQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    DeviceLocationQuery,
    DeviceLocationQueryVariables
  >(DeviceLocationDocument, baseOptions);
}
export function useDeviceLocationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    DeviceLocationQuery,
    DeviceLocationQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    DeviceLocationQuery,
    DeviceLocationQueryVariables
  >(DeviceLocationDocument, baseOptions);
}
export type DeviceLocationQueryHookResult = ReturnType<
  typeof useDeviceLocationQuery
>;
export type DeviceLocationLazyQueryHookResult = ReturnType<
  typeof useDeviceLocationLazyQuery
>;
export type DeviceLocationQueryResult = ApolloReactCommon.QueryResult<
  DeviceLocationQuery,
  DeviceLocationQueryVariables
>;
export const allEditableSettingsDocument = gql`
  query allEditableSettings(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
  ) {
    items: allEditableSettings(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      ...AdminEditableSettingFields
    }
    total: _allEditableSettingsMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      count
      __typename
    }
  }
  ${AdminEditableSettingFieldsFragmentDoc}
`;
export type allEditableSettingsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    allEditableSettingsQuery,
    allEditableSettingsQueryVariables
  >,
  'query'
>;

export const allEditableSettingsComponent = (
  props: allEditableSettingsComponentProps,
) => (
  <ApolloReactComponents.Query<
    allEditableSettingsQuery,
    allEditableSettingsQueryVariables
  >
    query={allEditableSettingsDocument}
    {...props}
  />
);

export type allEditableSettingsProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    allEditableSettingsQuery,
    allEditableSettingsQueryVariables
  >;
} &
  TChildProps;
export function withallEditableSettings<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    allEditableSettingsQuery,
    allEditableSettingsQueryVariables,
    allEditableSettingsProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    allEditableSettingsQuery,
    allEditableSettingsQueryVariables,
    allEditableSettingsProps<TChildProps, TDataName>
  >(allEditableSettingsDocument, {
    alias: 'allEditableSettings',
    ...operationOptions,
  });
}

/**
 * __useallEditableSettingsQuery__
 *
 * To run a query within a React component, call `useallEditableSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useallEditableSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useallEditableSettingsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *   },
 * });
 */
export function useallEditableSettingsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    allEditableSettingsQuery,
    allEditableSettingsQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    allEditableSettingsQuery,
    allEditableSettingsQueryVariables
  >(allEditableSettingsDocument, baseOptions);
}
export function useallEditableSettingsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    allEditableSettingsQuery,
    allEditableSettingsQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    allEditableSettingsQuery,
    allEditableSettingsQueryVariables
  >(allEditableSettingsDocument, baseOptions);
}
export type allEditableSettingsQueryHookResult = ReturnType<
  typeof useallEditableSettingsQuery
>;
export type allEditableSettingsLazyQueryHookResult = ReturnType<
  typeof useallEditableSettingsLazyQuery
>;
export type allEditableSettingsQueryResult = ApolloReactCommon.QueryResult<
  allEditableSettingsQuery,
  allEditableSettingsQueryVariables
>;
export const EditableSettingDocument = gql`
  query EditableSetting($id: ID!) {
    data: EditableSetting(id: $id) {
      ...AdminEditableSettingFields
    }
  }
  ${AdminEditableSettingFieldsFragmentDoc}
`;
export type EditableSettingComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    EditableSettingQuery,
    EditableSettingQueryVariables
  >,
  'query'
> &
  (
    | { variables: EditableSettingQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const EditableSettingComponent = (
  props: EditableSettingComponentProps,
) => (
  <ApolloReactComponents.Query<
    EditableSettingQuery,
    EditableSettingQueryVariables
  >
    query={EditableSettingDocument}
    {...props}
  />
);

export type EditableSettingProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    EditableSettingQuery,
    EditableSettingQueryVariables
  >;
} &
  TChildProps;
export function withEditableSetting<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    EditableSettingQuery,
    EditableSettingQueryVariables,
    EditableSettingProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    EditableSettingQuery,
    EditableSettingQueryVariables,
    EditableSettingProps<TChildProps, TDataName>
  >(EditableSettingDocument, {
    alias: 'editableSetting',
    ...operationOptions,
  });
}

/**
 * __useEditableSettingQuery__
 *
 * To run a query within a React component, call `useEditableSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditableSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditableSettingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditableSettingQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    EditableSettingQuery,
    EditableSettingQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    EditableSettingQuery,
    EditableSettingQueryVariables
  >(EditableSettingDocument, baseOptions);
}
export function useEditableSettingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EditableSettingQuery,
    EditableSettingQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    EditableSettingQuery,
    EditableSettingQueryVariables
  >(EditableSettingDocument, baseOptions);
}
export type EditableSettingQueryHookResult = ReturnType<
  typeof useEditableSettingQuery
>;
export type EditableSettingLazyQueryHookResult = ReturnType<
  typeof useEditableSettingLazyQuery
>;
export type EditableSettingQueryResult = ApolloReactCommon.QueryResult<
  EditableSettingQuery,
  EditableSettingQueryVariables
>;
export const updateEditableSettingDocument = gql`
  mutation updateEditableSetting(
    $predictionLeadDays: Int
    $notificationBlockerWindowHours: Float
    $geoTrackingBufferTime: Int
    $advancedOperationalWindowStartTime: AWSTime
    $advancedOperationalWindowEndTime: AWSTime
    $automatedTestingEnabled: Boolean
    $serverDatetime: AWSDateTime
    $featureFlagStudentGoingToBeLateSmsEnabled: Boolean
    $featureFlagStintSecondStartReminderEnabled: Boolean
    $availabilityMatchingBufferHours: Int
    $availabilityMatchingMaxOffersMonday: Int
    $availabilityMatchingMaxOffersTuesday: Int
    $availabilityMatchingMaxOffersWednesday: Int
    $availabilityMatchingMaxOffersThursday: Int
    $availabilityMatchingMaxOffersFriday: Int
    $availabilityMatchingMaxOffersSaturday: Int
    $availabilityMatchingMaxOffersSunday: Int
    $availabilityMatchingNotificationForNoOffersEnabled: Boolean
    $matchingAlgoNoOfferMaxCountPerTimeWindow: Int
    $matchingAlgoNoOfferTimeWindowSpan: Int
    $matchingAlgoNoOfferTitle: String
    $matchingAlgoNoOfferMessage: String
    $matchingAlgoNotificationDelayInMinutes: Int
    $featureFlagEtdNotificationsEnabled: Boolean
    $sendingStudentLeaveReminderAtRouteTimeEnabled: Boolean
    $studentCancellationOnHoldEmailEnabled: Boolean
    $etdCalculationBeforeMiddayOffsetInMinutes: Int
    $etdCalculationAfterMiddayOffsetInMinutes: Int
    $stintOfferExpiryThresholdInHours: Int
    $notificationTopUpThresholdInHours: Int
  ) {
    data: updateEditableSetting(
      predictionLeadDays: $predictionLeadDays
      notificationBlockerWindowHours: $notificationBlockerWindowHours
      geoTrackingBufferTime: $geoTrackingBufferTime
      advancedOperationalWindowStartTime: $advancedOperationalWindowStartTime
      advancedOperationalWindowEndTime: $advancedOperationalWindowEndTime
      automatedTestingEnabled: $automatedTestingEnabled
      featureFlagStintSecondStartReminderEnabled: $featureFlagStintSecondStartReminderEnabled
      serverDatetime: $serverDatetime
      featureFlagStudentGoingToBeLateSmsEnabled: $featureFlagStudentGoingToBeLateSmsEnabled
      availabilityMatchingBufferHours: $availabilityMatchingBufferHours
      availabilityMatchingMaxOffersMonday: $availabilityMatchingMaxOffersMonday
      availabilityMatchingMaxOffersTuesday: $availabilityMatchingMaxOffersTuesday
      availabilityMatchingMaxOffersWednesday: $availabilityMatchingMaxOffersWednesday
      availabilityMatchingMaxOffersThursday: $availabilityMatchingMaxOffersThursday
      availabilityMatchingMaxOffersFriday: $availabilityMatchingMaxOffersFriday
      availabilityMatchingMaxOffersSaturday: $availabilityMatchingMaxOffersSaturday
      availabilityMatchingMaxOffersSunday: $availabilityMatchingMaxOffersSunday
      availabilityMatchingNotificationForNoOffersEnabled: $availabilityMatchingNotificationForNoOffersEnabled
      matchingAlgoNoOfferMaxCountPerTimeWindow: $matchingAlgoNoOfferMaxCountPerTimeWindow
      matchingAlgoNoOfferTimeWindowSpan: $matchingAlgoNoOfferTimeWindowSpan
      matchingAlgoNoOfferTitle: $matchingAlgoNoOfferTitle
      matchingAlgoNoOfferMessage: $matchingAlgoNoOfferMessage
      matchingAlgoNotificationDelayInMinutes: $matchingAlgoNotificationDelayInMinutes
      featureFlagEtdNotificationsEnabled: $featureFlagEtdNotificationsEnabled
      sendingStudentLeaveReminderAtRouteTimeEnabled: $sendingStudentLeaveReminderAtRouteTimeEnabled
      studentCancellationOnHoldEmailEnabled: $studentCancellationOnHoldEmailEnabled
      etdCalculationBeforeMiddayOffsetInMinutes: $etdCalculationBeforeMiddayOffsetInMinutes
      etdCalculationAfterMiddayOffsetInMinutes: $etdCalculationAfterMiddayOffsetInMinutes
      stintOfferExpiryThresholdInHours: $stintOfferExpiryThresholdInHours
      notificationTopUpThresholdInHours: $notificationTopUpThresholdInHours
    ) {
      ...AdminEditableSettingFields
    }
  }
  ${AdminEditableSettingFieldsFragmentDoc}
`;
export type updateEditableSettingMutationFn = ApolloReactCommon.MutationFunction<
  updateEditableSettingMutation,
  updateEditableSettingMutationVariables
>;
export type updateEditableSettingComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    updateEditableSettingMutation,
    updateEditableSettingMutationVariables
  >,
  'mutation'
>;

export const updateEditableSettingComponent = (
  props: updateEditableSettingComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    updateEditableSettingMutation,
    updateEditableSettingMutationVariables
  >
    mutation={updateEditableSettingDocument}
    {...props}
  />
);

export type updateEditableSettingProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    updateEditableSettingMutation,
    updateEditableSettingMutationVariables
  >;
} &
  TChildProps;
export function withupdateEditableSetting<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    updateEditableSettingMutation,
    updateEditableSettingMutationVariables,
    updateEditableSettingProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    updateEditableSettingMutation,
    updateEditableSettingMutationVariables,
    updateEditableSettingProps<TChildProps, TDataName>
  >(updateEditableSettingDocument, {
    alias: 'updateEditableSetting',
    ...operationOptions,
  });
}

/**
 * __useupdateEditableSettingMutation__
 *
 * To run a mutation, you first call `useupdateEditableSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useupdateEditableSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEditableSettingMutation, { data, loading, error }] = useupdateEditableSettingMutation({
 *   variables: {
 *      predictionLeadDays: // value for 'predictionLeadDays'
 *      notificationBlockerWindowHours: // value for 'notificationBlockerWindowHours'
 *      geoTrackingBufferTime: // value for 'geoTrackingBufferTime'
 *      advancedOperationalWindowStartTime: // value for 'advancedOperationalWindowStartTime'
 *      advancedOperationalWindowEndTime: // value for 'advancedOperationalWindowEndTime'
 *      automatedTestingEnabled: // value for 'automatedTestingEnabled'
 *      serverDatetime: // value for 'serverDatetime'
 *      featureFlagStudentGoingToBeLateSmsEnabled: // value for 'featureFlagStudentGoingToBeLateSmsEnabled'
 *      featureFlagStintSecondStartReminderEnabled: // value for 'featureFlagStintSecondStartReminderEnabled'
 *      availabilityMatchingBufferHours: // value for 'availabilityMatchingBufferHours'
 *      availabilityMatchingMaxOffersMonday: // value for 'availabilityMatchingMaxOffersMonday'
 *      availabilityMatchingMaxOffersTuesday: // value for 'availabilityMatchingMaxOffersTuesday'
 *      availabilityMatchingMaxOffersWednesday: // value for 'availabilityMatchingMaxOffersWednesday'
 *      availabilityMatchingMaxOffersThursday: // value for 'availabilityMatchingMaxOffersThursday'
 *      availabilityMatchingMaxOffersFriday: // value for 'availabilityMatchingMaxOffersFriday'
 *      availabilityMatchingMaxOffersSaturday: // value for 'availabilityMatchingMaxOffersSaturday'
 *      availabilityMatchingMaxOffersSunday: // value for 'availabilityMatchingMaxOffersSunday'
 *      availabilityMatchingNotificationForNoOffersEnabled: // value for 'availabilityMatchingNotificationForNoOffersEnabled'
 *      matchingAlgoNoOfferMaxCountPerTimeWindow: // value for 'matchingAlgoNoOfferMaxCountPerTimeWindow'
 *      matchingAlgoNoOfferTimeWindowSpan: // value for 'matchingAlgoNoOfferTimeWindowSpan'
 *      matchingAlgoNoOfferTitle: // value for 'matchingAlgoNoOfferTitle'
 *      matchingAlgoNoOfferMessage: // value for 'matchingAlgoNoOfferMessage'
 *      matchingAlgoNotificationDelayInMinutes: // value for 'matchingAlgoNotificationDelayInMinutes'
 *      featureFlagEtdNotificationsEnabled: // value for 'featureFlagEtdNotificationsEnabled'
 *      sendingStudentLeaveReminderAtRouteTimeEnabled: // value for 'sendingStudentLeaveReminderAtRouteTimeEnabled'
 *      studentCancellationOnHoldEmailEnabled: // value for 'studentCancellationOnHoldEmailEnabled'
 *      etdCalculationBeforeMiddayOffsetInMinutes: // value for 'etdCalculationBeforeMiddayOffsetInMinutes'
 *      etdCalculationAfterMiddayOffsetInMinutes: // value for 'etdCalculationAfterMiddayOffsetInMinutes'
 *      stintOfferExpiryThresholdInHours: // value for 'stintOfferExpiryThresholdInHours'
 *      notificationTopUpThresholdInHours: // value for 'notificationTopUpThresholdInHours'
 *   },
 * });
 */
export function useupdateEditableSettingMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    updateEditableSettingMutation,
    updateEditableSettingMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    updateEditableSettingMutation,
    updateEditableSettingMutationVariables
  >(updateEditableSettingDocument, baseOptions);
}
export type updateEditableSettingMutationHookResult = ReturnType<
  typeof useupdateEditableSettingMutation
>;
export type updateEditableSettingMutationResult = ApolloReactCommon.MutationResult<updateEditableSettingMutation>;
export type updateEditableSettingMutationOptions = ApolloReactCommon.BaseMutationOptions<
  updateEditableSettingMutation,
  updateEditableSettingMutationVariables
>;
export const EmployerUserDocument = gql`
  query EmployerUser($id: ID!) {
    data: employerUser(userId: $id) {
      ...EmployerUserFields
    }
  }
  ${EmployerUserFieldsFragmentDoc}
`;
export type EmployerUserComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    EmployerUserQuery,
    EmployerUserQueryVariables
  >,
  'query'
> &
  (
    | { variables: EmployerUserQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const EmployerUserComponent = (props: EmployerUserComponentProps) => (
  <ApolloReactComponents.Query<EmployerUserQuery, EmployerUserQueryVariables>
    query={EmployerUserDocument}
    {...props}
  />
);

export type EmployerUserProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    EmployerUserQuery,
    EmployerUserQueryVariables
  >;
} &
  TChildProps;
export function withEmployerUser<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    EmployerUserQuery,
    EmployerUserQueryVariables,
    EmployerUserProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    EmployerUserQuery,
    EmployerUserQueryVariables,
    EmployerUserProps<TChildProps, TDataName>
  >(EmployerUserDocument, {
    alias: 'employerUser',
    ...operationOptions,
  });
}

/**
 * __useEmployerUserQuery__
 *
 * To run a query within a React component, call `useEmployerUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployerUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployerUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEmployerUserQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    EmployerUserQuery,
    EmployerUserQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    EmployerUserQuery,
    EmployerUserQueryVariables
  >(EmployerUserDocument, baseOptions);
}
export function useEmployerUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EmployerUserQuery,
    EmployerUserQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    EmployerUserQuery,
    EmployerUserQueryVariables
  >(EmployerUserDocument, baseOptions);
}
export type EmployerUserQueryHookResult = ReturnType<
  typeof useEmployerUserQuery
>;
export type EmployerUserLazyQueryHookResult = ReturnType<
  typeof useEmployerUserLazyQuery
>;
export type EmployerUserQueryResult = ApolloReactCommon.QueryResult<
  EmployerUserQuery,
  EmployerUserQueryVariables
>;
export const allEmployerUsersDocument = gql`
  query allEmployerUsers(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: String
  ) {
    items: allEmployerUsers(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      ...EmployerUserFields
    }
    total: _allEmployerUserMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
      __typename
    }
  }
  ${EmployerUserFieldsFragmentDoc}
`;
export type allEmployerUsersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    allEmployerUsersQuery,
    allEmployerUsersQueryVariables
  >,
  'query'
>;

export const allEmployerUsersComponent = (
  props: allEmployerUsersComponentProps,
) => (
  <ApolloReactComponents.Query<
    allEmployerUsersQuery,
    allEmployerUsersQueryVariables
  >
    query={allEmployerUsersDocument}
    {...props}
  />
);

export type allEmployerUsersProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    allEmployerUsersQuery,
    allEmployerUsersQueryVariables
  >;
} &
  TChildProps;
export function withallEmployerUsers<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    allEmployerUsersQuery,
    allEmployerUsersQueryVariables,
    allEmployerUsersProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    allEmployerUsersQuery,
    allEmployerUsersQueryVariables,
    allEmployerUsersProps<TChildProps, TDataName>
  >(allEmployerUsersDocument, {
    alias: 'allEmployerUsers',
    ...operationOptions,
  });
}

/**
 * __useallEmployerUsersQuery__
 *
 * To run a query within a React component, call `useallEmployerUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useallEmployerUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useallEmployerUsersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useallEmployerUsersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    allEmployerUsersQuery,
    allEmployerUsersQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    allEmployerUsersQuery,
    allEmployerUsersQueryVariables
  >(allEmployerUsersDocument, baseOptions);
}
export function useallEmployerUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    allEmployerUsersQuery,
    allEmployerUsersQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    allEmployerUsersQuery,
    allEmployerUsersQueryVariables
  >(allEmployerUsersDocument, baseOptions);
}
export type allEmployerUsersQueryHookResult = ReturnType<
  typeof useallEmployerUsersQuery
>;
export type allEmployerUsersLazyQueryHookResult = ReturnType<
  typeof useallEmployerUsersLazyQuery
>;
export type allEmployerUsersQueryResult = ApolloReactCommon.QueryResult<
  allEmployerUsersQuery,
  allEmployerUsersQueryVariables
>;
export const allEmploymentExceptionsDocument = gql`
  query allEmploymentExceptions(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: EmploymentExceptionFilter
  ) {
    items: allEmploymentExceptions(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      ...AdminEmploymentExceptionFields
    }
    total: _allEmploymentExceptionsMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
      __typename
    }
  }
  ${AdminEmploymentExceptionFieldsFragmentDoc}
`;
export type allEmploymentExceptionsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    allEmploymentExceptionsQuery,
    allEmploymentExceptionsQueryVariables
  >,
  'query'
>;

export const allEmploymentExceptionsComponent = (
  props: allEmploymentExceptionsComponentProps,
) => (
  <ApolloReactComponents.Query<
    allEmploymentExceptionsQuery,
    allEmploymentExceptionsQueryVariables
  >
    query={allEmploymentExceptionsDocument}
    {...props}
  />
);

export type allEmploymentExceptionsProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    allEmploymentExceptionsQuery,
    allEmploymentExceptionsQueryVariables
  >;
} &
  TChildProps;
export function withallEmploymentExceptions<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    allEmploymentExceptionsQuery,
    allEmploymentExceptionsQueryVariables,
    allEmploymentExceptionsProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    allEmploymentExceptionsQuery,
    allEmploymentExceptionsQueryVariables,
    allEmploymentExceptionsProps<TChildProps, TDataName>
  >(allEmploymentExceptionsDocument, {
    alias: 'allEmploymentExceptions',
    ...operationOptions,
  });
}

/**
 * __useallEmploymentExceptionsQuery__
 *
 * To run a query within a React component, call `useallEmploymentExceptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useallEmploymentExceptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useallEmploymentExceptionsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useallEmploymentExceptionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    allEmploymentExceptionsQuery,
    allEmploymentExceptionsQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    allEmploymentExceptionsQuery,
    allEmploymentExceptionsQueryVariables
  >(allEmploymentExceptionsDocument, baseOptions);
}
export function useallEmploymentExceptionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    allEmploymentExceptionsQuery,
    allEmploymentExceptionsQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    allEmploymentExceptionsQuery,
    allEmploymentExceptionsQueryVariables
  >(allEmploymentExceptionsDocument, baseOptions);
}
export type allEmploymentExceptionsQueryHookResult = ReturnType<
  typeof useallEmploymentExceptionsQuery
>;
export type allEmploymentExceptionsLazyQueryHookResult = ReturnType<
  typeof useallEmploymentExceptionsLazyQuery
>;
export type allEmploymentExceptionsQueryResult = ApolloReactCommon.QueryResult<
  allEmploymentExceptionsQuery,
  allEmploymentExceptionsQueryVariables
>;
export const EmploymentExceptionDocument = gql`
  query EmploymentException($id: ID!) {
    data: EmploymentException(id: $id) {
      ...AdminEmploymentExceptionFields
    }
  }
  ${AdminEmploymentExceptionFieldsFragmentDoc}
`;
export type EmploymentExceptionComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    EmploymentExceptionQuery,
    EmploymentExceptionQueryVariables
  >,
  'query'
> &
  (
    | { variables: EmploymentExceptionQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const EmploymentExceptionComponent = (
  props: EmploymentExceptionComponentProps,
) => (
  <ApolloReactComponents.Query<
    EmploymentExceptionQuery,
    EmploymentExceptionQueryVariables
  >
    query={EmploymentExceptionDocument}
    {...props}
  />
);

export type EmploymentExceptionProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    EmploymentExceptionQuery,
    EmploymentExceptionQueryVariables
  >;
} &
  TChildProps;
export function withEmploymentException<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    EmploymentExceptionQuery,
    EmploymentExceptionQueryVariables,
    EmploymentExceptionProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    EmploymentExceptionQuery,
    EmploymentExceptionQueryVariables,
    EmploymentExceptionProps<TChildProps, TDataName>
  >(EmploymentExceptionDocument, {
    alias: 'employmentException',
    ...operationOptions,
  });
}

/**
 * __useEmploymentExceptionQuery__
 *
 * To run a query within a React component, call `useEmploymentExceptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmploymentExceptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmploymentExceptionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEmploymentExceptionQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    EmploymentExceptionQuery,
    EmploymentExceptionQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    EmploymentExceptionQuery,
    EmploymentExceptionQueryVariables
  >(EmploymentExceptionDocument, baseOptions);
}
export function useEmploymentExceptionLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EmploymentExceptionQuery,
    EmploymentExceptionQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    EmploymentExceptionQuery,
    EmploymentExceptionQueryVariables
  >(EmploymentExceptionDocument, baseOptions);
}
export type EmploymentExceptionQueryHookResult = ReturnType<
  typeof useEmploymentExceptionQuery
>;
export type EmploymentExceptionLazyQueryHookResult = ReturnType<
  typeof useEmploymentExceptionLazyQuery
>;
export type EmploymentExceptionQueryResult = ApolloReactCommon.QueryResult<
  EmploymentExceptionQuery,
  EmploymentExceptionQueryVariables
>;
export const createEmploymentExceptionDocument = gql`
  mutation createEmploymentException(
    $name: String!
    $category: EmploymentExceptionCategoryInputType
  ) {
    data: createEmploymentException(name: $name, category: $category) {
      ...AdminEmploymentExceptionFields
    }
  }
  ${AdminEmploymentExceptionFieldsFragmentDoc}
`;
export type createEmploymentExceptionMutationFn = ApolloReactCommon.MutationFunction<
  createEmploymentExceptionMutation,
  createEmploymentExceptionMutationVariables
>;
export type createEmploymentExceptionComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    createEmploymentExceptionMutation,
    createEmploymentExceptionMutationVariables
  >,
  'mutation'
>;

export const createEmploymentExceptionComponent = (
  props: createEmploymentExceptionComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    createEmploymentExceptionMutation,
    createEmploymentExceptionMutationVariables
  >
    mutation={createEmploymentExceptionDocument}
    {...props}
  />
);

export type createEmploymentExceptionProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    createEmploymentExceptionMutation,
    createEmploymentExceptionMutationVariables
  >;
} &
  TChildProps;
export function withcreateEmploymentException<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    createEmploymentExceptionMutation,
    createEmploymentExceptionMutationVariables,
    createEmploymentExceptionProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    createEmploymentExceptionMutation,
    createEmploymentExceptionMutationVariables,
    createEmploymentExceptionProps<TChildProps, TDataName>
  >(createEmploymentExceptionDocument, {
    alias: 'createEmploymentException',
    ...operationOptions,
  });
}

/**
 * __usecreateEmploymentExceptionMutation__
 *
 * To run a mutation, you first call `usecreateEmploymentExceptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usecreateEmploymentExceptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmploymentExceptionMutation, { data, loading, error }] = usecreateEmploymentExceptionMutation({
 *   variables: {
 *      name: // value for 'name'
 *      category: // value for 'category'
 *   },
 * });
 */
export function usecreateEmploymentExceptionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    createEmploymentExceptionMutation,
    createEmploymentExceptionMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    createEmploymentExceptionMutation,
    createEmploymentExceptionMutationVariables
  >(createEmploymentExceptionDocument, baseOptions);
}
export type createEmploymentExceptionMutationHookResult = ReturnType<
  typeof usecreateEmploymentExceptionMutation
>;
export type createEmploymentExceptionMutationResult = ApolloReactCommon.MutationResult<createEmploymentExceptionMutation>;
export type createEmploymentExceptionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  createEmploymentExceptionMutation,
  createEmploymentExceptionMutationVariables
>;
export const updateEmploymentExceptionDocument = gql`
  mutation updateEmploymentException(
    $id: ID!
    $name: String!
    $category: EmploymentExceptionCategoryInputType
  ) {
    data: updateEmploymentException(id: $id, name: $name, category: $category) {
      ...AdminEmploymentExceptionFields
    }
  }
  ${AdminEmploymentExceptionFieldsFragmentDoc}
`;
export type updateEmploymentExceptionMutationFn = ApolloReactCommon.MutationFunction<
  updateEmploymentExceptionMutation,
  updateEmploymentExceptionMutationVariables
>;
export type updateEmploymentExceptionComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    updateEmploymentExceptionMutation,
    updateEmploymentExceptionMutationVariables
  >,
  'mutation'
>;

export const updateEmploymentExceptionComponent = (
  props: updateEmploymentExceptionComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    updateEmploymentExceptionMutation,
    updateEmploymentExceptionMutationVariables
  >
    mutation={updateEmploymentExceptionDocument}
    {...props}
  />
);

export type updateEmploymentExceptionProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    updateEmploymentExceptionMutation,
    updateEmploymentExceptionMutationVariables
  >;
} &
  TChildProps;
export function withupdateEmploymentException<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    updateEmploymentExceptionMutation,
    updateEmploymentExceptionMutationVariables,
    updateEmploymentExceptionProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    updateEmploymentExceptionMutation,
    updateEmploymentExceptionMutationVariables,
    updateEmploymentExceptionProps<TChildProps, TDataName>
  >(updateEmploymentExceptionDocument, {
    alias: 'updateEmploymentException',
    ...operationOptions,
  });
}

/**
 * __useupdateEmploymentExceptionMutation__
 *
 * To run a mutation, you first call `useupdateEmploymentExceptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useupdateEmploymentExceptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmploymentExceptionMutation, { data, loading, error }] = useupdateEmploymentExceptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      category: // value for 'category'
 *   },
 * });
 */
export function useupdateEmploymentExceptionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    updateEmploymentExceptionMutation,
    updateEmploymentExceptionMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    updateEmploymentExceptionMutation,
    updateEmploymentExceptionMutationVariables
  >(updateEmploymentExceptionDocument, baseOptions);
}
export type updateEmploymentExceptionMutationHookResult = ReturnType<
  typeof useupdateEmploymentExceptionMutation
>;
export type updateEmploymentExceptionMutationResult = ApolloReactCommon.MutationResult<updateEmploymentExceptionMutation>;
export type updateEmploymentExceptionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  updateEmploymentExceptionMutation,
  updateEmploymentExceptionMutationVariables
>;
export const deleteEmploymentExceptionDocument = gql`
  mutation deleteEmploymentException($id: ID!) {
    data: deleteEmploymentException(id: $id) {
      ...AdminEmploymentExceptionFields
    }
  }
  ${AdminEmploymentExceptionFieldsFragmentDoc}
`;
export type deleteEmploymentExceptionMutationFn = ApolloReactCommon.MutationFunction<
  deleteEmploymentExceptionMutation,
  deleteEmploymentExceptionMutationVariables
>;
export type deleteEmploymentExceptionComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    deleteEmploymentExceptionMutation,
    deleteEmploymentExceptionMutationVariables
  >,
  'mutation'
>;

export const deleteEmploymentExceptionComponent = (
  props: deleteEmploymentExceptionComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    deleteEmploymentExceptionMutation,
    deleteEmploymentExceptionMutationVariables
  >
    mutation={deleteEmploymentExceptionDocument}
    {...props}
  />
);

export type deleteEmploymentExceptionProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    deleteEmploymentExceptionMutation,
    deleteEmploymentExceptionMutationVariables
  >;
} &
  TChildProps;
export function withdeleteEmploymentException<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    deleteEmploymentExceptionMutation,
    deleteEmploymentExceptionMutationVariables,
    deleteEmploymentExceptionProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    deleteEmploymentExceptionMutation,
    deleteEmploymentExceptionMutationVariables,
    deleteEmploymentExceptionProps<TChildProps, TDataName>
  >(deleteEmploymentExceptionDocument, {
    alias: 'deleteEmploymentException',
    ...operationOptions,
  });
}

/**
 * __usedeleteEmploymentExceptionMutation__
 *
 * To run a mutation, you first call `usedeleteEmploymentExceptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usedeleteEmploymentExceptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmploymentExceptionMutation, { data, loading, error }] = usedeleteEmploymentExceptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usedeleteEmploymentExceptionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    deleteEmploymentExceptionMutation,
    deleteEmploymentExceptionMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    deleteEmploymentExceptionMutation,
    deleteEmploymentExceptionMutationVariables
  >(deleteEmploymentExceptionDocument, baseOptions);
}
export type deleteEmploymentExceptionMutationHookResult = ReturnType<
  typeof usedeleteEmploymentExceptionMutation
>;
export type deleteEmploymentExceptionMutationResult = ApolloReactCommon.MutationResult<deleteEmploymentExceptionMutation>;
export type deleteEmploymentExceptionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  deleteEmploymentExceptionMutation,
  deleteEmploymentExceptionMutationVariables
>;
export const allLanguagesDocument = gql`
  query allLanguages(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: LanguageFilter
  ) {
    items: allLanguages(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      ...AdminLanguageFields
    }
    total: _allLanguagesMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
      __typename
    }
  }
  ${AdminLanguageFieldsFragmentDoc}
`;
export type allLanguagesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    allLanguagesQuery,
    allLanguagesQueryVariables
  >,
  'query'
>;

export const allLanguagesComponent = (props: allLanguagesComponentProps) => (
  <ApolloReactComponents.Query<allLanguagesQuery, allLanguagesQueryVariables>
    query={allLanguagesDocument}
    {...props}
  />
);

export type allLanguagesProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    allLanguagesQuery,
    allLanguagesQueryVariables
  >;
} &
  TChildProps;
export function withallLanguages<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    allLanguagesQuery,
    allLanguagesQueryVariables,
    allLanguagesProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    allLanguagesQuery,
    allLanguagesQueryVariables,
    allLanguagesProps<TChildProps, TDataName>
  >(allLanguagesDocument, {
    alias: 'allLanguages',
    ...operationOptions,
  });
}

/**
 * __useallLanguagesQuery__
 *
 * To run a query within a React component, call `useallLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useallLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useallLanguagesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useallLanguagesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    allLanguagesQuery,
    allLanguagesQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    allLanguagesQuery,
    allLanguagesQueryVariables
  >(allLanguagesDocument, baseOptions);
}
export function useallLanguagesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    allLanguagesQuery,
    allLanguagesQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    allLanguagesQuery,
    allLanguagesQueryVariables
  >(allLanguagesDocument, baseOptions);
}
export type allLanguagesQueryHookResult = ReturnType<
  typeof useallLanguagesQuery
>;
export type allLanguagesLazyQueryHookResult = ReturnType<
  typeof useallLanguagesLazyQuery
>;
export type allLanguagesQueryResult = ApolloReactCommon.QueryResult<
  allLanguagesQuery,
  allLanguagesQueryVariables
>;
export const LanguageDocument = gql`
  query Language($id: ID!) {
    data: Language(id: $id) {
      ...AdminLanguageFields
    }
  }
  ${AdminLanguageFieldsFragmentDoc}
`;
export type LanguageComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    LanguageQuery,
    LanguageQueryVariables
  >,
  'query'
> &
  ({ variables: LanguageQueryVariables; skip?: boolean } | { skip: boolean });

export const LanguageComponent = (props: LanguageComponentProps) => (
  <ApolloReactComponents.Query<LanguageQuery, LanguageQueryVariables>
    query={LanguageDocument}
    {...props}
  />
);

export type LanguageProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    LanguageQuery,
    LanguageQueryVariables
  >;
} &
  TChildProps;
export function withLanguage<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    LanguageQuery,
    LanguageQueryVariables,
    LanguageProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    LanguageQuery,
    LanguageQueryVariables,
    LanguageProps<TChildProps, TDataName>
  >(LanguageDocument, {
    alias: 'language',
    ...operationOptions,
  });
}

/**
 * __useLanguageQuery__
 *
 * To run a query within a React component, call `useLanguageQuery` and pass it any options that fit your needs.
 * When your component renders, `useLanguageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLanguageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLanguageQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    LanguageQuery,
    LanguageQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<LanguageQuery, LanguageQueryVariables>(
    LanguageDocument,
    baseOptions,
  );
}
export function useLanguageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LanguageQuery,
    LanguageQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<LanguageQuery, LanguageQueryVariables>(
    LanguageDocument,
    baseOptions,
  );
}
export type LanguageQueryHookResult = ReturnType<typeof useLanguageQuery>;
export type LanguageLazyQueryHookResult = ReturnType<
  typeof useLanguageLazyQuery
>;
export type LanguageQueryResult = ApolloReactCommon.QueryResult<
  LanguageQuery,
  LanguageQueryVariables
>;
export const allNotificationStudentOrEmployerUsersDocument = gql`
  query allNotificationStudentOrEmployerUsers($filter: StintUserFilter) {
    total: _allStudentOrEmployerUsersMeta(filter: $filter) {
      count
      __typename
    }
  }
`;
export type allNotificationStudentOrEmployerUsersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    allNotificationStudentOrEmployerUsersQuery,
    allNotificationStudentOrEmployerUsersQueryVariables
  >,
  'query'
>;

export const allNotificationStudentOrEmployerUsersComponent = (
  props: allNotificationStudentOrEmployerUsersComponentProps,
) => (
  <ApolloReactComponents.Query<
    allNotificationStudentOrEmployerUsersQuery,
    allNotificationStudentOrEmployerUsersQueryVariables
  >
    query={allNotificationStudentOrEmployerUsersDocument}
    {...props}
  />
);

export type allNotificationStudentOrEmployerUsersProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    allNotificationStudentOrEmployerUsersQuery,
    allNotificationStudentOrEmployerUsersQueryVariables
  >;
} &
  TChildProps;
export function withallNotificationStudentOrEmployerUsers<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    allNotificationStudentOrEmployerUsersQuery,
    allNotificationStudentOrEmployerUsersQueryVariables,
    allNotificationStudentOrEmployerUsersProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    allNotificationStudentOrEmployerUsersQuery,
    allNotificationStudentOrEmployerUsersQueryVariables,
    allNotificationStudentOrEmployerUsersProps<TChildProps, TDataName>
  >(allNotificationStudentOrEmployerUsersDocument, {
    alias: 'allNotificationStudentOrEmployerUsers',
    ...operationOptions,
  });
}

/**
 * __useallNotificationStudentOrEmployerUsersQuery__
 *
 * To run a query within a React component, call `useallNotificationStudentOrEmployerUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useallNotificationStudentOrEmployerUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useallNotificationStudentOrEmployerUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useallNotificationStudentOrEmployerUsersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    allNotificationStudentOrEmployerUsersQuery,
    allNotificationStudentOrEmployerUsersQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    allNotificationStudentOrEmployerUsersQuery,
    allNotificationStudentOrEmployerUsersQueryVariables
  >(allNotificationStudentOrEmployerUsersDocument, baseOptions);
}
export function useallNotificationStudentOrEmployerUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    allNotificationStudentOrEmployerUsersQuery,
    allNotificationStudentOrEmployerUsersQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    allNotificationStudentOrEmployerUsersQuery,
    allNotificationStudentOrEmployerUsersQueryVariables
  >(allNotificationStudentOrEmployerUsersDocument, baseOptions);
}
export type allNotificationStudentOrEmployerUsersQueryHookResult = ReturnType<
  typeof useallNotificationStudentOrEmployerUsersQuery
>;
export type allNotificationStudentOrEmployerUsersLazyQueryHookResult = ReturnType<
  typeof useallNotificationStudentOrEmployerUsersLazyQuery
>;
export type allNotificationStudentOrEmployerUsersQueryResult = ApolloReactCommon.QueryResult<
  allNotificationStudentOrEmployerUsersQuery,
  allNotificationStudentOrEmployerUsersQueryVariables
>;
export const allNotificationsDocument = gql`
  query allNotifications(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: NotificationFilter
  ) {
    items: allNotifications(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      ...AdminNotificationFields
    }
    total: _allNotificationsMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
      __typename
    }
  }
  ${AdminNotificationFieldsFragmentDoc}
`;
export type allNotificationsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    allNotificationsQuery,
    allNotificationsQueryVariables
  >,
  'query'
>;

export const allNotificationsComponent = (
  props: allNotificationsComponentProps,
) => (
  <ApolloReactComponents.Query<
    allNotificationsQuery,
    allNotificationsQueryVariables
  >
    query={allNotificationsDocument}
    {...props}
  />
);

export type allNotificationsProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    allNotificationsQuery,
    allNotificationsQueryVariables
  >;
} &
  TChildProps;
export function withallNotifications<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    allNotificationsQuery,
    allNotificationsQueryVariables,
    allNotificationsProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    allNotificationsQuery,
    allNotificationsQueryVariables,
    allNotificationsProps<TChildProps, TDataName>
  >(allNotificationsDocument, {
    alias: 'allNotifications',
    ...operationOptions,
  });
}

/**
 * __useallNotificationsQuery__
 *
 * To run a query within a React component, call `useallNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useallNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useallNotificationsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useallNotificationsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    allNotificationsQuery,
    allNotificationsQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    allNotificationsQuery,
    allNotificationsQueryVariables
  >(allNotificationsDocument, baseOptions);
}
export function useallNotificationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    allNotificationsQuery,
    allNotificationsQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    allNotificationsQuery,
    allNotificationsQueryVariables
  >(allNotificationsDocument, baseOptions);
}
export type allNotificationsQueryHookResult = ReturnType<
  typeof useallNotificationsQuery
>;
export type allNotificationsLazyQueryHookResult = ReturnType<
  typeof useallNotificationsLazyQuery
>;
export type allNotificationsQueryResult = ApolloReactCommon.QueryResult<
  allNotificationsQuery,
  allNotificationsQueryVariables
>;
export const NotificationDocument = gql`
  query Notification($id: ID!) {
    data: Notification(id: $id) {
      ...AdminNotificationFields
    }
  }
  ${AdminNotificationFieldsFragmentDoc}
`;
export type NotificationComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    NotificationQuery,
    NotificationQueryVariables
  >,
  'query'
> &
  (
    | { variables: NotificationQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const NotificationComponent = (props: NotificationComponentProps) => (
  <ApolloReactComponents.Query<NotificationQuery, NotificationQueryVariables>
    query={NotificationDocument}
    {...props}
  />
);

export type NotificationProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    NotificationQuery,
    NotificationQueryVariables
  >;
} &
  TChildProps;
export function withNotification<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    NotificationQuery,
    NotificationQueryVariables,
    NotificationProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    NotificationQuery,
    NotificationQueryVariables,
    NotificationProps<TChildProps, TDataName>
  >(NotificationDocument, {
    alias: 'notification',
    ...operationOptions,
  });
}

/**
 * __useNotificationQuery__
 *
 * To run a query within a React component, call `useNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNotificationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    NotificationQuery,
    NotificationQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    NotificationQuery,
    NotificationQueryVariables
  >(NotificationDocument, baseOptions);
}
export function useNotificationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    NotificationQuery,
    NotificationQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    NotificationQuery,
    NotificationQueryVariables
  >(NotificationDocument, baseOptions);
}
export type NotificationQueryHookResult = ReturnType<
  typeof useNotificationQuery
>;
export type NotificationLazyQueryHookResult = ReturnType<
  typeof useNotificationLazyQuery
>;
export type NotificationQueryResult = ApolloReactCommon.QueryResult<
  NotificationQuery,
  NotificationQueryVariables
>;
export const createNotificationDocument = gql`
  mutation createNotification(
    $title: String!
    $message: String!
    $stint_id: ID
    $student_ids: [String]!
    $sendPushNotification: Boolean
  ) {
    data: createNotification(
      title: $title
      message: $message
      sendPushNotification: $sendPushNotification
      student_ids: $student_ids
      stint_id: $stint_id
    ) {
      ...AdminNotificationFields
    }
  }
  ${AdminNotificationFieldsFragmentDoc}
`;
export type createNotificationMutationFn = ApolloReactCommon.MutationFunction<
  createNotificationMutation,
  createNotificationMutationVariables
>;
export type createNotificationComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    createNotificationMutation,
    createNotificationMutationVariables
  >,
  'mutation'
>;

export const createNotificationComponent = (
  props: createNotificationComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    createNotificationMutation,
    createNotificationMutationVariables
  >
    mutation={createNotificationDocument}
    {...props}
  />
);

export type createNotificationProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    createNotificationMutation,
    createNotificationMutationVariables
  >;
} &
  TChildProps;
export function withcreateNotification<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    createNotificationMutation,
    createNotificationMutationVariables,
    createNotificationProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    createNotificationMutation,
    createNotificationMutationVariables,
    createNotificationProps<TChildProps, TDataName>
  >(createNotificationDocument, {
    alias: 'createNotification',
    ...operationOptions,
  });
}

/**
 * __usecreateNotificationMutation__
 *
 * To run a mutation, you first call `usecreateNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usecreateNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNotificationMutation, { data, loading, error }] = usecreateNotificationMutation({
 *   variables: {
 *      title: // value for 'title'
 *      message: // value for 'message'
 *      stint_id: // value for 'stint_id'
 *      student_ids: // value for 'student_ids'
 *      sendPushNotification: // value for 'sendPushNotification'
 *   },
 * });
 */
export function usecreateNotificationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    createNotificationMutation,
    createNotificationMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    createNotificationMutation,
    createNotificationMutationVariables
  >(createNotificationDocument, baseOptions);
}
export type createNotificationMutationHookResult = ReturnType<
  typeof usecreateNotificationMutation
>;
export type createNotificationMutationResult = ApolloReactCommon.MutationResult<createNotificationMutation>;
export type createNotificationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  createNotificationMutation,
  createNotificationMutationVariables
>;
export const CreateOffenceDocument = gql`
  mutation CreateOffence($createInputs: CreateOffenceInput!) {
    data: createOffence(createInputs: $createInputs) {
      id
      appealStatus
      business {
        id
        name
      }
      createdAt
      reason
      stint {
        id
        stintTimesDisplay
      }
      strikesAwarded
      createdBy {
        id
        fullname
      }
    }
  }
`;
export type CreateOffenceMutationFn = ApolloReactCommon.MutationFunction<
  CreateOffenceMutation,
  CreateOffenceMutationVariables
>;
export type CreateOffenceComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateOffenceMutation,
    CreateOffenceMutationVariables
  >,
  'mutation'
>;

export const CreateOffenceComponent = (props: CreateOffenceComponentProps) => (
  <ApolloReactComponents.Mutation<
    CreateOffenceMutation,
    CreateOffenceMutationVariables
  >
    mutation={CreateOffenceDocument}
    {...props}
  />
);

export type CreateOffenceProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    CreateOffenceMutation,
    CreateOffenceMutationVariables
  >;
} &
  TChildProps;
export function withCreateOffence<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateOffenceMutation,
    CreateOffenceMutationVariables,
    CreateOffenceProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateOffenceMutation,
    CreateOffenceMutationVariables,
    CreateOffenceProps<TChildProps, TDataName>
  >(CreateOffenceDocument, {
    alias: 'createOffence',
    ...operationOptions,
  });
}

/**
 * __useCreateOffenceMutation__
 *
 * To run a mutation, you first call `useCreateOffenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOffenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOffenceMutation, { data, loading, error }] = useCreateOffenceMutation({
 *   variables: {
 *      createInputs: // value for 'createInputs'
 *   },
 * });
 */
export function useCreateOffenceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateOffenceMutation,
    CreateOffenceMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CreateOffenceMutation,
    CreateOffenceMutationVariables
  >(CreateOffenceDocument, baseOptions);
}
export type CreateOffenceMutationHookResult = ReturnType<
  typeof useCreateOffenceMutation
>;
export type CreateOffenceMutationResult = ApolloReactCommon.MutationResult<CreateOffenceMutation>;
export type CreateOffenceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateOffenceMutation,
  CreateOffenceMutationVariables
>;
export const OffenceDocument = gql`
  query Offence($id: ID!) {
    data: offence(offenceId: $id) {
      ...OffenceBasicFields
      createdAt
      reason
      createdBy {
        id
        fullname
      }
      appeals {
        id
        status
        date
        notes
        decisionAt
        decisionBy {
          id
          fullname
        }
      }
    }
  }
  ${OffenceBasicFieldsFragmentDoc}
`;
export type OffenceComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    OffenceQuery,
    OffenceQueryVariables
  >,
  'query'
> &
  ({ variables: OffenceQueryVariables; skip?: boolean } | { skip: boolean });

export const OffenceComponent = (props: OffenceComponentProps) => (
  <ApolloReactComponents.Query<OffenceQuery, OffenceQueryVariables>
    query={OffenceDocument}
    {...props}
  />
);

export type OffenceProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    OffenceQuery,
    OffenceQueryVariables
  >;
} &
  TChildProps;
export function withOffence<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    OffenceQuery,
    OffenceQueryVariables,
    OffenceProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    OffenceQuery,
    OffenceQueryVariables,
    OffenceProps<TChildProps, TDataName>
  >(OffenceDocument, {
    alias: 'offence',
    ...operationOptions,
  });
}

/**
 * __useOffenceQuery__
 *
 * To run a query within a React component, call `useOffenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useOffenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOffenceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOffenceQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OffenceQuery,
    OffenceQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<OffenceQuery, OffenceQueryVariables>(
    OffenceDocument,
    baseOptions,
  );
}
export function useOffenceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OffenceQuery,
    OffenceQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<OffenceQuery, OffenceQueryVariables>(
    OffenceDocument,
    baseOptions,
  );
}
export type OffenceQueryHookResult = ReturnType<typeof useOffenceQuery>;
export type OffenceLazyQueryHookResult = ReturnType<typeof useOffenceLazyQuery>;
export type OffenceQueryResult = ApolloReactCommon.QueryResult<
  OffenceQuery,
  OffenceQueryVariables
>;
export const stinterOffencesDocument = gql`
  query stinterOffences($id: ID!, $perPage: Int!) {
    data: stinterOffences(stinterProfileId: $id, first: $perPage) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...OffenceBasicFields
        }
      }
    }
  }
  ${OffenceBasicFieldsFragmentDoc}
`;
export type stinterOffencesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    stinterOffencesQuery,
    stinterOffencesQueryVariables
  >,
  'query'
> &
  (
    | { variables: stinterOffencesQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const stinterOffencesComponent = (
  props: stinterOffencesComponentProps,
) => (
  <ApolloReactComponents.Query<
    stinterOffencesQuery,
    stinterOffencesQueryVariables
  >
    query={stinterOffencesDocument}
    {...props}
  />
);

export type stinterOffencesProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    stinterOffencesQuery,
    stinterOffencesQueryVariables
  >;
} &
  TChildProps;
export function withstinterOffences<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    stinterOffencesQuery,
    stinterOffencesQueryVariables,
    stinterOffencesProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    stinterOffencesQuery,
    stinterOffencesQueryVariables,
    stinterOffencesProps<TChildProps, TDataName>
  >(stinterOffencesDocument, {
    alias: 'stinterOffences',
    ...operationOptions,
  });
}

/**
 * __usestinterOffencesQuery__
 *
 * To run a query within a React component, call `usestinterOffencesQuery` and pass it any options that fit your needs.
 * When your component renders, `usestinterOffencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usestinterOffencesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function usestinterOffencesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    stinterOffencesQuery,
    stinterOffencesQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    stinterOffencesQuery,
    stinterOffencesQueryVariables
  >(stinterOffencesDocument, baseOptions);
}
export function usestinterOffencesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    stinterOffencesQuery,
    stinterOffencesQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    stinterOffencesQuery,
    stinterOffencesQueryVariables
  >(stinterOffencesDocument, baseOptions);
}
export type stinterOffencesQueryHookResult = ReturnType<
  typeof usestinterOffencesQuery
>;
export type stinterOffencesLazyQueryHookResult = ReturnType<
  typeof usestinterOffencesLazyQuery
>;
export type stinterOffencesQueryResult = ApolloReactCommon.QueryResult<
  stinterOffencesQuery,
  stinterOffencesQueryVariables
>;
export const allPeriodicTasksDocument = gql`
  query allPeriodicTasks($page: Int, $perPage: Int) {
    items: allPeriodicTasks(page: $page, perPage: $perPage) {
      ...AdminPeriodicTaskFields
    }
    total: _allPeriodicTasksMeta(page: $page, perPage: $perPage) {
      count
      __typename
    }
  }
  ${AdminPeriodicTaskFieldsFragmentDoc}
`;
export type allPeriodicTasksComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    allPeriodicTasksQuery,
    allPeriodicTasksQueryVariables
  >,
  'query'
>;

export const allPeriodicTasksComponent = (
  props: allPeriodicTasksComponentProps,
) => (
  <ApolloReactComponents.Query<
    allPeriodicTasksQuery,
    allPeriodicTasksQueryVariables
  >
    query={allPeriodicTasksDocument}
    {...props}
  />
);

export type allPeriodicTasksProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    allPeriodicTasksQuery,
    allPeriodicTasksQueryVariables
  >;
} &
  TChildProps;
export function withallPeriodicTasks<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    allPeriodicTasksQuery,
    allPeriodicTasksQueryVariables,
    allPeriodicTasksProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    allPeriodicTasksQuery,
    allPeriodicTasksQueryVariables,
    allPeriodicTasksProps<TChildProps, TDataName>
  >(allPeriodicTasksDocument, {
    alias: 'allPeriodicTasks',
    ...operationOptions,
  });
}

/**
 * __useallPeriodicTasksQuery__
 *
 * To run a query within a React component, call `useallPeriodicTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useallPeriodicTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useallPeriodicTasksQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useallPeriodicTasksQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    allPeriodicTasksQuery,
    allPeriodicTasksQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    allPeriodicTasksQuery,
    allPeriodicTasksQueryVariables
  >(allPeriodicTasksDocument, baseOptions);
}
export function useallPeriodicTasksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    allPeriodicTasksQuery,
    allPeriodicTasksQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    allPeriodicTasksQuery,
    allPeriodicTasksQueryVariables
  >(allPeriodicTasksDocument, baseOptions);
}
export type allPeriodicTasksQueryHookResult = ReturnType<
  typeof useallPeriodicTasksQuery
>;
export type allPeriodicTasksLazyQueryHookResult = ReturnType<
  typeof useallPeriodicTasksLazyQuery
>;
export type allPeriodicTasksQueryResult = ApolloReactCommon.QueryResult<
  allPeriodicTasksQuery,
  allPeriodicTasksQueryVariables
>;
export const PeriodicTaskDocument = gql`
  query PeriodicTask($id: ID!) {
    data: PeriodicTask(id: $id) {
      ...AdminPeriodicTaskFields
    }
  }
  ${AdminPeriodicTaskFieldsFragmentDoc}
`;
export type PeriodicTaskComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    PeriodicTaskQuery,
    PeriodicTaskQueryVariables
  >,
  'query'
> &
  (
    | { variables: PeriodicTaskQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const PeriodicTaskComponent = (props: PeriodicTaskComponentProps) => (
  <ApolloReactComponents.Query<PeriodicTaskQuery, PeriodicTaskQueryVariables>
    query={PeriodicTaskDocument}
    {...props}
  />
);

export type PeriodicTaskProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    PeriodicTaskQuery,
    PeriodicTaskQueryVariables
  >;
} &
  TChildProps;
export function withPeriodicTask<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    PeriodicTaskQuery,
    PeriodicTaskQueryVariables,
    PeriodicTaskProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    PeriodicTaskQuery,
    PeriodicTaskQueryVariables,
    PeriodicTaskProps<TChildProps, TDataName>
  >(PeriodicTaskDocument, {
    alias: 'periodicTask',
    ...operationOptions,
  });
}

/**
 * __usePeriodicTaskQuery__
 *
 * To run a query within a React component, call `usePeriodicTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `usePeriodicTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePeriodicTaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePeriodicTaskQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    PeriodicTaskQuery,
    PeriodicTaskQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    PeriodicTaskQuery,
    PeriodicTaskQueryVariables
  >(PeriodicTaskDocument, baseOptions);
}
export function usePeriodicTaskLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PeriodicTaskQuery,
    PeriodicTaskQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    PeriodicTaskQuery,
    PeriodicTaskQueryVariables
  >(PeriodicTaskDocument, baseOptions);
}
export type PeriodicTaskQueryHookResult = ReturnType<
  typeof usePeriodicTaskQuery
>;
export type PeriodicTaskLazyQueryHookResult = ReturnType<
  typeof usePeriodicTaskLazyQuery
>;
export type PeriodicTaskQueryResult = ApolloReactCommon.QueryResult<
  PeriodicTaskQuery,
  PeriodicTaskQueryVariables
>;
export const allPostalCodesDocument = gql`
  query allPostalCodes(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: PostalCodeFilter
  ) {
    items: allPostalCodes(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      ...AdminPostalCodeFields
    }
    total: _allPostalCodesMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
      __typename
    }
  }
  ${AdminPostalCodeFieldsFragmentDoc}
`;
export type allPostalCodesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    allPostalCodesQuery,
    allPostalCodesQueryVariables
  >,
  'query'
>;

export const allPostalCodesComponent = (
  props: allPostalCodesComponentProps,
) => (
  <ApolloReactComponents.Query<
    allPostalCodesQuery,
    allPostalCodesQueryVariables
  >
    query={allPostalCodesDocument}
    {...props}
  />
);

export type allPostalCodesProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    allPostalCodesQuery,
    allPostalCodesQueryVariables
  >;
} &
  TChildProps;
export function withallPostalCodes<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    allPostalCodesQuery,
    allPostalCodesQueryVariables,
    allPostalCodesProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    allPostalCodesQuery,
    allPostalCodesQueryVariables,
    allPostalCodesProps<TChildProps, TDataName>
  >(allPostalCodesDocument, {
    alias: 'allPostalCodes',
    ...operationOptions,
  });
}

/**
 * __useallPostalCodesQuery__
 *
 * To run a query within a React component, call `useallPostalCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useallPostalCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useallPostalCodesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useallPostalCodesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    allPostalCodesQuery,
    allPostalCodesQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    allPostalCodesQuery,
    allPostalCodesQueryVariables
  >(allPostalCodesDocument, baseOptions);
}
export function useallPostalCodesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    allPostalCodesQuery,
    allPostalCodesQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    allPostalCodesQuery,
    allPostalCodesQueryVariables
  >(allPostalCodesDocument, baseOptions);
}
export type allPostalCodesQueryHookResult = ReturnType<
  typeof useallPostalCodesQuery
>;
export type allPostalCodesLazyQueryHookResult = ReturnType<
  typeof useallPostalCodesLazyQuery
>;
export type allPostalCodesQueryResult = ApolloReactCommon.QueryResult<
  allPostalCodesQuery,
  allPostalCodesQueryVariables
>;
export const PostalCodeDocument = gql`
  query PostalCode($id: ID!) {
    data: PostalCode(id: $id) {
      ...AdminPostalCodeFields
    }
  }
  ${AdminPostalCodeFieldsFragmentDoc}
`;
export type PostalCodeComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    PostalCodeQuery,
    PostalCodeQueryVariables
  >,
  'query'
> &
  ({ variables: PostalCodeQueryVariables; skip?: boolean } | { skip: boolean });

export const PostalCodeComponent = (props: PostalCodeComponentProps) => (
  <ApolloReactComponents.Query<PostalCodeQuery, PostalCodeQueryVariables>
    query={PostalCodeDocument}
    {...props}
  />
);

export type PostalCodeProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    PostalCodeQuery,
    PostalCodeQueryVariables
  >;
} &
  TChildProps;
export function withPostalCode<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    PostalCodeQuery,
    PostalCodeQueryVariables,
    PostalCodeProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    PostalCodeQuery,
    PostalCodeQueryVariables,
    PostalCodeProps<TChildProps, TDataName>
  >(PostalCodeDocument, {
    alias: 'postalCode',
    ...operationOptions,
  });
}

/**
 * __usePostalCodeQuery__
 *
 * To run a query within a React component, call `usePostalCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostalCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostalCodeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePostalCodeQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    PostalCodeQuery,
    PostalCodeQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<PostalCodeQuery, PostalCodeQueryVariables>(
    PostalCodeDocument,
    baseOptions,
  );
}
export function usePostalCodeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PostalCodeQuery,
    PostalCodeQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    PostalCodeQuery,
    PostalCodeQueryVariables
  >(PostalCodeDocument, baseOptions);
}
export type PostalCodeQueryHookResult = ReturnType<typeof usePostalCodeQuery>;
export type PostalCodeLazyQueryHookResult = ReturnType<
  typeof usePostalCodeLazyQuery
>;
export type PostalCodeQueryResult = ApolloReactCommon.QueryResult<
  PostalCodeQuery,
  PostalCodeQueryVariables
>;
export const createPostalCodeDocument = gql`
  mutation createPostalCode($postalCode: String!, $city_id: ID!) {
    data: createPostalCode(postalCode: $postalCode, city_id: $city_id) {
      ...AdminPostalCodeFields
    }
  }
  ${AdminPostalCodeFieldsFragmentDoc}
`;
export type createPostalCodeMutationFn = ApolloReactCommon.MutationFunction<
  createPostalCodeMutation,
  createPostalCodeMutationVariables
>;
export type createPostalCodeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    createPostalCodeMutation,
    createPostalCodeMutationVariables
  >,
  'mutation'
>;

export const createPostalCodeComponent = (
  props: createPostalCodeComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    createPostalCodeMutation,
    createPostalCodeMutationVariables
  >
    mutation={createPostalCodeDocument}
    {...props}
  />
);

export type createPostalCodeProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    createPostalCodeMutation,
    createPostalCodeMutationVariables
  >;
} &
  TChildProps;
export function withcreatePostalCode<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    createPostalCodeMutation,
    createPostalCodeMutationVariables,
    createPostalCodeProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    createPostalCodeMutation,
    createPostalCodeMutationVariables,
    createPostalCodeProps<TChildProps, TDataName>
  >(createPostalCodeDocument, {
    alias: 'createPostalCode',
    ...operationOptions,
  });
}

/**
 * __usecreatePostalCodeMutation__
 *
 * To run a mutation, you first call `usecreatePostalCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usecreatePostalCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostalCodeMutation, { data, loading, error }] = usecreatePostalCodeMutation({
 *   variables: {
 *      postalCode: // value for 'postalCode'
 *      city_id: // value for 'city_id'
 *   },
 * });
 */
export function usecreatePostalCodeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    createPostalCodeMutation,
    createPostalCodeMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    createPostalCodeMutation,
    createPostalCodeMutationVariables
  >(createPostalCodeDocument, baseOptions);
}
export type createPostalCodeMutationHookResult = ReturnType<
  typeof usecreatePostalCodeMutation
>;
export type createPostalCodeMutationResult = ApolloReactCommon.MutationResult<createPostalCodeMutation>;
export type createPostalCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  createPostalCodeMutation,
  createPostalCodeMutationVariables
>;
export const updatePostalCodeDocument = gql`
  mutation updatePostalCode($id: ID!, $postalCode: String, $city_id: ID) {
    data: updatePostalCode(
      id: $id
      postalCode: $postalCode
      city_id: $city_id
    ) {
      ...AdminPostalCodeFields
    }
  }
  ${AdminPostalCodeFieldsFragmentDoc}
`;
export type updatePostalCodeMutationFn = ApolloReactCommon.MutationFunction<
  updatePostalCodeMutation,
  updatePostalCodeMutationVariables
>;
export type updatePostalCodeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    updatePostalCodeMutation,
    updatePostalCodeMutationVariables
  >,
  'mutation'
>;

export const updatePostalCodeComponent = (
  props: updatePostalCodeComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    updatePostalCodeMutation,
    updatePostalCodeMutationVariables
  >
    mutation={updatePostalCodeDocument}
    {...props}
  />
);

export type updatePostalCodeProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    updatePostalCodeMutation,
    updatePostalCodeMutationVariables
  >;
} &
  TChildProps;
export function withupdatePostalCode<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    updatePostalCodeMutation,
    updatePostalCodeMutationVariables,
    updatePostalCodeProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    updatePostalCodeMutation,
    updatePostalCodeMutationVariables,
    updatePostalCodeProps<TChildProps, TDataName>
  >(updatePostalCodeDocument, {
    alias: 'updatePostalCode',
    ...operationOptions,
  });
}

/**
 * __useupdatePostalCodeMutation__
 *
 * To run a mutation, you first call `useupdatePostalCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useupdatePostalCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostalCodeMutation, { data, loading, error }] = useupdatePostalCodeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      postalCode: // value for 'postalCode'
 *      city_id: // value for 'city_id'
 *   },
 * });
 */
export function useupdatePostalCodeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    updatePostalCodeMutation,
    updatePostalCodeMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    updatePostalCodeMutation,
    updatePostalCodeMutationVariables
  >(updatePostalCodeDocument, baseOptions);
}
export type updatePostalCodeMutationHookResult = ReturnType<
  typeof useupdatePostalCodeMutation
>;
export type updatePostalCodeMutationResult = ApolloReactCommon.MutationResult<updatePostalCodeMutation>;
export type updatePostalCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  updatePostalCodeMutation,
  updatePostalCodeMutationVariables
>;
export const allRegionsDocument = gql`
  query allRegions(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: RegionFilter
  ) {
    items: allRegions(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      ...AdminRegionFields
    }
    total: _allRegionsMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
      __typename
    }
  }
  ${AdminRegionFieldsFragmentDoc}
`;
export type allRegionsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    allRegionsQuery,
    allRegionsQueryVariables
  >,
  'query'
>;

export const allRegionsComponent = (props: allRegionsComponentProps) => (
  <ApolloReactComponents.Query<allRegionsQuery, allRegionsQueryVariables>
    query={allRegionsDocument}
    {...props}
  />
);

export type allRegionsProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    allRegionsQuery,
    allRegionsQueryVariables
  >;
} &
  TChildProps;
export function withallRegions<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    allRegionsQuery,
    allRegionsQueryVariables,
    allRegionsProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    allRegionsQuery,
    allRegionsQueryVariables,
    allRegionsProps<TChildProps, TDataName>
  >(allRegionsDocument, {
    alias: 'allRegions',
    ...operationOptions,
  });
}

/**
 * __useallRegionsQuery__
 *
 * To run a query within a React component, call `useallRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useallRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useallRegionsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useallRegionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    allRegionsQuery,
    allRegionsQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<allRegionsQuery, allRegionsQueryVariables>(
    allRegionsDocument,
    baseOptions,
  );
}
export function useallRegionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    allRegionsQuery,
    allRegionsQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    allRegionsQuery,
    allRegionsQueryVariables
  >(allRegionsDocument, baseOptions);
}
export type allRegionsQueryHookResult = ReturnType<typeof useallRegionsQuery>;
export type allRegionsLazyQueryHookResult = ReturnType<
  typeof useallRegionsLazyQuery
>;
export type allRegionsQueryResult = ApolloReactCommon.QueryResult<
  allRegionsQuery,
  allRegionsQueryVariables
>;
export const RegionDocument = gql`
  query Region($id: ID!) {
    data: Region(id: $id) {
      ...AdminRegionFields
    }
  }
  ${AdminRegionFieldsFragmentDoc}
`;
export type RegionComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    RegionQuery,
    RegionQueryVariables
  >,
  'query'
> &
  ({ variables: RegionQueryVariables; skip?: boolean } | { skip: boolean });

export const RegionComponent = (props: RegionComponentProps) => (
  <ApolloReactComponents.Query<RegionQuery, RegionQueryVariables>
    query={RegionDocument}
    {...props}
  />
);

export type RegionProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    RegionQuery,
    RegionQueryVariables
  >;
} &
  TChildProps;
export function withRegion<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RegionQuery,
    RegionQueryVariables,
    RegionProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    RegionQuery,
    RegionQueryVariables,
    RegionProps<TChildProps, TDataName>
  >(RegionDocument, {
    alias: 'region',
    ...operationOptions,
  });
}

/**
 * __useRegionQuery__
 *
 * To run a query within a React component, call `useRegionQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRegionQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    RegionQuery,
    RegionQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<RegionQuery, RegionQueryVariables>(
    RegionDocument,
    baseOptions,
  );
}
export function useRegionLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    RegionQuery,
    RegionQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<RegionQuery, RegionQueryVariables>(
    RegionDocument,
    baseOptions,
  );
}
export type RegionQueryHookResult = ReturnType<typeof useRegionQuery>;
export type RegionLazyQueryHookResult = ReturnType<typeof useRegionLazyQuery>;
export type RegionQueryResult = ApolloReactCommon.QueryResult<
  RegionQuery,
  RegionQueryVariables
>;
export const createRegionDocument = gql`
  mutation createRegion($name: String!, $countryId: ID!) {
    data: createRegion(name: $name, countryId: $countryId) {
      ...AdminRegionFields
    }
  }
  ${AdminRegionFieldsFragmentDoc}
`;
export type createRegionMutationFn = ApolloReactCommon.MutationFunction<
  createRegionMutation,
  createRegionMutationVariables
>;
export type createRegionComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    createRegionMutation,
    createRegionMutationVariables
  >,
  'mutation'
>;

export const createRegionComponent = (props: createRegionComponentProps) => (
  <ApolloReactComponents.Mutation<
    createRegionMutation,
    createRegionMutationVariables
  >
    mutation={createRegionDocument}
    {...props}
  />
);

export type createRegionProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    createRegionMutation,
    createRegionMutationVariables
  >;
} &
  TChildProps;
export function withcreateRegion<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    createRegionMutation,
    createRegionMutationVariables,
    createRegionProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    createRegionMutation,
    createRegionMutationVariables,
    createRegionProps<TChildProps, TDataName>
  >(createRegionDocument, {
    alias: 'createRegion',
    ...operationOptions,
  });
}

/**
 * __usecreateRegionMutation__
 *
 * To run a mutation, you first call `usecreateRegionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usecreateRegionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRegionMutation, { data, loading, error }] = usecreateRegionMutation({
 *   variables: {
 *      name: // value for 'name'
 *      countryId: // value for 'countryId'
 *   },
 * });
 */
export function usecreateRegionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    createRegionMutation,
    createRegionMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    createRegionMutation,
    createRegionMutationVariables
  >(createRegionDocument, baseOptions);
}
export type createRegionMutationHookResult = ReturnType<
  typeof usecreateRegionMutation
>;
export type createRegionMutationResult = ApolloReactCommon.MutationResult<createRegionMutation>;
export type createRegionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  createRegionMutation,
  createRegionMutationVariables
>;
export const updateRegionDocument = gql`
  mutation updateRegion($id: ID!, $name: String!, $countryId: ID) {
    data: updateRegion(id: $id, name: $name, countryId: $countryId) {
      ...AdminRegionFields
    }
  }
  ${AdminRegionFieldsFragmentDoc}
`;
export type updateRegionMutationFn = ApolloReactCommon.MutationFunction<
  updateRegionMutation,
  updateRegionMutationVariables
>;
export type updateRegionComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    updateRegionMutation,
    updateRegionMutationVariables
  >,
  'mutation'
>;

export const updateRegionComponent = (props: updateRegionComponentProps) => (
  <ApolloReactComponents.Mutation<
    updateRegionMutation,
    updateRegionMutationVariables
  >
    mutation={updateRegionDocument}
    {...props}
  />
);

export type updateRegionProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    updateRegionMutation,
    updateRegionMutationVariables
  >;
} &
  TChildProps;
export function withupdateRegion<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    updateRegionMutation,
    updateRegionMutationVariables,
    updateRegionProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    updateRegionMutation,
    updateRegionMutationVariables,
    updateRegionProps<TChildProps, TDataName>
  >(updateRegionDocument, {
    alias: 'updateRegion',
    ...operationOptions,
  });
}

/**
 * __useupdateRegionMutation__
 *
 * To run a mutation, you first call `useupdateRegionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useupdateRegionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRegionMutation, { data, loading, error }] = useupdateRegionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      countryId: // value for 'countryId'
 *   },
 * });
 */
export function useupdateRegionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    updateRegionMutation,
    updateRegionMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    updateRegionMutation,
    updateRegionMutationVariables
  >(updateRegionDocument, baseOptions);
}
export type updateRegionMutationHookResult = ReturnType<
  typeof useupdateRegionMutation
>;
export type updateRegionMutationResult = ApolloReactCommon.MutationResult<updateRegionMutation>;
export type updateRegionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  updateRegionMutation,
  updateRegionMutationVariables
>;
export const listStinterRightToWorkStatusDocument = gql`
  query listStinterRightToWorkStatus(
    $filter: StinterRightToWorkStatusFilter
    $perPage: Int
    $next: String
  ) {
    data: listStinterRightToWorkStatus(
      filter: $filter
      limit: $perPage
      next: $next
    ) {
      items {
        ...RTWStatusFields
      }
      next
    }
  }
  ${RTWStatusFieldsFragmentDoc}
`;
export type listStinterRightToWorkStatusComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    listStinterRightToWorkStatusQuery,
    listStinterRightToWorkStatusQueryVariables
  >,
  'query'
>;

export const listStinterRightToWorkStatusComponent = (
  props: listStinterRightToWorkStatusComponentProps,
) => (
  <ApolloReactComponents.Query<
    listStinterRightToWorkStatusQuery,
    listStinterRightToWorkStatusQueryVariables
  >
    query={listStinterRightToWorkStatusDocument}
    {...props}
  />
);

export type listStinterRightToWorkStatusProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    listStinterRightToWorkStatusQuery,
    listStinterRightToWorkStatusQueryVariables
  >;
} &
  TChildProps;
export function withlistStinterRightToWorkStatus<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    listStinterRightToWorkStatusQuery,
    listStinterRightToWorkStatusQueryVariables,
    listStinterRightToWorkStatusProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    listStinterRightToWorkStatusQuery,
    listStinterRightToWorkStatusQueryVariables,
    listStinterRightToWorkStatusProps<TChildProps, TDataName>
  >(listStinterRightToWorkStatusDocument, {
    alias: 'listStinterRightToWorkStatus',
    ...operationOptions,
  });
}

/**
 * __uselistStinterRightToWorkStatusQuery__
 *
 * To run a query within a React component, call `uselistStinterRightToWorkStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `uselistStinterRightToWorkStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = uselistStinterRightToWorkStatusQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      perPage: // value for 'perPage'
 *      next: // value for 'next'
 *   },
 * });
 */
export function uselistStinterRightToWorkStatusQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    listStinterRightToWorkStatusQuery,
    listStinterRightToWorkStatusQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    listStinterRightToWorkStatusQuery,
    listStinterRightToWorkStatusQueryVariables
  >(listStinterRightToWorkStatusDocument, baseOptions);
}
export function uselistStinterRightToWorkStatusLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    listStinterRightToWorkStatusQuery,
    listStinterRightToWorkStatusQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    listStinterRightToWorkStatusQuery,
    listStinterRightToWorkStatusQueryVariables
  >(listStinterRightToWorkStatusDocument, baseOptions);
}
export type listStinterRightToWorkStatusQueryHookResult = ReturnType<
  typeof uselistStinterRightToWorkStatusQuery
>;
export type listStinterRightToWorkStatusLazyQueryHookResult = ReturnType<
  typeof uselistStinterRightToWorkStatusLazyQuery
>;
export type listStinterRightToWorkStatusQueryResult = ApolloReactCommon.QueryResult<
  listStinterRightToWorkStatusQuery,
  listStinterRightToWorkStatusQueryVariables
>;
export const listStinterRightToWorkScreeningDocument = gql`
  query listStinterRightToWorkScreening(
    $input: StinterRightToWorkScreeningFilter
    $perPage: Int
    $next: String
  ) {
    data: listStinterRightToWorkScreening(
      filter: $input
      limit: $perPage
      next: $next
    ) {
      items {
        ...RTWScreeningFields
      }
      next
    }
  }
  ${RTWScreeningFieldsFragmentDoc}
`;
export type listStinterRightToWorkScreeningComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    listStinterRightToWorkScreeningQuery,
    listStinterRightToWorkScreeningQueryVariables
  >,
  'query'
>;

export const listStinterRightToWorkScreeningComponent = (
  props: listStinterRightToWorkScreeningComponentProps,
) => (
  <ApolloReactComponents.Query<
    listStinterRightToWorkScreeningQuery,
    listStinterRightToWorkScreeningQueryVariables
  >
    query={listStinterRightToWorkScreeningDocument}
    {...props}
  />
);

export type listStinterRightToWorkScreeningProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    listStinterRightToWorkScreeningQuery,
    listStinterRightToWorkScreeningQueryVariables
  >;
} &
  TChildProps;
export function withlistStinterRightToWorkScreening<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    listStinterRightToWorkScreeningQuery,
    listStinterRightToWorkScreeningQueryVariables,
    listStinterRightToWorkScreeningProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    listStinterRightToWorkScreeningQuery,
    listStinterRightToWorkScreeningQueryVariables,
    listStinterRightToWorkScreeningProps<TChildProps, TDataName>
  >(listStinterRightToWorkScreeningDocument, {
    alias: 'listStinterRightToWorkScreening',
    ...operationOptions,
  });
}

/**
 * __uselistStinterRightToWorkScreeningQuery__
 *
 * To run a query within a React component, call `uselistStinterRightToWorkScreeningQuery` and pass it any options that fit your needs.
 * When your component renders, `uselistStinterRightToWorkScreeningQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = uselistStinterRightToWorkScreeningQuery({
 *   variables: {
 *      input: // value for 'input'
 *      perPage: // value for 'perPage'
 *      next: // value for 'next'
 *   },
 * });
 */
export function uselistStinterRightToWorkScreeningQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    listStinterRightToWorkScreeningQuery,
    listStinterRightToWorkScreeningQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    listStinterRightToWorkScreeningQuery,
    listStinterRightToWorkScreeningQueryVariables
  >(listStinterRightToWorkScreeningDocument, baseOptions);
}
export function uselistStinterRightToWorkScreeningLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    listStinterRightToWorkScreeningQuery,
    listStinterRightToWorkScreeningQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    listStinterRightToWorkScreeningQuery,
    listStinterRightToWorkScreeningQueryVariables
  >(listStinterRightToWorkScreeningDocument, baseOptions);
}
export type listStinterRightToWorkScreeningQueryHookResult = ReturnType<
  typeof uselistStinterRightToWorkScreeningQuery
>;
export type listStinterRightToWorkScreeningLazyQueryHookResult = ReturnType<
  typeof uselistStinterRightToWorkScreeningLazyQuery
>;
export type listStinterRightToWorkScreeningQueryResult = ApolloReactCommon.QueryResult<
  listStinterRightToWorkScreeningQuery,
  listStinterRightToWorkScreeningQueryVariables
>;
export const listStinterRightToWorkStinterScreeningDocument = gql`
  query listStinterRightToWorkStinterScreening($id: ID!) {
    data: listStinterRightToWorkStinterScreening(stinterID: $id) {
      ...RTWScreeningFields
    }
  }
  ${RTWScreeningFieldsFragmentDoc}
`;
export type listStinterRightToWorkStinterScreeningComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    listStinterRightToWorkStinterScreeningQuery,
    listStinterRightToWorkStinterScreeningQueryVariables
  >,
  'query'
> &
  (
    | {
        variables: listStinterRightToWorkStinterScreeningQueryVariables;
        skip?: boolean;
      }
    | { skip: boolean }
  );

export const listStinterRightToWorkStinterScreeningComponent = (
  props: listStinterRightToWorkStinterScreeningComponentProps,
) => (
  <ApolloReactComponents.Query<
    listStinterRightToWorkStinterScreeningQuery,
    listStinterRightToWorkStinterScreeningQueryVariables
  >
    query={listStinterRightToWorkStinterScreeningDocument}
    {...props}
  />
);

export type listStinterRightToWorkStinterScreeningProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    listStinterRightToWorkStinterScreeningQuery,
    listStinterRightToWorkStinterScreeningQueryVariables
  >;
} &
  TChildProps;
export function withlistStinterRightToWorkStinterScreening<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    listStinterRightToWorkStinterScreeningQuery,
    listStinterRightToWorkStinterScreeningQueryVariables,
    listStinterRightToWorkStinterScreeningProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    listStinterRightToWorkStinterScreeningQuery,
    listStinterRightToWorkStinterScreeningQueryVariables,
    listStinterRightToWorkStinterScreeningProps<TChildProps, TDataName>
  >(listStinterRightToWorkStinterScreeningDocument, {
    alias: 'listStinterRightToWorkStinterScreening',
    ...operationOptions,
  });
}

/**
 * __uselistStinterRightToWorkStinterScreeningQuery__
 *
 * To run a query within a React component, call `uselistStinterRightToWorkStinterScreeningQuery` and pass it any options that fit your needs.
 * When your component renders, `uselistStinterRightToWorkStinterScreeningQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = uselistStinterRightToWorkStinterScreeningQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function uselistStinterRightToWorkStinterScreeningQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    listStinterRightToWorkStinterScreeningQuery,
    listStinterRightToWorkStinterScreeningQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    listStinterRightToWorkStinterScreeningQuery,
    listStinterRightToWorkStinterScreeningQueryVariables
  >(listStinterRightToWorkStinterScreeningDocument, baseOptions);
}
export function uselistStinterRightToWorkStinterScreeningLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    listStinterRightToWorkStinterScreeningQuery,
    listStinterRightToWorkStinterScreeningQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    listStinterRightToWorkStinterScreeningQuery,
    listStinterRightToWorkStinterScreeningQueryVariables
  >(listStinterRightToWorkStinterScreeningDocument, baseOptions);
}
export type listStinterRightToWorkStinterScreeningQueryHookResult = ReturnType<
  typeof uselistStinterRightToWorkStinterScreeningQuery
>;
export type listStinterRightToWorkStinterScreeningLazyQueryHookResult = ReturnType<
  typeof uselistStinterRightToWorkStinterScreeningLazyQuery
>;
export type listStinterRightToWorkStinterScreeningQueryResult = ApolloReactCommon.QueryResult<
  listStinterRightToWorkStinterScreeningQuery,
  listStinterRightToWorkStinterScreeningQueryVariables
>;
export const ListStintTemplatesDocument = gql`
  query ListStintTemplates($businessId: ID!, $page: Int, $pageSize: Int) {
    data: stintTemplates(
      businessId: $businessId
      page: $page
      pageSize: $pageSize
    ) {
      objects {
        id
        name
      }
    }
  }
`;
export type ListStintTemplatesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ListStintTemplatesQuery,
    ListStintTemplatesQueryVariables
  >,
  'query'
> &
  (
    | { variables: ListStintTemplatesQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const ListStintTemplatesComponent = (
  props: ListStintTemplatesComponentProps,
) => (
  <ApolloReactComponents.Query<
    ListStintTemplatesQuery,
    ListStintTemplatesQueryVariables
  >
    query={ListStintTemplatesDocument}
    {...props}
  />
);

export type ListStintTemplatesProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ListStintTemplatesQuery,
    ListStintTemplatesQueryVariables
  >;
} &
  TChildProps;
export function withListStintTemplates<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ListStintTemplatesQuery,
    ListStintTemplatesQueryVariables,
    ListStintTemplatesProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ListStintTemplatesQuery,
    ListStintTemplatesQueryVariables,
    ListStintTemplatesProps<TChildProps, TDataName>
  >(ListStintTemplatesDocument, {
    alias: 'listStintTemplates',
    ...operationOptions,
  });
}

/**
 * __useListStintTemplatesQuery__
 *
 * To run a query within a React component, call `useListStintTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListStintTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListStintTemplatesQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useListStintTemplatesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ListStintTemplatesQuery,
    ListStintTemplatesQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    ListStintTemplatesQuery,
    ListStintTemplatesQueryVariables
  >(ListStintTemplatesDocument, baseOptions);
}
export function useListStintTemplatesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ListStintTemplatesQuery,
    ListStintTemplatesQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    ListStintTemplatesQuery,
    ListStintTemplatesQueryVariables
  >(ListStintTemplatesDocument, baseOptions);
}
export type ListStintTemplatesQueryHookResult = ReturnType<
  typeof useListStintTemplatesQuery
>;
export type ListStintTemplatesLazyQueryHookResult = ReturnType<
  typeof useListStintTemplatesLazyQuery
>;
export type ListStintTemplatesQueryResult = ApolloReactCommon.QueryResult<
  ListStintTemplatesQuery,
  ListStintTemplatesQueryVariables
>;
export const AdminUserDocument = gql`
  query AdminUser($id: ID!) {
    data: adminUser(userId: $id) {
      ...StaffUserFields
    }
  }
  ${StaffUserFieldsFragmentDoc}
`;
export type AdminUserComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    AdminUserQuery,
    AdminUserQueryVariables
  >,
  'query'
> &
  ({ variables: AdminUserQueryVariables; skip?: boolean } | { skip: boolean });

export const AdminUserComponent = (props: AdminUserComponentProps) => (
  <ApolloReactComponents.Query<AdminUserQuery, AdminUserQueryVariables>
    query={AdminUserDocument}
    {...props}
  />
);

export type AdminUserProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AdminUserQuery,
    AdminUserQueryVariables
  >;
} &
  TChildProps;
export function withAdminUser<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AdminUserQuery,
    AdminUserQueryVariables,
    AdminUserProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AdminUserQuery,
    AdminUserQueryVariables,
    AdminUserProps<TChildProps, TDataName>
  >(AdminUserDocument, {
    alias: 'adminUser',
    ...operationOptions,
  });
}

/**
 * __useAdminUserQuery__
 *
 * To run a query within a React component, call `useAdminUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminUserQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AdminUserQuery,
    AdminUserQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<AdminUserQuery, AdminUserQueryVariables>(
    AdminUserDocument,
    baseOptions,
  );
}
export function useAdminUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminUserQuery,
    AdminUserQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<AdminUserQuery, AdminUserQueryVariables>(
    AdminUserDocument,
    baseOptions,
  );
}
export type AdminUserQueryHookResult = ReturnType<typeof useAdminUserQuery>;
export type AdminUserLazyQueryHookResult = ReturnType<
  typeof useAdminUserLazyQuery
>;
export type AdminUserQueryResult = ApolloReactCommon.QueryResult<
  AdminUserQuery,
  AdminUserQueryVariables
>;
export const allAdminUsersDocument = gql`
  query allAdminUsers(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: String
  ) {
    items: allAdminUsers(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      ...StaffUserFields
    }
    total: _allAdminUserMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
      __typename
    }
  }
  ${StaffUserFieldsFragmentDoc}
`;
export type allAdminUsersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    allAdminUsersQuery,
    allAdminUsersQueryVariables
  >,
  'query'
>;

export const allAdminUsersComponent = (props: allAdminUsersComponentProps) => (
  <ApolloReactComponents.Query<allAdminUsersQuery, allAdminUsersQueryVariables>
    query={allAdminUsersDocument}
    {...props}
  />
);

export type allAdminUsersProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    allAdminUsersQuery,
    allAdminUsersQueryVariables
  >;
} &
  TChildProps;
export function withallAdminUsers<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    allAdminUsersQuery,
    allAdminUsersQueryVariables,
    allAdminUsersProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    allAdminUsersQuery,
    allAdminUsersQueryVariables,
    allAdminUsersProps<TChildProps, TDataName>
  >(allAdminUsersDocument, {
    alias: 'allAdminUsers',
    ...operationOptions,
  });
}

/**
 * __useallAdminUsersQuery__
 *
 * To run a query within a React component, call `useallAdminUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useallAdminUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useallAdminUsersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useallAdminUsersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    allAdminUsersQuery,
    allAdminUsersQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    allAdminUsersQuery,
    allAdminUsersQueryVariables
  >(allAdminUsersDocument, baseOptions);
}
export function useallAdminUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    allAdminUsersQuery,
    allAdminUsersQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    allAdminUsersQuery,
    allAdminUsersQueryVariables
  >(allAdminUsersDocument, baseOptions);
}
export type allAdminUsersQueryHookResult = ReturnType<
  typeof useallAdminUsersQuery
>;
export type allAdminUsersLazyQueryHookResult = ReturnType<
  typeof useallAdminUsersLazyQuery
>;
export type allAdminUsersQueryResult = ApolloReactCommon.QueryResult<
  allAdminUsersQuery,
  allAdminUsersQueryVariables
>;
export const allStintsDocument = gql`
  query allStints(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: StintFilter
  ) {
    items: allStints(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      ...AdminStintListFields
    }
    total: _allStintsMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
      __typename
    }
  }
  ${AdminStintListFieldsFragmentDoc}
`;
export type allStintsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    allStintsQuery,
    allStintsQueryVariables
  >,
  'query'
>;

export const allStintsComponent = (props: allStintsComponentProps) => (
  <ApolloReactComponents.Query<allStintsQuery, allStintsQueryVariables>
    query={allStintsDocument}
    {...props}
  />
);

export type allStintsProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    allStintsQuery,
    allStintsQueryVariables
  >;
} &
  TChildProps;
export function withallStints<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    allStintsQuery,
    allStintsQueryVariables,
    allStintsProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    allStintsQuery,
    allStintsQueryVariables,
    allStintsProps<TChildProps, TDataName>
  >(allStintsDocument, {
    alias: 'allStints',
    ...operationOptions,
  });
}

/**
 * __useallStintsQuery__
 *
 * To run a query within a React component, call `useallStintsQuery` and pass it any options that fit your needs.
 * When your component renders, `useallStintsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useallStintsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useallStintsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    allStintsQuery,
    allStintsQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<allStintsQuery, allStintsQueryVariables>(
    allStintsDocument,
    baseOptions,
  );
}
export function useallStintsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    allStintsQuery,
    allStintsQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<allStintsQuery, allStintsQueryVariables>(
    allStintsDocument,
    baseOptions,
  );
}
export type allStintsQueryHookResult = ReturnType<typeof useallStintsQuery>;
export type allStintsLazyQueryHookResult = ReturnType<
  typeof useallStintsLazyQuery
>;
export type allStintsQueryResult = ApolloReactCommon.QueryResult<
  allStintsQuery,
  allStintsQueryVariables
>;
export const StintDocument = gql`
  query Stint($id: ID!) {
    data: Stint(id: $id) {
      ...AdminStintFields
    }
  }
  ${AdminStintFieldsFragmentDoc}
`;
export type StintComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<StintQuery, StintQueryVariables>,
  'query'
> &
  ({ variables: StintQueryVariables; skip?: boolean } | { skip: boolean });

export const StintComponent = (props: StintComponentProps) => (
  <ApolloReactComponents.Query<StintQuery, StintQueryVariables>
    query={StintDocument}
    {...props}
  />
);

export type StintProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<StintQuery, StintQueryVariables>;
} &
  TChildProps;
export function withStint<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    StintQuery,
    StintQueryVariables,
    StintProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    StintQuery,
    StintQueryVariables,
    StintProps<TChildProps, TDataName>
  >(StintDocument, {
    alias: 'stint',
    ...operationOptions,
  });
}

/**
 * __useStintQuery__
 *
 * To run a query within a React component, call `useStintQuery` and pass it any options that fit your needs.
 * When your component renders, `useStintQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStintQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStintQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    StintQuery,
    StintQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<StintQuery, StintQueryVariables>(
    StintDocument,
    baseOptions,
  );
}
export function useStintLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    StintQuery,
    StintQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<StintQuery, StintQueryVariables>(
    StintDocument,
    baseOptions,
  );
}
export type StintQueryHookResult = ReturnType<typeof useStintQuery>;
export type StintLazyQueryHookResult = ReturnType<typeof useStintLazyQuery>;
export type StintQueryResult = ApolloReactCommon.QueryResult<
  StintQuery,
  StintQueryVariables
>;
export const updateStintDocument = gql`
  mutation updateStint(
    $cancellationFee: Float
    $cancellationFeeApplied: Boolean
    $controlCheck: Boolean
    $dateFrom: AWSDateTime
    $dateTo: AWSDateTime
    $checkInDate: AWSDateTime
    $displayAddress: String
    $exceptions: [StintTemplateExceptionInput]
    $isBestEffort: Boolean
    $id: ID!
    $name: String
    $status: Int
    $notes: String
    $placeId: String
    $primaryTasks: [StintTemplateTaskInput]
    $student_id: ID
    $studentUnassignedReasons: [StudentUnassignedReasons]
    $topTips: [StintTemplateTopTipInput]
    $uniform: [StintUniform]
    $baseFeeOverride: Float
    $baseRateOverride: Float
    $surgeRate: Float
  ) {
    data: updateStint(
      cancellationFee: $cancellationFee
      cancellationFeeOverride: $cancellationFeeApplied
      controlCheck: $controlCheck
      dateFrom: $dateFrom
      dateTo: $dateTo
      checkInDate: $checkInDate
      displayAddress: $displayAddress
      exceptions: $exceptions
      id: $id
      isBestEffort: $isBestEffort
      name: $name
      status: $status
      notes: $notes
      placeId: $placeId
      primaryTasks: $primaryTasks
      student_id: $student_id
      studentUnassignedReasons: $studentUnassignedReasons
      topTips: $topTips
      uniform: $uniform
      overrideBaseFee: $baseFeeOverride
      studentBaseFeeOverrideGrossAmount: $baseRateOverride
      studentSurgeBaseFeeGrossAmount: $surgeRate
    ) {
      ...AdminStintFields
    }
  }
  ${AdminStintFieldsFragmentDoc}
`;
export type updateStintMutationFn = ApolloReactCommon.MutationFunction<
  updateStintMutation,
  updateStintMutationVariables
>;
export type updateStintComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    updateStintMutation,
    updateStintMutationVariables
  >,
  'mutation'
>;

export const updateStintComponent = (props: updateStintComponentProps) => (
  <ApolloReactComponents.Mutation<
    updateStintMutation,
    updateStintMutationVariables
  >
    mutation={updateStintDocument}
    {...props}
  />
);

export type updateStintProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    updateStintMutation,
    updateStintMutationVariables
  >;
} &
  TChildProps;
export function withupdateStint<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    updateStintMutation,
    updateStintMutationVariables,
    updateStintProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    updateStintMutation,
    updateStintMutationVariables,
    updateStintProps<TChildProps, TDataName>
  >(updateStintDocument, {
    alias: 'updateStint',
    ...operationOptions,
  });
}

/**
 * __useupdateStintMutation__
 *
 * To run a mutation, you first call `useupdateStintMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useupdateStintMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStintMutation, { data, loading, error }] = useupdateStintMutation({
 *   variables: {
 *      cancellationFee: // value for 'cancellationFee'
 *      cancellationFeeApplied: // value for 'cancellationFeeApplied'
 *      controlCheck: // value for 'controlCheck'
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *      checkInDate: // value for 'checkInDate'
 *      displayAddress: // value for 'displayAddress'
 *      exceptions: // value for 'exceptions'
 *      isBestEffort: // value for 'isBestEffort'
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      status: // value for 'status'
 *      notes: // value for 'notes'
 *      placeId: // value for 'placeId'
 *      primaryTasks: // value for 'primaryTasks'
 *      student_id: // value for 'student_id'
 *      studentUnassignedReasons: // value for 'studentUnassignedReasons'
 *      topTips: // value for 'topTips'
 *      uniform: // value for 'uniform'
 *      baseFeeOverride: // value for 'baseFeeOverride'
 *      baseRateOverride: // value for 'baseRateOverride'
 *      surgeRate: // value for 'surgeRate'
 *   },
 * });
 */
export function useupdateStintMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    updateStintMutation,
    updateStintMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    updateStintMutation,
    updateStintMutationVariables
  >(updateStintDocument, baseOptions);
}
export type updateStintMutationHookResult = ReturnType<
  typeof useupdateStintMutation
>;
export type updateStintMutationResult = ApolloReactCommon.MutationResult<updateStintMutation>;
export type updateStintMutationOptions = ApolloReactCommon.BaseMutationOptions<
  updateStintMutation,
  updateStintMutationVariables
>;
export const cancelStintsDocument = gql`
  mutation cancelStints($stintRefs: [ID]!) {
    data: cancelStints(stintRefs: $stintRefs) {
      entries {
        id
      }
    }
  }
`;
export type cancelStintsMutationFn = ApolloReactCommon.MutationFunction<
  cancelStintsMutation,
  cancelStintsMutationVariables
>;
export type cancelStintsComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    cancelStintsMutation,
    cancelStintsMutationVariables
  >,
  'mutation'
>;

export const cancelStintsComponent = (props: cancelStintsComponentProps) => (
  <ApolloReactComponents.Mutation<
    cancelStintsMutation,
    cancelStintsMutationVariables
  >
    mutation={cancelStintsDocument}
    {...props}
  />
);

export type cancelStintsProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    cancelStintsMutation,
    cancelStintsMutationVariables
  >;
} &
  TChildProps;
export function withcancelStints<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    cancelStintsMutation,
    cancelStintsMutationVariables,
    cancelStintsProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    cancelStintsMutation,
    cancelStintsMutationVariables,
    cancelStintsProps<TChildProps, TDataName>
  >(cancelStintsDocument, {
    alias: 'cancelStints',
    ...operationOptions,
  });
}

/**
 * __usecancelStintsMutation__
 *
 * To run a mutation, you first call `usecancelStintsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usecancelStintsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelStintsMutation, { data, loading, error }] = usecancelStintsMutation({
 *   variables: {
 *      stintRefs: // value for 'stintRefs'
 *   },
 * });
 */
export function usecancelStintsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    cancelStintsMutation,
    cancelStintsMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    cancelStintsMutation,
    cancelStintsMutationVariables
  >(cancelStintsDocument, baseOptions);
}
export type cancelStintsMutationHookResult = ReturnType<
  typeof usecancelStintsMutation
>;
export type cancelStintsMutationResult = ApolloReactCommon.MutationResult<cancelStintsMutation>;
export type cancelStintsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  cancelStintsMutation,
  cancelStintsMutationVariables
>;
export const CreateStintsDocument = gql`
  mutation CreateStints($createInputs: CreateStintsInput!) {
    createStints(createInputs: $createInputs) {
      id
    }
  }
`;
export type CreateStintsMutationFn = ApolloReactCommon.MutationFunction<
  CreateStintsMutation,
  CreateStintsMutationVariables
>;
export type CreateStintsComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateStintsMutation,
    CreateStintsMutationVariables
  >,
  'mutation'
>;

export const CreateStintsComponent = (props: CreateStintsComponentProps) => (
  <ApolloReactComponents.Mutation<
    CreateStintsMutation,
    CreateStintsMutationVariables
  >
    mutation={CreateStintsDocument}
    {...props}
  />
);

export type CreateStintsProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    CreateStintsMutation,
    CreateStintsMutationVariables
  >;
} &
  TChildProps;
export function withCreateStints<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateStintsMutation,
    CreateStintsMutationVariables,
    CreateStintsProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateStintsMutation,
    CreateStintsMutationVariables,
    CreateStintsProps<TChildProps, TDataName>
  >(CreateStintsDocument, {
    alias: 'createStints',
    ...operationOptions,
  });
}

/**
 * __useCreateStintsMutation__
 *
 * To run a mutation, you first call `useCreateStintsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStintsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStintsMutation, { data, loading, error }] = useCreateStintsMutation({
 *   variables: {
 *      createInputs: // value for 'createInputs'
 *   },
 * });
 */
export function useCreateStintsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateStintsMutation,
    CreateStintsMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CreateStintsMutation,
    CreateStintsMutationVariables
  >(CreateStintsDocument, baseOptions);
}
export type CreateStintsMutationHookResult = ReturnType<
  typeof useCreateStintsMutation
>;
export type CreateStintsMutationResult = ApolloReactCommon.MutationResult<CreateStintsMutation>;
export type CreateStintsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateStintsMutation,
  CreateStintsMutationVariables
>;
export const allStintJourneysDocument = gql`
  query allStintJourneys(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: StintJourneyFilter
  ) {
    items: allStintJourneys(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      ...AdminStintJourneyFields
    }
    total: _allStintJourneysMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
      __typename
    }
  }
  ${AdminStintJourneyFieldsFragmentDoc}
`;
export type allStintJourneysComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    allStintJourneysQuery,
    allStintJourneysQueryVariables
  >,
  'query'
>;

export const allStintJourneysComponent = (
  props: allStintJourneysComponentProps,
) => (
  <ApolloReactComponents.Query<
    allStintJourneysQuery,
    allStintJourneysQueryVariables
  >
    query={allStintJourneysDocument}
    {...props}
  />
);

export type allStintJourneysProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    allStintJourneysQuery,
    allStintJourneysQueryVariables
  >;
} &
  TChildProps;
export function withallStintJourneys<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    allStintJourneysQuery,
    allStintJourneysQueryVariables,
    allStintJourneysProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    allStintJourneysQuery,
    allStintJourneysQueryVariables,
    allStintJourneysProps<TChildProps, TDataName>
  >(allStintJourneysDocument, {
    alias: 'allStintJourneys',
    ...operationOptions,
  });
}

/**
 * __useallStintJourneysQuery__
 *
 * To run a query within a React component, call `useallStintJourneysQuery` and pass it any options that fit your needs.
 * When your component renders, `useallStintJourneysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useallStintJourneysQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useallStintJourneysQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    allStintJourneysQuery,
    allStintJourneysQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    allStintJourneysQuery,
    allStintJourneysQueryVariables
  >(allStintJourneysDocument, baseOptions);
}
export function useallStintJourneysLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    allStintJourneysQuery,
    allStintJourneysQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    allStintJourneysQuery,
    allStintJourneysQueryVariables
  >(allStintJourneysDocument, baseOptions);
}
export type allStintJourneysQueryHookResult = ReturnType<
  typeof useallStintJourneysQuery
>;
export type allStintJourneysLazyQueryHookResult = ReturnType<
  typeof useallStintJourneysLazyQuery
>;
export type allStintJourneysQueryResult = ApolloReactCommon.QueryResult<
  allStintJourneysQuery,
  allStintJourneysQueryVariables
>;
export const StintJourneyDocument = gql`
  query StintJourney($id: ID!) {
    data: StintJourney(id: $id) {
      ...AdminStintJourneyFields
    }
  }
  ${AdminStintJourneyFieldsFragmentDoc}
`;
export type StintJourneyComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    StintJourneyQuery,
    StintJourneyQueryVariables
  >,
  'query'
> &
  (
    | { variables: StintJourneyQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const StintJourneyComponent = (props: StintJourneyComponentProps) => (
  <ApolloReactComponents.Query<StintJourneyQuery, StintJourneyQueryVariables>
    query={StintJourneyDocument}
    {...props}
  />
);

export type StintJourneyProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    StintJourneyQuery,
    StintJourneyQueryVariables
  >;
} &
  TChildProps;
export function withStintJourney<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    StintJourneyQuery,
    StintJourneyQueryVariables,
    StintJourneyProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    StintJourneyQuery,
    StintJourneyQueryVariables,
    StintJourneyProps<TChildProps, TDataName>
  >(StintJourneyDocument, {
    alias: 'stintJourney',
    ...operationOptions,
  });
}

/**
 * __useStintJourneyQuery__
 *
 * To run a query within a React component, call `useStintJourneyQuery` and pass it any options that fit your needs.
 * When your component renders, `useStintJourneyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStintJourneyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStintJourneyQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    StintJourneyQuery,
    StintJourneyQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    StintJourneyQuery,
    StintJourneyQueryVariables
  >(StintJourneyDocument, baseOptions);
}
export function useStintJourneyLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    StintJourneyQuery,
    StintJourneyQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    StintJourneyQuery,
    StintJourneyQueryVariables
  >(StintJourneyDocument, baseOptions);
}
export type StintJourneyQueryHookResult = ReturnType<
  typeof useStintJourneyQuery
>;
export type StintJourneyLazyQueryHookResult = ReturnType<
  typeof useStintJourneyLazyQuery
>;
export type StintJourneyQueryResult = ApolloReactCommon.QueryResult<
  StintJourneyQuery,
  StintJourneyQueryVariables
>;
export const updateStintJourneyDocument = gql`
  mutation updateStintJourney(
    $id: ID!
    $estimatedTimeOfArrival: AWSDateTime
    $estimatedTimeOfDeparture: AWSDateTime
    $checkInDate: AWSDateTime
    $differentStartTimeReasons: [DifferentStartTimeReason]
    $lateNote: String
    $internalNotes: String
    $statusResolved: Boolean
  ) {
    data: updateStintJourney(
      id: $id
      estimatedTimeOfArrival: $estimatedTimeOfArrival
      estimatedTimeOfDeparture: $estimatedTimeOfDeparture
      checkInDate: $checkInDate
      differentStartTimeReasons: $differentStartTimeReasons
      lateNote: $lateNote
      internalNotes: $internalNotes
      statusResolved: $statusResolved
    ) {
      ...AdminStintJourneyFields
    }
  }
  ${AdminStintJourneyFieldsFragmentDoc}
`;
export type updateStintJourneyMutationFn = ApolloReactCommon.MutationFunction<
  updateStintJourneyMutation,
  updateStintJourneyMutationVariables
>;
export type updateStintJourneyComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    updateStintJourneyMutation,
    updateStintJourneyMutationVariables
  >,
  'mutation'
>;

export const updateStintJourneyComponent = (
  props: updateStintJourneyComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    updateStintJourneyMutation,
    updateStintJourneyMutationVariables
  >
    mutation={updateStintJourneyDocument}
    {...props}
  />
);

export type updateStintJourneyProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    updateStintJourneyMutation,
    updateStintJourneyMutationVariables
  >;
} &
  TChildProps;
export function withupdateStintJourney<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    updateStintJourneyMutation,
    updateStintJourneyMutationVariables,
    updateStintJourneyProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    updateStintJourneyMutation,
    updateStintJourneyMutationVariables,
    updateStintJourneyProps<TChildProps, TDataName>
  >(updateStintJourneyDocument, {
    alias: 'updateStintJourney',
    ...operationOptions,
  });
}

/**
 * __useupdateStintJourneyMutation__
 *
 * To run a mutation, you first call `useupdateStintJourneyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useupdateStintJourneyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStintJourneyMutation, { data, loading, error }] = useupdateStintJourneyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      estimatedTimeOfArrival: // value for 'estimatedTimeOfArrival'
 *      estimatedTimeOfDeparture: // value for 'estimatedTimeOfDeparture'
 *      checkInDate: // value for 'checkInDate'
 *      differentStartTimeReasons: // value for 'differentStartTimeReasons'
 *      lateNote: // value for 'lateNote'
 *      internalNotes: // value for 'internalNotes'
 *      statusResolved: // value for 'statusResolved'
 *   },
 * });
 */
export function useupdateStintJourneyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    updateStintJourneyMutation,
    updateStintJourneyMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    updateStintJourneyMutation,
    updateStintJourneyMutationVariables
  >(updateStintJourneyDocument, baseOptions);
}
export type updateStintJourneyMutationHookResult = ReturnType<
  typeof useupdateStintJourneyMutation
>;
export type updateStintJourneyMutationResult = ApolloReactCommon.MutationResult<updateStintJourneyMutation>;
export type updateStintJourneyMutationOptions = ApolloReactCommon.BaseMutationOptions<
  updateStintJourneyMutation,
  updateStintJourneyMutationVariables
>;
export const resolveStintJourneysDocument = gql`
  mutation resolveStintJourneys($ids: [ID!]!) {
    data: resolveStintJourneys(stintJourneyIds: $ids) {
      ...AdminStintJourneyFields
    }
  }
  ${AdminStintJourneyFieldsFragmentDoc}
`;
export type resolveStintJourneysMutationFn = ApolloReactCommon.MutationFunction<
  resolveStintJourneysMutation,
  resolveStintJourneysMutationVariables
>;
export type resolveStintJourneysComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    resolveStintJourneysMutation,
    resolveStintJourneysMutationVariables
  >,
  'mutation'
>;

export const resolveStintJourneysComponent = (
  props: resolveStintJourneysComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    resolveStintJourneysMutation,
    resolveStintJourneysMutationVariables
  >
    mutation={resolveStintJourneysDocument}
    {...props}
  />
);

export type resolveStintJourneysProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    resolveStintJourneysMutation,
    resolveStintJourneysMutationVariables
  >;
} &
  TChildProps;
export function withresolveStintJourneys<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    resolveStintJourneysMutation,
    resolveStintJourneysMutationVariables,
    resolveStintJourneysProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    resolveStintJourneysMutation,
    resolveStintJourneysMutationVariables,
    resolveStintJourneysProps<TChildProps, TDataName>
  >(resolveStintJourneysDocument, {
    alias: 'resolveStintJourneys',
    ...operationOptions,
  });
}

/**
 * __useresolveStintJourneysMutation__
 *
 * To run a mutation, you first call `useresolveStintJourneysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useresolveStintJourneysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolveStintJourneysMutation, { data, loading, error }] = useresolveStintJourneysMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useresolveStintJourneysMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    resolveStintJourneysMutation,
    resolveStintJourneysMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    resolveStintJourneysMutation,
    resolveStintJourneysMutationVariables
  >(resolveStintJourneysDocument, baseOptions);
}
export type resolveStintJourneysMutationHookResult = ReturnType<
  typeof useresolveStintJourneysMutation
>;
export type resolveStintJourneysMutationResult = ApolloReactCommon.MutationResult<resolveStintJourneysMutation>;
export type resolveStintJourneysMutationOptions = ApolloReactCommon.BaseMutationOptions<
  resolveStintJourneysMutation,
  resolveStintJourneysMutationVariables
>;
export const allStintTemplatesDocument = gql`
  query allStintTemplates(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: StintTemplateFilter
  ) {
    items: allStintTemplates(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      ...AdminStintTemplateFields
    }
    total: _allStintTemplatesMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
      __typename
    }
  }
  ${AdminStintTemplateFieldsFragmentDoc}
`;
export type allStintTemplatesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    allStintTemplatesQuery,
    allStintTemplatesQueryVariables
  >,
  'query'
>;

export const allStintTemplatesComponent = (
  props: allStintTemplatesComponentProps,
) => (
  <ApolloReactComponents.Query<
    allStintTemplatesQuery,
    allStintTemplatesQueryVariables
  >
    query={allStintTemplatesDocument}
    {...props}
  />
);

export type allStintTemplatesProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    allStintTemplatesQuery,
    allStintTemplatesQueryVariables
  >;
} &
  TChildProps;
export function withallStintTemplates<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    allStintTemplatesQuery,
    allStintTemplatesQueryVariables,
    allStintTemplatesProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    allStintTemplatesQuery,
    allStintTemplatesQueryVariables,
    allStintTemplatesProps<TChildProps, TDataName>
  >(allStintTemplatesDocument, {
    alias: 'allStintTemplates',
    ...operationOptions,
  });
}

/**
 * __useallStintTemplatesQuery__
 *
 * To run a query within a React component, call `useallStintTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useallStintTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useallStintTemplatesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useallStintTemplatesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    allStintTemplatesQuery,
    allStintTemplatesQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    allStintTemplatesQuery,
    allStintTemplatesQueryVariables
  >(allStintTemplatesDocument, baseOptions);
}
export function useallStintTemplatesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    allStintTemplatesQuery,
    allStintTemplatesQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    allStintTemplatesQuery,
    allStintTemplatesQueryVariables
  >(allStintTemplatesDocument, baseOptions);
}
export type allStintTemplatesQueryHookResult = ReturnType<
  typeof useallStintTemplatesQuery
>;
export type allStintTemplatesLazyQueryHookResult = ReturnType<
  typeof useallStintTemplatesLazyQuery
>;
export type allStintTemplatesQueryResult = ApolloReactCommon.QueryResult<
  allStintTemplatesQuery,
  allStintTemplatesQueryVariables
>;
export const StintTemplateDocument = gql`
  query StintTemplate($id: ID!) {
    data: StintTemplate(id: $id) {
      ...AdminSingleStintTemplateFields
    }
  }
  ${AdminSingleStintTemplateFieldsFragmentDoc}
`;
export type StintTemplateComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    StintTemplateQuery,
    StintTemplateQueryVariables
  >,
  'query'
> &
  (
    | { variables: StintTemplateQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const StintTemplateComponent = (props: StintTemplateComponentProps) => (
  <ApolloReactComponents.Query<StintTemplateQuery, StintTemplateQueryVariables>
    query={StintTemplateDocument}
    {...props}
  />
);

export type StintTemplateProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    StintTemplateQuery,
    StintTemplateQueryVariables
  >;
} &
  TChildProps;
export function withStintTemplate<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    StintTemplateQuery,
    StintTemplateQueryVariables,
    StintTemplateProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    StintTemplateQuery,
    StintTemplateQueryVariables,
    StintTemplateProps<TChildProps, TDataName>
  >(StintTemplateDocument, {
    alias: 'stintTemplate',
    ...operationOptions,
  });
}

/**
 * __useStintTemplateQuery__
 *
 * To run a query within a React component, call `useStintTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useStintTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStintTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStintTemplateQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    StintTemplateQuery,
    StintTemplateQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    StintTemplateQuery,
    StintTemplateQueryVariables
  >(StintTemplateDocument, baseOptions);
}
export function useStintTemplateLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    StintTemplateQuery,
    StintTemplateQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    StintTemplateQuery,
    StintTemplateQueryVariables
  >(StintTemplateDocument, baseOptions);
}
export type StintTemplateQueryHookResult = ReturnType<
  typeof useStintTemplateQuery
>;
export type StintTemplateLazyQueryHookResult = ReturnType<
  typeof useStintTemplateLazyQuery
>;
export type StintTemplateQueryResult = ApolloReactCommon.QueryResult<
  StintTemplateQuery,
  StintTemplateQueryVariables
>;
export const updateStintTemplateDocument = gql`
  mutation updateStintTemplate($input: UpdateStintTemplateInput!) {
    data: updateStintTemplate(input: $input) {
      ...AdminSingleStintTemplateFields
    }
  }
  ${AdminSingleStintTemplateFieldsFragmentDoc}
`;
export type updateStintTemplateMutationFn = ApolloReactCommon.MutationFunction<
  updateStintTemplateMutation,
  updateStintTemplateMutationVariables
>;
export type updateStintTemplateComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    updateStintTemplateMutation,
    updateStintTemplateMutationVariables
  >,
  'mutation'
>;

export const updateStintTemplateComponent = (
  props: updateStintTemplateComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    updateStintTemplateMutation,
    updateStintTemplateMutationVariables
  >
    mutation={updateStintTemplateDocument}
    {...props}
  />
);

export type updateStintTemplateProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    updateStintTemplateMutation,
    updateStintTemplateMutationVariables
  >;
} &
  TChildProps;
export function withupdateStintTemplate<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    updateStintTemplateMutation,
    updateStintTemplateMutationVariables,
    updateStintTemplateProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    updateStintTemplateMutation,
    updateStintTemplateMutationVariables,
    updateStintTemplateProps<TChildProps, TDataName>
  >(updateStintTemplateDocument, {
    alias: 'updateStintTemplate',
    ...operationOptions,
  });
}

/**
 * __useupdateStintTemplateMutation__
 *
 * To run a mutation, you first call `useupdateStintTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useupdateStintTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStintTemplateMutation, { data, loading, error }] = useupdateStintTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useupdateStintTemplateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    updateStintTemplateMutation,
    updateStintTemplateMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    updateStintTemplateMutation,
    updateStintTemplateMutationVariables
  >(updateStintTemplateDocument, baseOptions);
}
export type updateStintTemplateMutationHookResult = ReturnType<
  typeof useupdateStintTemplateMutation
>;
export type updateStintTemplateMutationResult = ApolloReactCommon.MutationResult<updateStintTemplateMutation>;
export type updateStintTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  updateStintTemplateMutation,
  updateStintTemplateMutationVariables
>;
export const allStintopediasDocument = gql`
  query allStintopedias(
    $filter: StintopediaFilter
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
  ) {
    items: allStintopedias(
      filter: $filter
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      ...AdminStintopediaFields
    }
    total: _allStintopediasMeta(
      filter: $filter
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      count
      __typename
    }
  }
  ${AdminStintopediaFieldsFragmentDoc}
`;
export type allStintopediasComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    allStintopediasQuery,
    allStintopediasQueryVariables
  >,
  'query'
>;

export const allStintopediasComponent = (
  props: allStintopediasComponentProps,
) => (
  <ApolloReactComponents.Query<
    allStintopediasQuery,
    allStintopediasQueryVariables
  >
    query={allStintopediasDocument}
    {...props}
  />
);

export type allStintopediasProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    allStintopediasQuery,
    allStintopediasQueryVariables
  >;
} &
  TChildProps;
export function withallStintopedias<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    allStintopediasQuery,
    allStintopediasQueryVariables,
    allStintopediasProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    allStintopediasQuery,
    allStintopediasQueryVariables,
    allStintopediasProps<TChildProps, TDataName>
  >(allStintopediasDocument, {
    alias: 'allStintopedias',
    ...operationOptions,
  });
}

/**
 * __useallStintopediasQuery__
 *
 * To run a query within a React component, call `useallStintopediasQuery` and pass it any options that fit your needs.
 * When your component renders, `useallStintopediasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useallStintopediasQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *   },
 * });
 */
export function useallStintopediasQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    allStintopediasQuery,
    allStintopediasQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    allStintopediasQuery,
    allStintopediasQueryVariables
  >(allStintopediasDocument, baseOptions);
}
export function useallStintopediasLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    allStintopediasQuery,
    allStintopediasQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    allStintopediasQuery,
    allStintopediasQueryVariables
  >(allStintopediasDocument, baseOptions);
}
export type allStintopediasQueryHookResult = ReturnType<
  typeof useallStintopediasQuery
>;
export type allStintopediasLazyQueryHookResult = ReturnType<
  typeof useallStintopediasLazyQuery
>;
export type allStintopediasQueryResult = ApolloReactCommon.QueryResult<
  allStintopediasQuery,
  allStintopediasQueryVariables
>;
export const StintopediaDocument = gql`
  query Stintopedia($id: ID!) {
    data: Stintopedia(id: $id) {
      ...AdminStintopediaFields
    }
  }
  ${AdminStintopediaFieldsFragmentDoc}
`;
export type StintopediaComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    StintopediaQuery,
    StintopediaQueryVariables
  >,
  'query'
> &
  (
    | { variables: StintopediaQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const StintopediaComponent = (props: StintopediaComponentProps) => (
  <ApolloReactComponents.Query<StintopediaQuery, StintopediaQueryVariables>
    query={StintopediaDocument}
    {...props}
  />
);

export type StintopediaProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    StintopediaQuery,
    StintopediaQueryVariables
  >;
} &
  TChildProps;
export function withStintopedia<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    StintopediaQuery,
    StintopediaQueryVariables,
    StintopediaProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    StintopediaQuery,
    StintopediaQueryVariables,
    StintopediaProps<TChildProps, TDataName>
  >(StintopediaDocument, {
    alias: 'stintopedia',
    ...operationOptions,
  });
}

/**
 * __useStintopediaQuery__
 *
 * To run a query within a React component, call `useStintopediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useStintopediaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStintopediaQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStintopediaQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    StintopediaQuery,
    StintopediaQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<StintopediaQuery, StintopediaQueryVariables>(
    StintopediaDocument,
    baseOptions,
  );
}
export function useStintopediaLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    StintopediaQuery,
    StintopediaQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    StintopediaQuery,
    StintopediaQueryVariables
  >(StintopediaDocument, baseOptions);
}
export type StintopediaQueryHookResult = ReturnType<typeof useStintopediaQuery>;
export type StintopediaLazyQueryHookResult = ReturnType<
  typeof useStintopediaLazyQuery
>;
export type StintopediaQueryResult = ApolloReactCommon.QueryResult<
  StintopediaQuery,
  StintopediaQueryVariables
>;
export const updateStintopediaDocument = gql`
  mutation updateStintopedia(
    $businessId: ID!
    $aboutUs: String
    $accessToAnnualPlAccounts: Boolean
    $accessToRotaExamples: Boolean
    $accessToTransactionSalesData: Boolean
    $agreedTakePartInMarketingInitiatives: Boolean
    $agreedToContinuePostingIfWeSeeDesireResults: Boolean
    $agreedToOperateStaffingRota: Boolean
    $agreedToReferFriendsIfFinancialMetricsImproved: Boolean
    $businessLogo: String
    $characteristicIds: [ID]
    $donts: [String]
    $dos: [String]
    $emoji: String
    $exceptionIds: [ID]
    $facebook: String
    $generalPhoto1: String
    $generalPhoto2: String
    $generalPhoto3: String
    $generalPhoto4: String
    $instagram: String
    $internalNotes: String
    $linkedin: String
    $onboardingComplete: Boolean
    $photoOfEntrance: String
    $preferredLanguageIds: [ID]
    $prepVideoUrl: String
    $qrCodeLocation: String
    $teamStructure: String
    $website: String
  ) {
    data: updateStintopedia(
      businessId: $businessId
      aboutUs: $aboutUs
      accessToAnnualPlAccounts: $accessToAnnualPlAccounts
      accessToRotaExamples: $accessToRotaExamples
      accessToTransactionSalesData: $accessToTransactionSalesData
      agreedTakePartInMarketingInitiatives: $agreedTakePartInMarketingInitiatives
      agreedToContinuePostingIfWeSeeDesireResults: $agreedToContinuePostingIfWeSeeDesireResults
      agreedToOperateStaffingRota: $agreedToOperateStaffingRota
      agreedToReferFriendsIfFinancialMetricsImproved: $agreedToReferFriendsIfFinancialMetricsImproved
      businessLogo: $businessLogo
      characteristicIds: $characteristicIds
      donts: $donts
      dos: $dos
      emoji: $emoji
      exceptionIds: $exceptionIds
      facebook: $facebook
      generalPhoto1: $generalPhoto1
      generalPhoto2: $generalPhoto2
      generalPhoto3: $generalPhoto3
      generalPhoto4: $generalPhoto4
      instagram: $instagram
      internalNotes: $internalNotes
      linkedin: $linkedin
      onboardingComplete: $onboardingComplete
      photoOfEntrance: $photoOfEntrance
      preferredLanguageIds: $preferredLanguageIds
      prepVideoUrl: $prepVideoUrl
      qrCodeLocation: $qrCodeLocation
      teamStructure: $teamStructure
      website: $website
    ) {
      ...AdminStintopediaFields
    }
  }
  ${AdminStintopediaFieldsFragmentDoc}
`;
export type updateStintopediaMutationFn = ApolloReactCommon.MutationFunction<
  updateStintopediaMutation,
  updateStintopediaMutationVariables
>;
export type updateStintopediaComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    updateStintopediaMutation,
    updateStintopediaMutationVariables
  >,
  'mutation'
>;

export const updateStintopediaComponent = (
  props: updateStintopediaComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    updateStintopediaMutation,
    updateStintopediaMutationVariables
  >
    mutation={updateStintopediaDocument}
    {...props}
  />
);

export type updateStintopediaProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    updateStintopediaMutation,
    updateStintopediaMutationVariables
  >;
} &
  TChildProps;
export function withupdateStintopedia<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    updateStintopediaMutation,
    updateStintopediaMutationVariables,
    updateStintopediaProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    updateStintopediaMutation,
    updateStintopediaMutationVariables,
    updateStintopediaProps<TChildProps, TDataName>
  >(updateStintopediaDocument, {
    alias: 'updateStintopedia',
    ...operationOptions,
  });
}

/**
 * __useupdateStintopediaMutation__
 *
 * To run a mutation, you first call `useupdateStintopediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useupdateStintopediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStintopediaMutation, { data, loading, error }] = useupdateStintopediaMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      aboutUs: // value for 'aboutUs'
 *      accessToAnnualPlAccounts: // value for 'accessToAnnualPlAccounts'
 *      accessToRotaExamples: // value for 'accessToRotaExamples'
 *      accessToTransactionSalesData: // value for 'accessToTransactionSalesData'
 *      agreedTakePartInMarketingInitiatives: // value for 'agreedTakePartInMarketingInitiatives'
 *      agreedToContinuePostingIfWeSeeDesireResults: // value for 'agreedToContinuePostingIfWeSeeDesireResults'
 *      agreedToOperateStaffingRota: // value for 'agreedToOperateStaffingRota'
 *      agreedToReferFriendsIfFinancialMetricsImproved: // value for 'agreedToReferFriendsIfFinancialMetricsImproved'
 *      businessLogo: // value for 'businessLogo'
 *      characteristicIds: // value for 'characteristicIds'
 *      donts: // value for 'donts'
 *      dos: // value for 'dos'
 *      emoji: // value for 'emoji'
 *      exceptionIds: // value for 'exceptionIds'
 *      facebook: // value for 'facebook'
 *      generalPhoto1: // value for 'generalPhoto1'
 *      generalPhoto2: // value for 'generalPhoto2'
 *      generalPhoto3: // value for 'generalPhoto3'
 *      generalPhoto4: // value for 'generalPhoto4'
 *      instagram: // value for 'instagram'
 *      internalNotes: // value for 'internalNotes'
 *      linkedin: // value for 'linkedin'
 *      onboardingComplete: // value for 'onboardingComplete'
 *      photoOfEntrance: // value for 'photoOfEntrance'
 *      preferredLanguageIds: // value for 'preferredLanguageIds'
 *      prepVideoUrl: // value for 'prepVideoUrl'
 *      qrCodeLocation: // value for 'qrCodeLocation'
 *      teamStructure: // value for 'teamStructure'
 *      website: // value for 'website'
 *   },
 * });
 */
export function useupdateStintopediaMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    updateStintopediaMutation,
    updateStintopediaMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    updateStintopediaMutation,
    updateStintopediaMutationVariables
  >(updateStintopediaDocument, baseOptions);
}
export type updateStintopediaMutationHookResult = ReturnType<
  typeof useupdateStintopediaMutation
>;
export type updateStintopediaMutationResult = ApolloReactCommon.MutationResult<updateStintopediaMutation>;
export type updateStintopediaMutationOptions = ApolloReactCommon.BaseMutationOptions<
  updateStintopediaMutation,
  updateStintopediaMutationVariables
>;
export const StudentUserDocument = gql`
  query StudentUser($id: ID!) {
    mono: studentUser(userId: $id) {
      id
      firstName
      lastName
      email
      emailVerified
      enabled
      attributes {
        name
        value
      }
      user {
        id
        email
        secondaryEmail
        locationSetting
        profilePhotoUrl
        phoneNumber
        deactivated
        isVerifiedByStint
        permanentDeactivated
        appVersion
        dateOfBirth
        exceptions {
          id
          name
        }
        studentProfile {
          id
          university_id
          emergencyContactName
          emergencyContactNumber
          emergencyContactRelation
          employeeStatement
          studentLoanPlan
          strikesAwarded
          paymentMethods {
            accountName
            accountNumber
            sortCode
            __typename
          }
          __typename
        }
        publicRating
        canSeeSurge
        __typename
      }
    }
    rightToWork: getStinterRightToWorkStatus(stinterID: $id) {
      until
      weeklyCap
      approvedAt
    }
  }
`;
export type StudentUserComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    StudentUserQuery,
    StudentUserQueryVariables
  >,
  'query'
> &
  (
    | { variables: StudentUserQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const StudentUserComponent = (props: StudentUserComponentProps) => (
  <ApolloReactComponents.Query<StudentUserQuery, StudentUserQueryVariables>
    query={StudentUserDocument}
    {...props}
  />
);

export type StudentUserProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    StudentUserQuery,
    StudentUserQueryVariables
  >;
} &
  TChildProps;
export function withStudentUser<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    StudentUserQuery,
    StudentUserQueryVariables,
    StudentUserProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    StudentUserQuery,
    StudentUserQueryVariables,
    StudentUserProps<TChildProps, TDataName>
  >(StudentUserDocument, {
    alias: 'studentUser',
    ...operationOptions,
  });
}

/**
 * __useStudentUserQuery__
 *
 * To run a query within a React component, call `useStudentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStudentUserQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    StudentUserQuery,
    StudentUserQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<StudentUserQuery, StudentUserQueryVariables>(
    StudentUserDocument,
    baseOptions,
  );
}
export function useStudentUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    StudentUserQuery,
    StudentUserQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    StudentUserQuery,
    StudentUserQueryVariables
  >(StudentUserDocument, baseOptions);
}
export type StudentUserQueryHookResult = ReturnType<typeof useStudentUserQuery>;
export type StudentUserLazyQueryHookResult = ReturnType<
  typeof useStudentUserLazyQuery
>;
export type StudentUserQueryResult = ApolloReactCommon.QueryResult<
  StudentUserQuery,
  StudentUserQueryVariables
>;
export const allStudentUsersDocument = gql`
  query allStudentUsers(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: String
  ) {
    items: allStudentUsers(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      firstName
      lastName
      email
      attributes {
        name
        value
      }
      user {
        id
        dateOfBirth
        profilePhotoUrl
        appVersion
        __typename
      }
    }
    total: _allStudentUserMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
      __typename
    }
  }
`;
export type allStudentUsersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    allStudentUsersQuery,
    allStudentUsersQueryVariables
  >,
  'query'
>;

export const allStudentUsersComponent = (
  props: allStudentUsersComponentProps,
) => (
  <ApolloReactComponents.Query<
    allStudentUsersQuery,
    allStudentUsersQueryVariables
  >
    query={allStudentUsersDocument}
    {...props}
  />
);

export type allStudentUsersProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    allStudentUsersQuery,
    allStudentUsersQueryVariables
  >;
} &
  TChildProps;
export function withallStudentUsers<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    allStudentUsersQuery,
    allStudentUsersQueryVariables,
    allStudentUsersProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    allStudentUsersQuery,
    allStudentUsersQueryVariables,
    allStudentUsersProps<TChildProps, TDataName>
  >(allStudentUsersDocument, {
    alias: 'allStudentUsers',
    ...operationOptions,
  });
}

/**
 * __useallStudentUsersQuery__
 *
 * To run a query within a React component, call `useallStudentUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useallStudentUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useallStudentUsersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useallStudentUsersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    allStudentUsersQuery,
    allStudentUsersQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    allStudentUsersQuery,
    allStudentUsersQueryVariables
  >(allStudentUsersDocument, baseOptions);
}
export function useallStudentUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    allStudentUsersQuery,
    allStudentUsersQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    allStudentUsersQuery,
    allStudentUsersQueryVariables
  >(allStudentUsersDocument, baseOptions);
}
export type allStudentUsersQueryHookResult = ReturnType<
  typeof useallStudentUsersQuery
>;
export type allStudentUsersLazyQueryHookResult = ReturnType<
  typeof useallStudentUsersLazyQuery
>;
export type allStudentUsersQueryResult = ApolloReactCommon.QueryResult<
  allStudentUsersQuery,
  allStudentUsersQueryVariables
>;
export const allStudentReviewsDocument = gql`
  query allStudentReviews(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: StudentReviewFilter
  ) {
    items: allStudentReviews(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      __typename
      ...AdminStudentReviewFields
    }
    total: _allStudentReviewsMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
      __typename
    }
  }
  ${AdminStudentReviewFieldsFragmentDoc}
`;
export type allStudentReviewsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    allStudentReviewsQuery,
    allStudentReviewsQueryVariables
  >,
  'query'
>;

export const allStudentReviewsComponent = (
  props: allStudentReviewsComponentProps,
) => (
  <ApolloReactComponents.Query<
    allStudentReviewsQuery,
    allStudentReviewsQueryVariables
  >
    query={allStudentReviewsDocument}
    {...props}
  />
);

export type allStudentReviewsProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    allStudentReviewsQuery,
    allStudentReviewsQueryVariables
  >;
} &
  TChildProps;
export function withallStudentReviews<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    allStudentReviewsQuery,
    allStudentReviewsQueryVariables,
    allStudentReviewsProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    allStudentReviewsQuery,
    allStudentReviewsQueryVariables,
    allStudentReviewsProps<TChildProps, TDataName>
  >(allStudentReviewsDocument, {
    alias: 'allStudentReviews',
    ...operationOptions,
  });
}

/**
 * __useallStudentReviewsQuery__
 *
 * To run a query within a React component, call `useallStudentReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useallStudentReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useallStudentReviewsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useallStudentReviewsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    allStudentReviewsQuery,
    allStudentReviewsQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    allStudentReviewsQuery,
    allStudentReviewsQueryVariables
  >(allStudentReviewsDocument, baseOptions);
}
export function useallStudentReviewsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    allStudentReviewsQuery,
    allStudentReviewsQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    allStudentReviewsQuery,
    allStudentReviewsQueryVariables
  >(allStudentReviewsDocument, baseOptions);
}
export type allStudentReviewsQueryHookResult = ReturnType<
  typeof useallStudentReviewsQuery
>;
export type allStudentReviewsLazyQueryHookResult = ReturnType<
  typeof useallStudentReviewsLazyQuery
>;
export type allStudentReviewsQueryResult = ApolloReactCommon.QueryResult<
  allStudentReviewsQuery,
  allStudentReviewsQueryVariables
>;
export const StudentReviewDocument = gql`
  query StudentReview($id: ID!) {
    data: StudentReview(id: $id) {
      __typename
      ...AdminStudentReviewFields
    }
  }
  ${AdminStudentReviewFieldsFragmentDoc}
`;
export type StudentReviewComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    StudentReviewQuery,
    StudentReviewQueryVariables
  >,
  'query'
> &
  (
    | { variables: StudentReviewQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const StudentReviewComponent = (props: StudentReviewComponentProps) => (
  <ApolloReactComponents.Query<StudentReviewQuery, StudentReviewQueryVariables>
    query={StudentReviewDocument}
    {...props}
  />
);

export type StudentReviewProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    StudentReviewQuery,
    StudentReviewQueryVariables
  >;
} &
  TChildProps;
export function withStudentReview<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    StudentReviewQuery,
    StudentReviewQueryVariables,
    StudentReviewProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    StudentReviewQuery,
    StudentReviewQueryVariables,
    StudentReviewProps<TChildProps, TDataName>
  >(StudentReviewDocument, {
    alias: 'studentReview',
    ...operationOptions,
  });
}

/**
 * __useStudentReviewQuery__
 *
 * To run a query within a React component, call `useStudentReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentReviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStudentReviewQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    StudentReviewQuery,
    StudentReviewQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    StudentReviewQuery,
    StudentReviewQueryVariables
  >(StudentReviewDocument, baseOptions);
}
export function useStudentReviewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    StudentReviewQuery,
    StudentReviewQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    StudentReviewQuery,
    StudentReviewQueryVariables
  >(StudentReviewDocument, baseOptions);
}
export type StudentReviewQueryHookResult = ReturnType<
  typeof useStudentReviewQuery
>;
export type StudentReviewLazyQueryHookResult = ReturnType<
  typeof useStudentReviewLazyQuery
>;
export type StudentReviewQueryResult = ApolloReactCommon.QueryResult<
  StudentReviewQuery,
  StudentReviewQueryVariables
>;
export const allTagsDocument = gql`
  query allTags(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
  ) {
    items: allTags(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      ...AdminTagFields
    }
    total: _allTagsMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      count
      __typename
    }
  }
  ${AdminTagFieldsFragmentDoc}
`;
export type allTagsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    allTagsQuery,
    allTagsQueryVariables
  >,
  'query'
>;

export const allTagsComponent = (props: allTagsComponentProps) => (
  <ApolloReactComponents.Query<allTagsQuery, allTagsQueryVariables>
    query={allTagsDocument}
    {...props}
  />
);

export type allTagsProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    allTagsQuery,
    allTagsQueryVariables
  >;
} &
  TChildProps;
export function withallTags<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    allTagsQuery,
    allTagsQueryVariables,
    allTagsProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    allTagsQuery,
    allTagsQueryVariables,
    allTagsProps<TChildProps, TDataName>
  >(allTagsDocument, {
    alias: 'allTags',
    ...operationOptions,
  });
}

/**
 * __useallTagsQuery__
 *
 * To run a query within a React component, call `useallTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useallTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useallTagsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *   },
 * });
 */
export function useallTagsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    allTagsQuery,
    allTagsQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<allTagsQuery, allTagsQueryVariables>(
    allTagsDocument,
    baseOptions,
  );
}
export function useallTagsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    allTagsQuery,
    allTagsQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<allTagsQuery, allTagsQueryVariables>(
    allTagsDocument,
    baseOptions,
  );
}
export type allTagsQueryHookResult = ReturnType<typeof useallTagsQuery>;
export type allTagsLazyQueryHookResult = ReturnType<typeof useallTagsLazyQuery>;
export type allTagsQueryResult = ApolloReactCommon.QueryResult<
  allTagsQuery,
  allTagsQueryVariables
>;
export const TagDocument = gql`
  query Tag($id: ID!) {
    data: Tag(id: $id) {
      ...AdminTagFields
    }
  }
  ${AdminTagFieldsFragmentDoc}
`;
export type TagComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<TagQuery, TagQueryVariables>,
  'query'
> &
  ({ variables: TagQueryVariables; skip?: boolean } | { skip: boolean });

export const TagComponent = (props: TagComponentProps) => (
  <ApolloReactComponents.Query<TagQuery, TagQueryVariables>
    query={TagDocument}
    {...props}
  />
);

export type TagProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<TagQuery, TagQueryVariables>;
} &
  TChildProps;
export function withTag<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    TagQuery,
    TagQueryVariables,
    TagProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    TagQuery,
    TagQueryVariables,
    TagProps<TChildProps, TDataName>
  >(TagDocument, {
    alias: 'tag',
    ...operationOptions,
  });
}

/**
 * __useTagQuery__
 *
 * To run a query within a React component, call `useTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTagQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<TagQuery, TagQueryVariables>,
) {
  return ApolloReactHooks.useQuery<TagQuery, TagQueryVariables>(
    TagDocument,
    baseOptions,
  );
}
export function useTagLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TagQuery,
    TagQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<TagQuery, TagQueryVariables>(
    TagDocument,
    baseOptions,
  );
}
export type TagQueryHookResult = ReturnType<typeof useTagQuery>;
export type TagLazyQueryHookResult = ReturnType<typeof useTagLazyQuery>;
export type TagQueryResult = ApolloReactCommon.QueryResult<
  TagQuery,
  TagQueryVariables
>;
export const createTagDocument = gql`
  mutation createTag($name: String!, $description: String) {
    data: createTag(name: $name, description: $description) {
      ...AdminTagFields
    }
  }
  ${AdminTagFieldsFragmentDoc}
`;
export type createTagMutationFn = ApolloReactCommon.MutationFunction<
  createTagMutation,
  createTagMutationVariables
>;
export type createTagComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    createTagMutation,
    createTagMutationVariables
  >,
  'mutation'
>;

export const createTagComponent = (props: createTagComponentProps) => (
  <ApolloReactComponents.Mutation<createTagMutation, createTagMutationVariables>
    mutation={createTagDocument}
    {...props}
  />
);

export type createTagProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    createTagMutation,
    createTagMutationVariables
  >;
} &
  TChildProps;
export function withcreateTag<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    createTagMutation,
    createTagMutationVariables,
    createTagProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    createTagMutation,
    createTagMutationVariables,
    createTagProps<TChildProps, TDataName>
  >(createTagDocument, {
    alias: 'createTag',
    ...operationOptions,
  });
}

/**
 * __usecreateTagMutation__
 *
 * To run a mutation, you first call `usecreateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usecreateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagMutation, { data, loading, error }] = usecreateTagMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function usecreateTagMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    createTagMutation,
    createTagMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    createTagMutation,
    createTagMutationVariables
  >(createTagDocument, baseOptions);
}
export type createTagMutationHookResult = ReturnType<
  typeof usecreateTagMutation
>;
export type createTagMutationResult = ApolloReactCommon.MutationResult<createTagMutation>;
export type createTagMutationOptions = ApolloReactCommon.BaseMutationOptions<
  createTagMutation,
  createTagMutationVariables
>;
export const updateTagDocument = gql`
  mutation updateTag($id: ID!, $name: String!, $description: String) {
    data: updateTag(id: $id, name: $name, description: $description) {
      ...AdminTagFields
    }
  }
  ${AdminTagFieldsFragmentDoc}
`;
export type updateTagMutationFn = ApolloReactCommon.MutationFunction<
  updateTagMutation,
  updateTagMutationVariables
>;
export type updateTagComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    updateTagMutation,
    updateTagMutationVariables
  >,
  'mutation'
>;

export const updateTagComponent = (props: updateTagComponentProps) => (
  <ApolloReactComponents.Mutation<updateTagMutation, updateTagMutationVariables>
    mutation={updateTagDocument}
    {...props}
  />
);

export type updateTagProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    updateTagMutation,
    updateTagMutationVariables
  >;
} &
  TChildProps;
export function withupdateTag<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    updateTagMutation,
    updateTagMutationVariables,
    updateTagProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    updateTagMutation,
    updateTagMutationVariables,
    updateTagProps<TChildProps, TDataName>
  >(updateTagDocument, {
    alias: 'updateTag',
    ...operationOptions,
  });
}

/**
 * __useupdateTagMutation__
 *
 * To run a mutation, you first call `useupdateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useupdateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTagMutation, { data, loading, error }] = useupdateTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useupdateTagMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    updateTagMutation,
    updateTagMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    updateTagMutation,
    updateTagMutationVariables
  >(updateTagDocument, baseOptions);
}
export type updateTagMutationHookResult = ReturnType<
  typeof useupdateTagMutation
>;
export type updateTagMutationResult = ApolloReactCommon.MutationResult<updateTagMutation>;
export type updateTagMutationOptions = ApolloReactCommon.BaseMutationOptions<
  updateTagMutation,
  updateTagMutationVariables
>;
export const allStintTasksDocument = gql`
  query allStintTasks(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: StintTaskFilter
  ) {
    items: allStintTasks(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      ...AdminStintTaskFields
    }
    total: _allStintTasksMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
      __typename
    }
  }
  ${AdminStintTaskFieldsFragmentDoc}
`;
export type allStintTasksComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    allStintTasksQuery,
    allStintTasksQueryVariables
  >,
  'query'
>;

export const allStintTasksComponent = (props: allStintTasksComponentProps) => (
  <ApolloReactComponents.Query<allStintTasksQuery, allStintTasksQueryVariables>
    query={allStintTasksDocument}
    {...props}
  />
);

export type allStintTasksProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    allStintTasksQuery,
    allStintTasksQueryVariables
  >;
} &
  TChildProps;
export function withallStintTasks<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    allStintTasksQuery,
    allStintTasksQueryVariables,
    allStintTasksProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    allStintTasksQuery,
    allStintTasksQueryVariables,
    allStintTasksProps<TChildProps, TDataName>
  >(allStintTasksDocument, {
    alias: 'allStintTasks',
    ...operationOptions,
  });
}

/**
 * __useallStintTasksQuery__
 *
 * To run a query within a React component, call `useallStintTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useallStintTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useallStintTasksQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useallStintTasksQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    allStintTasksQuery,
    allStintTasksQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    allStintTasksQuery,
    allStintTasksQueryVariables
  >(allStintTasksDocument, baseOptions);
}
export function useallStintTasksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    allStintTasksQuery,
    allStintTasksQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    allStintTasksQuery,
    allStintTasksQueryVariables
  >(allStintTasksDocument, baseOptions);
}
export type allStintTasksQueryHookResult = ReturnType<
  typeof useallStintTasksQuery
>;
export type allStintTasksLazyQueryHookResult = ReturnType<
  typeof useallStintTasksLazyQuery
>;
export type allStintTasksQueryResult = ApolloReactCommon.QueryResult<
  allStintTasksQuery,
  allStintTasksQueryVariables
>;
export const StintTaskDocument = gql`
  query StintTask($id: ID!) {
    data: StintTask(id: $id) {
      ...AdminStintTaskFields
    }
  }
  ${AdminStintTaskFieldsFragmentDoc}
`;
export type StintTaskComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    StintTaskQuery,
    StintTaskQueryVariables
  >,
  'query'
> &
  ({ variables: StintTaskQueryVariables; skip?: boolean } | { skip: boolean });

export const StintTaskComponent = (props: StintTaskComponentProps) => (
  <ApolloReactComponents.Query<StintTaskQuery, StintTaskQueryVariables>
    query={StintTaskDocument}
    {...props}
  />
);

export type StintTaskProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    StintTaskQuery,
    StintTaskQueryVariables
  >;
} &
  TChildProps;
export function withStintTask<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    StintTaskQuery,
    StintTaskQueryVariables,
    StintTaskProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    StintTaskQuery,
    StintTaskQueryVariables,
    StintTaskProps<TChildProps, TDataName>
  >(StintTaskDocument, {
    alias: 'stintTask',
    ...operationOptions,
  });
}

/**
 * __useStintTaskQuery__
 *
 * To run a query within a React component, call `useStintTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useStintTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStintTaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStintTaskQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    StintTaskQuery,
    StintTaskQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<StintTaskQuery, StintTaskQueryVariables>(
    StintTaskDocument,
    baseOptions,
  );
}
export function useStintTaskLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    StintTaskQuery,
    StintTaskQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<StintTaskQuery, StintTaskQueryVariables>(
    StintTaskDocument,
    baseOptions,
  );
}
export type StintTaskQueryHookResult = ReturnType<typeof useStintTaskQuery>;
export type StintTaskLazyQueryHookResult = ReturnType<
  typeof useStintTaskLazyQuery
>;
export type StintTaskQueryResult = ApolloReactCommon.QueryResult<
  StintTaskQuery,
  StintTaskQueryVariables
>;
export const updateStintTaskDocument = gql`
  mutation updateStintTask(
    $id: ID!
    $name: String
    $taskType: String
    $topTips: [TaskTopTipInput]
  ) {
    data: updateStintTask(
      id: $id
      name: $name
      taskType: $taskType
      topTips: $topTips
    ) {
      ...AdminStintTaskFields
    }
  }
  ${AdminStintTaskFieldsFragmentDoc}
`;
export type updateStintTaskMutationFn = ApolloReactCommon.MutationFunction<
  updateStintTaskMutation,
  updateStintTaskMutationVariables
>;
export type updateStintTaskComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    updateStintTaskMutation,
    updateStintTaskMutationVariables
  >,
  'mutation'
>;

export const updateStintTaskComponent = (
  props: updateStintTaskComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    updateStintTaskMutation,
    updateStintTaskMutationVariables
  >
    mutation={updateStintTaskDocument}
    {...props}
  />
);

export type updateStintTaskProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    updateStintTaskMutation,
    updateStintTaskMutationVariables
  >;
} &
  TChildProps;
export function withupdateStintTask<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    updateStintTaskMutation,
    updateStintTaskMutationVariables,
    updateStintTaskProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    updateStintTaskMutation,
    updateStintTaskMutationVariables,
    updateStintTaskProps<TChildProps, TDataName>
  >(updateStintTaskDocument, {
    alias: 'updateStintTask',
    ...operationOptions,
  });
}

/**
 * __useupdateStintTaskMutation__
 *
 * To run a mutation, you first call `useupdateStintTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useupdateStintTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStintTaskMutation, { data, loading, error }] = useupdateStintTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      taskType: // value for 'taskType'
 *      topTips: // value for 'topTips'
 *   },
 * });
 */
export function useupdateStintTaskMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    updateStintTaskMutation,
    updateStintTaskMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    updateStintTaskMutation,
    updateStintTaskMutationVariables
  >(updateStintTaskDocument, baseOptions);
}
export type updateStintTaskMutationHookResult = ReturnType<
  typeof useupdateStintTaskMutation
>;
export type updateStintTaskMutationResult = ApolloReactCommon.MutationResult<updateStintTaskMutation>;
export type updateStintTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<
  updateStintTaskMutation,
  updateStintTaskMutationVariables
>;
export const createStintTaskDocument = gql`
  mutation createStintTask($name: String!, $taskType: String) {
    data: createStintTask(name: $name, taskType: $taskType) {
      ...AdminStintTaskFields
    }
  }
  ${AdminStintTaskFieldsFragmentDoc}
`;
export type createStintTaskMutationFn = ApolloReactCommon.MutationFunction<
  createStintTaskMutation,
  createStintTaskMutationVariables
>;
export type createStintTaskComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    createStintTaskMutation,
    createStintTaskMutationVariables
  >,
  'mutation'
>;

export const createStintTaskComponent = (
  props: createStintTaskComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    createStintTaskMutation,
    createStintTaskMutationVariables
  >
    mutation={createStintTaskDocument}
    {...props}
  />
);

export type createStintTaskProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    createStintTaskMutation,
    createStintTaskMutationVariables
  >;
} &
  TChildProps;
export function withcreateStintTask<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    createStintTaskMutation,
    createStintTaskMutationVariables,
    createStintTaskProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    createStintTaskMutation,
    createStintTaskMutationVariables,
    createStintTaskProps<TChildProps, TDataName>
  >(createStintTaskDocument, {
    alias: 'createStintTask',
    ...operationOptions,
  });
}

/**
 * __usecreateStintTaskMutation__
 *
 * To run a mutation, you first call `usecreateStintTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usecreateStintTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStintTaskMutation, { data, loading, error }] = usecreateStintTaskMutation({
 *   variables: {
 *      name: // value for 'name'
 *      taskType: // value for 'taskType'
 *   },
 * });
 */
export function usecreateStintTaskMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    createStintTaskMutation,
    createStintTaskMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    createStintTaskMutation,
    createStintTaskMutationVariables
  >(createStintTaskDocument, baseOptions);
}
export type createStintTaskMutationHookResult = ReturnType<
  typeof usecreateStintTaskMutation
>;
export type createStintTaskMutationResult = ApolloReactCommon.MutationResult<createStintTaskMutation>;
export type createStintTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<
  createStintTaskMutation,
  createStintTaskMutationVariables
>;
export const allTopTipsDocument = gql`
  query allTopTips(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: TopTipFilter
  ) {
    items: allTopTips(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      ...AdminTopTipFields
    }
    total: _allTopTipsMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
      __typename
    }
  }
  ${AdminTopTipFieldsFragmentDoc}
`;
export type allTopTipsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    allTopTipsQuery,
    allTopTipsQueryVariables
  >,
  'query'
>;

export const allTopTipsComponent = (props: allTopTipsComponentProps) => (
  <ApolloReactComponents.Query<allTopTipsQuery, allTopTipsQueryVariables>
    query={allTopTipsDocument}
    {...props}
  />
);

export type allTopTipsProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    allTopTipsQuery,
    allTopTipsQueryVariables
  >;
} &
  TChildProps;
export function withallTopTips<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    allTopTipsQuery,
    allTopTipsQueryVariables,
    allTopTipsProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    allTopTipsQuery,
    allTopTipsQueryVariables,
    allTopTipsProps<TChildProps, TDataName>
  >(allTopTipsDocument, {
    alias: 'allTopTips',
    ...operationOptions,
  });
}

/**
 * __useallTopTipsQuery__
 *
 * To run a query within a React component, call `useallTopTipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useallTopTipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useallTopTipsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useallTopTipsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    allTopTipsQuery,
    allTopTipsQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<allTopTipsQuery, allTopTipsQueryVariables>(
    allTopTipsDocument,
    baseOptions,
  );
}
export function useallTopTipsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    allTopTipsQuery,
    allTopTipsQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    allTopTipsQuery,
    allTopTipsQueryVariables
  >(allTopTipsDocument, baseOptions);
}
export type allTopTipsQueryHookResult = ReturnType<typeof useallTopTipsQuery>;
export type allTopTipsLazyQueryHookResult = ReturnType<
  typeof useallTopTipsLazyQuery
>;
export type allTopTipsQueryResult = ApolloReactCommon.QueryResult<
  allTopTipsQuery,
  allTopTipsQueryVariables
>;
export const TopTipDocument = gql`
  query TopTip($id: ID!) {
    data: TopTip(id: $id) {
      ...AdminTopTipFields
    }
  }
  ${AdminTopTipFieldsFragmentDoc}
`;
export type TopTipComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    TopTipQuery,
    TopTipQueryVariables
  >,
  'query'
> &
  ({ variables: TopTipQueryVariables; skip?: boolean } | { skip: boolean });

export const TopTipComponent = (props: TopTipComponentProps) => (
  <ApolloReactComponents.Query<TopTipQuery, TopTipQueryVariables>
    query={TopTipDocument}
    {...props}
  />
);

export type TopTipProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    TopTipQuery,
    TopTipQueryVariables
  >;
} &
  TChildProps;
export function withTopTip<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    TopTipQuery,
    TopTipQueryVariables,
    TopTipProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    TopTipQuery,
    TopTipQueryVariables,
    TopTipProps<TChildProps, TDataName>
  >(TopTipDocument, {
    alias: 'topTip',
    ...operationOptions,
  });
}

/**
 * __useTopTipQuery__
 *
 * To run a query within a React component, call `useTopTipQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopTipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopTipQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTopTipQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    TopTipQuery,
    TopTipQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<TopTipQuery, TopTipQueryVariables>(
    TopTipDocument,
    baseOptions,
  );
}
export function useTopTipLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TopTipQuery,
    TopTipQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<TopTipQuery, TopTipQueryVariables>(
    TopTipDocument,
    baseOptions,
  );
}
export type TopTipQueryHookResult = ReturnType<typeof useTopTipQuery>;
export type TopTipLazyQueryHookResult = ReturnType<typeof useTopTipLazyQuery>;
export type TopTipQueryResult = ApolloReactCommon.QueryResult<
  TopTipQuery,
  TopTipQueryVariables
>;
export const updateTopTipDocument = gql`
  mutation updateTopTip($id: ID!, $name: String) {
    data: updateTopTip(id: $id, name: $name) {
      ...AdminTopTipFields
    }
  }
  ${AdminTopTipFieldsFragmentDoc}
`;
export type updateTopTipMutationFn = ApolloReactCommon.MutationFunction<
  updateTopTipMutation,
  updateTopTipMutationVariables
>;
export type updateTopTipComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    updateTopTipMutation,
    updateTopTipMutationVariables
  >,
  'mutation'
>;

export const updateTopTipComponent = (props: updateTopTipComponentProps) => (
  <ApolloReactComponents.Mutation<
    updateTopTipMutation,
    updateTopTipMutationVariables
  >
    mutation={updateTopTipDocument}
    {...props}
  />
);

export type updateTopTipProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    updateTopTipMutation,
    updateTopTipMutationVariables
  >;
} &
  TChildProps;
export function withupdateTopTip<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    updateTopTipMutation,
    updateTopTipMutationVariables,
    updateTopTipProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    updateTopTipMutation,
    updateTopTipMutationVariables,
    updateTopTipProps<TChildProps, TDataName>
  >(updateTopTipDocument, {
    alias: 'updateTopTip',
    ...operationOptions,
  });
}

/**
 * __useupdateTopTipMutation__
 *
 * To run a mutation, you first call `useupdateTopTipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useupdateTopTipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTopTipMutation, { data, loading, error }] = useupdateTopTipMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useupdateTopTipMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    updateTopTipMutation,
    updateTopTipMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    updateTopTipMutation,
    updateTopTipMutationVariables
  >(updateTopTipDocument, baseOptions);
}
export type updateTopTipMutationHookResult = ReturnType<
  typeof useupdateTopTipMutation
>;
export type updateTopTipMutationResult = ApolloReactCommon.MutationResult<updateTopTipMutation>;
export type updateTopTipMutationOptions = ApolloReactCommon.BaseMutationOptions<
  updateTopTipMutation,
  updateTopTipMutationVariables
>;
export const createTopTipDocument = gql`
  mutation createTopTip($name: String!, $isDefault: Boolean, $taskId: ID) {
    data: createTopTip(name: $name, isDefault: $isDefault, taskId: $taskId) {
      ...AdminTopTipFields
    }
  }
  ${AdminTopTipFieldsFragmentDoc}
`;
export type createTopTipMutationFn = ApolloReactCommon.MutationFunction<
  createTopTipMutation,
  createTopTipMutationVariables
>;
export type createTopTipComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    createTopTipMutation,
    createTopTipMutationVariables
  >,
  'mutation'
>;

export const createTopTipComponent = (props: createTopTipComponentProps) => (
  <ApolloReactComponents.Mutation<
    createTopTipMutation,
    createTopTipMutationVariables
  >
    mutation={createTopTipDocument}
    {...props}
  />
);

export type createTopTipProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    createTopTipMutation,
    createTopTipMutationVariables
  >;
} &
  TChildProps;
export function withcreateTopTip<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    createTopTipMutation,
    createTopTipMutationVariables,
    createTopTipProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    createTopTipMutation,
    createTopTipMutationVariables,
    createTopTipProps<TChildProps, TDataName>
  >(createTopTipDocument, {
    alias: 'createTopTip',
    ...operationOptions,
  });
}

/**
 * __usecreateTopTipMutation__
 *
 * To run a mutation, you first call `usecreateTopTipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usecreateTopTipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTopTipMutation, { data, loading, error }] = usecreateTopTipMutation({
 *   variables: {
 *      name: // value for 'name'
 *      isDefault: // value for 'isDefault'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function usecreateTopTipMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    createTopTipMutation,
    createTopTipMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    createTopTipMutation,
    createTopTipMutationVariables
  >(createTopTipDocument, baseOptions);
}
export type createTopTipMutationHookResult = ReturnType<
  typeof usecreateTopTipMutation
>;
export type createTopTipMutationResult = ApolloReactCommon.MutationResult<createTopTipMutation>;
export type createTopTipMutationOptions = ApolloReactCommon.BaseMutationOptions<
  createTopTipMutation,
  createTopTipMutationVariables
>;
export const allStudentOrEmployerUsersDocument = gql`
  query allStudentOrEmployerUsers(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: StintUserFilter
  ) {
    items: allStudentOrEmployerUsers(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      firstName
      lastName
      fullname
      email
    }
    total: _allStudentOrEmployerUsersMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
      __typename
    }
  }
`;
export type allStudentOrEmployerUsersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    allStudentOrEmployerUsersQuery,
    allStudentOrEmployerUsersQueryVariables
  >,
  'query'
>;

export const allStudentOrEmployerUsersComponent = (
  props: allStudentOrEmployerUsersComponentProps,
) => (
  <ApolloReactComponents.Query<
    allStudentOrEmployerUsersQuery,
    allStudentOrEmployerUsersQueryVariables
  >
    query={allStudentOrEmployerUsersDocument}
    {...props}
  />
);

export type allStudentOrEmployerUsersProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    allStudentOrEmployerUsersQuery,
    allStudentOrEmployerUsersQueryVariables
  >;
} &
  TChildProps;
export function withallStudentOrEmployerUsers<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    allStudentOrEmployerUsersQuery,
    allStudentOrEmployerUsersQueryVariables,
    allStudentOrEmployerUsersProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    allStudentOrEmployerUsersQuery,
    allStudentOrEmployerUsersQueryVariables,
    allStudentOrEmployerUsersProps<TChildProps, TDataName>
  >(allStudentOrEmployerUsersDocument, {
    alias: 'allStudentOrEmployerUsers',
    ...operationOptions,
  });
}

/**
 * __useallStudentOrEmployerUsersQuery__
 *
 * To run a query within a React component, call `useallStudentOrEmployerUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useallStudentOrEmployerUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useallStudentOrEmployerUsersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useallStudentOrEmployerUsersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    allStudentOrEmployerUsersQuery,
    allStudentOrEmployerUsersQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    allStudentOrEmployerUsersQuery,
    allStudentOrEmployerUsersQueryVariables
  >(allStudentOrEmployerUsersDocument, baseOptions);
}
export function useallStudentOrEmployerUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    allStudentOrEmployerUsersQuery,
    allStudentOrEmployerUsersQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    allStudentOrEmployerUsersQuery,
    allStudentOrEmployerUsersQueryVariables
  >(allStudentOrEmployerUsersDocument, baseOptions);
}
export type allStudentOrEmployerUsersQueryHookResult = ReturnType<
  typeof useallStudentOrEmployerUsersQuery
>;
export type allStudentOrEmployerUsersLazyQueryHookResult = ReturnType<
  typeof useallStudentOrEmployerUsersLazyQuery
>;
export type allStudentOrEmployerUsersQueryResult = ApolloReactCommon.QueryResult<
  allStudentOrEmployerUsersQuery,
  allStudentOrEmployerUsersQueryVariables
>;
export const StintUserDocument = gql`
  query StintUser($id: ID!) {
    mono: StintUser(id: $id) {
      ...AdminStintUserFields
    }
    rightToWork: getStinterRightToWorkStatus(stinterID: $id) {
      until
      weeklyCap
      approvedAt
    }
  }
  ${AdminStintUserFieldsFragmentDoc}
`;
export type StintUserComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    StintUserQuery,
    StintUserQueryVariables
  >,
  'query'
> &
  ({ variables: StintUserQueryVariables; skip?: boolean } | { skip: boolean });

export const StintUserComponent = (props: StintUserComponentProps) => (
  <ApolloReactComponents.Query<StintUserQuery, StintUserQueryVariables>
    query={StintUserDocument}
    {...props}
  />
);

export type StintUserProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    StintUserQuery,
    StintUserQueryVariables
  >;
} &
  TChildProps;
export function withStintUser<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    StintUserQuery,
    StintUserQueryVariables,
    StintUserProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    StintUserQuery,
    StintUserQueryVariables,
    StintUserProps<TChildProps, TDataName>
  >(StintUserDocument, {
    alias: 'stintUser',
    ...operationOptions,
  });
}

/**
 * __useStintUserQuery__
 *
 * To run a query within a React component, call `useStintUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useStintUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStintUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStintUserQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    StintUserQuery,
    StintUserQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<StintUserQuery, StintUserQueryVariables>(
    StintUserDocument,
    baseOptions,
  );
}
export function useStintUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    StintUserQuery,
    StintUserQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<StintUserQuery, StintUserQueryVariables>(
    StintUserDocument,
    baseOptions,
  );
}
export type StintUserQueryHookResult = ReturnType<typeof useStintUserQuery>;
export type StintUserLazyQueryHookResult = ReturnType<
  typeof useStintUserLazyQuery
>;
export type StintUserQueryResult = ApolloReactCommon.QueryResult<
  StintUserQuery,
  StintUserQueryVariables
>;
export const updateStintUserDocument = gql`
  mutation updateStintUser(
    $id: ID!
    $firstName: String
    $lastName: String
    $dateOfBirth: AWSDate
    $email: String
    $secondaryEmail: String
    $exceptions: [StintTemplateExceptionInput]
    $phoneNumber: String
    $deactivated: Boolean
    $permanentDeactivated: Boolean
    $studentProfile: StudentProfileInput
    $paymentMethod: PaymentMethodInput
  ) {
    data: updateStintUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      dateOfBirth: $dateOfBirth
      email: $email
      secondaryEmail: $secondaryEmail
      exceptions: $exceptions
      phoneNumber: $phoneNumber
      deactivated: $deactivated
      permanentDeactivated: $permanentDeactivated
      studentProfile: $studentProfile
      paymentMethod: $paymentMethod
    ) {
      ...AdminStintUserFields
    }
  }
  ${AdminStintUserFieldsFragmentDoc}
`;
export type updateStintUserMutationFn = ApolloReactCommon.MutationFunction<
  updateStintUserMutation,
  updateStintUserMutationVariables
>;
export type updateStintUserComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    updateStintUserMutation,
    updateStintUserMutationVariables
  >,
  'mutation'
>;

export const updateStintUserComponent = (
  props: updateStintUserComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    updateStintUserMutation,
    updateStintUserMutationVariables
  >
    mutation={updateStintUserDocument}
    {...props}
  />
);

export type updateStintUserProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    updateStintUserMutation,
    updateStintUserMutationVariables
  >;
} &
  TChildProps;
export function withupdateStintUser<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    updateStintUserMutation,
    updateStintUserMutationVariables,
    updateStintUserProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    updateStintUserMutation,
    updateStintUserMutationVariables,
    updateStintUserProps<TChildProps, TDataName>
  >(updateStintUserDocument, {
    alias: 'updateStintUser',
    ...operationOptions,
  });
}

/**
 * __useupdateStintUserMutation__
 *
 * To run a mutation, you first call `useupdateStintUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useupdateStintUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStintUserMutation, { data, loading, error }] = useupdateStintUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      dateOfBirth: // value for 'dateOfBirth'
 *      email: // value for 'email'
 *      secondaryEmail: // value for 'secondaryEmail'
 *      exceptions: // value for 'exceptions'
 *      phoneNumber: // value for 'phoneNumber'
 *      deactivated: // value for 'deactivated'
 *      permanentDeactivated: // value for 'permanentDeactivated'
 *      studentProfile: // value for 'studentProfile'
 *      paymentMethod: // value for 'paymentMethod'
 *   },
 * });
 */
export function useupdateStintUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    updateStintUserMutation,
    updateStintUserMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    updateStintUserMutation,
    updateStintUserMutationVariables
  >(updateStintUserDocument, baseOptions);
}
export type updateStintUserMutationHookResult = ReturnType<
  typeof useupdateStintUserMutation
>;
export type updateStintUserMutationResult = ApolloReactCommon.MutationResult<updateStintUserMutation>;
export type updateStintUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  updateStintUserMutation,
  updateStintUserMutationVariables
>;
